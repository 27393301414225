import { Fragment, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  Row,
  Stack,
  Table,
} from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { Panel, ThLoading } from '@components/elements';
import useAlert from '@hooks/useAlert';
import { workerRatingsSession } from '@company/services/rating/api';
import IBatchRateWorkerSession from '@company/types/batch-rate-worker.type';
import { IJobOptionsData } from '@th-types/jobs.type';

interface Props {
  jobOptions: IJobOptionsData | null;
  batchRateWorkerSession?: IBatchRateWorkerSession;
  setBatchRateWorkerSession: (value: IBatchRateWorkerSession) => void;
  stepTitle?: string;
  previousStep?: () => void;
  nextStep?: () => void;
  isActive?: boolean;
}

function GigInfoPreview({
  jobOptions,
  batchRateWorkerSession,
  setBatchRateWorkerSession,
  stepTitle,
  previousStep,
  nextStep,
  isActive,
}: Props) {
  const { showErrorAlert, alertMessage } = useAlert();

  const [loading, setLoading] = useState(false);
  const periodStart =
    batchRateWorkerSession && batchRateWorkerSession.periods
      ? batchRateWorkerSession?.periods[0]?.start
      : '';
  const periodEnd =
    batchRateWorkerSession && batchRateWorkerSession.periods
      ? batchRateWorkerSession?.periods[0]?.end
      : '';

  const onSubmit = async () => {
    if (batchRateWorkerSession) {
      const body: IBatchRateWorkerSession = {
        ...batchRateWorkerSession,
      };

      try {
        setLoading(true);
        const result = await workerRatingsSession(body);
        if (result) {
          if (result.ratings) {
            const ratedWorkers = batchRateWorkerSession.workers.map(
              (worker) => {
                const ratingWorker = result.ratings.find(
                  (r) => r.worker?.id === worker.id
                );
                return {
                  ...worker,
                  workerRating: {
                    ...worker.workerRating,
                    workerId: worker.id,
                    title: batchRateWorkerSession.title,
                    jobWorkerRatingId: ratingWorker?.jobWorkerRatingId,
                    assignment: ratingWorker?.assignment,
                  },
                };
              }
            );

            const updatedBatchRating = {
              ...batchRateWorkerSession,
              sessionId: result.sessionId,
              workers: ratedWorkers,
            };
            setBatchRateWorkerSession(updatedBatchRating);
          }
        }
        if (nextStep) {
          nextStep();
        }
      } catch {
        showErrorAlert(
          'Occurred an error when Trusted Herd tried to save the session for Worker Ratings'
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const onPrevious = () => {
    if (previousStep) {
      previousStep();
    }
  };

  const workersNameList = batchRateWorkerSession?.workers
    ?.map((worker) => ` ${worker?.name}`)
    .join(',  ')
    .split('  ');

  return (
    <>
      {loading && <ThLoading />}
      <Row key={stepTitle} className={isActive ? 'd-block' : 'd-none'}>
        <Col md={{ span: 6, offset: 3 }}>
          <Panel className={isMobile ? 'shadow-none' : 'panel'}>
            <Row>
              {!isMobile && (
                <Col xs={12} className="fw-bold fs-5">
                  Does this info look correct?
                </Col>
              )}
              <Col xs={12} className="mt-3">
                <Table striped borderless size="lg">
                  <tbody>
                    <tr>
                      <td className="text-start fw-bold">Event Name</td>
                      <td className="text-end">
                        {batchRateWorkerSession?.eventName || ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start fw-bold">Hiring Company</td>
                      <td className="text-end">
                        {batchRateWorkerSession?.companyName
                          ? batchRateWorkerSession?.companyName
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start fw-bold">Brand Represented</td>
                      <td className="text-end">
                        {batchRateWorkerSession?.brandRepresented
                          ? batchRateWorkerSession?.brandRepresented
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start fw-bold">Job Title</td>
                      <td className="text-end">
                        {batchRateWorkerSession?.title?.id
                          ? jobOptions?.jobTitles.find(
                              (t) => t.id === batchRateWorkerSession?.title?.id
                            )?.name
                          : ''}
                      </td>
                    </tr>
                    {batchRateWorkerSession?.otherJobTitleDescription && (
                      <tr>
                        <td className="text-start fw-bold">Other Job Title</td>
                        <td className="text-end">
                          {batchRateWorkerSession?.otherJobTitleDescription}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className="text-start fw-bold">Dates Worked</td>
                      <td className="text-end">
                        {periodStart} - {periodEnd}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start fw-bold">Workers</td>
                      <td className="text-end">
                        {isMobile
                          ? workersNameList?.map((workerName) => (
                              <div key={workerName}>
                                {workerName}
                                <br />
                              </div>
                            ))
                          : workersNameList?.map(
                              (workerName) => `${workerName}`
                            )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>
      <Container fluid className="d-flex flex-column">
        <Row className={`${isActive ? 'd-block' : 'd-none'} h-100`}>
          <Col md={{ span: 6, offset: 3 }}>
            <Stack
              direction="horizontal"
              className="d-flex justify-content-center"
              gap={3}
            >
              <Button
                type="button"
                variant="light"
                className={`fw-bold mt-4 ${isMobile ? 'w-50' : 'w-25'}`}
                onClick={onPrevious}
              >
                Go Back
              </Button>
              <Button
                type="button"
                className={`fw-bold mt-4 ${isMobile ? 'w-50' : 'w-25'}`}
                onClick={onSubmit}
                disabled={loading}
              >
                {loading ? 'Submitting' : 'Continue'}
              </Button>
            </Stack>
          </Col>
        </Row>
      </Container>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default GigInfoPreview;
