import { privateApi } from '@services/api';
import ICompanyPremiumPlans from '@th-types/company-premium-plans.type';
import IPaymentMethods from '@th-types/payment-methods.type';
import IResultResponse from '@th-types/resultResponse.type';

export const MAIN_URL = 'member/payment-methods';

export const fetchPremiumPlans = async () => {
  const result = await privateApi
    .get<ICompanyPremiumPlans>(`billing/company/plans`)
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried fetch company premium plans. ${error}`
      );
      return error.response;
    });
  return result?.data;
};

export const fetchPaymentMethods = async () => {
  const result = await privateApi
    .get<IPaymentMethods>(`${MAIN_URL}`)
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried fetch user payment methods. ${error}`
      );
      return error.response;
    });
  return result?.data;
};

export const saveNewPaymentMethod = async (paymentMethodId: string) => {
  const result = await privateApi
    .post<IResultResponse>(`${MAIN_URL}`, {
      paymentMethodId,
    })
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried to save a new credit card as payment method. ${error}`
      );
      return error.response;
    });
  return result?.data;
};

export const payPremiumSubscription = async (
  planId: string,
  paymentMethodId: string | number
) => {
  const result = await privateApi
    .post<IResultResponse>('billing/company/pay-subscription', {
      planId,
      creditCardId: paymentMethodId,
    })
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried to save a new credit card as payment method. ${error}`
      );
      return error.response;
    });
  return result?.data;
};
