import { ShiftPosition } from './job.detail.type';
import IS3File from './s3file.type';
import TimeSheet from './time-sheet.type';

export default interface DailyActivity {
  id: number;
  checkInTime: Date;
  checkOutTime?: Date;
  localizedCheckInTime?: string;
  localizedCheckOutTime?: string;
  checkInLat?: number;
  checkOutLat?: number;
  checkInLng?: number;
  checkOutLng?: number;
  checkInSelfie?: IS3File;
  checkOutSelfie?: IS3File;
  autoCheckInTime: Date;
  autoCheckOutTime?: Date;
}

export interface ActivityDetail {
  shiftPosition: ShiftPosition;
  dailyActivity?: DailyActivity;
  timeSheet?: TimeSheet;
}

export interface ActivityDetailList {
  activities: ActivityDetail[];
  isJobMember: boolean;
}

export enum ActivityStatus {
  TO_APPROVE = 'To Approve',
  IN_PROGRESS = 'In Progress',
  CANCELLED = 'Cancelled',
}
