// Calculate whether we need to render empty or half star
function getStarSize(starPosition: number, ratingValue: number) {
  if (starPosition > ratingValue) {
    const decimal = ratingValue - starPosition + 1;
    if (decimal < 0.25) return 0;
    if (decimal < 0.75) return 0.5;
    return 1;
  }
  return 0;
}

export { getStarSize };
