import React, { CSSProperties, forwardRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import './styles.css';

export interface DropdownListItemEmpty {
  label: string;
}

export interface DropdownListItemTag {
  status: string;
  className: string;
}
export interface DropdownListItem {
  header?: string;
  label: string;
  key: string;
  id: number;
  tag?: DropdownListItemTag;
  action: React.MouseEventHandler<HTMLElement> | null;
}

interface DropdownListProps {
  items?: Array<DropdownListItem> | DropdownListItemEmpty | null;
  label?: string;
  customToggle?: any;
  hidden?: boolean;
  showMenu?: boolean;
  isVariant?: boolean;
  loadData?: () => Promise<
    Array<DropdownListItem> | DropdownListItemEmpty | null
  >;
}

interface DropdownListParams {
  children: JSX.Element;
  style: CSSProperties | undefined;
  className: string;
}

const CustomMenu = forwardRef<any, DropdownListParams>(
  (
    { children, style, className }: DropdownListParams,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const isEmptyLabel = children?.props?.children?.key === 'empty-list';

    const [value, setValue] = useState('');
    const findFilterValue = (child: any, v: string) =>
      (child.props.children[0].key &&
        child.props.children[0].key.toLowerCase().includes(v.toLowerCase())) ||
      (child.props.children[1].key &&
        child.props.children[1].key.toLowerCase().includes(v.toLowerCase()));

    return (
      <div ref={ref} style={style} className={className}>
        {!isEmptyLabel && (
          <Form.Control
            autoFocus
            className="mx-3 my-2 form-control-filter-jobs"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
        )}

        <div
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <ul className="list-unstyled" style={{ marginBottom: 0 }}>
            {React.Children.toArray(children).filter(
              (child) => !value || findFilterValue(child, value)
            )}
          </ul>
        </div>
      </div>
    );
  }
);
CustomMenu.displayName = 'CustomMenu';

export default function DropdownList({
  items,
  label,
  customToggle = undefined,
  hidden = false,
  showMenu = false,
  isVariant = false,
  loadData = undefined,
}: DropdownListProps) {
  const renderListItemsEmpty = () => {
    const item = items as DropdownListItemEmpty;
    return (
      <div key={item.label}>
        <Dropdown.Item
          disabled
          className="small"
          eventKey={item.label}
          key="empty-list"
          href="#"
        >
          {item.label}
        </Dropdown.Item>
      </div>
    );
  };

  const renderListItems = () => {
    let groupHeader = '';
    const result: Array<JSX.Element> = [];
    (items as DropdownListItem[]).map((item: DropdownListItem) => {
      const dropdownItem = (
        <div key={`${item.header}-${item.id}`}>
          {item.header && groupHeader !== item.header && (
            <Dropdown.ItemText
              key={item.header || ''}
              className="small "
              style={{ backgroundColor: '#F9F1CA' }}
            >
              <strong>{item.header}</strong>
            </Dropdown.ItemText>
          )}
          <Dropdown.Item
            disabled={!item.action}
            className="small"
            eventKey={item.key}
            key={`${item.key}`}
            onClick={item.action || undefined}
            href="#"
          >
            <div className="list-item-header">
              <span className="text-wrap">{item.label}</span>
            </div>
            <div className="list-item-tag ms-2 me-1">
              {item.tag && (
                <span
                  className={`list-item-tag-badge badge ${item.tag.className}`}
                >
                  {item.tag.status}
                </span>
              )}
            </div>
          </Dropdown.Item>
        </div>
      );
      groupHeader = item.header || '';
      result.push(dropdownItem);
      return item;
    });
    return result;
  };

  return (
    <Dropdown key={label}>
      <Dropdown.Toggle
        as={customToggle}
        isVariant={isVariant}
        loadData={loadData}
        hidden={hidden}
        id="dropdown-list"
      >
        {label}
      </Dropdown.Toggle>

      {items && (
        <Dropdown.Menu as={CustomMenu} flip show={showMenu}>
          {items instanceof Array ? renderListItems() : renderListItemsEmpty()}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
}
