import { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ThLoading } from '@components/elements';
import ThPagination from '@components/ThPagination/ThPagination';
import FilterBar from '@company/components/CommunicationHub/FilterBar/FilterBar';
import ReviewsAndEndorsementsTable from '@company/components/CommunicationHub/CommunicationHubTables/ReviewsAndEndorsementsTable';
import { getReviewsAndEndorsements } from '@company/services/com-hub/api';
import {
  ComHubQueryParamsEnum,
  ComHubTabs,
  GivenReview,
} from '@company/types/comm-hub.type';
import QueryKeys from '@constants/queryKeys';
import {
  handleComHubSearch,
  handleComHubClear,
  extractComHubUrlParams,
} from '@utils/ComHubUtils';

interface ReviewsAndEndoresementsLeftProps {
  onShowWorkerProfile?: (workerId: number) => void;
  onSeeGivenReview: (review: GivenReview) => void;
  onSeeGivenEndorsement: (endorsementCaseId: number) => void;
}

function ReviewsAndEndoresementsLeft({
  onShowWorkerProfile,
  onSeeGivenReview,
  onSeeGivenEndorsement,
}: ReviewsAndEndoresementsLeftProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentOffset, setCurrentOffset] = useState(0);
  const [searchState, setSearchState] = useState<{
    searchText: string;
    dateRange: { startDate: Date | undefined; endDate: Date | undefined };
  }>();
  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize state from URL parameters
  useEffect(() => {
    if (isInitialized) return;

    const { searchState: urlSearchState, offset: urlOffset } =
      extractComHubUrlParams(searchParams);

    if (urlSearchState) {
      setSearchState(urlSearchState);
    }

    if (urlOffset !== undefined) {
      setCurrentOffset(urlOffset);
    }

    setIsInitialized(true);
  }, [searchParams, isInitialized]);

  const itemsPerPage = 20;
  const queryKeyReviewAndEndorsements = [
    QueryKeys.ComHub.GIVEN_REVIEWS,
    currentOffset,
    itemsPerPage,
    searchState,
  ];

  const { data: reviewsLeftResponse, isLoading } = useQuery({
    queryKey: queryKeyReviewAndEndorsements,
    queryFn: () =>
      getReviewsAndEndorsements(
        currentOffset,
        itemsPerPage,
        searchState?.searchText,
        searchState?.dateRange
      ),
    enabled: isInitialized,
  });

  const updateSearchParams = useCallback(
    (params: URLSearchParams) => {
      setSearchParams(params);
    },
    [setSearchParams]
  );

  const handleOnSearch = useCallback(
    (search: {
      searchText: string;
      dateRange: { startDate: Date | undefined; endDate: Date | undefined };
    }) => {
      handleComHubSearch(search, setSearchState, updateSearchParams, {
        itemsPerPage,
        setCurrentOffset,
      });
    },
    [updateSearchParams, itemsPerPage, setCurrentOffset]
  );

  const handleOnClear = useCallback(() => {
    handleComHubClear(setSearchState, updateSearchParams, {
      itemsPerPage,
      setCurrentOffset,
    });
  }, [updateSearchParams, itemsPerPage, setCurrentOffset]);

  // Update URL when offset changes
  useEffect(() => {
    if (!isInitialized) return;

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(ComHubQueryParamsEnum.OFFSET, currentOffset.toString());
    newSearchParams.set(ComHubQueryParamsEnum.MAX, itemsPerPage.toString());
    updateSearchParams(newSearchParams);
  }, [
    currentOffset,
    searchParams,
    updateSearchParams,
    isInitialized,
    itemsPerPage,
  ]);

  return (
    <>
      <FilterBar onSearch={handleOnSearch} onClear={handleOnClear} />
      {isLoading && <ThLoading />}

      {reviewsLeftResponse?.reviews ? (
        <>
          <ReviewsAndEndorsementsTable
            title={ComHubTabs.REVIEWS_AND_ENDORSEMENTS}
            givenReviews={reviewsLeftResponse.reviews}
            onSeeGivenReview={onSeeGivenReview}
            onSeeGivenEndorsement={onSeeGivenEndorsement}
            onShowWorkerProfile={onShowWorkerProfile}
          />
          <ThPagination
            currentOffset={currentOffset}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentOffset}
            totalItems={reviewsLeftResponse.pagination.count}
          />
        </>
      ) : null}
    </>
  );
}

export default ReviewsAndEndoresementsLeft;
