import { privateApi, getRequestConfig } from '@services/api';
import ITalentsData from '@company/types/talents.type';
import IFilterData from '@company/types/talent-filterdata.type';
import IResultResponse from '@th-types/resultResponse.type';
import { IWorkerJobData } from '@th-types/workerProfile.type';
import { isArray, isEmpty } from 'lodash';
import { AxiosError } from 'axios';
import IWorkerNoteCreation from '@company/types/workerNoteCreation.type';

import { IWorkerAvailabilityData } from '@company/types/workerAvailability.type';
import { IWorkerSuggestions } from '@th-types/suggestions.type';
import { IWorkerAssignment } from '@company/types/workerAssignment';
import IWorkerNotesData from '@company/types/workerNotes.type';
import IWorkerFiltersData from '@company/types/workerFilters.type';

export const MAIN_URL = 'workers';
export const NOTES_URL = 'notes';
export const ASSIGNMENTS_URL = 'assignments';
export const WORKER_SET_CUSTOM_URL = 'worker/set-profile-url';

function getEncodedParams(params: object) {
  return encodeURI(
    Object.keys(params)
      .filter(
        (key) =>
          params[key as keyof object] !== undefined &&
          params[key as keyof object] !== null
      )
      .map((key) => {
        if (isArray(params[key as keyof object])) {
          return Array(params[key as keyof object])
            .map((p: unknown) => `${key}=${p}`)
            .join('&');
        }
        return `${key}=${params[key as keyof object]}`;
      })
      .join('&')
  );
}

export const saveCustomUrl = async (customUrl: string) => {
  const params = getEncodedParams({
    workerProfileCustomUrl: customUrl,
  });
  const url = `${WORKER_SET_CUSTOM_URL}?${params}`;
  const result = await privateApi.put<IResultResponse>(url);
  return result.data;
};

export const fetchTalents = async (
  filterSearch: IFilterData,
  page = 0,
  max = 24
) => {
  const nextPage = page * max;
  const params = getEncodedParams({
    jobId: filterSearch.jobId,
    filterType: filterSearch.filterType.toLowerCase(),
    address: filterSearch.address,
    addressLatitude: filterSearch.addressLatitude,
    addressLongitude: filterSearch.addressLongitude,
    miles: filterSearch.miles === 500 ? 'ALL' : filterSearch.miles,
    name: filterSearch.name,
    email: filterSearch.email,
    workerStatus: filterSearch.workerStatus,
    filterWorkers: filterSearch.filterWorkers || 'ALL',
    skills: isEmpty(filterSearch.skills) ? undefined : filterSearch.skills,
    eventTypes: isEmpty(filterSearch.eventTypes)
      ? undefined
      : filterSearch.eventTypes,
    traits: isEmpty(filterSearch.traits) ? undefined : filterSearch.traits,
    badges: isEmpty(filterSearch.badges) ? undefined : filterSearch.badges,
    countries: isEmpty(filterSearch.countries)
      ? undefined
      : filterSearch.countries,
    travelNationally: filterSearch.travelNationally,
    travelLonger: filterSearch.travelLonger,
    gender: filterSearch.gender,
    profileStatus: isEmpty(filterSearch.profileStatus)
      ? undefined
      : filterSearch.profileStatus,
    sortOrder: filterSearch.sortOrder,
  });
  const result = await privateApi.get<ITalentsData>(
    `${MAIN_URL}?${params}`,
    getRequestConfig(nextPage, max)
  );
  return result.data;
};

export const blockWorker = async (workerId: number) => {
  const result = privateApi
    .post<IResultResponse>(`${MAIN_URL}/${workerId}/block`)
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried block the worker.`
      );
      return error.response;
    });
  return result;
};

export const unblockWorker = async (workerId: number) => {
  const result = privateApi
    .post<IResultResponse>(`${MAIN_URL}/${workerId}/unblock`)
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried unblock the worker.`
      );
      return error.response;
    });
  return result;
};

export const getWorkerAssignment = async (assignmentId: number) => {
  const result = await privateApi.get<IWorkerAssignment>(
    `${ASSIGNMENTS_URL}/${assignmentId}`
  );
  return result.data;
};

export const getWorkerActivityCompanyHistory = async (workerId: number) => {
  if (workerId === undefined) {
    return Promise.reject(AxiosError);
  }
  // FIXME: is the same data type returned?
  const result = await privateApi.get<IWorkerAssignment>(
    `${MAIN_URL}/${workerId}/company-history`
  );
  return result.data;
};

export const fetchNotes = async (workerId: number, page = 0, max = 10) => {
  const result = await privateApi.get<IWorkerNotesData>(
    `${MAIN_URL}/${workerId}/${NOTES_URL}`,
    getRequestConfig(page, max)
  );
  return result.data;
};

export const createNote = async (
  workerId: number,
  dataRequest: IWorkerNoteCreation
) => {
  const result = await privateApi.post<IResultResponse>(
    `${MAIN_URL}/${workerId}/${NOTES_URL}`,
    dataRequest
  );
  return result.data;
};

export const updateNote = async (
  workerId: number,
  workerNoteId: number,
  dataRequest: IWorkerNoteCreation
) => {
  const result = await privateApi.put<IResultResponse>(
    `${MAIN_URL}/${workerId}/${NOTES_URL}/${workerNoteId}`,
    dataRequest
  );
  return result.data;
};

export const deleteNote = async (workerId: number, workerNoteId: number) => {
  await privateApi.delete(
    `${MAIN_URL}/${workerId}/${NOTES_URL}/${workerNoteId}`
  );
};

/**
 * This is temporary function to show JobWorkerSideModal component on  CommunicationHub mocked component
 * @returns the following structure: { data: { jobId, workerId, jobAssignmentId} }
 */
// TODO: Remove this function when CommunicationHub is completely integrated with backend
export const fetchFirstWorkerJobAndAssignmentIds = async () => {
  try {
    const result = await privateApi.get<IResultResponse>(
      `${MAIN_URL}/first-id`
    );
    return result.data;
  } catch (error: any) {
    console.log(
      `Occurred an error fetching jobAssignmentId, workerId and jobId. Url: ${MAIN_URL}/first-id`
    );
    console.error(error);
    return error.response.data;
  }
};

export const fetchWorkerJobInfo = async (
  workerId: number | string | undefined,
  jobId?: number,
  triggerProfileView?: boolean
) => {
  if (workerId === undefined) {
    return Promise.reject(AxiosError);
  }
  const params = { jobId, triggerProfileView };
  const result = await privateApi.get<IWorkerJobData>(
    `${MAIN_URL}/${workerId}`,
    { params }
  );
  return result.data;
};

export const fetchWorkerFilters = async () => {
  const result = await privateApi.get<IWorkerFiltersData>(
    `${MAIN_URL}/searchWorkerFilters`
  );
  return result.data;
};

export const favoriteWorker = async (workerId: number) => {
  const result = await privateApi.post<IResultResponse>(
    `${MAIN_URL}/${workerId}/favorite`
  );
  return result.data;
};

export const removeFavoriteWorker = async (workerId: number) => {
  const result = await privateApi.post<IResultResponse>(
    `${MAIN_URL}/${workerId}/remove-favorite`
  );
  return result.data;
};

export const fetchWorkerAvailability = async (
  workerId: number,
  fromDate?: string,
  toDate?: string
) => {
  if (workerId === undefined) {
    return Promise.reject(AxiosError);
  }

  const params = { fromDate, toDate };
  const result = await privateApi.get<IWorkerAvailabilityData>(
    `${MAIN_URL}/${workerId}/availability`,
    { params }
  );
  return result.data;
};

export const fetchSuggestedWorkers = async (query: string) => {
  if (!query) {
    return Promise.reject(AxiosError);
  }
  const params: Record<string, string> = { query };
  const result = await privateApi.get<IWorkerSuggestions>(
    `${MAIN_URL}/suggest`,
    {
      params,
    }
  );
  return result.data;
};
