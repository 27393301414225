import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IWorkerData } from '@th-types/worker.type';
import { useWorkerProfileContext } from './workerProfileContext';

interface IWorkerContext {
  id: number | undefined;
  name: string | undefined;
  email: string;
  profileCompletedPercentage: number | undefined;
  profileImageUrl: string;
}

interface ProviderProps {
  children: React.ReactNode;
}

const WorkerContext: IWorkerContext | any = React.createContext({});

export function WorkerProvider({ children }: ProviderProps) {
  const [id, setId] = useState<number>();
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>('');
  const [profileImageUrl, setProfileImageUrl] = useState<string>('');
  const [profileCompletedPercentage, setProfileCompletedPercentage] =
    useState<number>();

  const { workerProfile } = useWorkerProfileContext();

  function updateState(worker: IWorkerData) {
    if (worker) {
      setId(worker.id);
      setName(`${worker.firstName} ${worker.lastName}`);
      setProfileCompletedPercentage(worker.profileCompletedPercentage);
      setProfileImageUrl(worker.profileImageUrl);
      setEmail(worker.email);
    }
  }

  useEffect(() => {
    if (workerProfile) {
      updateState(workerProfile);
    }
  }, [workerProfile]);

  const contextValue: IWorkerContext = useMemo(() => {
    return {
      id,
      name,
      email,
      profileCompletedPercentage,
      profileImageUrl,
    };
  }, [id, name, email, profileCompletedPercentage, profileImageUrl]);

  return (
    <WorkerContext.Provider value={contextValue}>
      {children}
    </WorkerContext.Provider>
  );
}

export function useWorkerContext() {
  const context: IWorkerContext = useContext(WorkerContext);
  if (context === undefined) {
    throw new Error(
      'useWorkerContext must be used within a WorkerProvider. Wrap a parent component in <WorkerProvider> to fix this error.'
    );
  }
  return context;
}
