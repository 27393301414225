import { CompanyRating } from '@th-types/rating.type';
import { Review, UserState, WorkInfo } from '@company/types/comm-hub.type';
import { formatWorkDateRange } from '@utils/DateUtils';
import DATE_FORMATS from '@constants/dateFormat';

export class ReviewRequestModel implements Review {
  jobAssignmentId: number;

  eventName: string;

  workerId: number | null;

  workerFullName: string | null;

  jobId: number;

  companyRating: CompanyRating;

  workInfo: WorkInfo;

  userState: UserState;

  completed?: boolean;

  constructor(reviewRequest: Review) {
    this.jobAssignmentId = reviewRequest.jobAssignmentId;
    this.eventName = reviewRequest.eventName;
    this.workerId = reviewRequest.workerId;
    this.workerFullName = reviewRequest.workerFullName || null;
    this.jobId = reviewRequest.jobId;
    this.companyRating = reviewRequest.companyRating;
    this.workInfo = reviewRequest.workInfo;
    this.userState = reviewRequest.userState;
    this.completed = reviewRequest.completed;
  }

  getUniqueKey(): number {
    return this.jobAssignmentId;
  }

  isNew(): boolean {
    return this.userState.new;
  }

  getWorkerId(): number | null {
    return this.workerId;
  }

  getWorkerName(): string {
    return this.workerFullName ?? 'n/a';
  }

  getPosition(): string {
    return this.workInfo?.position ?? '';
  }

  hasResponded(): boolean {
    return this.completed === true;
  }

  getDateCreated(): string {
    return this.companyRating?.dateCreated || '';
  }

  getWorkedDateRange(): string {
    if (!this.workInfo?.start || !this.workInfo?.end) {
      return '-';
    }

    const startDate = new Date(this.workInfo.start);
    const endDate = new Date(this.workInfo.end);

    return formatWorkDateRange(startDate, endDate, DATE_FORMATS.DATE_FORMAT);
  }
}
