import { Alert, Button, Col, Form, Image, Row } from 'react-bootstrap';
import { Panel, StarRatingButton } from '@components/elements';
import defaultAvatar from '@assets/default-avatar.png';
import { useEffect, useState } from 'react';
import { ISuggestion } from '@th-types/suggestions.type';
import useAlert from '@hooks/useAlert';
import { LoaderSquare } from '@components/elements/ComponentLoader';
import { IRatingVisibility } from '@th-types/rating.type';
import { IPaymentRating, PaymentRating } from '@constants/various';
import IRateCompanyCreation from '@worker/types/rate-company-creation.type';
import { IThJobAssignmentData } from '@worker/types/th-job-assignment.type';
import { INonThJobAssignmentData } from '@worker/types/non-th-job-assignment.type';
import './styles.scss';

const MAX_COMMENTS_LENGTH = 300;

interface CommentsCounter {
  comments: number;
  improvementComments: number;
}
interface Props {
  stepTitle?: string;
  nextStep?: () => void;
  lastStep?: () => void;
  isActive?: boolean;
  selectedCompany?: ISuggestion;
  jobAssignment: IThJobAssignmentData | INonThJobAssignmentData | undefined;
  setFinished: (value: boolean) => void;
  setRateCompanyCreation: (value: IRateCompanyCreation) => void;
}

function RateCompany({
  nextStep,
  lastStep,
  stepTitle,
  isActive,
  selectedCompany,
  jobAssignment,
  setFinished,
  setRateCompanyCreation,
}: Props) {
  const { showWarningAlert, alertMessage } = useAlert();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [communication, setCommunication] = useState(0);
  const [atEventManagement, setAtEventManagement] = useState(0);
  const [professionalism, setProfessionalism] = useState(0);
  const [overall, setOverall] = useState(0);
  const [payment, setPayment] = useState<number | null>(0);
  const [paymentIdx, setPaymentIdx] = useState<number | null>(0);
  const [comments, setComments] = useState('');
  const [improvementComments, setImprovementComments] = useState('');
  const [visibility, setVisibility] = useState(false);
  const [commentsCounter, setCommentsCounter] = useState<CommentsCounter>({
    comments: 0,
    improvementComments: 0,
  });

  useEffect(() => {
    if (jobAssignment?.companyRating) {
      const populateRating = () => {
        setComments(jobAssignment.companyRating.comments || '');
        setCommunication(jobAssignment.companyRating.communication || 0);
        setAtEventManagement(
          jobAssignment.companyRating.atEventManagement || 0
        );
        setProfessionalism(jobAssignment.companyRating.professionalism || 0);
        setImprovementComments(
          jobAssignment.companyRating?.improvementComments || ''
        );
        setVisibility(
          jobAssignment.companyRating.visibility === IRatingVisibility.PUBLIC
        );
      };
      setIsReadOnly(true);
      populateRating();
    }
  }, [jobAssignment]);

  useEffect(() => {
    if (communication > 0 || atEventManagement > 0 || professionalism > 0) {
      const calcOverall =
        (communication + atEventManagement + professionalism) / 3;
      setOverall(Number(calcOverall.toFixed(2)));
    }
  }, [communication, atEventManagement, professionalism, overall]);

  const handleSkip = () => {
    if (lastStep) {
      setFinished(true);
      lastStep();
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      communication === 0 ||
      atEventManagement === 0 ||
      professionalism === 0 ||
      payment === 0
    ) {
      showWarningAlert('Please complete all required fields');
      return;
    }

    const body: IRateCompanyCreation = {
      communication,
      atEventManagement,
      professionalism,
      comments,
      improvementComments,
      payment: paymentIdx,
      visibility: visibility
        ? IRatingVisibility.PUBLIC
        : IRatingVisibility.PRIVATE,
    };

    setRateCompanyCreation(body);
    if (nextStep) {
      nextStep();
    }
  };

  const handlePayment = (payRate: IPaymentRating): void => {
    setPaymentIdx(payRate.index);
    setPayment(payRate.value);
  };

  const handleChangeComments = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComments(e.target.value);
    setCommentsCounter((prev) => {
      return { ...prev, comments: e.target.value.length || 0 };
    });
  };

  const handleChangeImprovementComments = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setImprovementComments(e.target.value);
    setCommentsCounter((prev) => {
      return { ...prev, improvementComments: e.target.value.length || 0 };
    });
  };

  let companyName = selectedCompany?.name;
  let companyImage = selectedCompany?.profileImageUrl;
  if (jobAssignment && 'job' in jobAssignment) {
    companyName = jobAssignment?.job?.company?.name;
    companyImage = jobAssignment?.job?.company?.profileImageUrl;
  }

  return (
    <>
      <Row key={stepTitle} className={isActive ? 'd-block' : 'd-none'}>
        <Col md={{ span: 8, offset: 2 }}>
          <Panel>
            <Form id="rateCompanyForm" onSubmit={onSubmit}>
              <Row>
                <Col xs={9}>
                  <b>
                    Rate Company -{' '}
                    {companyName || <LoaderSquare width="250" height="25" />}
                  </b>
                </Col>
                <Col xs={3} className="d-flex justify-content-end">
                  {companyImage ? (
                    <Image
                      className="avatar rounded"
                      src={companyImage || defaultAvatar}
                      style={{
                        width: 50,
                        height: 50,
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <LoaderSquare width="50" height="50" />
                  )}
                </Col>
                <Col xs={12} className="mt-3">
                  <b>Public Review Information</b>
                </Col>
                <Col xs={12} className="mt-3">
                  Please note this information will be shared publicly on the
                  company profile along with your full name and profile picture.
                </Col>
                <Col xs={12} className="mt-3">
                  <Form.Group controlId="rateGigForm.comments">
                    <Form.Label className="fw-bold">
                      What did this company do really well?
                    </Form.Label>
                    <Form.Control
                      name="comments"
                      as="textarea"
                      placeholder="They were amazing at X, did Y for the team. "
                      className="mt-2"
                      value={comments}
                      maxLength={MAX_COMMENTS_LENGTH}
                      onChange={handleChangeComments}
                      autoComplete="off"
                      readOnly={isReadOnly}
                    />
                    <Form.Text className="d-flex justify-content-end">
                      {commentsCounter.comments}/{MAX_COMMENTS_LENGTH}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} className="mt-3">
                  <Form.Group controlId="rateGigForm.companyName">
                    <Form.Label className="fw-bold">
                      How long did it take for you to get paid?*
                    </Form.Label>
                    <Row className="mt-2">
                      {PaymentRating.map((payRate) => (
                        <Col xs={4} key={`payment-rating-${payRate.index}`}>
                          <Button
                            type="button"
                            variant={
                              paymentIdx === payRate.index
                                ? 'primary'
                                : 'outline-dark'
                            }
                            className="mt-3 w-100"
                            onClick={() => handlePayment(payRate)}
                          >
                            <b>{payRate.title}</b>
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </Form.Group>
                </Col>
                <Col xs={12} className="mt-4">
                  <Row>
                    <Col>
                      <p>
                        <sub>
                          <b>COMMUNICATION</b>
                        </sub>
                      </p>
                      <StarRatingButton
                        value={communication}
                        setValue={setCommunication}
                        allowChange={!isReadOnly}
                      />
                    </Col>
                    <Col>
                      <p>
                        <sub>
                          <b>AT EVENT MANAGEMENT</b>
                        </sub>
                      </p>
                      <StarRatingButton
                        value={atEventManagement}
                        setValue={setAtEventManagement}
                        allowChange={!isReadOnly}
                      />
                    </Col>
                    <Col>
                      <p>
                        <sub>
                          <b>PROFESSIONALISM</b>
                        </sub>
                      </p>
                      <StarRatingButton
                        value={professionalism}
                        setValue={setProfessionalism}
                        allowChange={!isReadOnly}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mt-1">
                  <hr />
                </Col>
                <Col xs={12} className="mt-2">
                  <b>Optional - What Can Company Improve On</b>
                </Col>
                <Col xs={12} className="mt-3">
                  While your name and picture are associated with your review,
                  you have the option to share your improvement comment
                  privately with the company to improve internally, or select
                  the checkbox to make it public on their profile.
                </Col>
                <Col xs={12} className="mt-3">
                  <Form.Group controlId="rateGigForm.improvementComments">
                    <Form.Label className="fw-bold">
                      What can this company improve?
                    </Form.Label>
                    <Form.Control
                      name="improvementComments"
                      as="textarea"
                      placeholder="Share something valuable to help a company improve. "
                      className="mt-2"
                      value={improvementComments}
                      maxLength={MAX_COMMENTS_LENGTH}
                      onChange={handleChangeImprovementComments}
                      autoComplete="off"
                      readOnly={isReadOnly}
                    />
                    <Form.Text className="d-flex justify-content-end">
                      {commentsCounter.improvementComments}/
                      {MAX_COMMENTS_LENGTH}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} className="mt-3">
                  <Form.Check
                    inline
                    label="Agree to share improvement publicly with name and picture."
                    type="checkbox"
                    checked={visibility}
                    readOnly={isReadOnly}
                    onChange={() => setVisibility(!visibility)}
                  />
                </Col>
              </Row>
            </Form>
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="w-100 d-flex justify-content-end">
            <Button
              type="button"
              form="rateCompanyForm"
              size="sm"
              variant="light"
              className="fw-bold mt-4"
              onClick={handleSkip}
            >
              Skip Company Rating
            </Button>
          </div>
        </Col>
        <Col className="mb-5">
          <Button
            type="submit"
            form="rateCompanyForm"
            size="sm"
            className="fw-bold mt-4"
          >
            Continue
          </Button>
        </Col>
        <Alert
          show={alertMessage.show}
          variant={alertMessage.variant}
          className="alert-fixed"
          style={{ width: '20rem' }}
        >
          <Alert.Heading>{alertMessage.message}</Alert.Heading>
        </Alert>
      </Row>
    </>
  );
}

export default RateCompany;
