import { Col, Container, Row } from 'react-bootstrap';
import { IWorkerData } from '@th-types/worker.type';
import DocViewer from '@utils/DocViewer';

interface Params {
  worker: IWorkerData;
}

export default function WorkerResume({ worker }: Params) {
  if (!worker.resumeUrl) {
    return (
      <Row className="mt-4">
        <Col md={12}>
          Worker doesn&apos;t have a resume on file, message them to ask for
          one!
        </Col>
      </Row>
    );
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        {worker?.resumeUrl && (
          <a
            href={worker.resumeUrl}
            className="mb-5"
            target="_blank"
            rel="noreferrer"
            style={{ width: 'auto' }}
          >
            Download worker&apos;s resume file
          </a>
        )}

        <DocViewer
          file={worker.resumeUrl}
          style={{ width: '80%', height: '70svh' }}
        />
      </Row>
    </Container>
  );
}
