import React, { CSSProperties, useState } from 'react';
import { Stepper, Step } from 'react-form-stepper';
import { BrowserView, MobileView } from 'react-device-detect';
import StepWizard from 'react-step-wizard';
import { Col, Row } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { StepStyleDTO } from 'react-form-stepper/dist/components/Step/StepTypes';
import { ConnectorStyleProps } from 'react-form-stepper/dist/components/Connector/ConnectorTypes';

type ChildProps = {
  stepTitle: string;
};

interface StepChangeProps {
  previousStep: number;
  activeStep: number;
}
interface Params {
  children: React.ReactNode;
  back?: React.ReactNode;
}

function Wizard({ children, back }: Params) {
  const [activeStep, setActiveStep] = useState(0);
  const stepsLength = React.Children.toArray(children).length ?? 0;

  const handleStepChange = (e: StepChangeProps) => {
    setActiveStep(e.activeStep - 1);
  };

  const connectorStyle: ConnectorStyleProps = {
    size: 2,
    completedColor: '#f2d032',
    activeColor: '#f2d032',
    disabledColor: 'var(--light-silver)',
    stepSize: 0,
    style: 'solid',
  };

  const styleConfig: StepStyleDTO = {
    activeBgColor: '#f2d032',
    completedBgColor: '#f2d032',
    inactiveBgColor: '#fff',
    activeTextColor: '#232223',
    inactiveTextColor: 'var(--light-silver)',
    completedTextColor: '#232223',
    fontWeight: 'bold',
    size: 30,
    circleFontSize: 14,
    labelFontSize: 14,
    borderRadius: '50%',
  };

  const getStyle = (index: number) => {
    const incompleteStep = index > activeStep;
    const customStyle: CSSProperties = {
      fontWeight: 'bold',
    };
    if (incompleteStep) {
      customStyle.border = '2px solid var(--light-silver)';
    }
    return customStyle;
  };

  const getStepTitle = (item: number) => {
    const title = React.Children.map(children, (child, index) => {
      return React.isValidElement<ChildProps>(child) &&
        index === item &&
        child.props.stepTitle
        ? child.props.stepTitle
        : '';
    });
    return title;
  };

  return (
    <>
      <MobileView>
        <Row className="me-1 mb-4">
          <Col xs={4}>
            <div style={{ width: 60, height: 60 }}>
              <CircularProgressbar
                value={activeStep + 1}
                maxValue={stepsLength}
                strokeWidth={10}
                text={`${activeStep + 1} of ${stepsLength}`}
                styles={{
                  path: {
                    stroke: 'var(--yellow)',
                  },
                  text: {
                    fill: 'var(--black)',
                    fontSize: '20px',
                    fontWeight: '700',
                  },
                  trail: {
                    stroke: 'var(--light-silver)',
                  },
                }}
              />
            </div>
          </Col>
          <Col xs={8}>
            <div className="fw-bold fs-3 mb-1 d-flex justify-content-end">
              {getStepTitle(activeStep)}
            </div>
            <div className="justify-content-end">
              {activeStep + 1 < stepsLength ? 'Next step: ' : ''}
              {getStepTitle(activeStep + 1)}
            </div>
          </Col>
        </Row>
      </MobileView>
      <BrowserView>
        <Row>
          {back && (
            <Col xs={2} className="d-flex align-self-start">
              {back}
            </Col>
          )}
          <Col
            md={{ span: 8, offset: back ? 0 : 2 }}
            className="d-flex justify-content-center"
          >
            <Stepper
              activeStep={activeStep}
              connectorStateColors
              connectorStyleConfig={connectorStyle}
              className="col-12"
            >
              {React.Children.map(children, (child, index) => {
                if (
                  React.isValidElement<ChildProps>(child) &&
                  child.props.stepTitle
                ) {
                  return (
                    <Step
                      className="custom-button"
                      style={getStyle(index)}
                      styleConfig={styleConfig}
                      label={child.props.stepTitle}
                    />
                  );
                }
                return null;
              })}
            </Stepper>
          </Col>
        </Row>
      </BrowserView>
      <Row className="justify-content-md-center">
        <StepWizard onStepChange={handleStepChange} isLazyMount>
          {children as JSX.Element}
        </StepWizard>
      </Row>
    </>
  );
}

export default Wizard;
