import { useState } from 'react';
import { Figure, Modal } from 'react-bootstrap';
import './style.css';

export default function ShowImage(props: any) {
  const { src } = props;
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Figure.Image {...props} onClick={() => setModalShow(true)} />
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="show-image-modal"
        dialogClassName="dynamic-width-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body className="p-1 pb-0">
          <Figure.Image
            src={src}
            className="modal-figure"
            onClick={() => setModalShow(true)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
