import { BiLike } from '@react-icons/all-files/bi/BiLike';
import TooltipOverlay from '@components/elements/Tooltip';
import { PiHandsClappingThin } from 'react-icons/pi';
import Button from 'react-bootstrap/esm/Button';
import { useJobAssignmentContext } from '@company/state/jobAssignmentContext';

interface ReviewButtonParams {
  openReviewModal: () => void;
  workerRated?: boolean;
}

export default function ReviewButton({
  openReviewModal,
  workerRated,
}: ReviewButtonParams) {
  const { workerRatable } = useJobAssignmentContext();
  if (workerRated) {
    return (
      <TooltipOverlay
        text="Review was already submitted for this worker"
        position="bottom"
      >
        <Button size="sm" variant="light" disabled={workerRated}>
          <PiHandsClappingThin size={18} />
          <b>&nbsp; REVIEW</b>
        </Button>
      </TooltipOverlay>
    );
  }
  return (
    <Button
      size="sm"
      variant="light"
      className="fw-bolder"
      disabled={!workerRatable}
      onClick={openReviewModal}
    >
      <BiLike size={18} />
      <b>&nbsp;REVIEW</b>
    </Button>
  );
}
