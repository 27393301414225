import SearchTalentInput from '@company/components/SearchTalentInput/SearchTalentInput';
import { isBrowserOutdated } from '@utils/BrowserUtils';
import { useRef, useEffect, useCallback } from 'react';
import { FormControlProps } from 'react-bootstrap';
import { useJsApiLoader } from '@react-google-maps/api';

interface AutoCompleteAddressProps extends FormControlProps {
  initialValue: string | undefined;
  placeholder: string;
  disabled?: boolean;
  resultEvent: (place: google.maps.places.PlaceResult | string) => void;
  name: string;
}

const countryRestriction = ['US', 'CA'];
const libraries = ['places'] as const;

function AutoCompleteAddress({
  initialValue,
  placeholder,
  disabled,
  resultEvent,
  onChange,
  name,
}: AutoCompleteAddressProps) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    // @ts-ignore
    libraries,
    version: 'weekly',
  });

  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = initialValue || '';
    }
  }, [initialValue]);

  const handleInput = useCallback(() => {
    if (inputRef.current) {
      resultEvent(inputRef.current.value);
    }
  }, [inputRef, resultEvent]);

  useEffect(() => {
    if (isBrowserOutdated()) {
      console.warn('Safari version is outdated');
      return undefined;
    }

    if (!isLoaded) {
      console.log('Loading Google Places autocomplete');
      return undefined;
    }

    if (!inputRef?.current || typeof resultEvent !== 'function') {
      console.warn('Unable to load address input');
      return undefined;
    }

    if (window.google && window.google.maps) {
      const options = {
        componentRestrictions: { country: countryRestriction },
        fields: [
          'address_components',
          'formatted_address',
          'geometry',
          'icon',
          'name',
        ],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      window.google.maps.event.addListener(
        autocomplete,
        'place_changed',
        () => {
          resultEvent(autocomplete.getPlace());
        }
      );

      autoCompleteRef.current = autocomplete;

      return () => {
        window.google.maps.event.clearListeners(autocomplete, 'place_changed');
      };
    }

    console.warn('Unable to load google maps');
    const inputRefCurrent = inputRef.current;

    if (inputRefCurrent) {
      inputRefCurrent.addEventListener('input', handleInput);
    }

    return () => {
      if (inputRefCurrent) {
        inputRefCurrent.removeEventListener('input', handleInput);
      }
    };
  }, [isLoaded, inputRef, resultEvent, handleInput]);

  return (
    <SearchTalentInput
      name={name}
      inputRef={inputRef}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
    />
  );
}
export default AutoCompleteAddress;
