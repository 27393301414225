import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { UseFormRegister } from 'react-hook-form';
import IBatchRateWorkerSession, {
  IBatchRateWorker,
} from '@company/types/batch-rate-worker.type';

const COMMENTS_MAX_LENGTH = 300;

interface Props {
  register: UseFormRegister<IBatchRateWorkerSession>;
  worker: IBatchRateWorker;
}

function Comments({ register, worker }: Props) {
  const [commentsCount, setCommentsCount] = useState<number>(0);

  return (
    <Form.Group controlId="rateWorkerForm.comments">
      <Form.Label className="fw-bold">
        What does this worker do well?
      </Form.Label>
      <span className="mx-2 optional-badge text-uppercase small">
        (OPTIONAL)
      </span>
      <Form.Control
        {...register(`workers.${worker.id}.workerRating.comments`)}
        as="textarea"
        maxLength={COMMENTS_MAX_LENGTH}
        placeholder=""
        className="mt-2"
        onChange={(e) => setCommentsCount(e.target.value.length || 0)}
        autoComplete="off"
      />
      <Form.Text className="d-flex justify-content-end">
        {commentsCount}/{COMMENTS_MAX_LENGTH}
      </Form.Text>
    </Form.Group>
  );
}

export default Comments;
