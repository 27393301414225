import { CompanyRating, Reviewer } from '@th-types/rating.type';
import { WorkInfo, UserState, Review } from '@company/types/comm-hub.type';
import { formatWorkDateRange } from '@utils/DateUtils';
import DATE_FORMATS from '@constants/dateFormat';

export class ReceivedReviewModel implements Review {
  jobAssignmentId: number;

  eventName: string;

  workerId: number | null;

  workerFullName: string | null;

  jobId: number;

  companyRating: CompanyRating;

  workInfo: WorkInfo;

  userState: UserState;

  completed?: boolean;

  reviewer?: Reviewer | undefined;

  constructor(receivedReview: Review) {
    this.jobAssignmentId = receivedReview.jobAssignmentId;
    this.eventName = receivedReview.eventName;
    this.workerId = receivedReview.workerId;
    this.workerFullName = receivedReview.workerFullName;
    this.jobId = receivedReview.jobId;
    this.companyRating = receivedReview.companyRating;
    this.workInfo = receivedReview.workInfo;
    this.userState = receivedReview.userState;
    this.completed = receivedReview.completed;
    this.reviewer = receivedReview.reviewer;
  }

  getUniqueKey(): number {
    return this.jobAssignmentId;
  }

  /**
   * Gets the rating value
   */
  getRating(): number | undefined {
    return this.companyRating?.avgRating;
  }

  /**
   * Gets the worker name
   */
  getWorkerName(): string {
    return this.workerFullName ?? 'n/a';
  }

  /**
   * Gets the positions
   */
  getPosition(): string {
    return this.workInfo?.position;
  }

  /**
   * Gets the worker id
   */
  getWorkerId(): number | null {
    return this.workerId;
  }

  /**
   * Gets the date created regardless of review type
   */
  getDateCreated(): string {
    return this.companyRating?.dateCreated || '';
  }

  isNew(): boolean {
    return this.userState?.new;
  }

  hasReplied(): boolean {
    return this.companyRating.ratingReply !== undefined;
  }

  getWorkedDateRange(): string {
    if (!this.workInfo?.start || !this.workInfo?.end) {
      return '-';
    }

    const startDate = new Date(this.workInfo.start);
    const endDate = new Date(this.workInfo.end);

    return formatWorkDateRange(startDate, endDate, DATE_FORMATS.DATE_FORMAT);
  }
}
