import { FiCheckCircle } from '@react-icons/all-files/fi/FiCheckCircle';
import { Modal, Button } from 'react-bootstrap';

interface ModalActionCompletedParams {
  message: string;
  showModal: boolean;
  handleClose: () => void;
}

function ModalActionCompleted({
  message,
  handleClose,
  showModal,
}: ModalActionCompletedParams) {
  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingRight: '10px' }}>
          Action Completed Successfully
        </Modal.Title>
        <FiCheckCircle color="green" size={24} />
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button variant="primary" size="lg" onClick={handleClose}>
          Go back
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalActionCompleted;
