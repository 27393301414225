export enum JobSearchMessages {
  NO_RESULTS = 'Sorry, looks like there are no results that match your criteria.',
  NO_RESULTS_UPDATE_FILTERS = 'Try updating your filters.',
}

export enum ApplicantsMessages {
  HIRED = "You haven't hired anyone yet.",
  HIRED_HELP_TEXT = 'Check your applicant list or offer your job to workers.',
  OFFERED = "You haven't offered this job to any worker.",
  OFFERED_HELP_TEXT = 'Go to search talent and find someone fit.',
  APPLIED = 'You have no applicants yet.',
  APPLIED_HELP_TEXT = 'Go to search talent and share your job to someone fit.',
  HIDDEN = 'You have no hidden applicants.',
}
