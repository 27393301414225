import React, { forwardRef, useState } from 'react';
import { HiOutlineShare } from '@react-icons/all-files/hi/HiOutlineShare';
import { Button, Col } from 'react-bootstrap';
import DropdownList, {
  DropdownListItem,
  DropdownListItemEmpty,
} from '@components/elements/DropdownList';

interface ShareButtonParams {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loadData: () => Promise<DropdownListItem[] | DropdownListItemEmpty>;
  isVariant?: boolean;
}

interface ShareJobProps {
  getJobs: () => Promise<DropdownListItem[] | DropdownListItemEmpty | null>;
  isVariant?: boolean;
}

const renderShareButton = forwardRef<HTMLButtonElement, ShareButtonParams>(
  (
    { onClick, loadData, isVariant, ...props }: ShareButtonParams,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
      <Button
        {...props}
        ref={ref}
        key="share-job"
        variant={`${isVariant ? 'light' : 'primary'}`}
        size="sm"
        disabled={isLoading}
        className={`fw-bolder ${isVariant ? 'w-100 ps-0 pe-0' : ''}`}
        onClick={async (e) => {
          const { ariaExpanded } = e.currentTarget;
          if (ariaExpanded === 'false') {
            setIsLoading(true);
            e.preventDefault();
            if (loadData) {
              await loadData();
            }
            if (onClick) {
              onClick(e);
            }
            setIsLoading(false);
          }
        }}
      >
        <>
          <HiOutlineShare size={isVariant ? 10 : 18} />
          <b
            style={{
              fontSize: isVariant ? 9 : 'var(--bs-btn-font-size)',
            }}
          >
            &nbsp;SHARE
          </b>
        </>
      </Button>
    );
  }
);
renderShareButton.displayName = 'ShareButton';

export default function ShareJobButton({
  getJobs,
  isVariant = false,
}: ShareJobProps) {
  const [jobs, setJobs] = useState<
    DropdownListItem[] | DropdownListItemEmpty | null
  >(null);

  const handleLoadData = async () => {
    if (jobs) {
      return jobs;
    }
    const result = await getJobs();
    setJobs(result);
    return result;
  };

  const renderDropdownList = () => (
    <DropdownList
      items={jobs}
      isVariant={isVariant}
      loadData={handleLoadData}
      customToggle={renderShareButton}
    />
  );

  return isVariant ? (
    <Col key="share-job" className="p-1 mt-1">
      {renderDropdownList()}
    </Col>
  ) : (
    renderDropdownList()
  );
}
