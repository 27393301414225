import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Modal, Stack } from 'react-bootstrap';
import { CgAddR } from 'react-icons/cg';
import { IoMdClose } from 'react-icons/io';
import { Education } from '@th-types/workerProfile.type';
import { FaRegEdit } from 'react-icons/fa';
import { FaRegTrashCan } from 'react-icons/fa6';
import { useWorkerProfileContext } from '@worker/state/workerProfileContext';
import { format } from 'date-fns';
import DATE_FORMATS from '@constants/dateFormat';
import * as workerApi from '@worker/services/worker/api';
import { ThLoading } from '@components/elements';
import EducationForm from '@worker/components/WorkerProfile/Forms/EducationForm/EducationForm';

interface EducationModalProps {
  openAddItem?: boolean;
  onCloseModal: () => void;
}

function EducationModal({ openAddItem, onCloseModal }: EducationModalProps) {
  const [educationToEdit, setEducationToEdit] = useState<Education>();
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { workerEducation, setWorkerEducation } = useWorkerProfileContext();

  const onEditEducation = (education: Education) => {
    setIsAddingItem(false);
    setEducationToEdit(education);
  };

  const onDeleteEducation = async (educationId: number) => {
    setIsLoading(true);
    const result = await workerApi.deleteEducationFromWorker(educationId);
    if (result.status === 200) {
      setWorkerEducation((prevState) => {
        return prevState?.filter((edu) => edu.id !== educationId);
      });
    }
    setIsLoading(false);
  };

  const onAddItem = () => {
    setEducationToEdit(undefined);
    setIsAddingItem(true);
  };

  useEffect(() => {
    if (openAddItem === true) {
      onAddItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal show centered className="p-3" size="lg" onHide={onCloseModal}>
      {isLoading && <ThLoading />}
      <Modal.Header>
        <Modal.Title className="d-flex align-items-center">
          Education
          <span className="fs-5 ms-2" style={{ color: 'var(--silver)' }}>
            ({2}%)
          </span>
        </Modal.Title>
        <Stack
          direction="horizontal"
          className="justify-content-end ms-auto"
          gap={2}
        >
          <CgAddR size={20} className="cursor-point" onClick={onAddItem} />
          <IoMdClose
            size={20}
            className="cursor-point"
            onClick={onCloseModal}
          />
        </Stack>
      </Modal.Header>
      <Modal.Body>
        {(isAddingItem || workerEducation?.length === 0) && (
          <div className="mb-3">
            <EducationForm
              onCancel={
                workerEducation?.length === 0
                  ? onCloseModal
                  : () => setIsAddingItem(false)
              }
              onSuccess={() => setIsAddingItem(false)}
            />
          </div>
        )}
        <Stack gap={isMobile ? 4 : 3}>
          {workerEducation?.map((education: Education) => (
            <div key={education.id}>
              {educationToEdit && educationToEdit.id === education.id ? (
                <EducationForm
                  educationToEdit={educationToEdit}
                  onCancel={() => setEducationToEdit(undefined)}
                  onSuccess={() => setEducationToEdit(undefined)}
                />
              ) : (
                <Stack direction="horizontal">
                  <Stack
                    direction={isMobile ? 'vertical' : 'horizontal'}
                    className="align-items-start justfy-content-between flex-grow-1"
                  >
                    <Stack>
                      <b>{education.university}</b>
                      <span>{education.degree}</span>
                    </Stack>
                    <b className="me-2">
                      {format(
                        new Date(education.degreeDate),
                        DATE_FORMATS.DATE_FORMAT
                      )}
                    </b>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    className="align-items-start"
                    gap={2}
                  >
                    <FaRegEdit
                      size={20}
                      className="cursor-point"
                      onClick={() => onEditEducation(education)}
                    />
                    <FaRegTrashCan
                      size={20}
                      color="var(--red)"
                      className="cursor-point"
                      onClick={() => onDeleteEducation(education.id)}
                    />
                  </Stack>
                </Stack>
              )}
            </div>
          ))}
        </Stack>
      </Modal.Body>
    </Modal>
  );
}

export default EducationModal;
