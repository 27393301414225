import { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ReceivedReviewsTable from '@company/components/CommunicationHub/CommunicationHubTables/ReceivedReviewsTable';
import FilterBar from '@company/components/CommunicationHub/FilterBar/FilterBar';
import {
  dismissItemsFromNew,
  getReceivedReviews,
} from '@company/services/com-hub/api';
import ThPagination from '@components/ThPagination/ThPagination';
import { ThLoading } from '@components/elements';
import {
  ComHubDismissTypeEnum,
  ComHubQueryParamsEnum,
  ComHubTabs,
  Review,
} from '@company/types/comm-hub.type';
import QueryKeys from '@constants/queryKeys';
import {
  dismissItems,
  handleComHubSearch,
  handleComHubClear,
  extractComHubUrlParams,
} from '@utils/ComHubUtils';

interface ReceivedReviewsProps {
  onShowWorkerProfile?: (workerId: number) => void;
  onSeeReceivedReview: (review: Review) => void;
}

function ReceivedReviews({
  onShowWorkerProfile,
  onSeeReceivedReview,
}: ReceivedReviewsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentOffset, setCurrentOffset] = useState(0);
  const [searchState, setSearchState] = useState<{
    searchText: string;
    dateRange: { startDate: Date | undefined; endDate: Date | undefined };
  }>();
  const [showOnlyNew, setShowOnlyNew] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  // CONSTANTS FOR QUERY
  const itemsPerPage = 20;
  const queryClient = useQueryClient();
  const queryKeyReceivedReviews = [
    QueryKeys.ComHub.RECEIVED_REVIEWS,
    currentOffset,
    showOnlyNew,
    searchState,
  ];

  // useQuery fetch
  const { data: receivedReviewsResponse, isLoading } = useQuery({
    queryKey: queryKeyReceivedReviews,
    queryFn: () =>
      getReceivedReviews(
        currentOffset,
        itemsPerPage,
        searchState?.searchText,
        searchState?.dateRange,
        showOnlyNew
      ),
    enabled: isInitialized, // Only run query after initialization
  });

  // useQuery update
  const { mutateAsync, isPending: isLoadingDismiss } = useMutation({
    mutationFn: dismissItemsFromNew,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ComHub.RECEIVED_REVIEWS],
      });
    },
  });

  // Initialize state from URL parameters
  useEffect(() => {
    if (isInitialized) return;

    const {
      searchState: urlSearchState,
      showOnlyNew: urlShowOnlyNew,
      offset: urlOffset,
    } = extractComHubUrlParams(searchParams);

    if (urlSearchState) {
      setSearchState(urlSearchState);
    }

    if (urlShowOnlyNew !== undefined) {
      setShowOnlyNew(urlShowOnlyNew);
    }

    if (urlOffset !== undefined) {
      setCurrentOffset(urlOffset);
    }

    setIsInitialized(true);
  }, [searchParams, isInitialized]);

  const updateSearchParams = useCallback(
    (params: URLSearchParams) => {
      setSearchParams(params);
    },
    [setSearchParams]
  );

  const handleOnSearch = useCallback(
    (search: {
      searchText: string;
      dateRange: { startDate: Date | undefined; endDate: Date | undefined };
    }) => {
      handleComHubSearch(search, setSearchState, updateSearchParams, {
        itemsPerPage,
        setCurrentOffset,
      });
    },
    [updateSearchParams, itemsPerPage, setCurrentOffset]
  );

  const handleOnClear = useCallback(() => {
    handleComHubClear(setSearchState, updateSearchParams, {
      itemsPerPage,
      setCurrentOffset,
    });
  }, [updateSearchParams, itemsPerPage, setCurrentOffset]);

  // Update URL when offset changes
  useEffect(() => {
    if (!isInitialized) return;

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(ComHubQueryParamsEnum.OFFSET, currentOffset.toString());
    newSearchParams.set(ComHubQueryParamsEnum.MAX, itemsPerPage.toString());
    updateSearchParams(newSearchParams);
  }, [
    currentOffset,
    searchParams,
    updateSearchParams,
    isInitialized,
    itemsPerPage,
  ]);

  return (
    <>
      <FilterBar
        onSearch={handleOnSearch}
        onClear={handleOnClear}
        showOnlyNew={showOnlyNew}
        onShowOnlyNew={() => setShowOnlyNew((prevState) => !prevState)}
      />
      {(isLoading || isLoadingDismiss) && <ThLoading />}
      {receivedReviewsResponse ? (
        <>
          <ReceivedReviewsTable
            title={ComHubTabs.RECEIVED_REVIEWS}
            receivedReviews={receivedReviewsResponse.reviews}
            onSeeReceivedReview={onSeeReceivedReview}
            onShowWorkerProfile={onShowWorkerProfile}
            onDismissItems={(items) =>
              dismissItems(
                items,
                ComHubDismissTypeEnum.COMPANY_RATING,
                mutateAsync
              )
            }
          />
          <ThPagination
            currentOffset={receivedReviewsResponse.pagination.offset}
            onPageChange={setCurrentOffset}
            itemsPerPage={itemsPerPage}
            totalItems={receivedReviewsResponse.pagination.count}
          />
        </>
      ) : (
        <p className="mt-5 d-flex justify-content-center">
          There are no received reviews for the selected period. You can change
          the date range or search by event, name or position.
        </p>
      )}
    </>
  );
}

export default ReceivedReviews;
