import ContentLoader from 'react-content-loader';

interface Params {
  width?: string;
  height?: string;
}

function LoaderSquare({ width = '200', height = '200' }: Params): JSX.Element {
  return (
    <ContentLoader
      speed={1}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#f5f5f5"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
    </ContentLoader>
  );
}

export default LoaderSquare;
