import { useState } from 'react';

interface DeleteRowIconProps {
  color?: string;
  className?: string;
  onClick?: () => void;
  size?: number;
}
function DeleteRowIcon({
  color,
  className,
  onClick,
  size,
}: DeleteRowIconProps) {
  const [isHovered, setIsHovered] = useState(false); // Track hover state

  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      stroke={isHovered ? 'var(--yellow-dark-20)' : color || '#141B34'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)} // Set hover state to true
      onMouseLeave={() => setIsHovered(false)} // Reset hover state to false
      style={{ transition: 'stroke 0.3s ease' }} // Add smooth transition
    >
      <path
        d="M21 18.5605C21 19.965 21 20.6672 20.6208 21.1716C20.4567 21.39 20.2457 21.5775 20 21.7234C19.4325 22.0605 18.6425 22.0605 17.0625 22.0605H6.9375C5.35748 22.0605 4.56747 22.0605 3.99997 21.7234C3.75429 21.5775 3.54335 21.39 3.37919 21.1716C3 20.6672 3 19.965 3 18.5605C3 17.156 3 16.4538 3.37919 15.9494C3.54335 15.731 3.75429 15.5435 3.99997 15.3976C4.56747 15.0605 5.35748 15.0605 6.9375 15.0605H17.0625C18.6425 15.0605 19.4325 15.0605 20 15.3976C20.2457 15.5435 20.4567 15.731 20.6208 15.9494C21 16.4538 21 17.156 21 18.5605Z"
        strokeWidth="1.5"
      />
      <path
        d="M12.0625 4.06055H6.9375C5.35748 4.06055 4.56747 4.06055 3.99997 4.39761C3.75429 4.54353 3.54335 4.73103 3.37919 4.94941C3 5.45386 3 6.15609 3 7.56055C3 8.96501 3 9.66724 3.37919 10.1716C3.54335 10.39 3.75429 10.5775 3.99997 10.7234C4.56747 11.0605 5.35748 11.0605 6.9375 11.0605H17.0625C18.6425 11.0605 19.4325 11.0605 20 10.7234"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21 8.05991L15 2.06055M21 2.06119L15 8.06055"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeleteRowIcon;
