import { BrowserView, MobileView } from 'react-device-detect';
import { Card, Col, Row, Stack } from 'react-bootstrap';
import BriefcaseIcon from './BriefcaseIcon';
import BuildingIcon from './BuildingIcon';

import './styles.css';

interface WorkerStatsProps {
  confirmedJobsWorked: number;
  notConfirmedJobsWorked: number;
  confirmedJobsWorkedUniqueCompany: number;
  notConfirmedJobsWorkedUniqueCompany: number;
}

function WorkerStats({
  confirmedJobsWorked,
  notConfirmedJobsWorked,
  confirmedJobsWorkedUniqueCompany,
  notConfirmedJobsWorkedUniqueCompany,
}: WorkerStatsProps) {
  return (
    <Card className="worker-profile-card">
      <Card.Text as="div">
        <BrowserView>
          <Stack
            direction="horizontal"
            className="justify-content-around"
            gap={3}
          >
            <Stack direction="horizontal" gap={2}>
              <BriefcaseIcon fillColor="var(--yellow)" />
              <b
                className="stat-value font-yellow"
                data-testid="confirmed-jobs-worked"
              >
                {confirmedJobsWorked}
              </b>
              <span className="stat-label">Confirmed Jobs Worked</span>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <BuildingIcon fillColor="var(--yellow)" />
              <b
                className="stat-value font-yellow"
                data-testid="confirmed-jobs-worked-unique-company"
              >
                {confirmedJobsWorkedUniqueCompany}
              </b>
              <span className="stat-label">
                Confirmed Jobs Unique Companies
              </span>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <BriefcaseIcon fillColor="var(--silver)" />
              <b
                className="stat-value font-silver"
                data-testid="not-confirmed-jobs-worked"
              >
                {notConfirmedJobsWorked}
              </b>
              <span className="stat-label">Non-Confirmed Jobs Worked</span>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <BuildingIcon fillColor="var(--silver)" />
              <b
                className="stat-value font-silver"
                data-testid="not-confirmed-jobs-worked-unique-company"
              >
                {notConfirmedJobsWorkedUniqueCompany}
              </b>
              <span className="stat-label">
                Non-Confirmed Jobs Unique Companies
              </span>
            </Stack>
          </Stack>
        </BrowserView>
        <MobileView>
          <Row className="text-center">
            <Col>
              <Stack
                direction="horizontal"
                gap={2}
                className="align-items-center justify-content-center"
              >
                <BriefcaseIcon fillColor="var(--yellow)" />
                <b
                  className="stat-value font-yellow"
                  data-testid="confirmed-jobs-worked"
                >
                  {confirmedJobsWorked}
                </b>
              </Stack>
              <div className="stat-label">Confirmed Jobs Worked</div>
            </Col>
            <Col>
              <Stack
                direction="horizontal"
                gap={2}
                className="align-items-center justify-content-center"
              >
                <BuildingIcon fillColor="var(--yellow)" />
                <b
                  className="stat-value font-yellow"
                  data-testid="confirmed-jobs-worked-unique-company"
                >
                  {confirmedJobsWorkedUniqueCompany}
                </b>
              </Stack>
              <span className="stat-label">
                Confirmed Jobs Unique Companies
              </span>
            </Col>
          </Row>
          <hr />
          <Row className="text-center">
            <Col>
              <Stack
                direction="horizontal"
                gap={2}
                className="align-items-center justify-content-center"
              >
                <BriefcaseIcon fillColor="var(--silver)" />
                <b
                  className="stat-value font-silver"
                  data-testid="not-confirmed-jobs-worked"
                >
                  {notConfirmedJobsWorked}
                </b>
              </Stack>
              <span className="stat-label">Non-Confirmed Jobs Worked</span>
            </Col>
            <Col>
              <Stack
                direction="horizontal"
                gap={2}
                className="align-items-center justify-content-center"
              >
                <BuildingIcon fillColor="var(--silver)" />
                <b
                  className="stat-value font-silver"
                  data-testid="not-confirmed-jobs-worked-unique-company"
                >
                  {notConfirmedJobsWorkedUniqueCompany}
                </b>
              </Stack>
              <span className="stat-label">
                Non-Confirmed Jobs Unique Companies
              </span>
            </Col>
          </Row>
        </MobileView>
      </Card.Text>
    </Card>
  );
}

export default WorkerStats;
