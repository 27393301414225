import { BrowserView, MobileView } from 'react-device-detect';
import { Button, ListGroup, Table, Image, Stack } from 'react-bootstrap';
import { format } from 'date-fns';
import { ImNewTab } from 'react-icons/im';
import { IoIosCheckmark } from 'react-icons/io';
import defaultAvatar from '@assets/default-avatar.png';
import { formatTimeOrDateNow, formatWorkDateRange } from '@utils/DateUtils';
import { StarRating } from '@components/elements';
import DATE_FORMATS from '@constants/dateFormat';
import { GivenReviewModel } from '@company/models/GivenReviewModel';
import {
  ComHubHeadersEnum,
  GivenReview,
  GivenReviewTypeEnum,
} from '@company/types/comm-hub.type';

import './styles.css';

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

interface ReviewsAndEndorsementsTableProps {
  givenReviews: GivenReview[];
  title: string;
  onSeeGivenReview: (review: GivenReview) => void;
  onSeeGivenEndorsement: (endorsementCaseId: number) => void;
  onShowWorkerProfile?: (workerId: number) => void;
}

function ReviewsAndEndorsementsTable({
  title,
  givenReviews,
  onSeeGivenReview,
  onSeeGivenEndorsement,
  onShowWorkerProfile,
}: ReviewsAndEndorsementsTableProps) {
  const headers = [
    ComHubHeadersEnum.EVENT_NAME,
    ComHubHeadersEnum.WORKER_NAME,
    ComHubHeadersEnum.POSITION,
    ComHubHeadersEnum.DATES_WORKED,
    ComHubHeadersEnum.RATING,
    ComHubHeadersEnum.AUTHOR,
    ComHubHeadersEnum.ACTIONS_COLUMN,
  ];

  const openGivenReviewModal = (givenReview: GivenReview) => {
    if (givenReview.type === GivenReviewTypeEnum.ENDORSEMENT_CASE)
      onSeeGivenEndorsement(givenReview.endorsementCaseId);
    if (givenReview.type === GivenReviewTypeEnum.RATING)
      onSeeGivenReview(givenReview);
  };

  const getDateCreated = (givenReview: GivenReviewModel) => {
    return formatTimeOrDateNow(givenReview.getDateCreated());
  };

  return (
    <div className="th-table">
      <header>
        <h4>{title}</h4>
      </header>
      <hr style={{ margin: '10px 0' }} />
      {givenReviews.length ? (
        <>
          <BrowserView>
            <Table>
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* RENDER COLUMNS BY ROW */}
                {givenReviews.map((review) => {
                  const givenReview = new GivenReviewModel(review);
                  return (
                    <tr key={givenReview.getUniqueKey()}>
                      <td>
                        {givenReview.jobId ? (
                          <a
                            href={`${BASE_PATH}jobs/${givenReview.jobId}`}
                            target="_blank"
                            rel="noreferrer"
                            className="cursor-point event-name-link"
                          >
                            {givenReview.eventName}
                            <ImNewTab
                              color="var(--yellow-dark-20)"
                              className="ms-2 event-name-link-icon"
                            />
                          </a>
                        ) : (
                          <span>
                            {givenReview.type === GivenReviewTypeEnum.RATING
                              ? givenReview.eventName
                              : 'N/A - Endorsement'}
                          </span>
                        )}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() =>
                            onShowWorkerProfile!(givenReview.workerId)
                          }
                        >
                          {givenReview.getWorkerName()}
                        </button>
                      </td>
                      <td data-testid="reviews-and-endorsements-table-position">
                        {givenReview.workInfo?.position || 'N/A'}
                      </td>
                      <td>
                        {givenReview.type === GivenReviewTypeEnum.RATING &&
                        givenReview.workInfo
                          ? formatWorkDateRange(
                              new Date(givenReview.workInfo.start),
                              new Date(givenReview.workInfo.end),
                              DATE_FORMATS.DATE_FORMAT
                            )
                          : 'N/A'}
                      </td>
                      <td>{givenReview.getPerformance()}</td>
                      <td>{givenReview.getAuthorName()}</td>
                      {/* BUTTONS FOR ROW ACTIONS */}
                      <td className="actions">
                        <span className="me-3">
                          {getDateCreated(givenReview)}
                        </span>
                        <Button
                          onClick={() => openGivenReviewModal(givenReview)}
                          className="action-btn me-2"
                        >
                          open
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </BrowserView>
          <MobileView>
            <ListGroup variant="flush">
              {givenReviews.map((review) => {
                const givenReview = new GivenReviewModel(review);
                return (
                  <ListGroup.Item
                    key={givenReview.getUniqueKey()}
                    className="ps-0 pe-0 border-0 d-flex gap-2 align-items-center"
                    onClick={() => openGivenReviewModal(givenReview)}
                  >
                    <Image
                      src={
                        givenReview.endorsementCase?.endorsedWorker
                          ?.profileImageUrl ?? defaultAvatar
                      }
                      alt="reviewer profile picture"
                      width={40}
                      height={40}
                      roundedCircle
                    />
                    <Stack>
                      <span>{givenReview.getWorkerName()}</span>
                      <span>{givenReview.getAuthorName()}</span>
                      {givenReview.type === GivenReviewTypeEnum.RATING ? (
                        <Stack direction="horizontal" gap={2}>
                          <StarRating
                            value={givenReview.getPerformance()!}
                            color="yellow"
                          />
                          <span>{givenReview.getPerformance()}</span>
                        </Stack>
                      ) : (
                        <span className="d-flex gap-1 align-items-center">
                          Endorsed
                          <span
                            className="rounded-circle"
                            style={{
                              backgroundColor: 'var(--yellow)',
                              lineHeight: '0',
                            }}
                          >
                            <IoIosCheckmark />
                          </span>
                        </span>
                      )}
                    </Stack>
                    <Stack className="ms-auto justify-content-start align-items-end">
                      <span>
                        {format(
                          new Date(givenReview.getDateCreated()),
                          DATE_FORMATS.SHORT_DATE_FORMAT
                        )}
                      </span>
                    </Stack>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </MobileView>
        </>
      ) : (
        <p>No new notifications.</p>
      )}
    </div>
  );
}

export default ReviewsAndEndorsementsTable;
