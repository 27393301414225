import { AxiosResponse } from 'axios';
import { privateApi } from '.';

const MARK_NEW_EVENT_ENDPOINT: string = 'stats/event';

interface MarkPageViewPayload {
  pageUrl: string;
  eventType: string;
  referer: string;
}

export async function markPageView(eventType: string = 'DEFAULT_EVENT') {
  const payload: MarkPageViewPayload = {
    pageUrl: window.location.href,
    referer: document.referrer,
    eventType,
  };

  try {
    const response: AxiosResponse = await privateApi.post(
      MARK_NEW_EVENT_ENDPOINT,
      payload
    );
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
}
