import { ActivityTracker } from '@company/pages/JobOverview/ActivityTracker';
import Messaging from '@company/pages/JobOverview/Messaging/Messaging';
import { SearchTalent } from '@company/pages/JobOverview/SearchTalent';
import Detail from '@company/pages/JobOverview/Detail/Detail';
import { useChatContext } from '@company/state/chatContext';
import { useJobContext } from '@company/state/jobContext';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Badge, Col, Dropdown, Row, Stack, Tab } from 'react-bootstrap';
import { LoaderJobDetail } from '@components/elements/ComponentLoader';
import ThTabs from '@company/components/th/ThTabs';
import { Body } from '@company/components/layout';
import { SideEndModal, ThError } from '@components/elements';
import { FiChevronDown } from 'react-icons/fi';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { Chat } from '@components/Chat';
import { backToJobList } from '@company/services/job/jobUtils';
import PayrollServices from './PayrollServices/PayrollServices';
import { Scheduling } from './Scheduling';
import './style.css';

interface DemoTabParams {
  description: string;
  isDemo?: boolean;
}

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

enum OverviewTabs {
  DETAILS = 'details',
  SEARCH_TALENTS = 'searchTalents',
  SCHEDULING = 'scheduling',
  ACTIVITY_TRACKER = 'activityTracker',
  MESSAGING = 'messaging',
  PAYROLL_SERVICES = 'payrollServices',
}

function TabName({ description, isDemo = false }: DemoTabParams) {
  if (isDemo) {
    return (
      <Row style={{ justifyContent: 'flex-end' }}>
        <Col style={{ textAlign: 'right' }}>{description}</Col>
        <Col style={{ textAlign: 'left' }}>
          <Badge bg="info">Demo</Badge>
        </Col>
      </Row>
    );
  }
  return <div>{description}</div>;
}

export default function JobOverview() {
  const { id } = useParams();
  const { activeJobId, setJobParamId, isLoading, error } = useJobContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (activeJobId && activeJobId.toString() !== id) {
      navigate(`${BASE_PATH}jobs/${activeJobId}`);
      return () => {
        setJobParamId(activeJobId.toString());
      };
    }
    if (id) {
      setJobParamId(id);
    }
    return undefined;
  }, [id, setJobParamId, activeJobId, navigate]);

  const [key, setKey] = useState<string>(OverviewTabs.DETAILS);
  const [messageUnreadCount, setMessageUnreadCount] = useState(0);
  const [activitiesCount, setActivitiesCount] = useState(0);

  const {
    chatPerson,
    showPrivateInfo: showWorkerPrivateInfo,
    clearChatData,
  } = useChatContext();

  useEffect(() => {
    const activeTabParam = searchParams.get('activeTab');

    const tabParam = activeTabParam || OverviewTabs.DETAILS;
    searchParams.set('activeTab', tabParam);
    setSearchParams(searchParams);
    setKey(tabParam);
  }, [searchParams, setSearchParams]);

  // Whenever user clicks on back button from browser, it handles this action to back to job list
  useEffect(() => {
    const handlePopState = () => {
      backToJobList();
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  if (id === undefined) {
    return <ThError message="No Job Id Found" />;
  }

  if (isLoading) return <LoaderJobDetail />;

  if (error) {
    if (error.response?.status) {
      return <>No data found</>;
    }
    return <ThError message={error.message} />;
  }

  const jobTabs = [
    {
      name: OverviewTabs.DETAILS,
      description: 'Job Details',
      component: <Detail />,
    },
    {
      name: OverviewTabs.SEARCH_TALENTS,
      description: 'Search Talent',
      component: <SearchTalent />,
    },
    {
      name: OverviewTabs.SCHEDULING,
      description: 'Scheduling',
      component: <Scheduling />,
    },
    {
      name: OverviewTabs.ACTIVITY_TRACKER,
      description: `Activity Tracker (${activitiesCount})`,
      component: <ActivityTracker setActivitiesCount={setActivitiesCount} />,
    },
    {
      name: OverviewTabs.MESSAGING,
      description: `Messaging (${messageUnreadCount})`,
      component: <Messaging setMessageUnreadCount={setMessageUnreadCount} />,
    },
    {
      name: OverviewTabs.PAYROLL_SERVICES,
      description: 'Payroll Services',
      component: <PayrollServices isActive={key === 'payrollServices'} />,
    },
  ];

  const selectTab = (tabName: string) => {
    setKey(tabName);
    setSearchParams({ activeTab: tabName });
  };

  return (
    <>
      {isMobile ? (
        <div>
          <Dropdown>
            <Dropdown.Toggle variant="" className={isMobile ? 'ms-3 mt-2' : ''}>
              <Stack direction="horizontal" gap={2}>
                <b>
                  <TabName
                    description={
                      jobTabs.find((tab) => tab.name === key)?.description ||
                      'Tab'
                    }
                  />
                </b>
                <FiChevronDown size={24} />
              </Stack>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {jobTabs.map((tab) => (
                <Dropdown.Item
                  key={tab.name}
                  onClick={() => selectTab(tab.name)}
                >
                  <TabName description={tab.description} />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Body>{jobTabs.find((tab) => tab.name === key)?.component}</Body>
        </div>
      ) : (
        <div>
          <ThTabs
            id="controlled-tab-example"
            className="job-header"
            fill
            activeKey={key}
            onSelect={(tabName) => selectTab(tabName!)}
          >
            {jobTabs.map((tab) => (
              <Tab
                key={tab.name}
                eventKey={tab.name}
                title={<TabName description={tab.description} />}
              >
                <Body>{tab.component}</Body>
              </Tab>
            ))}
          </ThTabs>
        </div>
      )}
      {!!chatPerson && (
        <SideEndModal
          show={!!chatPerson}
          width={isMobile ? '90%' : '70%'}
          onHide={clearChatData}
        >
          <Chat showWorkerInfo={showWorkerPrivateInfo} worker={chatPerson} />
        </SideEndModal>
      )}
    </>
  );
}
