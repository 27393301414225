import { useMemo, useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { IoMdClose } from 'react-icons/io';
import { HiArrowLeft } from 'react-icons/hi';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ComHubGigInfoPreview from '@company/components/CommunicationHub/ComHubGigInfoPreview/ComHubGigInfoPreview';
import ReceivedReview from '@company/components/ReviewSidePopup/ReceivedReview/ReceivedReview';
import { JobAssignmentRatingType } from '@company/types/job-assignment-rating.type';
import { getReceivedCompanyRating } from '@company/services/rating/api';
import { useCompanyContext } from '@company/state/companyContext';
import { formatWorkDateRange } from '@utils/DateUtils';
import { ThLoading } from '@components/elements';
import DATE_FORMATS from '@constants/dateFormat';
import QueryKeys from '@constants/queryKeys';
import DeleteRowIcon from '@components/Icons/DeleteRowIcon/DeleteRowIcon';
import { Review } from '@company/types/comm-hub.type';
import RatingReply from './RatingReply';
import '../styles.css';

interface Props {
  selectedReview: Review;
  closeModal: () => void;
  showAlertMessage: (success: boolean, message: string) => void;
  onReplySuccess: () => void;
  onDismissItems?: (reviews: Review[]) => void;
}

function ReceivedReviewModalContent({
  selectedReview,
  closeModal,
  showAlertMessage,
  onReplySuccess,
  onDismissItems,
}: Props) {
  const { name } = useCompanyContext();
  const { jobAssignmentId, userState } = selectedReview;
  const [isLoadingDismiss, setIsLoadingDismiss] = useState(false);

  const RATING_BY_JOB_ASSIGNMENT_QUERY_KEY = [
    QueryKeys.WORKER_ASSIGNMENT_JOB,
    jobAssignmentId,
  ];

  const { data: ratingAssignmentData, isLoading } = useQuery<
    JobAssignmentRatingType,
    AxiosError
  >({
    queryKey: RATING_BY_JOB_ASSIGNMENT_QUERY_KEY,
    queryFn: () => getReceivedCompanyRating(jobAssignmentId),
    enabled: jobAssignmentId > 0,
  });

  const gigInfoPreviewData = useMemo(() => {
    return ratingAssignmentData && ratingAssignmentData.worker
      ? {
          brandRepresented: ratingAssignmentData.brandRepresented,
          companyName: ratingAssignmentData.companyName || name || '',
          eventName: ratingAssignmentData.eventName,
          jobTitle: ratingAssignmentData.jobTitle.name,
          workerName: ratingAssignmentData.worker.fullName,
          workedDays:
            ratingAssignmentData?.workedDates?.length > 1
              ? formatWorkDateRange(
                  new Date(ratingAssignmentData.workedDates[0]),
                  new Date(ratingAssignmentData.workedDates[1]),
                  DATE_FORMATS.DATE_FORMAT
                )
              : 'n/a',
        }
      : {
          brandRepresented: '',
          companyName: '',
          eventName: '',
          jobTitle: '',
          workerName: '',
          workedDays: '',
        };
  }, [ratingAssignmentData, name]);

  const handleDismissItem = async (itemsToDismiss: Review[]) => {
    if (onDismissItems) {
      setIsLoadingDismiss(true);
      await onDismissItems(itemsToDismiss);
      closeModal();
      setIsLoadingDismiss(false);
    }
  };

  return (
    <Stack>
      {(isLoading || isLoadingDismiss) && <ThLoading />}
      {isMobile ? (
        <>
          <Stack direction="horizontal" className="justify-content-between">
            <span className="received-review-title">
              Received Review from Worker
            </span>
            <IoMdClose size={20} onClick={closeModal} />
          </Stack>
          <hr />
        </>
      ) : (
        <Stack>
          <Stack
            direction="horizontal"
            gap={2}
            className="align-items-center p-3 ps-0 fw-bold"
          >
            <Button
              variant="light"
              onClick={closeModal}
              className="d-flex align-items-center fw-bold cursor-point"
            >
              <HiArrowLeft size={20} />
              <span className="ms-2">CLOSE</span>
            </Button>
            {userState?.new && (
              <Button
                variant="light"
                onClick={() => handleDismissItem([selectedReview])}
                className="d-flex align-items-center fw-bold"
              >
                <span className="me-1">DISMISS</span>
                <DeleteRowIcon />
              </Button>
            )}
          </Stack>
          <span className="received-review-title mb-3">
            Received Review from Worker
          </span>
        </Stack>
      )}
      {ratingAssignmentData ? (
        <Stack
          direction={isMobile ? 'vertical' : 'horizontal'}
          gap={isMobile ? 3 : 4}
          className="w-100 align-items-start"
        >
          <div className={isMobile ? 'w-100' : 'w-50'}>
            <ComHubGigInfoPreview
              gigInfoPreview={gigInfoPreviewData}
              isAnonymous={ratingAssignmentData.isAnonReview}
            />
          </div>
          <div className={isMobile ? 'w-100' : 'w-50'}>
            <ReceivedReview
              rating={ratingAssignmentData}
              workedDates={ratingAssignmentData.workedDates}
              worker={ratingAssignmentData.worker!}
              showSpanTitle={false}
            />
            <RatingReply
              ratingAssignmentData={ratingAssignmentData}
              showAlertMessage={showAlertMessage}
              onReplySuccess={onReplySuccess}
            />
          </div>
        </Stack>
      ) : null}
    </Stack>
  );
}

export default ReceivedReviewModalContent;
