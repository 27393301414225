import { JobAssignment } from '@th-types/job-assignment.type';

export enum AvailabilityType {
  NOT_AVAILABLE = 'Not Available to Work',
  ADDRESS_CHANGE = 'Address Change',
  WORK = 'TH Work Engagement',
}

export interface IAvailabilityData {
  id: string;
  workerId: number;
  from: string;
  to: string;
  type: AvailabilityType;
  allDay: boolean;
  jobAssignment?: JobAssignment;
}

export interface IWorkerAvailabilityData {
  workerAvailabilities: IAvailabilityData[];
}
