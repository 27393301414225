import { useEffect, useRef, useState } from 'react';
import { CandidateFilters } from '@company/pages/JobOverview/Detail/CandidateFilters';
import { Alert, Button, Col, Stack } from 'react-bootstrap';
import { IWorkerData } from '@th-types/worker.type';
import { ThWorkerCard } from '@company/components/th';
import { JobWorkerSideModal } from '@company/components/JobWorker';
import { JobAssignment } from '@th-types/job-assignment.type';
import { useChatContext } from '@company/state/chatContext';
import { isMobile } from 'react-device-detect';
import {
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query';
import QueryKeys from '@constants/queryKeys';
import { useJobContext } from '@company/state/jobContext';
import { ApplicantsType } from '@constants/applicantsType';
import { JobPlacement } from '@company/types/job-placement.type';
import { NoDataFound } from '@components/elements/NoDataFound';
import { ApplicantsMessages } from '@constants/noDataFoundMessages';
import { LoaderPersonCard } from '@components/elements/ComponentLoader';
import getNextPage from '@utils/Pagination';
import useAlert from '@hooks/useAlert';
import useOnScreen from '@hooks/useIntersectionObserver';
import { useJobWorkerShiftContext } from '@company/state/jobWorkerShiftContext';
import { useJobShiftContext } from '@company/state/jobShiftContext';

interface ListApplicantsProps {
  tabType: ApplicantsType;
  queryFn?: any;
}

type QueryType = {
  pageParams: number[];
  pages: {
    pagination: any;
    results: (JobPlacement | JobAssignment)[];
  }[];
};

function ListApplicants({ queryFn, tabType }: ListApplicantsProps) {
  const { chatPerson } = useChatContext();
  const { alertMessage } = useAlert();
  const tabRef = useRef(null);
  const isOnScreen = useOnScreen(tabRef);
  const {
    activeJobId,
    key,
    applicantFilters,
    newHired,
    newOffered,
    setNewHired,
    setNewOffered,
    fetchApplicantCounters,
  } = useJobContext();
  const [remaining, setRemaining] = useState(0);
  const [isLoadingComponent, setIsLoadingComponent] = useState(false);
  const [applicantsList, setApplicantsList] = useState<
    (JobPlacement | JobAssignment)[]
  >([]);
  const [showWorkerProfile, setShowWorkerProfile] = useState(false);
  const [selectedJobAssignment, setSelectedJobAssignment] =
    useState<JobAssignment>();
  const {
    setStatusFilter,
    selectedWorker,
    setSelectedWorker,
    setSelectedShifts,
    refetch: refetchShifts,
  } = useJobShiftContext();
  const { setIsJobWorkerSideModalOpen } = useJobWorkerShiftContext();

  const isActive = tabType.toString() === key.toString();

  const {
    data,
    isSuccess,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    error,
    refetch,
    isFetching,
  }: UseInfiniteQueryResult<QueryType> = useInfiniteQuery({
    queryKey: [`${QueryKeys.COMPANY_JOB_APPLICATION}_${activeJobId}_${key}`],
    queryFn: ({ pageParam }) => queryFn(pageParam),
    getNextPageParam: (lastPage, allPages) => getNextPage(lastPage, allPages),
    initialPageParam: 0,
    enabled: false,
  });

  const handleShowWorkerProfile = (
    jobAssignment: JobAssignment,
    worker: IWorkerData
  ) => {
    setSelectedJobAssignment(jobAssignment);
    setSelectedWorker(worker);
    setShowWorkerProfile(true);
    refetchShifts();
    setSelectedShifts([]);
    setIsJobWorkerSideModalOpen(true);
  };

  const handleCloseWorkerProfile = () => {
    setShowWorkerProfile(false);
    setIsJobWorkerSideModalOpen(false);
    setStatusFilter([]);
    setSelectedWorker(undefined);
  };

  useEffect(() => {
    if (isActive && isOnScreen) {
      refetch();
      fetchApplicantCounters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, applicantFilters, activeJobId, isOnScreen]);

  useEffect(() => {
    if (newHired && tabType === ApplicantsType.HIRED) {
      refetch();
      setNewHired(false);
    }
    if (newOffered && tabType === ApplicantsType.OFFERED) {
      refetch();
      setNewOffered(false);
    }
  }, [newHired, newOffered, refetch, setNewHired, setNewOffered, tabType]);

  const renderDataFound = () => {
    return applicantsList
      ? applicantsList.map((talent) => {
          const talentParam =
            'distance' in talent ? talent : talent.jobAssignment;
          return (
            <ThWorkerCard
              key={`hires_and_candidates_${talent.id}`}
              jobAssignment={talentParam}
              worker={talent.worker}
              isHidden={talentParam.hidden}
              distance={talentParam.distance}
              refetchApplicants={refetch}
              setIsLoading={setIsLoadingComponent}
              openDetails={handleShowWorkerProfile}
            />
          );
        })
      : null;
  };

  const getNoDataHelperText = () => {
    let noResultMessage = '';
    let helperMessage = '';
    if (applicantsList.length === 0) {
      if (tabType === ApplicantsType.APPLIED) {
        noResultMessage = ApplicantsMessages.APPLIED;
        helperMessage = ApplicantsMessages.APPLIED_HELP_TEXT;
      } else if (tabType === ApplicantsType.OFFERED) {
        noResultMessage = ApplicantsMessages.OFFERED;
        helperMessage = ApplicantsMessages.OFFERED_HELP_TEXT;
      } else if (tabType === ApplicantsType.HIRED) {
        noResultMessage = ApplicantsMessages.HIRED;
        helperMessage = ApplicantsMessages.HIRED_HELP_TEXT;
      } else if (tabType === ApplicantsType.HIDDEN) {
        noResultMessage = ApplicantsMessages.HIDDEN;
        helperMessage = '';
      }
    }
    return {
      noResultMessage,
      helperMessage,
    };
  };

  useEffect(() => {
    if (data && data.pages) {
      const flatenedPages: (JobPlacement | JobAssignment)[] =
        data.pages.flatMap((page) => {
          return page.results;
        });
      setApplicantsList(flatenedPages);
      setRemaining(data.pages[0].pagination.count - flatenedPages.length);
    }
  }, [data]);

  return (
    <div ref={tabRef}>
      <div className="mt-3">
        <CandidateFilters
          refreshApplicantFilters={refetch}
          applicantType={tabType}
        />
        <div className="mt-3 worker-list">
          {(isFetching || isLoading || isLoadingComponent) && (
            <LoaderPersonCard />
          )}
          {isSuccess && (
            <Stack gap={3}>
              {applicantsList.length ? (
                renderDataFound()
              ) : (
                <NoDataFound
                  showIcon={false}
                  noResultsMessage={getNoDataHelperText().noResultMessage}
                  helperMessage={getNoDataHelperText().helperMessage}
                />
              )}
              <Col md={12} className={`${isMobile ? '' : 'ps-0'}`}>
                <Button
                  variant="light ms-auto w-100"
                  onClick={() => fetchNextPage()}
                  disabled={isFetchingNextPage || !hasNextPage}
                  className="mt-3"
                >
                  {`View More (${isSuccess ? remaining : '0'})`}
                </Button>
              </Col>
            </Stack>
          )}
          {isError && (
            <div className="d-flex justify-content-center align-items-center p-5 m-5">
              <b>{error.message}</b>
            </div>
          )}
        </div>
      </div>
      {showWorkerProfile &&
        selectedWorker &&
        selectedJobAssignment &&
        chatPerson == null && (
          <JobWorkerSideModal
            handleCloseWorkerProfile={handleCloseWorkerProfile}
            worker={selectedWorker}
            jobAssignment={selectedJobAssignment}
            isJobRelated
          />
        )}
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </div>
  );
}

export default ListApplicants;
