export const PANEL_MESSAGES = {
  default: {
    title: 'You’ve found the most powerful TH feature.',
    line1: 'Want to cherry pick your staff? Need someone last minute?',
    line2:
      'Our database is yours, send an <b>immediate direct message </b>to any of the {workersCount} USA and Canadian-based workers in TH. This feature is reserved for our best.',
    line3: 'Welcome to TH Premium, <b>recruiting is now easy.</b>',
    buttons: ['LEARN MORE', 'JOIN PREMIUM'],
  },
  showMore: {
    title: 'TH Premium propels your business.',
    line1:
      'Sure, Premium nets your entire company unlimited job posts + blasts, In-network company status, appearing at the top of company lists, enhanced company profiles, and adding pre-screen job questions for applicants, but here’s the best part…',
    line2:
      'Search our entire worker directory by address, name or email. See everyone’s pictures, resumes, social handles, distance from location, stats, attributes, experience, endorsements, reviews, personality traits + more.',
    line3: 'Then use the <b>instant direct message<b> button on every profile!',
    buttons: ['CLOSE'],
  },
};
