import { BiSearch } from 'react-icons/bi';
import {
  IconPosition,
  IconPositionType,
  IconSize,
  IconSizeType,
} from '@constants/icons';

interface NoDataFoundParams {
  noResultsMessage: string;
  helperMessage?: string;
  iconColor?: string;
  iconBgColor?: string;
  iconPosition?: IconPositionType;
  iconSize?: IconSizeType;
  showIcon?: boolean;
}

export function NoDataFound({
  noResultsMessage,
  helperMessage,
  iconColor = 'var(--bs-primary)',
  iconBgColor = 'var(--bs-secondary)',
  iconPosition = IconPosition[IconPosition.TOP] as IconPositionType,
  iconSize = 'md',
  showIcon = true,
}: NoDataFoundParams) {
  return (
    <div
      className={`mt-3 text-center ${
        iconPosition === IconPosition[IconPosition.TOP]
          ? ''
          : 'd-flex justify-content-evenly align-items-center'
      }`}
    >
      {showIcon && (
        <>
          <BiSearch
            size={IconSize[iconSize]}
            color={iconColor}
            style={{
              backgroundColor: iconBgColor,
              borderRadius: '50%',
              padding: '10px',
            }}
            className={`${
              iconPosition === IconPosition[IconPosition.TOP] ? 'mb-3' : ''
            } fw-bold`}
          />
          <br />
        </>
      )}

      <div>
        {noResultsMessage}
        {helperMessage && (
          <>
            <br />
            {helperMessage}
          </>
        )}
      </div>
    </div>
  );
}
