import { privateApi } from '@services/api';
import {
  FolderDesignationEnum,
  S3PresignedUrl,
} from '@th-types/uploadImage.type';

export const S3_PRE_SIGN_URL = 'pre-signed-url';

export const getS3PreSignedUrl = async (
  fileName: string,
  contentType: string,
  designation: FolderDesignationEnum,
  entityId: string | undefined = undefined
) => {
  try {
    const result = await privateApi.get<S3PresignedUrl>(S3_PRE_SIGN_URL, {
      params: {
        fileName,
        contentType,
        designation,
        entity: entityId,
      },
    });
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
