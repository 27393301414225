import { useRef } from 'react';
import { Col, Stack } from 'react-bootstrap';
import { CiWarning } from 'react-icons/ci';
import { JobAssignmentRatingType } from '@company/types/job-assignment-rating.type';
import useAvatarImageFallback from '@hooks/useAvatarImageFallback';
import { formatOpenDateRange } from '@utils/DateUtils';
import defaultAvatar from '@assets/default-avatar.png';
import ShowImage from '@components/elements/ShowImage';
import { StarRating } from '@components/elements';
import { PaymentRating } from '@constants/various';
import { IRatingVisibility } from '@th-types/rating.type';
import { IWorkerData } from '@th-types/worker.type';
import '../styles.scss';

interface ReceivedReviewProps {
  rating: JobAssignmentRatingType;
  workedDates: string[];
  worker: IWorkerData;
  isModal?: boolean;
  showSpanTitle?: boolean;
}

function ReceivedReview({
  workedDates,
  rating,
  worker,
  isModal = false,
  showSpanTitle = true,
}: ReceivedReviewProps) {
  const avatarRef = useRef(null);
  useAvatarImageFallback(avatarRef);

  return rating && rating.companyRating ? (
    <Col className={`${isModal ? 'ps-4 pt-4 pe-0' : ''} `}>
      <Stack>
        {showSpanTitle && (
          <span className="mb-3 fw-bold fs-5 received-review-title">
            Received Review from Worker
          </span>
        )}
        <Stack className="received-review">
          <Stack direction="horizontal" gap={3} className="align-items-center">
            <ShowImage
              className="avatar rounded cursor-point mb-0"
              src={
                !rating.isAnonReview && worker.profileImageUrl
                  ? worker.profileImageUrl
                  : defaultAvatar
              }
              style={{
                width: 60,
                height: 60,
                objectFit: 'cover',
              }}
            />
            <span className="fw-bold">
              {rating.isAnonReview ? 'TH User' : worker.fullName}
            </span>
            <div className="d-inline-flex flex-column ms-auto">
              <span className="mb-3 text-end">
                Overall - {rating.companyRating.avgRating.toFixed(2)}
              </span>
              <StarRating
                value={rating.companyRating.avgRating}
                color="yellow"
                size={25}
              />
            </div>
          </Stack>
          <hr />

          <div className="note mb-3">
            <span>
              communication -{' '}
              {(rating.companyRating.communication ?? 0).toFixed(2)}
            </span>
            <span>
              At Event management -{' '}
              {(rating.companyRating.atEventManagement ?? 0).toFixed(2)}
            </span>
            <span>
              professionalism -{' '}
              {(rating.companyRating.professionalism ?? 0).toFixed(2)}
            </span>
          </div>

          <p className="question">How long did it take for you to get paid?</p>
          <p className="answer">
            {
              PaymentRating.find(
                (p) => p.index === rating.companyRating.payment
              )?.title
            }
          </p>

          <p className="question">What did this company do really well?</p>
          <p className="answer">
            {rating.companyRating.comments ?? <span>n/a</span>}
          </p>

          <p className="question">What can this company improve?</p>
          {rating.companyRating.improvementComments &&
          rating.companyRating.visibility === IRatingVisibility.PRIVATE ? (
            <p className="private-disclaimer">
              <CiWarning size={20} />
              The worker chose to share the below info privately for company to
              improve internally.
            </p>
          ) : null}
          <p className="answer mb-0">
            {rating.companyRating.improvementComments ?? <span>n/a</span>}
          </p>

          <hr />

          <p className="question">Event Name:</p>
          <p className="answer">{rating.eventName}</p>
          <p className="question">Dates Worked:</p>
          <p className="answer">
            {workedDates?.length > 1
              ? formatOpenDateRange(
                  new Date(workedDates[0]),
                  new Date(workedDates[1])
                )
              : 'n/a'}
          </p>
        </Stack>
      </Stack>
    </Col>
  ) : null;
}

export default ReceivedReview;
