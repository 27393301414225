import { useJobWorkerShiftContext } from '@company/state/jobWorkerShiftContext';
import { useJobShiftContext } from '@company/state/jobShiftContext';
import { useJobContext } from '@company/state/jobContext';
import { Alert, Button, Card, Row, Stack, Table } from 'react-bootstrap';
import { formatLocalDate, isDateAfterOrNow } from '@utils/DateUtils';
import * as jobApi from '@company/services/job/api';
import { removeDuplicatedShifts } from '@utils/ShiftsUtils';
import { useEffect, useId, useMemo, useState } from 'react';
import { ShiftPosition } from '@th-types/job.detail.type';
import { BiEdit } from '@react-icons/all-files/bi/BiEdit';
import ThLoading from '@components/elements/ThLoading';
import { PayRatePeriod } from '@th-types/payRatePeriod';
import DATE_FORMATS from '@constants/dateFormat';
import { ShiftStatus } from '@th-types/jobs.type';
import { SubmitHandler } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import useAlert from '@hooks/useAlert';
import {
  getFormattedTime,
  getValidationSchema,
} from '@services/shift/shiftService';
import { capitalize } from 'lodash';
import { format } from 'date-fns';
import { z } from 'zod';
import {
  CreateShiftMobileModal,
  CreateShiftTable,
} from '@company/pages/JobOverview/Scheduling/CreateShift';
import './styles.scss';

const TABLE_HEADER_WEB = [
  'Position',
  'Date',
  'Scheduled Time',
  'Full/Back up',
  'Wage',
  'Paid break',
];

interface OfferShiftTableProps {
  onEditShift: (isEditing: boolean) => void;
}

export default function OfferShiftTable({ onEditShift }: OfferShiftTableProps) {
  const [showEditShift, setShowEditShift] = useState(false);
  const [isLoadingAction, setLoadingAction] = useState(false);
  const [shiftToEdit, setShiftToEdit] = useState<ShiftPosition | undefined>();
  const [shiftsToOffer, setShiftsToOffer] = useState<ShiftPosition[]>([]);
  const { showSuccessAlert, alertMessage } = useAlert();

  const { shifts, selectedShifts, refetch } = useJobShiftContext();
  const { selectedJobWorkerShifts, isJobWorkerSideModalOpen } =
    useJobWorkerShiftContext();
  const { job, jobTimezone } = useJobContext();

  const tableKey = useId();
  const jobKey = useId();

  const selectedOfferableShiftList = useMemo(() => {
    const shiftList = isJobWorkerSideModalOpen
      ? removeDuplicatedShifts(selectedJobWorkerShifts)
      : removeDuplicatedShifts(selectedShifts);
    return shiftList.filter(
      (shiftItem) =>
        shiftItem.shiftStatus === ShiftStatus.OPEN &&
        isDateAfterOrNow(shiftItem.start, jobTimezone)
    );
  }, [
    isJobWorkerSideModalOpen,
    selectedJobWorkerShifts,
    selectedShifts,
    jobTimezone,
  ]);

  useEffect(() => {
    if (shifts) {
      const ids = selectedOfferableShiftList.map((item) => item.id);
      // Only open shifts can be offered
      const listShifts = shifts?.filter(
        (item) =>
          ids.includes(item.id) &&
          item.shiftStatus === ShiftStatus.OPEN &&
          isDateAfterOrNow(item.start, jobTimezone)
      );
      setShiftsToOffer(
        listShifts.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        )
      );
    }
  }, [jobTimezone, selectedOfferableShiftList, shifts]);

  if (!job) {
    return <div />;
  }

  const editShift = (shift: ShiftPosition) => {
    setShiftToEdit(shift);
    setShowEditShift(true);
    onEditShift(true);
  };
  const cancelEditShift = () => {
    setShiftToEdit(undefined);
    setShowEditShift(false);
    onEditShift(false);
  };

  const validationSchema = getValidationSchema(job.timezone);
  type ValidationShiftSchema = z.infer<typeof validationSchema>;

  const formatShiftValues = (data: ValidationShiftSchema) => {
    const formData: ValidationShiftSchema = { shifts: [] };
    formData.shifts = data.shifts.map((item) => {
      const shiftItem = item;
      shiftItem.start = format(item.startTime, DATE_FORMATS.DATE_TIME_FORMAT);
      shiftItem.end = format(item.endTime, DATE_FORMATS.DATE_TIME_FORMAT);
      shiftItem.payRatePeriod =
        shiftItem.payRatePeriod === 'daily'
          ? PayRatePeriod.DAY
          : PayRatePeriod.HOUR;
      shiftItem.payRate = shiftItem.payRate.replace('$', '').trim();
      return shiftItem;
    });
    return formData;
  };

  const onSubmit: SubmitHandler<ValidationShiftSchema> = async (
    data: ValidationShiftSchema
  ) => {
    try {
      setLoadingAction(true);
      const formData = formatShiftValues(data);
      let success = false;
      if (shiftToEdit) {
        const updateResult = await jobApi.updateJobShifts(
          job.id,
          shiftToEdit.id,
          formData.shifts[0]
        );
        success = updateResult?.success;
      } else {
        const result = await jobApi.addJobShifts(job.id, formData.shifts);
        success = result?.results?.length > 0;
      }
      if (success) {
        setShiftToEdit(undefined);
        setShowEditShift(false);
        onEditShift(false);
        await refetch();
      }
    } catch (err) {
      console.log(
        `Occurred an error trying to ${
          shiftToEdit ? 'update a shift' : 'create'
        } shift${!shiftToEdit && data.shifts.length > 1 ? 's' : ''}`,
        err
      );
    } finally {
      setLoadingAction(false);
    }
  };

  const shiftCreatedCloseModal = async (message?: string) => {
    showSuccessAlert(
      message ?? `Job shift${isMobile ? 's' : ''} successfully created`
    );
    setShowEditShift(false);
  };

  if (isLoadingAction) {
    return <ThLoading />;
  }

  return (
    <Row>
      <Table responsive borderless className="mt-2">
        <thead className="table-header fw-bold">
          <tr>
            {TABLE_HEADER_WEB.map((header, index) => (
              <th
                className={index === 0 ? 'text-start' : ''}
                key={`${tableKey}-${header}`}
              >
                {header}
              </th>
            ))}
            <th aria-label="edit" />
          </tr>
        </thead>
        <tbody className="table-body">
          {shiftsToOffer.map((shift) => (
            <tr
              key={`${jobKey}-${shift.id}-${shift.positionIndex}`}
              className={`${
                showEditShift && shiftToEdit?.id === shift.id
                  ? 'offer-shift-table'
                  : ''
              }`}
            >
              <td width="30%" className="text-start">
                {shift.title.name}
              </td>

              <td>
                {`${formatLocalDate(
                  new Date(shift.start),
                  DATE_FORMATS.DATE_FORMAT,
                  job.address.timezoneId
                )}`}
              </td>

              <td>{getFormattedTime(shift, job.address.timezoneId)}</td>
              <td>{capitalize(shift.type)}</td>
              <td>{`$${shift.wage.payRate}/${shift.wage.payRatePeriod}`}</td>
              <td>{shift.breakPaid ? 'Yes' : 'No'}</td>
              <td>
                {!showEditShift && (
                  <BiEdit
                    size={20}
                    className="cursor-point"
                    color="var(--black)"
                    onClick={() => editShift(shift)}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="divider mb-3" />
      {showEditShift && shiftToEdit && (
        <Row>
          <Card className="mb-3 ms-3">
            <Card.Body>
              <div className="mb-1 d-flex justify-content-center">
                <h4 className="fw-bold">Edit shift</h4>
              </div>
              <div>
                {isMobile ? (
                  <CreateShiftMobileModal
                    job={job}
                    shift={shiftToEdit}
                    handleClose={() => setShowEditShift(false)}
                    shiftCreatedCloseModal={shiftCreatedCloseModal}
                  />
                ) : (
                  <CreateShiftTable
                    job={job}
                    onSubmit={onSubmit}
                    shift={shiftToEdit}
                  />
                )}
              </div>
              <Stack
                direction="horizontal"
                gap={2}
                className="d-flex justify-content-end"
              >
                <Button variant="light" onClick={cancelEditShift}>
                  CANCEL
                </Button>
                <Button variant="primary" form="shift-form" type="submit">
                  SAVE
                </Button>
              </Stack>
            </Card.Body>
          </Card>
        </Row>
      )}
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </Row>
  );
}
