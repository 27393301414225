import { Badge, Card, Stack } from 'react-bootstrap';
import { Skill } from '@th-types/worker.type';
import { FiEdit, FiPlusSquare } from 'react-icons/fi';

import '../styles.css';

interface SkillsProps {
  skills: Skill[] | undefined;
  onEditSkills: () => void;
}

function Skills({ skills, onEditSkills }: SkillsProps) {
  return (
    <Card className="worker-profile-card">
      <Card.Title className="d-flex justify-content-between">
        <span>
          Skills
          <span className="ms-2 section-percentages">(10%)</span>
        </span>

        <Stack direction="horizontal" gap={2}>
          <FiPlusSquare
            size={20}
            className="cursor-point"
            onClick={onEditSkills}
          />
          <FiEdit size={18} className="cursor-point" onClick={onEditSkills} />
        </Stack>
      </Card.Title>
      <Card.Text as="div">
        {skills?.length ? (
          skills.map((skill) => (
            <Badge
              className="text-dark me-2 mb-2 p-2 text-uppercase"
              bg="light"
              key={skill.id}
            >
              {skill.name}
            </Badge>
          ))
        ) : (
          <span className="text-gray fst-italic">
            You have not added skills, click on the + to add yours
          </span>
        )}
      </Card.Text>
    </Card>
  );
}

export default Skills;
