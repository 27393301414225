import { BsStarFill } from '@react-icons/all-files/bs/BsStarFill';
import { Stack } from 'react-bootstrap';
import { useState } from 'react';

interface Props {
  value: number;
  setValue?: (val: number) => void;
  allowChange?: boolean;
  hideLabels?: boolean;
  size?: number;
  gap?: number;
}

export default function StarRatingButton({
  value,
  setValue,
  allowChange = true,
  hideLabels = false,
  size = 24,
  gap = 0,
}: Props) {
  const [hoverValue, setHoverValue] = useState<number | undefined>(undefined);

  function getColor(position: number) {
    if (hoverValue) {
      if (position <= hoverValue) {
        return 'var(--yellow)';
      }
      return 'var(--light-silver)';
    }
    if (position <= value) {
      return 'var(--yellow)';
    }
    return 'var(--light-silver)';
  }

  const starCount = [
    { position: 1, title: 'Poor' },
    { position: 2, title: 'Fair' },
    { position: 3, title: 'Average' },
    { position: 4, title: 'Good' },
    { position: 5, title: 'Perfect' },
  ];

  return (
    <Stack direction="horizontal" className="justify-content-start" gap={gap}>
      {starCount.map((star) => (
        <div
          key={`star-count-${star.position}`}
          className={`d-flex flex-column align-items-center star-container ${
            allowChange && ' cursor-point '
          }`}
          style={{ minWidth: hideLabels ? '' : 35 }}
          onClick={() => setValue && allowChange && setValue(star.position)}
          onMouseEnter={() =>
            setHoverValue && allowChange && setHoverValue(star.position)
          }
          onMouseLeave={() =>
            setHoverValue && allowChange && setHoverValue(undefined)
          }
        >
          <BsStarFill size={size} color={getColor(star.position)} />
          {!hideLabels && (
            <div>
              <sup>{star.title}</sup>
            </div>
          )}
        </div>
      ))}
    </Stack>
  );
}
