import { Alert, Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { differenceInDays } from 'date-fns';
import useAlert from '@hooks/useAlert';
import IBatchRateWorkerSession, {
  IBatchRateWorker,
} from '@company/types/batch-rate-worker.type';
import { REQUIRED_FIELD_MESSAGE } from '@constants/validation';

interface Props {
  control: Control<IBatchRateWorkerSession, null>;
  errors: FieldErrors<IBatchRateWorkerSession>;
  register: UseFormRegister<IBatchRateWorkerSession>;
  worker: IBatchRateWorker;
  setValue: UseFormSetValue<IBatchRateWorkerSession>;
  batchRateWorkerSession: IBatchRateWorkerSession | undefined;
}
function DaysWorked({
  control,
  errors,
  worker,
  register,
  setValue,
  batchRateWorkerSession,
}: Props) {
  const { alertMessage, showWarningAlert } = useAlert();

  function handleDaysChange(workerId: number, value: number): void {
    const periodStart =
      batchRateWorkerSession?.periods &&
      batchRateWorkerSession?.periods?.length > 0
        ? batchRateWorkerSession?.periods[0]?.start
        : undefined;
    const periodEnd =
      batchRateWorkerSession?.periods &&
      batchRateWorkerSession?.periods?.length > 0
        ? batchRateWorkerSession?.periods[0]?.end
        : undefined;
    const daysWorked =
      periodEnd && periodStart
        ? differenceInDays(new Date(periodEnd), new Date(periodStart)) + 1
        : undefined;

    if (value.toString().includes('.') || value < 0) {
      setValue(
        `workers.${workerId}.workerRating.approximatelyDaysWorked`,
        undefined
      );
    }
    if (periodEnd && periodStart && daysWorked) {
      if (value > daysWorked) {
        showWarningAlert('The number of days cannot exceed the date range');
        setValue(
          `workers.${workerId}.workerRating.approximatelyDaysWorked`,
          daysWorked
        );
      }
    }
  }

  return (
    <>
      <Form.Group controlId="rateWorkerForm.approximatelyDaysWorked">
        <Form.Label className="fw-bold">Approx. Days Worked*</Form.Label>
        <Controller
          control={control}
          name={`workers.${worker.id}.workerRating.approximatelyDaysWorked`}
          render={({ field: { onChange } }) => (
            <>
              <Form.Control
                {...register(
                  `workers.${worker.id}.workerRating.approximatelyDaysWorked`,
                  {
                    required: REQUIRED_FIELD_MESSAGE,
                  }
                )}
                type="number"
                min="0"
                inputMode="numeric"
                pattern="\d"
                autoComplete="off"
                step="1"
                placeholder="Enter # of days"
                className="mt-2"
                required
                onWheel={(e) => (e.target as HTMLElement).blur()}
                onChange={(e) => {
                  onChange(e);
                  handleDaysChange(worker.id, Number(e.target.value));
                }}
                isInvalid={
                  errors?.workers &&
                  !!errors?.workers[worker.id]?.workerRating
                    ?.approximatelyDaysWorked
                }
              />
              {errors?.workers &&
                !!errors?.workers[worker.id]?.workerRating
                  ?.approximatelyDaysWorked && (
                  <Form.Control.Feedback type="invalid">
                    {
                      errors?.workers[worker.id]?.workerRating
                        ?.approximatelyDaysWorked?.message
                    }
                  </Form.Control.Feedback>
                )}
            </>
          )}
        />
      </Form.Group>

      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default DaysWorked;
