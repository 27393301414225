import useUpdateWorker from '@company/state/useUpdateWorker/useUpdateWorker';
import { IWorkerAssignment } from '@company/types/workerAssignment';
import * as api from '@company/services/worker/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ThJobAssignmentLog } from '@th-types/job-assignment.type';
import DATE_FORMATS from '@constants/dateFormat';
import { ThLoading } from '@components/elements';
import QueryKeys from '@constants/queryKeys';
import { IWorkerData } from '@th-types/worker.type';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useCompanyContext } from '@company/state/companyContext';

interface WorkerHistoryParams {
  worker: IWorkerData;
  isActive: boolean;
}

export default function CompanyWorkerHistory({
  worker,
  isActive,
}: WorkerHistoryParams) {
  const [historyRows, setHistoryRows] = useState<ThJobAssignmentLog[] | null>(
    []
  );

  const { name: companyName } = useCompanyContext();
  const { updatedWorker } = useUpdateWorker();
  const queryKey = `${QueryKeys.WORKER_ACTIVITY_COMPANY}_${worker.id}`;
  const queryClient = useQueryClient();

  const { isLoading, data, isFetched } = useQuery<
    IWorkerAssignment,
    AxiosError
  >({
    queryKey: [queryKey],
    queryFn: () => api.getWorkerActivityCompanyHistory(worker.id),
    enabled: isActive,
  });

  useEffect(() => {
    if (updatedWorker.workerProfile && updatedWorker?.workerId === worker.id) {
      const updatedResult: IWorkerAssignment | undefined = {
        thJobAssignment: updatedWorker.workerProfile.jobAssignment!,
        thJobAssignmentLogList: updatedWorker.workerProfile.jobAssignmentLogs!,
      };
      queryClient.setQueryData([queryKey], updatedResult);
    }
  }, [updatedWorker, queryClient, queryKey, worker]);

  if (isLoading) return <ThLoading />;

  if (isFetched) {
    data?.thJobAssignmentLogList?.forEach((item) => {
      if (!historyRows?.find((x) => x.id === item.id)) {
        setHistoryRows((currentValues) =>
          currentValues
            ? [...currentValues, item].sort((a, b) => b.id - a.id)
            : [item]
        );
      }
    });
  }

  return (
    <Row className="mt-4">
      <p className="pb-4">
        <b>{`${worker.firstName}'s Engagement History with ${companyName}`}</b>
      </p>
      {historyRows?.map((history) => (
        <span key={history.id + Math.random()}>
          <p>
            <b>
              {format(new Date(history.dateCreated), DATE_FORMATS.DATE_FORMAT)}
            </b>
          </p>
          {history.reason ? (
            <p>
              <b>{history.reason}: </b>
              {`The worker is on `}
              <b>{history.toStatusNatural}</b> {' status at '}
              <b>
                {format(
                  new Date(history.dateCreated),
                  DATE_FORMATS.TIME_FORMAT
                )}
              </b>
            </p>
          ) : (
            <p>
              {`${history.toStatusNatural} for `}
              <b>{history.jobEventName}</b> {' as '}
              <b>{history.jobTitle}</b> {' at '}
              <b>
                {format(
                  new Date(history.dateCreated),
                  DATE_FORMATS.TIME_FORMAT
                )}
              </b>
            </p>
          )}
          <hr />
        </span>
      ))}
    </Row>
  );
}
