import { Form } from 'react-bootstrap';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import IBatchRateWorkerSession, {
  IBatchRateWorker,
} from '@company/types/batch-rate-worker.type';
import { StarRatingButton } from '@components/elements';
import { REQUIRED_FIELD_MESSAGE } from '@constants/validation';

interface Props {
  errors: FieldErrors<IBatchRateWorkerSession>;
  control: Control<IBatchRateWorkerSession, null>;
  worker: IBatchRateWorker;
  onRate: () => void;
}
function Rating({ errors, control, worker, onRate }: Props) {
  return (
    <Form.Group controlId="rateWorkerForm.performance">
      <Controller
        control={control}
        name={`workers.${worker.id}.workerRating.performance`}
        rules={{ required: REQUIRED_FIELD_MESSAGE }}
        render={({ field: { onChange, value } }) => (
          <>
            <StarRatingButton
              value={value!}
              setValue={(val) => {
                onChange(val);
                onRate();
              }}
              hideLabels
              size={30}
              gap={3}
            />
            {errors?.workers &&
              !!errors?.workers[worker.id]?.workerRating?.performance && (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: 'block  ' }}
                >
                  {
                    errors?.workers[worker.id]?.workerRating?.performance
                      ?.message
                  }
                </Form.Control.Feedback>
              )}
          </>
        )}
      />
    </Form.Group>
  );
}

export default Rating;
