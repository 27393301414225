import { Button, Col, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import { Panel } from '@components/elements';

interface Props {
  startedJobAssignmentParam: boolean;
}
const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

function SuccessReview({ startedJobAssignmentParam }: Props) {
  function handleAnother(): void {
    if (startedJobAssignmentParam) {
      window.location.href = `${SERVER_URL}/workerJobs/jobHistory`;
    } else {
      window.location.reload();
    }
  }

  function handleSkip(): void {
    window.location.href = `${SERVER_URL}`;
  }

  return (
    <>
      <Col
        md={{ span: 6, offset: 3 }}
        xs={12}
        className="d-flex justify-content-center"
      >
        <Panel>
          <Row>
            <Col xs={12}>
              <h4>
                <b>Success</b>
              </h4>
            </Col>
            <Col xs={12} className="mt-3">
              <b>What now?</b>
            </Col>
            <Col xs={12} className="mt-3">
              We will review and approve your rating before it goes live on
              Trusted Herd.
            </Col>
            <Col xs={12} className="mt-3">
              Rate more gigs to increase your profile status!
            </Col>
          </Row>
        </Panel>
      </Col>
      <Col
        md={{ span: 6, offset: 3 }}
        xs={12}
        className="d-flex justify-content-center mt-3"
      >
        <Row className="w-100">
          <Col md={6} xs={12} className="d-flex justify-content-end">
            <Button
              variant="light"
              type="button"
              onClick={() => handleAnother()}
              className={isMobile ? 'w-100' : ''}
            >
              <b>Rate Another</b>
            </Button>
          </Col>
          <Col md={6} xs={12} className={isMobile ? 'mt-3' : ''}>
            <Button
              variant="primary"
              type="button"
              onClick={() => handleSkip()}
              className={isMobile ? 'w-100' : ''}
            >
              <b>I&apos;m Done</b>
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default SuccessReview;
