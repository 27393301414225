import { Badge, Col, Container, Image, Row } from 'react-bootstrap';
import { Panel, StarRating } from '@components/elements';
import { Comment, PersonType } from '@th-types/workerProfile.type';
import { format } from 'date-fns';
import DATE_FORMATS from '@constants/dateFormat';
import { FaCircleCheck } from 'react-icons/fa6';
import './style.css';
import { isMobile } from 'react-device-detect';
import {
  IRatingVisibility,
  RATINGS_CURRENT_VERSION,
} from '@th-types/rating.type';

interface JobWorkerReviewsParams {
  comments?: Comment[];
}

export default function WorkerReviews({ comments }: JobWorkerReviewsParams) {
  const IMAGE_DEFAULT_SIZE = 35;

  const getRatingCard = (comment: Comment, index: number) => {
    return (
      <Col md={6} key={comment.id} className="mb-5 mt-1">
        <Container className="d-flex">
          <Col md={12} className="p-1">
            <Row className="text-black-50 d-flex">
              <Col md={6} xs={6}>
                <div className="mt-1">
                  <small>
                    {format(
                      new Date(comment.dateCreated),
                      DATE_FORMATS.DATE_FORMAT
                    )}
                  </small>
                </div>
              </Col>
              <Col
                md={6}
                xs={6}
                className={isMobile ? '' : 'd-flex justify-content-end'}
              >
                <div className="mb-2 me-3">
                  {comment.noCallNoShowed ? (
                    <Badge className="no-call-no-show">No Call/No Show</Badge>
                  ) : (
                    <StarRating value={comment.avgRating || 0} color="yellow" />
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4} xs={6} className="mt-1 fw-bold">
                Company
              </Col>
              <Col className="d-flex">
                <Col md={1}>
                  <Image
                    width={IMAGE_DEFAULT_SIZE}
                    height={IMAGE_DEFAULT_SIZE}
                    src={comment.profileImageUrl}
                    roundedCircle
                  />
                </Col>
                <Col>
                  <p className="ms-3 mt-1">{comment.companyName}</p>
                </Col>
              </Col>
            </Row>
            {comment.position && (
              <Row className="mt-2">
                <Col md={4} xs={6}>
                  <span className="fw-bold">Position:</span>
                </Col>
                <Col>{comment.position}</Col>
              </Row>
            )}
            {comment.workedDays && (
              <Row className="mt-2">
                <Col md={4} xs={6}>
                  <span className="fw-bold">Days worked:</span>
                </Col>
                <Col>{comment.workedDays}</Col>
              </Row>
            )}
            <hr className="table-horizontal-line mt-3 mb-1" />
            {comment.ratingVersion === RATINGS_CURRENT_VERSION &&
              (comment.comments || comment.improvementComments) && (
                <>
                  <Row className="mt-3">
                    <div>
                      <strong>What did this worker do really well?</strong>
                    </div>
                    <div className="mt-1">{comment.comments}</div>
                  </Row>
                  <Row className="mt-3">
                    <div>
                      <strong>What can they improve on?</strong>
                      {comment.visibility === IRatingVisibility.PRIVATE &&
                      comment.isSameCompany ? (
                        <small style={{ color: 'var(--silver)' }}>
                          {' '}
                          (This is visible only for your company and the worker)
                        </small>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="mt-1">
                      {comment.visibility === IRatingVisibility.PUBLIC ||
                      comment.isSameCompany
                        ? comment.improvementComments ?? 'N/A'
                        : 'N/A'}
                    </div>
                  </Row>
                </>
              )}
            {comment.ratingVersion !== 2 && (
              <Row className="mt-3">
                <Col className="mt-1" md={12}>
                  {comment.comments}
                </Col>
              </Row>
            )}
          </Col>
          {!isMobile && index % 2 === 0 && <div className="vl ms-3" />}
        </Container>
      </Col>
    );
  };

  const getEndorsementCard = (comment: Comment, index: number) => {
    return (
      <Col md={6} key={comment.id} className="mb-5 mt-1">
        <Container className="d-flex">
          <Col md={12} xs={12} className="p-1">
            <Row className="text-black-50 d-flex">
              <Col md={6} xs={6}>
                <div className="mt-1">
                  <small>
                    {format(
                      new Date(comment.dateCreated),
                      DATE_FORMATS.DATE_FORMAT
                    )}
                  </small>
                </div>
              </Col>
              <Col
                md={6}
                xs={6}
                className={isMobile ? '' : 'd-flex justify-content-end'}
              >
                {comment.personType === PersonType.COMPANY_MEMBER.valueOf() && (
                  <div className="mt-1 me-3">
                    <StarRating value={comment.avgRating || 0} color="yellow" />
                  </div>
                )}
                <div className="mt-1 me-3">
                  <small>
                    <b>Endorsed</b>
                  </small>
                  <FaCircleCheck
                    className="icon-style ms-1"
                    size={14}
                    color="var(--yellow)"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4} xs={6} className="mt-1 fw-bold">
                {comment.personType === PersonType.WORKER
                  ? 'From'
                  : 'From Company'}
              </Col>
              <Col className="d-flex">
                <Col md={1}>
                  <Image
                    width={IMAGE_DEFAULT_SIZE}
                    height={IMAGE_DEFAULT_SIZE}
                    src={comment.profileImageUrl}
                    roundedCircle
                    className="mb-2"
                  />
                </Col>
                <Col>
                  <p className="ms-3 mt-1">
                    {comment.personType === PersonType.COMPANY_MEMBER.valueOf()
                      ? comment.companyName
                      : comment.authorName}
                  </p>
                </Col>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4} xs={6}>
                <span className="fw-bold">Events worked</span>
              </Col>
              <Col>{comment.sharedEventsNumber}</Col>
            </Row>
            <hr className="table-horizontal-line mt-3 mb-1" />
            <Row className="mt-3">
              <Col md={12} xs={12}>
                {comment.comments}
              </Col>
            </Row>
          </Col>
          {!isMobile && index % 2 === 0 && <div className="vl ms-3" />}
        </Container>
      </Col>
    );
  };

  return (
    <Col md={12} className="mt-4">
      <Panel>
        <Row>
          <Col md={12} className="d-flex justify-content-start">
            <div className="text-center fs-5 fw-bold">
              <p>Reviews and Endorsements</p>
            </div>
          </Col>
        </Row>
        <Row>
          {!comments && (
            <p className=" mt-3 text-black-50 font-weight-bold">{`Know this worker's quality? help them out by clicking "Endorse" or "Review" now!`}</p>
          )}
          {comments &&
            comments.map((comment, index) => {
              return comment.type === 'rating'
                ? getRatingCard(comment, index)
                : getEndorsementCard(comment, index);
            })}
        </Row>
      </Panel>
    </Col>
  );
}
