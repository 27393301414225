import { Tabs } from 'react-bootstrap';
import { TabsProps } from 'react-bootstrap/Tabs';
import './style.css';

interface Props extends TabsProps {
  borderBottom?: boolean;
}

export default function ThTabs(props: Props) {
  const { children, borderBottom, className, ...domProps } = props;
  return (
    <Tabs
      {...domProps}
      className={`${className} ${borderBottom ? '' : 'removeBorderBottom'}`}
    >
      {children}
    </Tabs>
  );
}
