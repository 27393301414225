import Button from 'react-bootstrap/Button';
import './style.scss';

type Props = {
  name: string;
  total?: number;
  selected?: boolean;
  onClick?: () => void;
};

export default function ThFilterTab({ name, total, selected, onClick }: Props) {
  const selectedStyle = `btn-th-filter-tab__${name.toLowerCase()} fw-bold`;
  return (
    <Button
      className={`${selected ? selectedStyle : ''} btn-th-filter-tab`}
      size="sm"
      onClick={onClick}
    >
      {name} {total && `(${total})`}
    </Button>
  );
}
