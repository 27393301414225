import React from 'react';
import ContentLoader from 'react-content-loader';

function LoadPersonCircle(props: any) {
  return (
    <ContentLoader
      speed={1}
      width={476}
      height={50}
      viewBox="0 0 476 40"
      backgroundColor="#f5f5f5"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle cx="20" cy="20" r="20" />
      <circle cx="80" cy="20" r="20" />
      <circle cx="140" cy="20" r="20" />
    </ContentLoader>
  );
}

export default LoadPersonCircle;
