import { showJobWithNoShiftsMessage } from '@company/components/ShiftTables/ShiftTables';
import { useJobWorkerShiftContext } from '@company/state/jobWorkerShiftContext';
import { filterShiftsByDate } from '@services/shift/shiftService';
import { BrowserView, MobileView } from 'react-device-detect';
import { Fragment, useMemo } from 'react';
import JobWorkerShiftTableMobile from './JobWorkerShiftTableMobile';
import JobWorkerShiftTable from './JobWorkerShiftTable';
import './styles.scss';

interface JobWorkerShiftTablesProps {
  showResumed?: boolean;
  isEditable?: boolean;
  isSelectable?: boolean;
}

function JobWorkerShiftTables({
  showResumed = false,
  isEditable = true,
  isSelectable = true,
}: JobWorkerShiftTablesProps) {
  const {
    setSelectedJobWorkerShifts,
    selectedJobWorkerShifts,
    jobWorkerShifts,
  } = useJobWorkerShiftContext();

  const groupedShiftsByDate = useMemo(() => {
    return filterShiftsByDate(jobWorkerShifts);
  }, [jobWorkerShifts]);

  const handleSelecting = (id: number) => {
    const newCheckedIds = new Set(selectedJobWorkerShifts.map((x) => x.id));
    if (newCheckedIds.has(id)) {
      newCheckedIds.delete(id);
    } else {
      newCheckedIds.add(id);
    }
    const newSelectedShifts = jobWorkerShifts.filter((item) =>
      newCheckedIds.has(item.id)
    );
    setSelectedJobWorkerShifts(newSelectedShifts || []);
  };

  const getShiftTableComponent = () => {
    if (groupedShiftsByDate) {
      return groupedShiftsByDate.map(({ date, data }) => {
        return (
          <Fragment key={date}>
            <MobileView>
              <JobWorkerShiftTableMobile
                date={date}
                shifts={data}
                showResumed={showResumed}
                handleSelecting={handleSelecting}
              />
            </MobileView>
            <BrowserView>
              <JobWorkerShiftTable
                date={date}
                shifts={data}
                handleSelecting={handleSelecting}
                isSelectable={isSelectable}
                isEditable={isEditable}
              />
            </BrowserView>
          </Fragment>
        );
      });
    }
    return 'There are no shifts available.';
  };

  return (
    <>
      {(!jobWorkerShifts || jobWorkerShifts?.length === 0) && (
        <div className="mt-3 d-flex justify-content-center">
          {showJobWithNoShiftsMessage()}
        </div>
      )}
      {getShiftTableComponent()}
    </>
  );
}

export default JobWorkerShiftTables;
