import { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { MultiValue } from 'react-select/dist/declarations/src/types';
import IBatchRateWorkerSession from '@company/types/batch-rate-worker.type';
import { Panel } from '@components/elements';
import Select from 'react-select';
import SelectOption from '@th-types/selectOption.type';
import { IWorkerSuggestion } from '@th-types/suggestions.type';
import { debounce } from 'lodash';
import { IJobOptionsData, IJobTitle } from '@th-types/jobs.type';
import ThDateRangePicker from '@components/elements/Datepicker';
import useAlert from '@hooks/useAlert';
import * as workerApi from '@company/services/worker/api';
import { ThWorkerSelect } from '@company/components/th';
import { IFetchWorkerCallBack } from '@company/components/th/ThWorkerSelect/ThWorkerSelect';
import { CompanyOption } from '@company/types/rate-worker-creation.type';
import { IDatePeriod, datePeriodToString } from '@th-types/period.type';
import { REQUIRED_FIELD_MESSAGE } from '@constants/validation';
import './style.css';

interface Props {
  company: string | null;
  eventName?: string;
  brandRepresented?: string;
  period?: IDatePeriod;
  jobOptions: IJobOptionsData | null;
  workerOptions: IWorkerSuggestion[];
  setWorkerOptions: (value: IWorkerSuggestion[]) => void;
  setBatchRateWorkerSession: (value: IBatchRateWorkerSession) => void;
  stepTitle?: string;
  stepTitleMobile?: string;
  nextStep?: () => void;
  isActive?: boolean;
}

function GigInfo({
  company,
  eventName,
  brandRepresented,
  jobOptions,
  period,
  setBatchRateWorkerSession,
  setWorkerOptions,
  stepTitle,
  nextStep,
  isActive,
}: Props) {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IBatchRateWorkerSession>();
  const { alertMessage, showWarningAlert } = useAlert();
  const [periodStart, setPeriodStart] = useState('');
  const [periodEnd, setPeriodEnd] = useState('');
  const [showOtherPosition, setShowOtherPosition] = useState(false);
  const [selectedWorkers, setSelectedWorkers] = useState<
    MultiValue<IWorkerSuggestion>
  >([]);

  const selectedWorkersCount = selectedWorkers.length;
  const MAX_WORKER_BATCH_SIZE = 15;
  const DEBOUNCE_SEARCH_WORKERS = 500;
  const DEBOUNCE_SEARCH_JOBS = 100;
  const MIN_SEARCH_INPUT = 2;

  const companyOptions: CompanyOption[] = [{ name: company!, value: company! }];

  const mountSelect = (
    arr: CompanyOption[] | IJobTitle[] | undefined,
    field1: string,
    field2: string
  ): { value: number | string; label: string }[] => {
    return !arr
      ? []
      : arr.map((item: CompanyOption | IJobTitle) => {
          return {
            value: item[field1 as keyof typeof item],
            label: item[field2 as keyof typeof item],
          };
        });
  };

  const handleChangeWorker = (
    value: MultiValue<IWorkerSuggestion>,
    onChange: (value: MultiValue<IWorkerSuggestion>) => void
  ) => {
    onChange(value);
    setSelectedWorkers(value);
    setWorkerOptions([...value]);
  };

  const onJobPosition = debounce(() => {
    const jobTitle = jobOptions?.jobTitles.find(
      (jobOption: IJobTitle) => jobOption.id === getValues('title.id')
    );

    if (jobTitle?.name === 'Other') {
      setShowOtherPosition(true);
    } else {
      setShowOtherPosition(false);
      setValue('otherJobTitleDescription', '');
    }
  }, DEBOUNCE_SEARCH_JOBS);

  const fetchSuggestions = debounce(
    (input: string, callback: IFetchWorkerCallBack) => {
      workerApi.fetchSuggestedWorkers(input).then(({ suggestions }) => {
        callback(suggestions);
      });
    },
    DEBOUNCE_SEARCH_WORKERS
  );

  const fetchWorkersOptions = (
    input: string,
    callback: IFetchWorkerCallBack
  ) => {
    if (
      !input ||
      input.length < MIN_SEARCH_INPUT ||
      selectedWorkersCount >= MAX_WORKER_BATCH_SIZE
    ) {
      if (selectedWorkersCount >= MAX_WORKER_BATCH_SIZE) {
        showWarningAlert('Max workers selected');
      }
      const suggestions: IWorkerSuggestion[] = [];
      return callback(suggestions);
    }
    return fetchSuggestions(input, callback);
  };

  useEffect(() => {
    if (company) {
      setValue('companyName', company);
    }
    if (eventName) {
      setValue('eventName', eventName);
    }
    if (brandRepresented) {
      setValue('brandRepresented', brandRepresented);
    }
    if (brandRepresented) {
      setValue('brandRepresented', brandRepresented);
    }
    if (period) {
      setValue('periods', [datePeriodToString(period)]);
    }
    if (jobOptions?.jobTitles!.length === 1) {
      setValue('title', jobOptions.jobTitles[0]);
    }
  }, [brandRepresented, company, eventName, jobOptions, period, setValue]);

  const onSubmit = async (dataRequest: IBatchRateWorkerSession) => {
    const body: IBatchRateWorkerSession = {
      ...dataRequest,
      companyName: company || '',
      eventName: eventName || dataRequest.eventName,
      brandRepresented: brandRepresented || dataRequest.brandRepresented,
      periods: [{ start: periodStart, end: periodEnd }],
      otherJobTitleDescription: showOtherPosition
        ? dataRequest.otherJobTitleDescription
        : '',
      title:
        jobOptions?.jobTitles?.length === 1
          ? jobOptions?.jobTitles[0]
          : dataRequest.title,
      jobId:
        jobOptions?.jobTitles?.length === 1
          ? jobOptions?.jobTitles[0].id
          : dataRequest.title.id,
    };

    setBatchRateWorkerSession(body);

    if (nextStep) {
      window.scrollTo(0, 0);
      nextStep();
    }
  };

  return (
    <>
      <Row key={stepTitle} className={isActive ? 'd-block' : 'd-none'}>
        <Col md={{ span: 6, offset: 3 }}>
          <Panel className={isMobile ? 'shadow-none' : 'panel'}>
            <Form id="rateGigForm" noValidate onSubmit={handleSubmit(onSubmit)}>
              <Row>
                {!isMobile && (
                  <Col xs={12} className="fw-bold fs-5">
                    Gig Information
                  </Col>
                )}
                <Col
                  xs={12}
                  className={`mt-3 ${isMobile ? 'fw-bold fs-6' : ''}`}
                >
                  Enter your gig&apos;s information and choose which workers
                  were part of this gig&apos;s team.
                </Col>
                <Col xs={12} className="mt-3">
                  <Form.Group controlId="rateGigForm.eventName">
                    <Form.Label className="fw-bold">Event Name*</Form.Label>
                    <Form.Control
                      placeholder="Enter event name"
                      className={`mt-2 ${eventName ? 'disabled-input' : ''}`}
                      {...register('eventName', {
                        required: REQUIRED_FIELD_MESSAGE,
                      })}
                      required
                      autoComplete="off"
                      value={eventName}
                      disabled={!!eventName}
                      isInvalid={!!errors?.eventName}
                    />
                    {errors?.eventName && (
                      <Form.Control.Feedback type="invalid">
                        {errors?.eventName?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} className="mt-3">
                  <Form.Group controlId="rateGigForm.companyName">
                    <Form.Label className="fw-bold">Hiring Company*</Form.Label>
                    <Controller
                      control={control}
                      name="companyName"
                      rules={{ required: REQUIRED_FIELD_MESSAGE }}
                      render={({ field: { name, ref, onChange } }) => (
                        <>
                          <Select
                            name={name}
                            ref={ref}
                            required
                            isDisabled
                            isClearable
                            inputId="rateGigForm.companyName"
                            options={mountSelect(
                              companyOptions,
                              'value',
                              'name'
                            )}
                            className={
                              errors?.companyName
                                ? 'form-control is-invalid'
                                : ''
                            }
                            onChange={onChange}
                            value={
                              mountSelect(companyOptions, 'value', 'name')[0]
                            }
                          />
                          {errors?.companyName && (
                            <Form.Control.Feedback type="invalid">
                              {errors?.companyName?.message}
                            </Form.Control.Feedback>
                          )}
                        </>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mt-3">
                  <Form.Group controlId="rateGigForm.brandRepresented">
                    <Form.Label className="fw-bold">
                      Brand Represented*
                    </Form.Label>
                    <Form.Control
                      placeholder="Enter represented brand"
                      className={`mt-2 ${
                        brandRepresented ? 'disabled-input' : ''
                      }`}
                      {...register('brandRepresented', {
                        required: REQUIRED_FIELD_MESSAGE,
                      })}
                      required
                      autoComplete="off"
                      value={brandRepresented}
                      disabled={!!brandRepresented}
                      isInvalid={!!errors?.brandRepresented}
                    />
                    {errors?.brandRepresented && (
                      <Form.Control.Feedback type="invalid">
                        {errors?.brandRepresented?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} className="mt-3">
                  <Form.Group controlId="rateGigForm.title">
                    <Form.Label className="fw-bold">Job Title*</Form.Label>
                    <Controller
                      control={control}
                      name="title.id"
                      rules={{ required: REQUIRED_FIELD_MESSAGE }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <>
                          <Select
                            name={name}
                            ref={ref}
                            required
                            isClearable
                            inputId="rateGigForm.title"
                            placeholder="Select job title"
                            options={mountSelect(
                              jobOptions?.jobTitles,
                              'id',
                              'name'
                            )}
                            value={mountSelect(
                              jobOptions?.jobTitles,
                              'id',
                              'name'
                            ).find((c: SelectOption) => c.value === value)}
                            defaultValue={
                              jobOptions?.jobTitles!.length === 1
                                ? mountSelect(
                                    jobOptions?.jobTitles,
                                    'id',
                                    'name'
                                  )[0]
                                : null
                            }
                            className={
                              jobOptions?.jobTitles!.length !== 1 &&
                              errors?.title?.id
                                ? 'form-control is-invalid'
                                : ''
                            }
                            isDisabled={jobOptions?.jobTitles!.length === 1}
                            onChange={(val) => onChange(val?.value)}
                            onInputChange={onJobPosition}
                          />
                          {jobOptions?.jobTitles!.length !== 1 &&
                            errors?.title?.id && (
                              <Form.Control.Feedback type="invalid">
                                {errors?.title?.id?.message}
                              </Form.Control.Feedback>
                            )}
                        </>
                      )}
                    />
                  </Form.Group>
                </Col>
                {showOtherPosition && (
                  <Col xs={12} className="mt-3">
                    <Form.Group controlId="rateGigForm.otherJobTitleDescription">
                      <Form.Label className="fw-bold">Other*</Form.Label>
                      <Form.Control
                        placeholder="Enter Other Job Position"
                        className="mt-2"
                        {...register('otherJobTitleDescription', {
                          required: REQUIRED_FIELD_MESSAGE,
                        })}
                        required
                        autoComplete="off"
                        isInvalid={!!errors?.otherJobTitleDescription}
                      />
                      {errors?.otherJobTitleDescription && (
                        <Form.Control.Feedback type="invalid">
                          {errors?.otherJobTitleDescription?.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                )}
                <Col xs={12} className="mt-3">
                  <Form.Group controlId="rateGigForm.periods">
                    <Form.Label className="fw-bold">Dates Worked*</Form.Label>
                    <Row className="mt-2">
                      <Col>
                        <Controller
                          control={control}
                          name="periods"
                          rules={{ required: REQUIRED_FIELD_MESSAGE }}
                          render={({ field: { onChange } }) => (
                            <ThDateRangePicker
                              placeholder="Select Date Range"
                              setStart={setPeriodStart}
                              setEnd={setPeriodEnd}
                              onChange={onChange}
                              isInvalid={!!errors.periods}
                              validationError={errors.periods?.message}
                              start={period?.start}
                              end={period?.end}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col xs={12} className="mt-3">
                  <Form.Group controlId="rateGigForm.workers">
                    <Form.Label className="fw-bold">Workers*</Form.Label>
                    <Row className="mt-3">
                      <Col md={isMobile ? 12 : 8}>
                        You can select up to {MAX_WORKER_BATCH_SIZE} workers
                        using this gig info.
                      </Col>
                      <Col
                        xs={isMobile ? 12 : 4}
                        className={`fw-bold d-flex mb-2 ${
                          isMobile ? '' : 'justify-content-end'
                        }`}
                      >
                        {selectedWorkersCount}/{MAX_WORKER_BATCH_SIZE} workers
                        selected
                      </Col>
                    </Row>
                    <Controller
                      control={control}
                      name="workers"
                      rules={{ required: REQUIRED_FIELD_MESSAGE }}
                      render={({ field: { onChange, name, ref } }) => (
                        <>
                          <ThWorkerSelect
                            inputId="rateGigForm.workers"
                            name={name}
                            parentRef={ref}
                            required
                            className={
                              errors?.workers ? 'form-control is-invalid' : ''
                            }
                            fetchWorkersOptions={fetchWorkersOptions}
                            handleChangeWorker={(val) =>
                              handleChangeWorker(val, onChange)
                            }
                            canSelectMore={
                              selectedWorkersCount < MAX_WORKER_BATCH_SIZE
                            }
                          />
                          {errors?.workers && (
                            <Form.Control.Feedback type="invalid">
                              {errors?.workers?.message}
                            </Form.Control.Feedback>
                          )}
                        </>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Panel>
        </Col>
      </Row>
      <Row className={`mb-5 ${isActive ? 'd-block' : 'd-none'}`}>
        <Col md={{ span: 6, offset: 3 }}>
          <Stack
            direction="horizontal"
            className="d-flex justify-content-center"
            gap={3}
          >
            <Button
              type="submit"
              form="rateGigForm"
              className={`fw-bold mt-4 ${isMobile ? 'w-100' : 'w-25'}`}
            >
              Continue
            </Button>
          </Stack>
        </Col>
      </Row>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default GigInfo;
