import { useState } from 'react';
import { Alert, Col, Row, Tab } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { isEqual, sortBy } from 'lodash';
import useAlert from '@hooks/useAlert';
import { ThLoading } from '@components/elements';
import { workerRatingsSessionBatch } from '@company/services/rating/api';
import IBatchRateWorkerSession, {
  IBatchRateWorker,
  IBatchWorkerRatings,
  IWorkerRatingData,
} from '@company/types/batch-rate-worker.type';
import { IJobOptionsData } from '@th-types/jobs.type';
import { IRatingVisibility } from '@th-types/rating.type';
import WorkerList from './WorkerList';
import WorkerListMobile from './WorkerListMobile';
import WorkerRatingData from './WorkerRatingData';

import './style.css';

interface Props {
  isTHJob: boolean;
  jobOptions: IJobOptionsData | null;
  workerOptions: IBatchRateWorker[];
  batchRateWorkerSession?: IBatchRateWorkerSession;
  setBatchRateWorkerSession: (value: IBatchRateWorkerSession) => void;
  stepTitle?: string;
  nextStep?: () => void;
  previousStep?: () => void;
  isActive?: boolean;
}

function WorkerRating({
  isTHJob,
  jobOptions,
  workerOptions,
  batchRateWorkerSession,
  setBatchRateWorkerSession,
  stepTitle,
  nextStep,
  previousStep,
  isActive,
}: Props) {
  const { showErrorAlert, alertMessage } = useAlert();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IBatchRateWorkerSession>({
    defaultValues: batchRateWorkerSession,
  });
  const [loading, setLoading] = useState(false);
  const [activeWorkerIdx, setActiveWorkerIdx] = useState<number>(0);
  const [completedWorkerIds, setCompletedWorkerIds] = useState<number[]>([]);

  const scrollToTop = () => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  };

  const isOtherJobTitle = (worker: IBatchRateWorker) => {
    return (
      jobOptions?.jobTitles.find(
        (t) => t.id === worker?.workerRating?.title?.id
      )?.name === 'Other'
    );
  };

  const isWorkerCompleted = (worker: IBatchRateWorker) => {
    const workerFormValues = getValues(`workers.${worker.id}`);

    const { workerRating } = workerFormValues || {};

    if (!workerRating) return false;

    const {
      title,
      otherJobTitleDescription,
      performance,
      approximatelyDaysWorked,
      photoIsRepresentative,
    } = workerRating;

    const isTitleValid = title != null;
    const isOtherJobTitleValid =
      !isOtherJobTitle(worker) || otherJobTitleDescription?.trim() !== '';
    const isPerformanceValid = performance != null;
    const isDaysWorkedValid = approximatelyDaysWorked?.toString().trim() !== '';
    const isPhotoRepresentativeValid = photoIsRepresentative != null;

    const workerIsComplete =
      isTitleValid &&
      isOtherJobTitleValid &&
      isPerformanceValid &&
      isDaysWorkedValid &&
      isPhotoRepresentativeValid;

    setCompletedWorkerIds((prevState) => {
      if (workerIsComplete && !prevState.includes(worker.id)) {
        return [...prevState, worker.id];
      }
      if (!workerIsComplete && prevState.includes(worker.id)) {
        return prevState.filter((id) => id !== worker.id);
      }
      return prevState;
    });

    return workerIsComplete;
  };

  const allWorkersCompleted = () => {
    return isEqual(
      sortBy(completedWorkerIds),
      sortBy(workerOptions.map((workerOption) => workerOption.id))
    );
  };

  const navigateToWorker = (key: number) => {
    setActiveWorkerIdx(key);
    scrollToTop();
  };

  const navigateToNextWorker = () => {
    navigateToWorker(activeWorkerIdx + 1);
  };

  const navigateToPreviousWorker = () => {
    navigateToWorker(activeWorkerIdx - 1);
  };

  const hasNextWorker = (index: number = 1) => {
    return (
      workerOptions.length > 1 && activeWorkerIdx < workerOptions.length - index
    );
  };

  const canSubmitForm = () => {
    const workerId =
      workerOptions &&
      workerOptions.length > 0 &&
      activeWorkerIdx < workerOptions.length
        ? workerOptions[Number(activeWorkerIdx)].id
        : -1;

    return (
      (workerId > 0 && hasNextWorker() && !isMobile) || allWorkersCompleted()
    );
  };

  const onSubmit = async (dataRequest: IBatchRateWorkerSession) => {
    if (batchRateWorkerSession && batchRateWorkerSession.sessionId) {
      const ratings = completedWorkerIds.map((id) => {
        const worker = batchRateWorkerSession.workers.find(
          (sessionWorker) => sessionWorker.id === id
        ) as IBatchRateWorker;
        const { workerRating: oldWorkerRating } = worker;
        const newWorkerRating = dataRequest.workers[id].workerRating;
        const { title } = newWorkerRating ?? {};
        return {
          ...oldWorkerRating,
          ...newWorkerRating,
          ...(title ? { title } : {}),
          otherJobTitleDescription: isOtherJobTitle(worker)
            ? newWorkerRating?.otherJobTitleDescription
            : '',
          visibility: newWorkerRating?.visibility ?? IRatingVisibility.PRIVATE,
        };
      });

      const { periods, sessionId } = batchRateWorkerSession;
      const ratingBody: IBatchWorkerRatings = {
        ratings,
        periods,
      };
      try {
        setLoading(true);
        const result = await workerRatingsSessionBatch(sessionId, ratingBody);

        if (result && result.ratingIds && result.ratingIds.length > 0) {
          const { ratingIds } = result;
          const updatedRatedWorkers: IBatchRateWorker[] =
            batchRateWorkerSession.workers.map((worker, index) => {
              const { workerRating } = worker;
              const ratedWorker: IBatchRateWorker = {
                ...worker,
                workerRating: {
                  ...workerRating,
                  ratingId: ratingIds[index],
                },
              };
              return {
                ...ratedWorker,
              };
            });
          const updatedBatchRating = {
            ...batchRateWorkerSession,
            workers: updatedRatedWorkers,
          };
          setBatchRateWorkerSession(updatedBatchRating);
        } else {
          console.log(
            'Occurred an error when Trusted Herd tried to Worker Ratings Batch: Rating ids is empty'
          );
          throw new Error();
        }
        if (nextStep) {
          nextStep();
        }
      } catch {
        showErrorAlert(
          'Occurred an error when Trusted Herd tried to Worker Ratings Batch'
        );
      } finally {
        scrollToTop();
        setLoading(false);
      }
    }
  };

  const onPrevious = () => {
    scrollToTop();

    if (!isMobile && activeWorkerIdx > 0) {
      navigateToPreviousWorker();
      return;
    }
    if (previousStep) {
      previousStep();
    }
  };

  const workerInfoData: IWorkerRatingData = {
    batchRateWorkerSession,
    jobOptions,
    workerOptions,
    activeWorkerIdx,
    isActive,
    loading,
    control,
    errors,
    isTHJob,
    allWorkersCompleted,
    setBatchRateWorkerSession,
    nextStep,
    previousStep,
    onPrevious,
    navigateToWorker,
    navigateToNextWorker,
    hasNextWorker,
    isWorkerCompleted,
    handleSubmit,
    canSubmitForm,
    onSubmit,
    setLoading,
    register,
    setValue,
    getValues,
    scrollToTop,
  };

  return (
    <>
      {loading && <ThLoading />}
      <MobileView>
        <WorkerListMobile
          workerOptions={workerOptions}
          workerInfoData={workerInfoData}
          completedWorkerIds={completedWorkerIds}
        />
      </MobileView>
      <BrowserView>
        <Tab.Container
          id="workers-list"
          activeKey={activeWorkerIdx}
          onSelect={(key) => navigateToWorker(Number(key!))}
          key={stepTitle}
        >
          <Row>
            <Col className="col-2">
              <WorkerList
                workerOptions={workerOptions}
                completedWorkerIds={completedWorkerIds}
              />
            </Col>
            <Col className="col-10">
              <Tab.Content>
                {batchRateWorkerSession?.workers.map((worker, index) => (
                  <Tab.Pane
                    key={worker.id}
                    eventKey={index}
                    className="offset-1 col-8"
                  >
                    <WorkerRatingData
                      worker={worker}
                      workerInfoData={workerInfoData}
                    />
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </BrowserView>

      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default WorkerRating;
