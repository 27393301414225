import { useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as paymentApi from '@company/services/payment/api';
import { ThLoading } from '@components/elements';
import ICompanyPremiumPlans, {
  BillingPeriod,
  CompanyPremiumPlan,
} from '@th-types/company-premium-plans.type';
import IPaymentMethods from '@th-types/payment-methods.type';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useCompanyContext } from '@company/state/companyContext';
import { AiOutlineClose } from 'react-icons/ai';
import { PaymentForm, PaymentFormRef } from './PaymentMethod';
import './styles.css';

interface ModalJoinPremiumParams {
  setShowModal: (show: boolean) => void;
  showMessage: (success: boolean, message: string) => void;
}

function ModalJoinPremium({
  setShowModal,
  showMessage,
}: ModalJoinPremiumParams) {
  const [isLoading, setIsLoading] = useState(true);
  const [plans, setPlans] = useState<ICompanyPremiumPlans>();
  const [selectedPlan, setSelectedPlan] = useState<CompanyPremiumPlan | null>(
    null
  );
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethods | null>(
    null
  );
  const [isCreditCardValidData, setCreditCardValidData] = useState(false);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState('');
  const [showAddCard, setShowAddCard] = useState(false);

  const handleClose = () => setShowModal(false);

  const paymentRef = useRef<PaymentFormRef>(null);
  const { fetchCompany } = useCompanyContext();

  const ADD_CARD = 'addCard';

  const getFirstLineDescription = (plan: CompanyPremiumPlan) => {
    switch (plan.billingPeriod) {
      case BillingPeriod.MONTHLY:
        return (
          <p className="card-text-2">
            <b>Monthly Membership:</b>
            {` $${plan.price.toFixed(2)} per month`}
          </p>
        );
      case BillingPeriod.HALF_YEARLY:
        return (
          <p className="card-text-2">
            <b>6 Monthly Membership:</b>
            {` $${plan.price.toFixed(2)} every six months`}
          </p>
        );
      case BillingPeriod.YEARLY:
        return (
          <p className="card-text-2">
            <b>Anual Membership:</b>
            {` $${plan.price.toFixed(2)} for 365 days`}
          </p>
        );
      default:
        return <div />;
    }
  };

  useMemo(async () => {
    const plansData = await paymentApi.fetchPremiumPlans();
    setPlans(plansData);
    setSelectedPlan(
      plansData.plans.find(
        (plan: CompanyPremiumPlan) =>
          plan.billingPeriod === BillingPeriod.YEARLY
      )
    );
    setPaymentMethods(await paymentApi.fetchPaymentMethods());
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setShowAddCard(selectedPaymentMethodId === ADD_CARD);
  }, [selectedPaymentMethodId]);

  if (!plans?.publishableKey) {
    return <div />;
  }

  const stripePromise = loadStripe(plans?.publishableKey);

  const processPayment = async (paymentMethodId: number) => {
    if (selectedPlan && paymentMethodId) {
      const result = await paymentApi.payPremiumSubscription(
        selectedPlan.id,
        paymentMethodId
      );
      if (result.success) {
        fetchCompany();
        showMessage(true, result.message);
      } else {
        showMessage(false, result.message);
      }
    }
  };

  const handlePaymentClick = async (event: React.FormEvent) => {
    setIsLoading(true);
    try {
      if (selectedPaymentMethodId === ADD_CARD) {
        const newCreditCardId = await paymentRef.current?.handleSubmit(event);
        if (newCreditCardId) {
          await processPayment(newCreditCardId);
        }
      } else {
        await processPayment(Number(selectedPaymentMethodId));
      }
    } catch (error: any) {
      showMessage(false, error.message);
    }
    setIsLoading(false);
    handleClose();
  };

  const onCardPlanClick = async (plan: CompanyPremiumPlan) => {
    setSelectedPlan(plan);
  };

  const disablePaymentButton = () => {
    if (isLoading) {
      return true;
    }
    if (!selectedPaymentMethodId) {
      return true;
    }
    if (selectedPaymentMethodId === ADD_CARD) {
      return !isCreditCardValidData || !selectedPlan;
    }
    return !selectedPlan || !selectedPaymentMethodId;
  };

  return (
    <Modal
      dialogClassName="modal-premium-company"
      show
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title className="text-justify m-auto">
          Premium Membership Offers
        </Modal.Title>
        <Button variant="transparent" onClick={() => setShowModal(false)}>
          <AiOutlineClose />
        </Button>
      </Modal.Header>

      <Modal.Body>
        <Container>
          {isLoading && <ThLoading />}
          {plans?.plans.map((plan) => (
            <Card
              bg={selectedPlan?.id === plan.id ? 'secondary' : ''}
              className="mt-3 p-3 cursor-point"
              key={plan.id}
              onClick={() => onCardPlanClick(plan)}
            >
              <Row className="ms-1 me-0 align-items-center">
                <Col xs={9}>
                  <Row>{getFirstLineDescription(plan)}</Row>
                  <Row>
                    <p>{plan.description}</p>
                  </Row>
                </Col>
                <Col className="mb-3" xs={3}>
                  <b>${plan.price.toFixed(2)}</b>
                </Col>
              </Row>
            </Card>
          ))}
          <div className="divider mt-3" />
          <Form.Select
            onChange={(e) => setSelectedPaymentMethodId(e.target.value)}
          >
            <option value="">Select card</option>
            <option value={ADD_CARD}>Add new card</option>
            {paymentMethods &&
              paymentMethods.creditCards.map((card) => (
                <option key={card.id} value={card.id}>
                  {`${card.brand} ending in ${card.endsWith} - Expiring ${card.expirationMonth}/${card.expirationYear}`}
                </option>
              ))}
          </Form.Select>

          {showAddCard && (
            <Row className="mt-3 me-0 ms-0">
              {stripePromise && (
                <Elements stripe={stripePromise}>
                  <PaymentForm
                    setPaymentMethodId={setSelectedPaymentMethodId}
                    setCreditCardValidData={setCreditCardValidData}
                    ref={paymentRef}
                  />
                </Elements>
              )}
            </Row>
          )}
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={handlePaymentClick}
          disabled={disablePaymentButton()}
          variant="primary"
        >
          Join TH Premium
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalJoinPremium;
