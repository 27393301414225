import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select/dist/declarations/src/Select';
import { GroupBase, MultiValue, OptionsOrGroups } from 'react-select';
import { IWorkerSuggestion } from '@th-types/suggestions.type';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import ThWorkerSelectOption from './ThWorkerSelectOption';

export declare type IFetchWorkerCallBack = (
  options: OptionsOrGroups<IWorkerSuggestion, GroupBase<IWorkerSuggestion>>
) => void;
interface Params extends StateManagerProps {
  name: string;
  parentRef: React.Ref<
    Select<IWorkerSuggestion, true, GroupBase<IWorkerSuggestion>>
  >;
  fetchWorkersOptions:
    | ((input: string) => Promise<IWorkerSuggestion[]>)
    | ((input: string, callback: IFetchWorkerCallBack) => void);
  handleChangeWorker: (val: MultiValue<IWorkerSuggestion>) => void;
  canSelectMore?: boolean;
  required?: boolean;
}

const formatOptionLabel = ({ name }: IWorkerSuggestion) => (
  <div className="d-flex">{name}</div>
);

function ThWorkerSelect({
  name,
  parentRef,
  fetchWorkersOptions,
  handleChangeWorker,
  canSelectMore = true,
  required = false,
  ...props
}: Params) {
  const [selectedOptions, setSelectedOptions] = useState<
    MultiValue<IWorkerSuggestion>
  >([]);

  const handleChange = (options: MultiValue<IWorkerSuggestion>) => {
    if (!canSelectMore && options.length >= selectedOptions.length) {
      return;
    }
    setSelectedOptions(options);
    handleChangeWorker(options);
  };

  return (
    <AsyncSelect
      inputId={props.inputId}
      placeholder="Search workers"
      name={name}
      ref={parentRef}
      isMulti
      required={required}
      closeMenuOnSelect
      backspaceRemovesValue={false}
      loadOptions={fetchWorkersOptions}
      value={selectedOptions}
      getOptionLabel={(e) => e.name}
      getOptionValue={(e) => e.id.toString()}
      onChange={handleChange}
      formatOptionLabel={formatOptionLabel}
      components={{ Option: ThWorkerSelectOption }}
      className={props.className}
      styles={{
        multiValue: (base) => ({
          ...base,
          gap: '',
          margin: '0.5rem',
          backgroundColor: 'var(--secondary)',
          borderRadius: '1.5rem',
          padding: '0.3rem',
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          backgroundColor: 'var(--secondary)',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }),
        multiValueRemove: (styles) => ({
          ...styles,
          backgroundColor: 'var(--secondary)',
          color: 'black',
          borderRadius: '50%',
          ':hover': {
            backgroundColor: 'var(--yellow)',
          },
        }),
      }}
    />
  );
}

export default ThWorkerSelect;
