import { useState } from 'react';
import { Accordion, Col, Image } from 'react-bootstrap';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { LoadPersonCircle } from '@components/elements/ComponentLoader';
import defaultAvatar from '@assets/default-avatar.png';
import {
  IBatchRateWorker,
  IWorkerRatingData,
} from '@company/types/batch-rate-worker.type';
import WorkerRatingData from './WorkerRatingData';
import WorkerNavigationButtons from './WorkerNavigationButtons';

interface Props {
  workerOptions: IBatchRateWorker[];
  completedWorkerIds: number[];
  workerInfoData: IWorkerRatingData;
}
function WorkerListMobile({
  workerOptions,
  completedWorkerIds,
  workerInfoData,
  workerInfoData: { scrollToTop },
}: Props) {
  const [activeKey, setActiveKey] = useState('0');

  const handleClickButton = (key: string) => {
    if (key !== null) {
      scrollToTop();
    }
  };

  return (
    <>
      <Accordion
        flush
        defaultActiveKey="0"
        onSelect={(k) => setActiveKey(String(k))}
      >
        {workerOptions.map((worker, index) => (
          <Accordion.Item eventKey={String(index)} key={worker.id}>
            <Accordion.Button
              className="ps-0"
              onClick={() => handleClickButton(String(index))}
            >
              {activeKey !== String(index) && (
                <Col xs={1} className="ms-1 me-3">
                  {worker?.image ? (
                    <Image
                      width={40}
                      height={40}
                      src={worker.image || defaultAvatar}
                      roundedCircle
                    />
                  ) : (
                    <LoadPersonCircle width="40" height="40" />
                  )}
                </Col>
              )}

              <Col
                xs={activeKey === String(index) ? 9 : 8}
                className={`fw-bold fs-4 ${
                  activeKey === String(index) ? 'ms-1 me-3' : ''
                }`}
              >
                {worker.name}
                {completedWorkerIds.includes(worker.id) && (
                  <BsFillCheckCircleFill
                    className="mx-2"
                    size={18}
                    color="var(--green)"
                  />
                )}
              </Col>
              <Col xs={2} className="small text-uppercase">
                {index + 1} of {workerOptions.length}
              </Col>
            </Accordion.Button>
            <Accordion.Body className="mt-4 p-0">
              <WorkerRatingData
                worker={worker}
                workerInfoData={workerInfoData}
              />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <WorkerNavigationButtons workerInfoData={workerInfoData} />
    </>
  );
}

export default WorkerListMobile;
