export const containsDuplicates = (
  listOne: string[],
  listTwo: string[],
  fieldToCheck = null
) => {
  let listToCheckOne: string[];
  let listToCheckTwo: string[];
  if (fieldToCheck !== null) {
    listToCheckOne = listOne.map((listItem) => listItem[fieldToCheck]);
    listToCheckTwo = listTwo.map((listItem) => listItem[fieldToCheck]);
  } else {
    listToCheckOne = listOne;
    listToCheckTwo = listTwo;
  }
  return listToCheckOne.some((listItem) => listToCheckTwo.includes(listItem));
};
