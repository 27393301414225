import { useRef, useState } from 'react';
import { GrLocation } from '@react-icons/all-files/gr/GrLocation';
import {
  Badge,
  Button,
  Col,
  Image,
  Overlay,
  Row,
  Stack,
  Tooltip,
} from 'react-bootstrap';
import { StarRating } from '@components/elements';
import { isMobile } from 'react-device-detect';
import { IWorkerData } from '@th-types/worker.type';
import { IWorkerJobData } from '@th-types/workerProfile.type';
import { BiBlock } from 'react-icons/bi';
import { FaHeart } from 'react-icons/fa';
import { FaRegCopy } from 'react-icons/fa6';
import WorkerActions from '@company/components/WorkerParts/WorkerActions';
import ActionButtons from './ActionButtons';
import ActionButtonsMobile from './ActionButtonsMobile';
import Tags from './Tags';
import './style.css';

interface Params {
  workerProfile?: IWorkerJobData;
  worker: IWorkerData;
  thumbStyle?: boolean;
  tags?: any;
  actionButtons?: any;
  distance?: number;
  openDetails?: any;
  isHired?: boolean;
  index?: number;
  isFavoritedByCompany?: boolean;
  isBlockedByCompany?: boolean;
  isPremiumCompany?: boolean;
  isInternalMember?: boolean;
  isCompanyAdmin?: boolean;
  shareWorkerProfile?: boolean;
}

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

export default function ThWorkerRow({
  workerProfile,
  worker,
  thumbStyle = false,
  tags,
  actionButtons,
  distance,
  openDetails,
  isHired = false,
  isFavoritedByCompany = false,
  isBlockedByCompany = false,
  index = 0,
  isPremiumCompany = false,
  isInternalMember = false,
  isCompanyAdmin = false,
  shareWorkerProfile,
}: Params) {
  const shareWorkerRef = useRef(null);
  const [showCopiedToClipboard, setShowCopiedToClipboard] = useState(false);
  const canManageWorker = () => {
    return (isInternalMember || isCompanyAdmin) && isPremiumCompany;
  };

  const shareWorkerProfileUrl = () => {
    const workerProfileUrl = `${SERVER_URL}/w/${worker.customUrl ?? worker.id}`;
    navigator.clipboard.writeText(workerProfileUrl);
    setShowCopiedToClipboard(true);
    setTimeout(() => setShowCopiedToClipboard(false), 1500);
  };

  return (
    <Row>
      <Col xs={thumbStyle && !isMobile ? 12 : 5} className="square-container">
        <div className="square-content position-relative">
          {isHired && (
            <Badge className="position-absolute top-0 end-0 badge-custom text-dark">
              HIRED
            </Badge>
          )}
          <Image
            rounded
            className={`${isPremiumCompany ? 'cursor-point' : ''}`}
            src={worker.profileImageUrl}
            onClick={openDetails}
          />
        </div>
      </Col>
      <Col xs={thumbStyle && !isMobile ? 12 : 7} className="mt-2">
        <Stack className="justify-content-start h-100" gap={0}>
          <Row className="d-flex">
            <Col
              className={`float-left ${isPremiumCompany ? '' : 'blur'} d-flex`}
              xs={isMobile ? 10 : 12}
            >
              <b
                onClick={openDetails}
                className={`${
                  isPremiumCompany ? 'cursor-point' : ''
                } pt-1 me-2`}
              >
                {isPremiumCompany
                  ? `${worker.firstName} ${worker.lastName}`
                  : `Trusted Herd ${index}`}
                {isFavoritedByCompany && (
                  <FaHeart className="ms-1 me-1 mb-1" color="green" size={12} />
                )}
                {isBlockedByCompany && (
                  <BiBlock size={12} className="ms-1 me-1 mb-1" color="red" />
                )}
              </b>
              {workerProfile && <WorkerActions workerProfile={workerProfile} />}
            </Col>
            {isMobile && (
              <Col xs={2}>
                {actionButtons?.length > 0 && (
                  <ActionButtonsMobile
                    actions={actionButtons}
                    className="d-block d-sm-none"
                  />
                )}
              </Col>
            )}
          </Row>
          {tags && thumbStyle && (
            <Row className="mt-1">
              <Col>
                <small>
                  <Tags tags={tags} />
                </small>
              </Col>
            </Row>
          )}
          <Stack
            direction="horizontal"
            className={`${!isMobile && 'mt-2'} justify-content-start`}
            gap={1}
          >
            <Row>
              {distance !== undefined && distance >= 0 && (
                <Col>
                  <div className="text-nowrap">
                    <GrLocation className="location-icon" size={14} />
                    <small>&nbsp;{distance?.toFixed(2)} miles</small>
                  </div>
                </Col>
              )}
              <Col>
                <StarRating value={worker.rating} color="yellow" />
              </Col>
            </Row>
          </Stack>
          {tags && !thumbStyle && (
            <div className="mt-1">
              <small>
                <Tags tags={tags} />
              </small>
            </div>
          )}
          {actionButtons?.length > 0 && (
            <div className="mt-3 d-none d-sm-block">
              <ActionButtons
                actions={actionButtons}
                canManageWorker={canManageWorker()}
              />
            </div>
          )}
          {shareWorkerProfile && (
            <Stack
              direction="horizontal"
              className="mt-auto d-flex align-items-center gap-1"
            >
              <Button
                className="p-0 fw-bold"
                style={{
                  width: 'fit-content',
                  textTransform: 'capitalize',
                  color: 'var(--yellow-dark-20)',
                }}
                onClick={shareWorkerProfileUrl}
                ref={shareWorkerRef}
                variant="clear"
              >
                Share Profile
              </Button>
              <FaRegCopy fill="var(--yellow-dark-20)" />
              <Overlay
                target={shareWorkerRef.current}
                show={showCopiedToClipboard}
                placement="right"
              >
                {(props) => (
                  <Tooltip id="overlay-example" {...props}>
                    Link to worker profile copied to clipboard!
                  </Tooltip>
                )}
              </Overlay>
            </Stack>
          )}
        </Stack>
      </Col>
    </Row>
  );
}
