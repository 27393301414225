import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './styles.css';

interface ThBlurOverlayPanelParams {
  marginTop?: number;
  height?: string | number;
  width?: string | number;
  numberOfColumns?: number;
  numberOfRows?: number;
  offset?: number;
  children?: React.ReactNode;
}

export function ThOverlayBlurPanel({
  marginTop = 0,
  height = '100vh',
  width = '100vw',
  numberOfColumns = 12,
  numberOfRows = 12,
  offset = 0,
  children,
}: ThBlurOverlayPanelParams): JSX.Element {
  return (
    <Col
      style={{ height, width }}
      md={numberOfRows}
      className={`panel-not-premium mt-${marginTop} position-absolute`}
    >
      <Row>
        <Col
          md={{ span: numberOfColumns, offset }}
          className="top-0 bottom-0 position-absolute overlay-blur"
        >
          {children}
        </Col>
      </Row>
    </Col>
  );
}
