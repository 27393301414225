import { UAParser } from 'ua-parser-js';

const parser = new UAParser();

const MIN_SUPPORTED_SAFARI_VERSION = 16;

export const isBrowserOutdated = () => {
  return (
    parser.getResult().browser.name?.toLowerCase() === 'safari' &&
    Number(parser.getResult().browser.version) < MIN_SUPPORTED_SAFARI_VERSION
  );
};
