import { ThOverlayBlurPanel } from '@components/elements/ThBlurOverlayPanel';
import { isMobile } from 'react-device-detect';

interface ThPaywallPanelParams {
  children?: React.ReactNode;
}

export function ThPaywallPanel({
  children,
}: ThPaywallPanelParams): JSX.Element {
  const marginTop = 3;
  const height = 'calc(100vh + 400px)';
  const width = isMobile ? '100vw' : `calc(100vw - (var(--bs-gutter-x) * 2))`;
  const numberOfColumns = 6;
  const numberOfRows = 12;
  const offset = 6;

  return (
    <ThOverlayBlurPanel
      marginTop={marginTop}
      height={height}
      width={width}
      numberOfColumns={numberOfColumns}
      numberOfRows={numberOfRows}
      offset={offset}
    >
      {children}
    </ThOverlayBlurPanel>
  );
}
