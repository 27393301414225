interface Params {
  url: string;
  width?: number;
  height?: number;
}

export default function YoutubeEmbed({ url, width, height }: Params) {
  const getId = (): string => {
    const yturl = url.replace('shorts', 'embed');
    const ybEmbedVideoRegExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    let match = yturl.match(ybEmbedVideoRegExp);

    if (match && match[2].length === 11) {
      return match[2];
    }
    const ybChannelRegExp = /^.*(youtube.com\/channel\/)(.*)/;
    match = yturl.match(ybChannelRegExp);

    if (match && match[2].length === 24) {
      let videoSuffix = '?listType=playlist&list=UU';
      videoSuffix += match[2].substring(2);
      return videoSuffix;
    }
    return 'error';
  };

  return (
    <iframe
      width={width}
      height={height}
      src={`//www.youtube.com/embed/${getId()}`}
      title={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
  );
}
