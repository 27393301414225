import { format } from 'date-fns';
import DATE_FORMATS from '@constants/dateFormat';

export default interface IPeriod {
  start: string;
  end: string;
}
export interface IDatePeriod {
  start: Date;
  end: Date;
}

export const datePeriodToString = (period: IDatePeriod) => {
  const start = format(new Date(period.start), DATE_FORMATS.DATE_FORMAT);
  const end = format(new Date(period.end), DATE_FORMATS.DATE_FORMAT);
  return { start, end };
};
