import { Col, Form, Row, Stack } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Panel } from '@components/elements';
import {
  IBatchRateWorker,
  IWorkerRatingData,
} from '@company/types/batch-rate-worker.type';
import WorkerActions from '@company/components/WorkerParts/WorkerActions';
import WorkerNavigationButtons from './WorkerNavigationButtons';
import {
  ActionButton,
  Comments,
  DaysWorked,
  ImprovementComments,
  InternalNotes,
  JobTitle,
  OtherJobTitle,
  PhotoIsRepresentative,
  Rating,
  Visibility,
  WorkerImage,
  WorkerName,
} from './WorkerRatingComponents';

interface Props {
  worker: IBatchRateWorker;
  workerInfoData: IWorkerRatingData;
}
function WorkerRatingData({
  worker,
  workerInfoData,
  workerInfoData: {
    isTHJob,
    errors,
    batchRateWorkerSession,
    jobOptions,
    control,
    isWorkerCompleted,
    handleSubmit,
    onSubmit,
    register,
    setValue,
    getValues,
  },
}: Props) {
  const isOtherJobTitle = () => {
    return (
      jobOptions?.jobTitles.find(
        (t) => t.id === getValues(`workers.${worker.id}.workerRating.title.id`)
      )?.name === 'Other'
    );
  };

  const handleToggleValue = (field: string, value: boolean) => {
    if (field === 'noCallNoShowed') {
      const performanceValue = getValues(
        `workers.${worker.id}.workerRating.performance`
      );
      if (!performanceValue && value) {
        setValue(`workers.${worker.id}.workerRating.performance`, 1);
      }
      setValue(`workers.${worker.id}.workerRating.noCallNoShowed`, value);
      setValue(`workers.${worker.id}.workerRating.canceledUnexcused`, false);
    }
    if (field === 'canceledUnexcused') {
      setValue(`workers.${worker.id}.workerRating.canceledUnexcused`, value);
      setValue(`workers.${worker.id}.workerRating.noCallNoShowed`, false);
    }
  };

  const workerProfile = worker
    ? {
        worker,
        isBlockedByCompany:
          worker.workerRating?.assignment?.companyWorker?.isBlockedByCompany ??
          false,
        isFavoritedByCompany:
          worker.workerRating?.assignment?.companyWorker
            ?.isFavoritedByCompany ?? false,
      }
    : null;

  return (
    <>
      <Panel className={isMobile ? 'shadow-none' : 'panel'}>
        <Form
          id="rateWorkerForm"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          onChange={() => {
            isWorkerCompleted(worker);
          }}
        >
          <Row className={`${isMobile ? '' : 'd-flex justify-content-center'}`}>
            <Col xs={isMobile ? 12 : 6}>
              <Stack direction="horizontal" gap={isMobile ? 2 : 4}>
                <Col xs={2}>
                  <WorkerImage worker={worker} />
                </Col>
                <Stack gap={isMobile ? undefined : 2}>
                  <Stack direction="horizontal" gap={2}>
                    <WorkerName worker={worker} />
                    {workerProfile && (
                      <WorkerActions workerProfile={workerProfile} />
                    )}
                  </Stack>
                  <MobileView>
                    <Rating
                      control={control}
                      worker={worker}
                      errors={errors}
                      onRate={() => isWorkerCompleted(worker)}
                    />
                  </MobileView>
                </Stack>
              </Stack>
            </Col>
            <BrowserView className="col-6">
              <Col xs={12} className="d-flex justify-content-end">
                <Rating
                  control={control}
                  worker={worker}
                  errors={errors}
                  onRate={() => isWorkerCompleted(worker)}
                />
              </Col>
            </BrowserView>
            <BrowserView className="col-12">
              <hr className="table-horizontal-line mt-4" />
            </BrowserView>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <PhotoIsRepresentative
                register={register}
                worker={worker}
                errors={errors}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={isMobile ? 12 : 6} className={isMobile ? 'mb-4' : ''}>
              <JobTitle
                isTHJob={isTHJob}
                worker={worker}
                batchRateWorkerSession={batchRateWorkerSession}
                control={control}
                jobOptions={jobOptions}
                errors={errors}
                setValue={setValue}
              />
            </Col>
            {isMobile && isOtherJobTitle() && (
              <Col xs={isMobile ? 12 : 6} className={isMobile ? 'mb-4' : ''}>
                <OtherJobTitle
                  register={register}
                  worker={worker}
                  errors={errors}
                />
              </Col>
            )}
            <Col xs={isMobile ? 12 : 6}>
              <DaysWorked
                setValue={setValue}
                batchRateWorkerSession={batchRateWorkerSession}
                control={control}
                register={register}
                worker={worker}
                errors={errors}
              />
            </Col>
            {!isMobile && isOtherJobTitle() && (
              <Col xs={12} className="mt-4">
                <OtherJobTitle
                  register={register}
                  worker={worker}
                  errors={errors}
                />
              </Col>
            )}
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <Comments register={register} worker={worker} />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12}>
              <Form.Label
                htmlFor={`workers.${worker.id}.workerRating.noCallNoShowed`}
                className="fw-bold"
              >
                Did the worker do any of the following?
              </Form.Label>
              <span className="mx-2 optional-badge text-uppercase small">
                (OPTIONAL)
              </span>
              <Row>
                <Col xs={isMobile ? 12 : 6}>
                  <ActionButton
                    control={control}
                    worker={worker}
                    actionName="noCallNoShowed"
                    actionTitle="No Call/No Show"
                    handleToggleValue={handleToggleValue}
                  />
                </Col>
                <Col xs={isMobile ? 12 : 6}>
                  <ActionButton
                    control={control}
                    worker={worker}
                    actionName="canceledUnexcused"
                    actionTitle="Canceled within 24 hours"
                    handleToggleValue={handleToggleValue}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <ImprovementComments register={register} worker={worker} />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12}>
              <Visibility control={control} worker={worker} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <hr className="table-horizontal-line mt-4" />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <InternalNotes register={register} worker={worker} />
            </Col>
          </Row>
        </Form>
      </Panel>
      <BrowserView>
        <WorkerNavigationButtons
          workerInfoData={workerInfoData}
          worker={worker}
        />
      </BrowserView>
    </>
  );
}

export default WorkerRatingData;
