import { useState } from 'react';
import { IWorkerJobData } from '@th-types/workerProfile.type';
import { BlockButton, FavoriteButton } from '@company/components/WorkerParts';
import './style.css';
import { isMobile } from 'react-device-detect';
import { IWorkerRatingProfileData } from '@company/types/batch-rate-worker.type';

interface WorkerActionsParams {
  workerProfile: IWorkerJobData | IWorkerRatingProfileData;
}

export default function WorkerActions({ workerProfile }: WorkerActionsParams) {
  const { worker, isBlockedByCompany, isFavoritedByCompany } = workerProfile;

  const [isFavoriteWorker, setIsFavoriteWorker] =
    useState(isFavoritedByCompany);
  const [isBlockedWorker, setIsBlockedWorker] = useState(isBlockedByCompany);

  const handleToggleBlock = (state: boolean) => {
    setIsBlockedWorker(!state);
  };

  const handleToggleFavorite = (state: boolean) => {
    setIsFavoriteWorker(!state);
  };

  return (
    <>
      <BlockButton
        workerId={worker.id}
        isBlockedByDefault={isBlockedWorker}
        disable={isFavoriteWorker}
        className={`${isMobile ? 'mt-2 button-web-style' : ''} rounded-button`}
        isSmallButton
        handleToggleState={handleToggleBlock}
      />
      <FavoriteButton
        workerId={worker.id}
        isFavoritedByDefault={isFavoriteWorker}
        disable={isBlockedWorker}
        className={`${isMobile ? 'mt-2 button-web-style' : ''} rounded-button`}
        isSmallButton
        handleToggleState={handleToggleFavorite}
      />
    </>
  );
}
