import { privateApi } from '@services/api';
import MessageRequest from '@th-types/message-request.type';
import IDirectMessageChat from '@th-types/direct-messages-chat.type';
import IResultResponse from '@th-types/resultResponse.type';

export const MAIN_URL = 'chat';

export const fetchWorkerDirectMessages = async (workerId: number) => {
  const url = `workers/${workerId}/direct-messages`;
  try {
    const result = await privateApi.get<IDirectMessageChat>(url);
    return result.data;
  } catch (error) {
    console.log(
      `Occurred an error trying to fetch direct messages for conversation ${workerId}`
    );
    return Promise.reject(error);
  }
};

export const sendWorkerDirectMessage = async (
  workerId: number,
  message: MessageRequest
) => {
  try {
    const result = await privateApi.post<IResultResponse>(
      `workers/${workerId}/direct-messages`,
      message
    );
    return result.data;
  } catch (error: any) {
    const errorMessage = error?.message ?? 'Error while sending direct message';
    console.error(
      `Occurred an error trying to send a direct message with errorMessage:'${errorMessage}' for workerId: ${workerId}`
    );
    return { message: errorMessage, success: false };
  }
};

export const fetchDirectMessages = async (chatId: number) => {
  const url = `${MAIN_URL}/direct-messages/${chatId}`;
  try {
    const result = await privateApi.get<IDirectMessageChat>(url);
    return result.data;
  } catch (error) {
    console.log(
      `Occurred an error trying to fetch direct messages for conversation ${chatId}`
    );
    return Promise.reject(error);
  }
};

export const sendDirectMessage = async (
  receiverId: number | null,
  message: MessageRequest
) => {
  try {
    const result = await privateApi.post<IResultResponse>(
      `${MAIN_URL}/direct-messages/${receiverId}/send`,
      message
    );
    return result.data;
  } catch (error: any) {
    const errorMessage = error?.message ?? 'Error while sending direct message';
    console.error(
      `Occurred an error trying to send a direct message with errorMessage:'${errorMessage}' for receiverId: ${receiverId}`
    );
    return { message: errorMessage, success: false };
  }
};

export const readDirectMessages = async (chatId: number) => {
  try {
    const result = await privateApi.post<IResultResponse>(
      `${MAIN_URL}/direct-messages/${chatId}/read`
    );
    return result.data;
  } catch (error: any) {
    const errorMessage =
      error?.message ?? 'Error while mark as read the direct messages';
    console.log('Occurred an error trying to mark as read the direct messages');
    return { message: errorMessage, success: false };
  }
};
