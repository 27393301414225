import LoaderJobWorkerActions from '@company/components/th/ThWorkerRow/LoaderJobWorkerActions';
import DirectMessageButton from '@company/components/WorkerParts/DirectMessageButton';
import { useDirectMessageContext } from '@company/state/directMessageContext';
import useUpdateWorker from '@company/state/useUpdateWorker/useUpdateWorker';
import ShareJobButton from '@company/components/WorkerParts/ShareJobButton';
import * as jobAssignmentApi from '@company/services/jobAssignment/api';
import { useCompanyContext } from '@company/state/companyContext';
import { AiTwotoneCalendar } from '@react-icons/all-files/ai/AiTwotoneCalendar';
import { ThTabs, ThWorkerRow } from '@company/components/th';
import useFetchJobList from '@company/hooks/useJobList';
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown';
import { ThError, ThLoading } from '@components/elements';
import { WorkerReviews } from '@company/components/WorkerReviews';
import { CgNotes } from '@react-icons/all-files/cg/CgNotes';
import { IWorkerJobData } from '@th-types/workerProfile.type';
import { BiUser } from '@react-icons/all-files/bi/BiUser';
import {
  EndorseButton,
  WorkerDetails,
  WorkerNotes,
  WorkerResume,
} from '@company/components/WorkerParts';
import {
  CompanyWorkerHistory,
  JobWorkerCalendar,
} from '@company/components/JobWorker';
import { RiHistoryFill } from 'react-icons/ri';
import {
  DropdownListItem,
  DropdownListItemEmpty,
} from '@components/elements/DropdownList';
import useAlert from '@hooks/useAlert';
import { useState } from 'react';
import {
  Alert,
  Col,
  Container,
  Dropdown,
  Row,
  Stack,
  Tab,
} from 'react-bootstrap';

interface WorkerProfileParams {
  workerProfileData?: IWorkerJobData;
  isLoadingWorker: boolean;
}

enum WorkerProfileTab {
  DETAILS,
  REVIEWS,
  NOTES,
  RESUME,
  HISTORY,
  CALENDAR,
}

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

function WorkerProfile({
  workerProfileData,
  isLoadingWorker,
}: WorkerProfileParams) {
  const { showSuccessAlert, showWarningAlert, alertMessage } = useAlert();

  const jobList = useFetchJobList();

  const { updateWorker } = useUpdateWorker();
  const { premium: isPremiumCompany } = useCompanyContext();
  const { chatId, openDirectMessageChat } = useDirectMessageContext();

  const [notesCount, setNotesCount] = useState(0);
  const [isLoadingAction, setLoadingAction] = useState(false);

  const [activeKey, setActiveKey] = useState<WorkerProfileTab>(
    WorkerProfileTab.DETAILS
  );

  if (isLoadingAction || isLoadingWorker || !workerProfileData) {
    return <ThLoading />;
  }

  const shareJobToWorker = async (jobId: number) => {
    const { id: workerId } = workerProfileData?.worker || {}; // because workerId can be custom url slug
    setLoadingAction(true);
    const result = await jobAssignmentApi.shareJobToWorker(jobId, workerId);
    setLoadingAction(false);
    if (result?.success) {
      showSuccessAlert(result.message);
      updateWorker({
        workerId,
        jobId,
      });
    } else {
      showWarningAlert(
        `An error has occurred while was trying to share job with worker`
      );
    }
  };
  const getActiveJobs = async (
    idWorker: number
  ): Promise<DropdownListItem[] | DropdownListItemEmpty> => {
    try {
      const { fetchActiveJobsList } = jobList(idWorker);
      return await fetchActiveJobsList({ shareJobToWorker });
    } catch (e) {
      console.error('Cannot fetch active jobs', e);
      return Promise.reject(new Error('Cannot fetch active jobs'));
    }
  };

  const jobWorkerTabs = [
    {
      key: WorkerProfileTab.DETAILS,
      icon: <BiUser size={20} />,
      label: 'Profile Details',
      component: workerProfileData ? (
        <WorkerDetails key="workerDetail" workerProfile={workerProfileData} />
      ) : (
        <ThError message="No data found" />
      ),
    },
    {
      key: WorkerProfileTab.REVIEWS,
      icon: <BiUser size={20} />,
      label: `Reviews (${workerProfileData?.comments?.length ?? 0})`,
      component: <WorkerReviews comments={workerProfileData?.comments} />,
    },
    {
      key: WorkerProfileTab.RESUME,
      icon: <CgNotes size={20} />,
      label: `Resume`,
      component: <WorkerResume worker={workerProfileData!.worker} />,
    },
    {
      key: WorkerProfileTab.CALENDAR,
      icon: <AiTwotoneCalendar size={20} />,
      label: 'Calendar',
      component: <JobWorkerCalendar worker={workerProfileData!.worker} />,
    },
    {
      key: WorkerProfileTab.HISTORY,
      icon: <RiHistoryFill size={20} />,
      label: 'History',
      component: (
        <CompanyWorkerHistory
          worker={workerProfileData!.worker}
          isActive={
            activeKey.toString() === WorkerProfileTab.HISTORY.toString()
          }
        />
      ),
    },
    {
      key: WorkerProfileTab.NOTES,
      icon: <CgNotes size={20} />,
      label: `Internal Notes (${notesCount})`,
      component: (
        <WorkerNotes
          handleNotesCount={setNotesCount}
          worker={workerProfileData!.worker}
          isActive
        />
      ),
    },
  ];

  const renderComponent = () => {
    return (
      <>
        <Row>
          <Col md={4} className="d-none d-sm-block">
            {workerProfileData && (
              <ThWorkerRow
                workerProfile={workerProfileData}
                isPremiumCompany
                worker={workerProfileData.worker}
                shareWorkerProfile
              />
            )}
          </Col>
          <Col md={8} className="mt-2 d-none d-sm-block">
            {isLoadingWorker && <LoaderJobWorkerActions />}
            <Row className="mb-2">
              <Col sm={12} className="d-flex justify-content-end">
                <Stack direction="horizontal" gap={4}>
                  <EndorseButton
                    endorseUrl={`${SERVER_URL}/w/${`${
                      workerProfileData!.worker.customUrl ??
                      workerProfileData!.worker.id
                    }?openEndorsementModal=true`}`}
                    isEndorsedByUser={workerProfileData?.isEndorsedByUser}
                  />
                  <DirectMessageButton
                    disabled={!isPremiumCompany}
                    openDirectMessageChat={() => {
                      openDirectMessageChat(
                        workerProfileData!.worker,
                        chatId,
                        isPremiumCompany
                      );
                    }}
                  />
                  <ShareJobButton
                    getJobs={() => getActiveJobs(workerProfileData!.worker.id)}
                  />
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2">
          <div className="d-block d-sm-none">
            <Dropdown>
              <Dropdown.Toggle variant="">
                <Stack direction="horizontal" gap={2}>
                  <b>
                    {jobWorkerTabs.find((tab) => tab.key === activeKey)?.icon}
                    &nbsp;
                    {jobWorkerTabs.find((tab) => tab.key === activeKey)?.label}
                  </b>
                  <FiChevronDown size={24} />
                </Stack>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {jobWorkerTabs.map((tab) => (
                  <Dropdown.Item
                    key={tab.key}
                    onClick={() => setActiveKey(tab.key)}
                  >
                    {tab.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {jobWorkerTabs.find((tab) => tab.key === activeKey)?.component}
          </div>
          <div className="d-none d-sm-block">
            <ThTabs
              fill
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k! as unknown as WorkerProfileTab)}
              borderBottom
            >
              {jobWorkerTabs.map((tab) => (
                <Tab
                  key={tab.key}
                  eventKey={tab.key}
                  title={
                    <div className="d-flex justify-content-center">
                      {tab.icon}
                      &nbsp;{tab.label}
                    </div>
                  }
                >
                  {tab.component}
                </Tab>
              ))}
            </ThTabs>
          </div>
        </Row>
        <Alert
          show={alertMessage.show}
          variant={alertMessage.variant}
          className="alert-fixed"
          style={{ width: '20rem' }}
        >
          <Alert.Heading>{alertMessage.message}</Alert.Heading>
        </Alert>
      </>
    );
  };

  const workerProfile = () => {
    if (isLoadingWorker) {
      return <ThLoading />;
    }
    return <Container>{renderComponent()}</Container>;
  };

  return workerProfile();
}

export default WorkerProfile;
