import AdvancedFiltersBadges from '@company/pages/JobOverview/SearchTalent/AdvancedFiltersBadges';
import { PaywallSidePanel } from '@company/pages/JobOverview/SearchTalent/PaywallSidePanel';
import SearchTalentInput from '@company/components/SearchTalentInput/SearchTalentInput';
import AdvancedFilters from '@company/pages/JobOverview/SearchTalent/AdvancedFilters';
import DirectMessageButton from '@company/components/WorkerParts/DirectMessageButton';
import { useDirectMessageContext } from '@company/state/directMessageContext';
import useUpdateWorker from '@company/state/useUpdateWorker/useUpdateWorker';
import ShareJobButton from '@company/components/WorkerParts/ShareJobButton';
import * as jobAssignmentApi from '@company/services/jobAssignment/api';
import { JobWorkerSideModal } from '@company/components/JobWorker';
import { useCompanyContext } from '@company/state/companyContext';
import { MilesDistance } from '@components/elements/MilesDistance/MilesDistance';
import { useChatContext } from '@company/state/chatContext';
import * as workerApi from '@company/services/worker/api';
import ModalJoinPremium from '@company/components/Payment/ModalJoinPremium';
import { useJobAssignmentStatus } from '@hooks/useJobAssignmentStatus';
import { DirectMessageChat } from '@components/elements/DirectMessage';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useFetchJobList from '@company/hooks/useJobList';
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown';
import { ThWorkerRow } from '@company/components/th';
import { JobSearchMessages } from '@constants/noDataFoundMessages';
import { BiFilterAlt } from '@react-icons/all-files/bi/BiFilterAlt';
import { Body } from '@company/components/layout';
import { NoDataFound } from '@components/elements/NoDataFound';
import {
  SideEndModal,
  ThLoading,
  AutoCompleteAddress,
} from '@components/elements';
import { FilterType } from '@constants/jobFilterType';
import { IN_NETWORK_TAG, ITag } from '@th-types/tag.type';
import QueryKeys from '@constants/queryKeys';
import { IWorkerData } from '@th-types/worker.type';
import getNextPage from '@utils/Pagination';
import { isMobile } from 'react-device-detect';
import { BiSortAlt2 } from 'react-icons/bi';
import useAlert from '@hooks/useAlert';
import { debounce } from 'lodash';
import IFilterData, { ISortOrder } from '@company/types/talent-filterdata.type';
import ITalentsData, { ITalentData } from '@company/types/talents.type';
import {
  LoaderImageGrid,
  LoaderPersonCard,
} from '@components/elements/ComponentLoader';
import {
  DropdownListItem,
  DropdownListItemEmpty,
} from '@components/elements/DropdownList';
import {
  DEFAULT_ADDRESS,
  DEFAULT_MILES_RADIUS,
} from '@constants/jobInitialSearch';
import {
  Alert,
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from 'react-bootstrap';
import {
  Reducer,
  ReducerState,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useJobAssignmentContext } from '@company/state/jobAssignmentContext';

function GeneralSearchTalent(): JSX.Element {
  const DEBOUNCE_RANGE_TIME = 100;
  const DEBOUNCE_TEXT_TIME = 500;
  const { chatPerson } = useChatContext();
  const {
    chatId,
    worker: messagePerson,
    openDirectMessageChat,
    clearDirectMessageData,
  } = useDirectMessageContext();
  const [filterType, setFilterType] = useState(FilterType.ADDRESS);
  const [placeholderSearch, setPlaceholderSearch] = useState(
    `Search by ${filterType.toLowerCase()}`
  );
  const [openAdvancedFilters, setOpenAdvancedFilters] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showWorkerProfile, setShowWorkerProfile] = useState(false);
  const [selectedWorkerProfile, setSelectedWorkerProfile] =
    useState<ITalentData>();
  const [selectedWorker, setSelectedWorker] = useState<IWorkerData>(
    {} as IWorkerData
  );
  const [isLoadingAction, setLoadingAction] = useState(false);
  const [remainingTalents, setRemainingTalents] = useState(0);

  const { updateWorker, updatedWorker, fetchUpdatedWorker } = useUpdateWorker();
  const { showSuccessAlert, showWarningAlert, alertMessage } = useAlert();
  const { refetch: refetchWorkerProfile } = useJobAssignmentContext();
  const { premium: isPremiumCompany } = useCompanyContext();
  const jobAssignmentStatus = useJobAssignmentStatus();
  const jobList = useFetchJobList();

  const nameOrEmailRef = useRef<HTMLInputElement>(null);

  const initialFilters = {
    jobId: undefined,
    filterType: FilterType.ADDRESS,
    filterWorkers: 'ALL',
    miles: DEFAULT_MILES_RADIUS,
    address: DEFAULT_ADDRESS,
    addressLatitude: undefined,
    addressLongitude: undefined,
    name: '',
    email: '',
    sortOrder: ISortOrder.DESC,
  };

  type IFilterReducerData = IFilterData & Reducer<IFilterData, IFilterData>; // To handle the crybaby TS compiler
  // Allow setting partial filters state
  const filtersReducer: Reducer<IFilterData, Partial<IFilterData>> = (
    state: IFilterData,
    newState: Partial<IFilterData>
  ): IFilterData => ({
    ...state,
    ...newState,
  });
  const [filters, setFilters] = useReducer(
    filtersReducer,
    initialFilters as ReducerState<IFilterReducerData>
  );
  const [advancedFilters, setAdvancedFilters] = useState({} as IFilterData);

  const handleShowWorkerProfile = (talent: ITalentData) => {
    if (!isPremiumCompany) {
      return;
    }
    if (talent.worker.id === selectedWorker?.id) {
      refetchWorkerProfile();
    }
    setSelectedWorkerProfile(talent);
    setShowWorkerProfile(true);
    setSelectedWorker(talent.worker);
  };

  const handleToggleAdvancedFilters = () => {
    if (!isPremiumCompany) {
      return;
    }
    setOpenAdvancedFilters(!openAdvancedFilters);
  };

  const handleCloseAdvancedFilters = () => {
    if (!isPremiumCompany) {
      return;
    }
    setOpenAdvancedFilters(false);
  };

  const fetchTalents = useCallback(
    async ({ pageParam = 0 }) => {
      if (
        !filters.addressLatitude &&
        !filters.addressLongitude &&
        !filters.name &&
        !filters.address &&
        !filters.email
      ) {
        return new Promise<ITalentsData>((resolve) => {
          const emptyResponse: ITalentsData = {} as ITalentsData;
          resolve(emptyResponse);
        });
      }
      return workerApi.fetchTalents(
        { ...filters, ...advancedFilters },
        pageParam
      );
    },
    [filters, advancedFilters]
  );

  const queryKey = `${QueryKeys.COMPANY_TALENTS}`;
  const queryClient = useQueryClient();

  const {
    data,
    isSuccess,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKey],
    queryFn: fetchTalents,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    // TODO: enable this when component is on focus !!!
    getNextPageParam: (lastPage, allPages) => getNextPage(lastPage, allPages),
    initialPageParam: 0,
  });

  useEffect(() => {
    if (fetchUpdatedWorker) {
      const fetchUpdatedWorkerPayload = {
        updatedWorker,
        data,
        queryClient,
        queryKey,
      };
      fetchUpdatedWorker(fetchUpdatedWorkerPayload);
    }
  }, [fetchUpdatedWorker, updatedWorker, data, queryClient, queryKey]);

  const hasSomeDataToShow = !!data && data?.pages?.[0].results?.length > 0;

  const handleCloseWorkerProfile = () => {
    if (!isPremiumCompany) {
      return;
    }
    setShowWorkerProfile(false);
  };

  useEffect(() => {
    if (data && data?.pages[0]?.results) {
      let totalResults = 0;
      data.pages.map((page) => {
        totalResults += page.results.length;
        return page;
      });
      const count = data?.pages[0]?.pagination?.count ?? 0;
      setRemainingTalents(count - totalResults);
    }
  }, [data]);

  useEffect(() => {
    if (nameOrEmailRef.current) {
      nameOrEmailRef.current.focus();
    }
  });

  useEffect(() => {
    if (filterType === FilterType.NAME || filterType === FilterType.EMAIL) {
      if (nameOrEmailRef.current) {
        nameOrEmailRef.current.value = '';
      }
    }

    setFilters({
      name: filterType === FilterType.NAME ? '' : undefined,
      email: filterType === FilterType.EMAIL ? '' : undefined,
      address: filterType === FilterType.ADDRESS ? DEFAULT_ADDRESS : undefined,
      sortOrder: ISortOrder.DESC,
    });
  }, [filterType]);

  const handleAddressOnChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (!value) {
        event.preventDefault();
        setFilters({
          address: undefined,
          addressLatitude: undefined,
          addressLongitude: undefined,
        });
      }
    },
    DEBOUNCE_TEXT_TIME
  );

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value } = event.target;
    setFilters({
      name: filterType === FilterType.NAME ? value : undefined,
      email: filterType === FilterType.EMAIL ? value : undefined,
      address: filterType === FilterType.ADDRESS ? value : undefined,
      sortOrder: ISortOrder.DESC,
    });
  };

  useEffect(() => {
    if (isMobile) {
      refetch();
    } else if (
      filterType !== FilterType.ADDRESS &&
      !filters.name &&
      !filters.email
    ) {
      // refetch when the user clears the input
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.name, filters.email, filters.miles]);

  const handleMilesRange = debounce((newMilesRange: number | string) => {
    if (!isPremiumCompany) {
      return;
    }
    setFilters({
      miles: newMilesRange,
    });
  }, DEBOUNCE_RANGE_TIME);

  useEffect(() => {
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.sortBy, filters.sortOrder]);

  const handleSortSearchTalents = async () => {
    if (!isPremiumCompany) {
      return;
    }
    setFilters({
      sortOrder:
        filters.sortOrder !== undefined && filters.sortOrder === ISortOrder.DESC
          ? ISortOrder.ASC
          : ISortOrder.DESC,
    });
  };

  const handleSetAdvancedFilters = (newFilters: IFilterData) => {
    if (!isPremiumCompany) {
      return;
    }
    setAdvancedFilters({ ...newFilters });
  };

  const showModalCompanyPremiumMessage = (
    success: boolean,
    message: string
  ) => {
    if (success) {
      showSuccessAlert(message);
    } else {
      showWarningAlert(message);
    }
  };

  const isHired = (talent: ITalentData) => {
    const status = talent?.jobAssignment?.status;
    return jobAssignmentStatus(status).isHired();
  };

  const containsInNetworkBadge = (worker: IWorkerData): boolean => {
    return worker?.features
      ? worker.features.some((f) => f.feature === 'IN_NETWORK_BADGE')
      : false;
  };

  const shareJobToWorker = async (jobId: number, workerId: number) => {
    setLoadingAction(true);
    const result = await jobAssignmentApi.shareJobToWorker(jobId, workerId);
    setLoadingAction(false);
    if (result && result.success) {
      showSuccessAlert(result.message);
      updateWorker({
        workerId,
        jobId,
      });
    } else {
      showWarningAlert(
        `An error has occurred while was trying to share job with worker`
      );
    }
  };

  const getActiveJobs = async (
    workerId: number
  ): Promise<DropdownListItem[] | DropdownListItemEmpty> => {
    try {
      const { fetchActiveJobsList } = jobList(workerId);
      return await fetchActiveJobsList({ shareJobToWorker });
    } catch (e) {
      console.error('Cannot fetch active jobs', e);
      return Promise.reject(new Error('Cannot fetch active jobs'));
    }
  };

  const getTags = (matchSkills: boolean, worker: IWorkerData): ITag[] => {
    const tags: ITag[] = [];
    if (containsInNetworkBadge(worker)) {
      tags.push(IN_NETWORK_TAG);
    }
    if (matchSkills) {
      tags.push({
        label: 'MATCH',
        bg: 'light-success',
        bgText: 'text-success',
        className: 'cursor-default',
      });
    }
    return tags;
  };

  const handleFilterType = (type: FilterType) => {
    setFilterType(type);
    setFilters({
      filterType: type,
    });
    setPlaceholderSearch(
      `Search by ${type.toLowerCase().replace('email', 'e-mail')}`
    );
    if (type === FilterType.ADDRESS && filters.address === undefined) {
      setFilters({
        address: '',
        addressLatitude: undefined,
        addressLongitude: undefined,
      });
    }
  };

  const renderWorkers = () => {
    if (isMobile && openAdvancedFilters) {
      return '';
    }

    if (isFetching && !isFetchingNextPage) {
      return (
        <Col md={12}>
          {isMobile ? (
            <LoaderPersonCard />
          ) : (
            <LoaderImageGrid column={openAdvancedFilters ? 4 : 6} />
          )}
        </Col>
      );
    }

    const renderPremiumModal = () => {
      return (
        !isPremiumCompany && <PaywallSidePanel setShowModal={setShowModal} />
      );
    };

    const renderNoDataFound = () => (
      <Row>
        <Col>
          <NoDataFound
            noResultsMessage={JobSearchMessages.NO_RESULTS}
            helperMessage={JobSearchMessages.NO_RESULTS_UPDATE_FILTERS}
          />
        </Col>
        {renderPremiumModal()}
      </Row>
    );

    const renderDataFound = () => {
      return (
        <Row>
          {data?.pages?.map(
            (page) =>
              page?.results?.map((talent, index) => (
                <Col
                  key={talent.worker.id}
                  md={openAdvancedFilters ? 3 : 2}
                  className="mt-3"
                >
                  <div className="panel">
                    <ThWorkerRow
                      index={index + 1}
                      worker={talent.worker}
                      distance={talent.distance}
                      isFavoritedByCompany={talent.isFavoritedByCompany}
                      isBlockedByCompany={talent.isBlockedByCompany}
                      thumbStyle
                      tags={getTags(talent.matchSkills, talent.worker)}
                      openDetails={() => handleShowWorkerProfile(talent)}
                      isHired={isHired(talent)}
                      isPremiumCompany={isPremiumCompany}
                    />
                    <div className="mt-3 d-none d-sm-block">
                      <Row>
                        <Col key="direct-message" className="p-1 mt-1">
                          <DirectMessageButton
                            isSearchTalent
                            disabled={!isPremiumCompany}
                            openDirectMessageChat={() =>
                              openDirectMessageChat(
                                talent.worker,
                                chatId,
                                isPremiumCompany
                              )
                            }
                          />
                        </Col>
                        <ShareJobButton
                          isVariant
                          getJobs={() => getActiveJobs(talent.worker.id)}
                        />
                      </Row>
                    </div>
                  </div>
                </Col>
              ))
          )}
          {renderPremiumModal()}
        </Row>
      );
    };

    return (
      <>
        {isSuccess &&
          (hasSomeDataToShow ? renderDataFound() : renderNoDataFound())}
        {isFetchingNextPage && (
          <Col md={12}>
            {isMobile ? (
              <LoaderPersonCard />
            ) : (
              <LoaderImageGrid column={openAdvancedFilters ? 4 : 6} />
            )}
          </Col>
        )}
        {hasNextPage && isPremiumCompany && (
          <Row>
            <Col className="text-center mt-3">
              <Button
                variant="light"
                onClick={() => fetchNextPage()}
                disabled={isFetching}
              >
                <b>SHOW MORE ({remainingTalents})</b>
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const handleChangeAddress = (
    place: google.maps.places.PlaceResult | string
  ) => {
    if (typeof place === 'string') {
      setFilters({
        address: place,
        addressLatitude: undefined,
        addressLongitude: undefined,
      });
    } else {
      const location = place.geometry?.location;
      if (location) {
        setFilters({
          addressLatitude: location.lat(),
          addressLongitude: location.lng(),
        });
      } else {
        setFilters({
          address: '',
          addressLatitude: undefined,
          addressLongitude: undefined,
        });
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isPremiumCompany && !isFetching) {
      refetch();
    }
  };

  return (
    <Body>
      {isLoadingAction && <ThLoading />}
      <Row>
        <Col lg={2} xs={4}>
          <DropdownButton
            variant="light w-100"
            disabled={isFetching || !isPremiumCompany}
            title={
              <b style={{ fontSize: isMobile ? '14px' : '' }}>
                {(isMobile ? '' : 'FILTER BY ') + filterType}{' '}
                <FiChevronDown size={24} />
              </b>
            }
            onSelect={(value) => handleFilterType(value! as FilterType)}
          >
            <Dropdown.Item eventKey={FilterType.NAME}>
              {FilterType.NAME}
            </Dropdown.Item>
            <Dropdown.Item eventKey={FilterType.ADDRESS}>
              {FilterType.ADDRESS}
            </Dropdown.Item>
            <Dropdown.Item eventKey={FilterType.EMAIL}>
              {FilterType.EMAIL}
            </Dropdown.Item>
          </DropdownButton>
        </Col>

        <Col md={isMobile ? 5 : 4} xs={8}>
          {filterType === FilterType.ADDRESS ? (
            <AutoCompleteAddress
              initialValue={filters.address}
              name="address"
              placeholder={placeholderSearch}
              disabled={isFetching || !isPremiumCompany || !!filters.jobId}
              resultEvent={handleChangeAddress}
              onChange={handleAddressOnChange}
            />
          ) : (
            <SearchTalentInput
              placeholder={placeholderSearch}
              disabled={isFetching || !isPremiumCompany}
              onChange={handleInputOnChange}
              name={filterType.toLowerCase()}
              inputRef={nameOrEmailRef}
              onKeyDown={handleKeyDown}
            />
          )}
        </Col>

        <Col md={2} xs={6} className={isMobile ? 'mt-3' : ''}>
          <MilesDistance
            handleMilesRange={handleMilesRange}
            disabled={isFetching || !isPremiumCompany}
            milesRange={filters.miles ?? DEFAULT_MILES_RADIUS}
          />
        </Col>

        {!isMobile && (
          <Col md={1}>
            <Button
              disabled={!isPremiumCompany}
              type="submit"
              onClick={() => refetch()}
            >
              SEARCH
            </Button>
          </Col>
        )}

        {isPremiumCompany && (
          <Col
            md={3}
            xs={6}
            className={`${
              isMobile ? 'mt-3 d-inline-flex justify-content-end' : ''
            } text-end`}
            style={{ marginLeft: 'auto' }}
          >
            <Button
              variant="light"
              disabled={data?.pages[0]?.results?.length === 0 || isFetching}
              onClick={handleSortSearchTalents}
            >
              <BiSortAlt2 size={25} />
            </Button>
            <Button
              className="ms-3"
              variant={openAdvancedFilters ? 'primary' : 'light'}
              disabled={isFetching}
              onClick={handleToggleAdvancedFilters}
            >
              <BiFilterAlt size={25} />
              {!isMobile && <b className="">&nbsp;ADVANCED FILTERS</b>}
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={openAdvancedFilters ? 8 : 12}>
          <AdvancedFiltersBadges
            handleClose={handleCloseAdvancedFilters}
            setAdvancedFilters={handleSetAdvancedFilters}
            advancedFilters={advancedFilters}
          />
          {renderWorkers()}
        </Col>
        {openAdvancedFilters && (
          <Col md={4}>
            <AdvancedFilters
              handleClose={handleCloseAdvancedFilters}
              advancedFilters={advancedFilters}
              setAdvancedFilters={setAdvancedFilters}
              onSearch={refetch}
            />
          </Col>
        )}
      </Row>
      {showWorkerProfile &&
        selectedWorkerProfile &&
        chatPerson == null &&
        !messagePerson && (
          <JobWorkerSideModal
            handleCloseWorkerProfile={handleCloseWorkerProfile}
            worker={selectedWorker}
            isJobRelated={false}
          />
        )}
      {showModal && (
        <ModalJoinPremium
          showMessage={showModalCompanyPremiumMessage}
          setShowModal={setShowModal}
        />
      )}
      {messagePerson && (
        <SideEndModal
          show={!!messagePerson}
          width={isMobile ? '90%' : '50%'}
          onHide={clearDirectMessageData}
        >
          <DirectMessageChat worker={messagePerson} />
        </SideEndModal>
      )}
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </Body>
  );
}

export default GeneralSearchTalent;
