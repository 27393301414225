import Body from '@worker/components/layout/Body';

function Dashboard() {
  return (
    <Body>
      <h1>Dashboard Worker</h1>
    </Body>
  );
}

export default Dashboard;
