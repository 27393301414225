export interface JobQuestionResponseModel {
  jobId: number;
  questionId: number;
  responseId: number;
  responsesId: number;
  questionText: string;
  questionType: JobQuestionType;
  response: string;
  choices?: string[];
  s3FileId?: number;
  s3FileName?: string;
  s3FileUrl?: string;
  active: boolean;
  deleted: boolean;
}

export enum JobQuestionType {
  VIDEO = 'VIDEO',
  OPEN_FIELD = 'OPEN_FIELD',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  ATTACH_FILE = 'ATTACH_FILE',
}
