import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/home', { replace: true });
    navigate(0);
  };

  return (
    <Container
      fluid
      className="vh-100 d-flex align-items-center justify-content-center"
    >
      <Row className="text-center">
        <Col>
          <h1 className="display-4">404 - Page Not Found</h1>
          <p className="lead">
            Oops! The page you are looking for does not exist.
          </p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleRedirect}
          >
            Go Back Home
          </button>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;
