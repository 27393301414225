import { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Card, Badge, Tooltip, OverlayTrigger, Stack } from 'react-bootstrap';
import dollarSign from '@assets/survey/dollar-sign-logo.svg';
import calendar from '@assets/survey/calendar-logo.svg';
import clock from '@assets/survey/clock-logo.svg';
import { format } from 'date-fns';
import DATE_FORMATS from '@constants/dateFormat';
import {
  SubmissionStatus,
  StatusBadge,
  SurveyStatusProps,
  CallbackStatus,
} from './types';
import './styles.scss';

function SurveyListItem({
  title,
  reward,
  date,
  status: submissionStatus,
  callbackReceived,
  callbackStatus,
}: Readonly<SurveyStatusProps>) {
  const [statusBadge, setStatusBadge] = useState<StatusBadge | null>(null);

  useEffect(() => {
    if (callbackReceived) {
      if (callbackStatus === CallbackStatus.COMPLETED) {
        setStatusBadge(StatusBadge.APPROVED);
      } else if (callbackStatus === CallbackStatus.TERMINATED) {
        setStatusBadge(StatusBadge.DECLINED);
      }
    } else {
      setStatusBadge(StatusBadge.PENDING);
    }
  }, [submissionStatus, callbackReceived, callbackStatus]);

  const handleRedeemClick = () => {
    if (statusBadge === StatusBadge.APPROVED) {
      setStatusBadge(StatusBadge.REDEEMED);
    }
  };

  const getStatusBadge = (
    badgeStatus: StatusBadge,
    subStatus: SubmissionStatus
  ) => {
    if (subStatus === SubmissionStatus.TERMINATED) {
      return (
        <Badge className="status-card min-width-badge bg-light">
          <span className="text-dark fw-bold">N/A</span>
        </Badge>
      );
    }
    switch (badgeStatus) {
      case StatusBadge.PENDING:
        return (
          <Badge bg="secondary" className="status-card min-width-badge">
            <span className="text-primary fw-bold">PENDING</span>
          </Badge>
        );
      case StatusBadge.APPROVED:
        return (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="approved-tooltip">
                We&apos;re actively building this right now!
              </Tooltip>
            }
          >
            <Badge
              bg="light-success"
              className="status-card min-width-badge"
              onClick={handleRedeemClick}
            >
              <span className="text-success fw-bold">APPROVED</span>
            </Badge>
          </OverlayTrigger>
        );
      case StatusBadge.DECLINED:
        return (
          <Badge bg="warning" className="status-card min-width-badge">
            <span className="text-danger fw-bold">DECLINED</span>
          </Badge>
        );
      case StatusBadge.REDEEMED:
        return (
          <Badge bg="success" className="status-card min-width-badge">
            <span className="text-primary fw-bold">REDEEMED</span>
          </Badge>
        );
      default:
        return null;
    }
  };

  const getSubmissionBadge = (subStatus: SubmissionStatus) => {
    return subStatus === SubmissionStatus.TERMINATED ? (
      <Badge bg="warning" className="status-card min-width-badge">
        <span className="text-danger fw-bold">DECLINED</span>
      </Badge>
    ) : (
      <Badge bg="light-success" className="status-card min-width-badge">
        <span className="text-success fw-bold">COMPLETED</span>
      </Badge>
    );
  };

  return (
    <>
      <BrowserView>
        <Card className="survey-status me-3">
          <Card.Body className="d-flex justify-content-between align-items-center">
            {statusBadge !== StatusBadge.REDEEMED ? (
              <>
                <div className="d-flex">
                  <div className="ms-3">
                    <div className="d-flex gap-5">
                      <Card.Title className="mb-0 completed-survey-title">
                        {title}
                      </Card.Title>
                      <div>
                        <span className="ms-1 me-1">
                          <img className="mb-1" src={dollarSign} alt="money" />
                          <span className="ms-1">{`${parseFloat(
                            reward
                          )?.toFixed(2)}`}</span>
                        </span>
                      </div>
                    </div>
                    <span className="d-flex align-items-center">
                      <img className="me-2" src={calendar} alt="calendar" />
                      {format(
                        new Date(date),
                        DATE_FORMATS.DATE_TIME_FORMAT_24H
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-row gap-2">
                  <div className="flex-grow-1 text-center">
                    {getSubmissionBadge(submissionStatus)}
                  </div>
                  <div className="flex-grow-1 text-center">
                    {getStatusBadge(
                      statusBadge as StatusBadge,
                      submissionStatus
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="mt-3 w-100 d-flex justify-content-between">
                <div>
                  <strong>You redeemed your survey bank!</strong>
                  <span className="ms-5">
                    <img className="mb-1" src={dollarSign} alt="money" />{' '}
                    Amount: {reward} USD
                  </span>
                  <span className="ms-5">
                    <img className="mb-1" src={calendar} alt="calendar" />
                    {date}
                  </span>
                </div>
                <Badge bg="white" className="status-card">
                  <span className="text-primary fw-bold">REDEEMED</span>
                </Badge>
              </div>
            )}
          </Card.Body>
        </Card>
      </BrowserView>
      <MobileView>
        <div className="py-3 border-bottom">
          <Stack direction="horizontal" className="justify-content-between">
            <b>{title}</b>
            {getStatusBadge(statusBadge as StatusBadge, submissionStatus)}
          </Stack>
          <div>
            Submission{' '}
            <span className="text-capitalize">{submissionStatus}</span>
          </div>
          <Stack direction="horizontal" gap={3}>
            <Stack direction="horizontal" className="align-items-center">
              <img src={dollarSign} alt="money" />
              <span className="ms-1">{`${parseFloat(reward)?.toFixed(
                2
              )}`}</span>
            </Stack>

            <Stack
              direction="horizontal"
              className="align-items-center"
              gap={1}
            >
              <img src={calendar} alt="calendar" />
              {format(new Date(date), DATE_FORMATS.DATE_FORMAT)}
            </Stack>

            <Stack
              direction="horizontal"
              className="align-items-center"
              gap={1}
            >
              <img src={clock} alt="clock" />
              {format(new Date(date), DATE_FORMATS.TIME_FORMAT_24H)}
            </Stack>
          </Stack>
        </div>
      </MobileView>
    </>
  );
}

export default SurveyListItem;
