import { Col, Row, Stack } from 'react-bootstrap';
import { HiArrowLeft } from 'react-icons/hi';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { JobAssignmentRatingType } from '@company/types/job-assignment-rating.type';
import { getJobRatingByJobAssignment } from '@company/services/rating/api';
import { JobAssignment } from '@th-types/job-assignment.type';
import { IWorkerData } from '@th-types/worker.type';
import QueryKeys from '@constants/queryKeys';
import ReviewWorkerForm from './ReviewWorkerForm/ReviewWorkerForm';
import ReceivedReview from './ReceivedReview/ReceivedReview';

import './styles.scss';

interface ReviewSidePopUpProps {
  jobAssignment: JobAssignment;
  workerInfo: IWorkerData;
  onSubmitReview: () => void;
  onCloseReviewForm: () => void;
}

function ReviewSidePopUp({
  jobAssignment,
  workerInfo,
  onSubmitReview,
  onCloseReviewForm,
}: ReviewSidePopUpProps) {
  const { data: ratingAssignmentData } = useQuery<
    JobAssignmentRatingType,
    AxiosError
  >({
    queryKey: [QueryKeys.WORKER_ASSIGNMENT_JOB, jobAssignment.id],
    queryFn: () => getJobRatingByJobAssignment(jobAssignment.id),
    enabled: jobAssignment?.id !== undefined,
  });

  return (
    <Row>
      <Stack
        direction="horizontal"
        gap={2}
        className="align-items-center p-3 cursor-point fw-bold"
        onClick={onCloseReviewForm}
      >
        <HiArrowLeft size={20} />
        GO BACK
      </Stack>
      {ratingAssignmentData && (
        <ReceivedReview
          rating={ratingAssignmentData}
          workedDates={jobAssignment!.datesWorked}
          worker={workerInfo}
        />
      )}
      <Col>
        <Stack>
          <span className="mb-3 fw-bold fs-5 review-worker-title">
            Review this Worker
          </span>
          <ReviewWorkerForm
            worker={workerInfo}
            jobAssignment={jobAssignment!}
            onSuccess={onSubmitReview}
          />
        </Stack>
      </Col>
    </Row>
  );
}

export default ReviewSidePopUp;
