import { useRef } from 'react';
import ContentLoader from 'react-content-loader';
import { isMobile } from 'react-device-detect';

interface DevtoCardProps {
  [key: string]: any;
}

function LoaderJobDetail(props: DevtoCardProps): JSX.Element {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const windowHeight = windowSize.current[0];

  if (isMobile) {
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height={600}
        viewBox={`0 0 ${windowHeight} 600`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="5%" y="20" rx="0" ry="0" width="90%" height="30" />
        <rect x="5%" y="80" rx="0" ry="0" width="50%" height="15" />
        <rect x="5%" y="110" rx="0" ry="0" width="50%" height="15" />
        <rect x="5%" y="140" rx="0" ry="0" width="50%" height="15" />
        <rect x="80%" y="80" rx="0" ry="0" width="15%" height="20" />
        <rect x="5%" y="200" rx="0" ry="0" width="20%" height="37" />
        <rect x="28%" y="200" rx="0" ry="0" width="20%" height="37" />
        <rect x="52%" y="200" rx="0" ry="0" width="20%" height="37" />
        <rect x="75%" y="200" rx="0" ry="0" width="20%" height="37" />

        <rect x="5%" y="260" rx="0" ry="0" width="122" height="110" />
        <rect x="163" y="260" rx="0" ry="0" width="167" height="15" />
        <rect x="164" y="290" rx="0" ry="0" width="167" height="15" />
        <rect x="163" y="320" rx="0" ry="0" width="167" height="15" />
        <rect x="5%" y="380" rx="0" ry="0" width="90%" height="139" />
      </ContentLoader>
    );
  }

  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={460}
      viewBox={`0 0 ${windowHeight} 460`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="2%" y="10" rx="0" ry="0" width="96%" height="60" />
      <rect x="5%" y="90" rx="0" ry="0" width="20%" height="15" />
      <rect x="5%" y="120" rx="0" ry="0" width="20%" height="15" />
      <rect x="5%" y="150" rx="0" ry="0" width="20%" height="15" />
      <rect x="27%" y="90" rx="0" ry="0" width="20%" height="30" />
      <rect x="5%" y="180" rx="0" ry="0" width="8%" height="34" />
      <rect x="15%" y="180" rx="0" ry="0" width="8%" height="34" />
      <rect x="25%" y="180" rx="0" ry="0" width="8%" height="34" />
      <rect x="35%" y="180" rx="0" ry="0" width="8%" height="34" />
      <rect x="5%" y="240" rx="0" ry="0" width="12%" height="120" />
      <rect x="20%" y="240" rx="0" ry="0" width="20%" height="15" />
      <rect x="20%" y="270" rx="0" ry="0" width="20%" height="15" />
      <rect x="20%" y="300" rx="0" ry="0" width="20%" height="15" />
      <rect x="20%" y="330" rx="0" ry="0" width="20%" height="15" />
      <rect x="5%" y="372" rx="0" ry="0" width="42%" height="15" />
      <rect x="5%" y="409" rx="0" ry="0" width="12%" height="120" />
      <rect x="20%" y="407" rx="0" ry="0" width="12%" height="120" />
      <rect x="35%" y="406" rx="0" ry="0" width="12%" height="120" />
      <rect x="52%" y="90" rx="0" ry="0" width="42%" height="300" />
    </ContentLoader>
  );
}

export default LoaderJobDetail;
