import { privateApi } from '@services/api';
import { IConversationList } from '@company/types/conversation.type';
import Messages from '@th-types/messages.type';
import MessageRequest from '@th-types/message-request.type';
import IResultResponse from '@th-types/resultResponse.type';

export const MAIN_URL = 'chat';

export const fetchConversations = async (
  jobId: number | undefined,
  conversationNameFilter: string | null
) => {
  if (jobId === undefined) {
    return Promise.reject(new Error('No job id was passed'));
  }
  const url = `${MAIN_URL}/job-conversations/${jobId}`;
  const params: Record<string, string> = {
    max: '999',
  };
  if (conversationNameFilter) {
    params.filter = conversationNameFilter;
  }

  try {
    const result = await privateApi.get<IConversationList>(url, { params });
    return result.data;
  } catch (error) {
    console.log('Occurred an error trying to retrieve the conversation');
    return Promise.reject(error);
  }
};

export const fetchMessages = async (jobAssignmentId: number) => {
  const url = `${MAIN_URL}/job-messages/${jobAssignmentId}`;
  try {
    const result = await privateApi.get<Messages>(url);
    return result.data;
  } catch (error) {
    console.log(
      `Occurred an error trying to fetch messages for conversation ${jobAssignmentId}`
    );
    return Promise.reject(error);
  }
};

export const sendMessage = async (
  jobAssignmentId: number | null,
  message: MessageRequest
) => {
  try {
    const result = await privateApi.post<IResultResponse>(
      `${MAIN_URL}/job-messages/${jobAssignmentId}/send`,
      message
    );
    return result.data;
  } catch (error: any) {
    const errorMessage = error?.message ?? 'Error while sending message';
    console.error(
      `Occurred an error trying to send a message with errorMessage:'${errorMessage}' for \njobAssignmentId: ${jobAssignmentId}`
    );
    return { message: errorMessage, success: false };
  }
};

export const readMessages = async (jobAssignmentId: number) => {
  const result = await privateApi
    .post<IResultResponse>(`${MAIN_URL}/job-messages/${jobAssignmentId}/read`)
    .catch((error) => {
      console.log('Occurred an error trying to read the conversation');
      return error.response;
    });
  return result.data;
};
