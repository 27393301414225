import './style.css';

export default function ThRange(props: any) {
  const { value, max } = props;

  return (
    <input
      {...props}
      type="range"
      style={{
        backgroundSize: `${(value * 100) / max}% 100%`,
      }}
    />
  );
}
