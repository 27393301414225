// Calculate the distance between two points on the Earth
export const haversineDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const toRad = (x: number) => (x * Math.PI) / 180;
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon1 - lon2);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in kilometers
};

// Returns the zoom level based on the distance between two points
export const getZoomLevel = (distance: number) => {
  if (distance < 1) return 12;
  if (distance < 5) return 11;
  if (distance < 10) return 10;
  if (distance < 20) return 9;
  if (distance < 50) return 8;
  if (distance < 100) return 7;
  if (distance < 200) return 6;
  if (distance < 400) return 5;
  if (distance < 800) return 4;
  if (distance < 1000) return 3;
  return 10;
};
