import { Button, Form } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import IBatchRateWorkerSession, {
  IBatchRateWorker,
} from '@company/types/batch-rate-worker.type';

interface Props {
  control: Control<IBatchRateWorkerSession, null>;
  worker: IBatchRateWorker;
  actionName: 'noCallNoShowed' | 'canceledUnexcused';
  actionTitle: string;
  handleToggleValue: (field: string, value: boolean) => void;
}
function ActionButton({
  control,
  worker,
  actionName,
  actionTitle,
  handleToggleValue,
}: Props) {
  return (
    <Form.Group controlId={`rateWorkerForm.${actionName}`}>
      <Controller
        control={control}
        name={`workers.${worker.id}.workerRating.${actionName}`}
        render={({ field: { onChange, name, value, ref } }) => (
          <Button
            ref={ref}
            name={name}
            id={name}
            type="button"
            variant={value ? 'primary' : 'outline-dark border-color-dark'}
            className="mt-3 w-100"
            onClick={() => {
              onChange(value);
              handleToggleValue(actionName, !value);
            }}
          >
            <b>{actionTitle}</b>
          </Button>
        )}
      />
    </Form.Group>
  );
}

export default ActionButton;
