import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import Select from 'react-select';
import SelectOption from '@th-types/selectOption.type';
import { IJobOptionsData, IJobTitle } from '@th-types/jobs.type';
import IBatchRateWorkerSession, {
  IBatchRateWorker,
} from '@company/types/batch-rate-worker.type';
import { REQUIRED_FIELD_MESSAGE } from '@constants/validation';
import { useEffect, useState } from 'react';

interface Props {
  isTHJob: boolean;
  worker: IBatchRateWorker;
  errors: FieldErrors<IBatchRateWorkerSession>;
  control: Control<IBatchRateWorkerSession, null>;
  setValue: UseFormSetValue<IBatchRateWorkerSession>;
  jobOptions: IJobOptionsData | null;
  batchRateWorkerSession: IBatchRateWorkerSession | undefined;
}
function JobTitle({
  isTHJob,
  worker,
  errors,
  setValue,
  control,
  jobOptions,
  batchRateWorkerSession,
}: Props) {
  const [jobOptionsList, setJobOptionsList] = useState<SelectOption[]>([]);
  const mountSelect = (
    arr: IJobTitle[] | undefined
  ): { value: number; label: string }[] => {
    return !arr
      ? []
      : arr.map((item: IJobTitle) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
  };

  const getJobOptions = () => {
    if (isTHJob && batchRateWorkerSession !== undefined) {
      const thJobOptions = [];
      const { assignment } =
        batchRateWorkerSession.workers.find((w) => w.id === worker.id)
          ?.workerRating ?? {};
      if (assignment) {
        const { jobTitle } = assignment;
        const jaJobTitle = [jobTitle].map((jt) => {
          return { value: jt.id, label: jt.name! };
        });

        thJobOptions.push(...jaJobTitle);
      } else if (batchRateWorkerSession) {
        const ratingJobTitle = [batchRateWorkerSession.title].map((jt) => {
          return { value: jt.id, label: jt.name! };
        });
        thJobOptions.push(...ratingJobTitle);
      }
      return thJobOptions;
    }
    return mountSelect(jobOptions?.jobTitles);
  };

  useEffect(() => {
    const workerJobTitle = batchRateWorkerSession?.workers.find(
      (w) => w.id === worker.id
    )?.workerRating?.title;
    if (workerJobTitle !== undefined) {
      const options = getJobOptions();
      if (options.length > 0) {
        const option = isTHJob
          ? options[0]
          : options.find((o) => o.value === batchRateWorkerSession?.title.id);
        if (option) {
          const jobTitle = {
            id: Number(option.value),
            name: option.label,
          };
          setValue(`workers.${worker.id}.workerRating.title`, jobTitle);
          if (option.label === 'Other') {
            setValue(
              `workers.${worker.id}.workerRating.otherJobTitleDescription`,
              batchRateWorkerSession?.otherJobTitleDescription
            );
          }
        }
        setJobOptionsList(options);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    batchRateWorkerSession?.title,
    batchRateWorkerSession?.workers,
    isTHJob,
    worker.id,
  ]);

  return (
    <Form.Group controlId={`workers.${worker.id}.workerRating.title`}>
      <Form.Label className="fw-bold">Job Title*</Form.Label>
      <Controller
        control={control}
        name={`workers.${worker.id}.workerRating.title`}
        rules={{ required: REQUIRED_FIELD_MESSAGE }}
        render={({ field: { onChange, value, name, ref } }) => (
          <>
            <Select
              name={name}
              ref={ref}
              required
              isClearable
              inputId={`workers.${worker.id}.workerRating.title`}
              placeholder="Select job title"
              options={jobOptionsList}
              value={jobOptionsList.find(
                (c: SelectOption) => c.value === value?.id
              )}
              isDisabled={isTHJob}
              className={`mt-2 
                ${
                  errors?.workers &&
                  !!errors?.workers[worker.id]?.workerRating?.title
                    ? 'form-control is-invalid'
                    : ''
                }`}
              onChange={(val) => {
                onChange(val ? { id: val?.value } : val);
              }}
            />
            {errors?.workers &&
              !!errors?.workers[worker.id]?.workerRating?.title && (
                <Form.Control.Feedback type="invalid">
                  {errors?.workers[worker.id]?.workerRating?.title?.message}
                </Form.Control.Feedback>
              )}
          </>
        )}
      />
    </Form.Group>
  );
}

export default JobTitle;
