import { completeOnboarding } from '@services/activity-tracker/activityTrackerService';
import { FiAlertTriangle } from '@react-icons/all-files/fi/FiAlertTriangle';
import { GrLocation } from '@react-icons/all-files/gr/GrLocation';
import * as api from '@company/services/job/api';
import { JobAssignment } from '@th-types/job-assignment.type';
import { ActivityTrackerView } from '@th-types/jobs.type';
import DATE_FORMATS from '@constants/dateFormat';
import QueryKeys from '@constants/queryKeys';
import { groupBy, sortBy } from 'lodash';
import { Button } from 'react-bootstrap';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  QueryObserverResult,
  RefetchOptions,
  useQuery,
} from '@tanstack/react-query';
import {
  convertToTimezone,
  getDateNowByTimezone,
  UTC_TIMEZONE,
} from '@utils/DateUtils';
import {
  ActivityDetail,
  ActivityDetailList,
  ActivityStatus,
} from '@th-types/daily-activity.type';
import { useJobContext } from './jobContext';

interface GroupByActivityType {
  groupedBy: string;
  groupedByActivities: ActivityDetail[];
}

interface IActivityContext {
  upcoming: boolean;
  isChatOpen: boolean;
  isLoadingAction: boolean;
  activitiesFilter: string[];
  viewType: ActivityTrackerView;
  activities?: ActivityDetail[];
  showReviewShiftModal: boolean;
  selectedActivity: ActivityDetail | undefined;
  groupedByDateActivities: GroupByActivityType[];
  groupedByWorkerActivities: GroupByActivityType[];
  getCheckInValue: (
    activityDetail: ActivityDetail,
    handleOpenReviewShiftModal?: (activity: ActivityDetail) => void
  ) => ReactElement | null;
  getCheckOutValue: (
    activityDetail: ActivityDetail,
    handleOpenReviewShiftModal?: (activity: ActivityDetail) => void
  ) => ReactElement | null;
  setUpcoming: (state: boolean) => void;
  setIsChatOpen: (isOpen: boolean) => void;
  setIsLoadingAction: (state: boolean) => void;
  setActivitiesFilter: (filters: string[]) => void;
  setShowReviewShiftModal: (show: boolean) => void;
  setViewType: (type: ActivityTrackerView) => void;
  getActivityStatus: (item: ActivityDetail) => string;
  isShiftPayable: (activity: ActivityDetail) => boolean;
  isShiftUnPayable: (activity: ActivityDetail) => boolean;
  setActivities: (activities: ActivityDetail[]) => void;
  setSelectedActivity: (activity: ActivityDetail | undefined) => void;
  completeOnboardingAction: (
    jobAssignments: JobAssignment[],
    completed?: boolean
  ) => void;
  error?: AxiosError<unknown, unknown> | null;
  isLoading?: boolean;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<
    QueryObserverResult<ActivityDetailList, AxiosError<unknown, unknown>>
  >;
  isRefetching: boolean;
}

const ActivityContext: IActivityContext | any = createContext({});
interface ProviderProps {
  children: React.ReactNode;
}
export function ActivityProvider({ children }: ProviderProps) {
  const { activeJobId, job } = useJobContext();

  const [viewType, setViewType] = useState<ActivityTrackerView>(
    ActivityTrackerView.DATE
  );
  const [upcoming, setUpcoming] = useState(false);
  const [activities, setActivities] = useState<ActivityDetail[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<
    ActivityDetail | undefined
  >();
  const [groupedByDateActivities, setGroupedByDateActivities] = useState<
    GroupByActivityType[]
  >([]);
  const [groupedByWorkerActivities, setGroupedByWorkerActivities] = useState<
    GroupByActivityType[]
  >([]);
  const [activitiesFilter, setActivitiesFilter] = useState<string[]>([]);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [showReviewShiftModal, setShowReviewShiftModal] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const { isLoading, error, data, refetch, isRefetching } = useQuery<
    ActivityDetailList,
    AxiosError
  >({
    queryKey: [
      `${QueryKeys.COMPANY_JOB_ACTIVITY_TRACKER}_${activeJobId}`,
      activeJobId,
    ],
    queryFn: async () => await api.fetchActivityTrackerList(activeJobId),
    enabled: job !== null && activeJobId > 0,
  });

  useEffect(() => {
    if (job?.id) {
      refetch();
    }
  }, [job?.id, refetch]);

  useEffect(() => {
    const getGroupByDateData = () => {
      const groupByDateData: GroupByActivityType[] = [];
      if (data) {
        const groupByDate = groupBy(
          sortBy(data.activities, (d) => d.shiftPosition.date),
          (item) => item.shiftPosition.date
        );
        Object.entries(groupByDate).forEach(([key, value]) => {
          groupByDateData.push({ groupedBy: key, groupedByActivities: value });
        });
      }
      return groupByDateData;
    };

    const getGroupByWorkerData = () => {
      const groupByWorkerData: GroupByActivityType[] = [];
      if (data) {
        const groupByWorker = groupBy(
          sortBy(
            data.activities,
            (item) => item.shiftPosition.jobAssignment?.worker.fullName
          ),
          (item) => item.shiftPosition.jobAssignment?.worker.fullName
        );
        Object.entries(groupByWorker).forEach(([key, value]) => {
          groupByWorkerData.push({
            groupedBy: key,
            groupedByActivities: value,
          });
        });
      }
      return groupByWorkerData;
    };
    if (data?.activities) {
      setActivities(data.activities);
      setGroupedByDateActivities(getGroupByDateData());
      setGroupedByWorkerActivities(getGroupByWorkerData());
    } else if (activities) {
      setGroupedByDateActivities(getGroupByDateData());
      setGroupedByWorkerActivities(getGroupByWorkerData());
    }
  }, [data, viewType, activities]);

  const getActivityStatus = useCallback((item: ActivityDetail) => {
    if (item.timeSheet && item.dailyActivity && !item.shiftPosition.isPaid) {
      return ActivityStatus.TO_APPROVE.toString();
    }
    if (!item.dailyActivity || !item.timeSheet) {
      return ActivityStatus.IN_PROGRESS.toString();
    }
    return ActivityStatus.CANCELLED.toString();
  }, []);

  const isShiftPayable = useCallback(
    (activity: ActivityDetail) => {
      return (
        convertToTimezone(
          new Date(activity.shiftPosition.end),
          job?.timezone || UTC_TIMEZONE
        ) < getDateNowByTimezone(job?.timezone) &&
        !activity.shiftPosition.isPaid &&
        activity.timeSheet !== undefined
      );
    },
    [job?.timezone]
  );

  const isShiftUnPayable = useCallback((activity: ActivityDetail) => {
    return activity.shiftPosition.isPaid;
  }, []);

  const getCheckInValue = useCallback(
    (
      activity: ActivityDetail,
      handleOpenReviewShiftModal?: (activity: ActivityDetail) => void
    ) => {
      if (!job || !job.timezone) {
        return null;
      }
      if (activity.dailyActivity?.localizedCheckInTime) {
        const date = new Date(activity.dailyActivity.localizedCheckInTime);
        if (handleOpenReviewShiftModal) {
          return (
            <Button
              variant="link"
              onClick={() => handleOpenReviewShiftModal(activity)}
            >
              <GrLocation className="mb-1" />{' '}
              {format(date, DATE_FORMATS.TIME_FORMAT)}
            </Button>
          );
        }
        return (
          <>
            <GrLocation className="mb-1" />{' '}
            {format(date, DATE_FORMATS.TIME_FORMAT)}
          </>
        );
      }
      if (
        convertToTimezone(
          new Date(activity.shiftPosition.start),
          job?.timezone || UTC_TIMEZONE
        ) > getDateNowByTimezone(job?.timezone)
      ) {
        return <span className="fst-italic">Upcoming</span>;
      }
      return (
        <>
          <FiAlertTriangle color="var(--red)" className="mb-1" size={16} />
          <span className="ps-1 missing-timesheet">Missing</span>
        </>
      );
    },
    [job]
  );

  const getCheckOutValue = useCallback(
    (
      activity: ActivityDetail,
      handleOpenReviewShiftModal?: (activity: ActivityDetail) => void
    ) => {
      if (!job || !job.timezone) {
        return null;
      }
      if (activity.dailyActivity?.localizedCheckOutTime) {
        const date = new Date(activity.dailyActivity.localizedCheckOutTime);
        if (handleOpenReviewShiftModal) {
          return (
            <Button
              variant="link"
              onClick={() => handleOpenReviewShiftModal(activity)}
            >
              <GrLocation className="mb-1" />{' '}
              {format(date, DATE_FORMATS.TIME_FORMAT)}
            </Button>
          );
        }
        return (
          <>
            <GrLocation className="mb-1" />{' '}
            {format(date, DATE_FORMATS.TIME_FORMAT)}
          </>
        );
      }
      const startDate = convertToTimezone(
        new Date(activity.shiftPosition.start),
        job.timezone
      );
      const endDate = convertToTimezone(
        new Date(activity.shiftPosition.end),
        job.timezone
      );
      if (startDate > getDateNowByTimezone(job?.timezone)) {
        return <span className="fst-italic">Upcoming</span>;
      }
      const isJobInProgress =
        startDate <= getDateNowByTimezone(job?.timezone) &&
        endDate > getDateNowByTimezone(job?.timezone);
      if (isJobInProgress) {
        return <span className="fst-italic">In progress</span>;
      }
      return (
        <>
          <FiAlertTriangle color="var(--red)" className="mb-1" size={16} />
          <span className="ms-1 ps-1 missing-timesheet">Missing</span>
        </>
      );
    },
    [job]
  );

  const completeOnboardingAction = useCallback(
    async (jobAssignments: JobAssignment[], completed: boolean = true) => {
      try {
        if (activities) {
          setIsLoadingAction(true);
          setActivities(
            await completeOnboarding(jobAssignments, activities, completed)
          );
        }
      } catch (err) {
        console.log(
          `Occurred an error trying to mark onboardings as completed`,
          err
        );
      } finally {
        setIsLoadingAction(false);
      }
    },
    [activities]
  );

  const contextValue: IActivityContext = useMemo(() => {
    return {
      error,
      viewType,
      upcoming,
      isLoading,
      activities,
      isChatOpen,
      isRefetching,
      isLoadingAction,
      activitiesFilter,
      selectedActivity,
      showReviewShiftModal,
      groupedByDateActivities,
      groupedByWorkerActivities,
      refetch,
      setUpcoming,
      setViewType,
      setIsChatOpen,
      setActivities,
      isShiftPayable,
      isShiftUnPayable,
      getCheckInValue,
      getCheckOutValue,
      getActivityStatus,
      setIsLoadingAction,
      setActivitiesFilter,
      setSelectedActivity,
      setShowReviewShiftModal,
      completeOnboardingAction,
    };
  }, [
    error,
    viewType,
    upcoming,
    isLoading,
    activities,
    isChatOpen,
    isRefetching,
    isLoadingAction,
    activitiesFilter,
    selectedActivity,
    showReviewShiftModal,
    groupedByDateActivities,
    groupedByWorkerActivities,
    refetch,
    setUpcoming,
    setViewType,
    setIsChatOpen,
    setActivities,
    isShiftPayable,
    isShiftUnPayable,
    getCheckInValue,
    getCheckOutValue,
    getActivityStatus,
    setIsLoadingAction,
    setActivitiesFilter,
    setSelectedActivity,
    setShowReviewShiftModal,
    completeOnboardingAction,
  ]);
  return (
    <ActivityContext.Provider value={contextValue}>
      {children}
    </ActivityContext.Provider>
  );
}

export function useActivityContext() {
  const context: IActivityContext = useContext(ActivityContext);
  if (context === undefined) {
    throw new Error(
      'useActivityContext must be used within a ActivityProvider. Wrap a parent component in <ActivityProvider> to fix this error.'
    );
  }
  return context;
}
