import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Button, Col, Form, Row, Stack } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  NonEventExperienceSchemaType,
  nonEventExperienceValidationSchema,
} from '@th-types/workerProfile.type';
import { ThLoading } from '@components/elements';
import useAlert from '@hooks/useAlert';
import { useWorkerProfileContext } from '@worker/state/workerProfileContext';
import * as api from '@worker/services/worker/api';

import './styles.css';

interface NonEventExperienceFormProps {
  experienceToEdit?: NonEventExperienceSchemaType;
  onCancel: () => void;
  onSuccess: () => void;
}

function NonEventExperienceForm({
  experienceToEdit,
  onCancel,
  onSuccess,
}: NonEventExperienceFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { setNonPromoWorkExperience } = useWorkerProfileContext();
  const { showErrorAlert, alertMessage } = useAlert();
  /**
   * CREATE VALIDATION SCHEMA FOR EVENT EXPERIENCE AND UPDATE HERE
   */
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<NonEventExperienceSchemaType>({
    resolver: zodResolver(nonEventExperienceValidationSchema),
    defaultValues: {
      endDate: experienceToEdit?.endDate
        ? new Date(experienceToEdit.endDate)
        : undefined,
      id: experienceToEdit?.id,
      startDate: experienceToEdit?.startDate
        ? new Date(experienceToEdit.startDate)
        : undefined,
      hiringCompany: experienceToEdit?.hiringCompany,
      position: experienceToEdit?.position,
      duties: experienceToEdit?.duties,
    },
  });

  const onSubmit: SubmitHandler<NonEventExperienceSchemaType> = async (
    data: NonEventExperienceSchemaType
  ) => {
    try {
      setIsLoading(true);
      const result = await api.addNonEeventExperience(data);
      if (result) {
        // THIS CAN BE MOVED TO A FUNCTION IN THE CONTEXT
        setNonPromoWorkExperience((prevState) => {
          if (!prevState) {
            return [{ ...result }];
          }
          console.log(prevState);
          const newState = [...prevState!];
          const expIndex = prevState.findIndex((exp) => exp.id === result.id);
          if (expIndex >= 0) {
            newState[expIndex] = { ...result };
          } else {
            newState.unshift({ ...result });
          }
          return newState;
        });
        reset();
        onSuccess();
      }
    } catch (error) {
      showErrorAlert('An error ocurred submitting the form.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // need to handle errors in the form showing error borders and error messages
    console.log(errors);
  }, [errors]);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="non-event-experience-form"
      autoComplete="off"
    >
      {isLoading && <ThLoading />}
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>
            <b>Hiring Company *</b>
          </Form.Label>
          <Form.Control
            {...register('hiringCompany')}
            placeholder="Enter hiring company"
          />
          {errors.hiringCompany && (
            <span className="form-field-error-message">
              {errors.hiringCompany.message}
            </span>
          )}
        </Form.Group>
        <Form.Group>
          {/* SHOULD JOB TITLE BE SELECTABLE ??? */}
          <Form.Label>
            <b>Job Title *</b>
          </Form.Label>
          <Form.Control
            {...register('position')}
            placeholder="Enter job title"
          />
          {errors.position && (
            <span className="form-field-error-message">
              {errors.position.message}
            </span>
          )}
        </Form.Group>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                <b>Start Date *</b>
              </Form.Label>
              <Controller
                control={control}
                name="startDate"
                render={({ field }) => (
                  <ReactDatePicker
                    className={`form-control ${
                      errors.startDate?.message ? 'border-danger' : ''
                    }`}
                    selected={field.value}
                    onChange={(date) => field.onChange(date as Date)}
                    placeholderText="MM/DD/YYYY"
                    maxDate={new Date()}
                  />
                )}
              />
              {errors.startDate && (
                <span className="form-field-error-message">
                  {errors.startDate.message}
                </span>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>
                <b>End Date</b>
              </Form.Label>
              <Controller
                control={control}
                name="endDate"
                render={({ field }) => (
                  <ReactDatePicker
                    className={`form-control ${
                      errors.endDate?.message ? 'border-danger' : ''
                    }`}
                    selected={field.value}
                    onChange={(date) => field.onChange(date as Date)}
                    placeholderText="MM/DD/YYYY"
                    maxDate={new Date()}
                  />
                )}
              />
              {errors.endDate && (
                <span className="form-field-error-message">
                  {errors.endDate.message}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>
            <b>What did you do in this role?</b>
          </Form.Label>
          <Form.Control
            as="textarea"
            {...register('duties')}
            className={errors.duties ? 'border-danger' : ''}
          />
          {errors.duties && (
            <span className="form-field-error-message">
              {errors.duties.message}
            </span>
          )}
        </Form.Group>
        <Stack direction="horizontal" className="justify-content-end" gap={3}>
          <Button
            variant="light"
            onClick={onCancel}
            className={isMobile ? 'flex-grow-1' : ''}
          >
            Cancel
          </Button>
          <Button type="submit">Save Changes</Button>
        </Stack>
      </Stack>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </Form>
  );
}

export default NonEventExperienceForm;
