import { useState } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { UseFormRegister } from 'react-hook-form';
import IBatchRateWorkerSession, {
  IBatchRateWorker,
} from '@company/types/batch-rate-worker.type';

const COMMENTS_MAX_LENGTH = 300;

interface Props {
  register: UseFormRegister<IBatchRateWorkerSession>;
  worker: IBatchRateWorker;
}
function InternalNotes({ register, worker }: Props) {
  const [commentsCount, setCommentsCount] = useState<number>(0);
  return (
    <Form.Group controlId="rateWorkerForm.internalNotes">
      <Stack direction={`${isMobile ? 'vertical' : 'horizontal'}`}>
        <Form.Label className="fw-bold">
          Internal Note on Worker Profile
        </Form.Label>
        <span
          className={`${
            isMobile ? '' : 'mx-2'
          } optional-badge text-uppercase small`}
        >
          (Optional, Only your company can see these)
        </span>
      </Stack>
      <Form.Control
        {...register(`workers.${worker.id}.workerRating.internalNotes`)}
        as="textarea"
        maxLength={COMMENTS_MAX_LENGTH}
        placeholder=""
        className="mt-2"
        onChange={(e) => setCommentsCount(e.target.value.length || 0)}
        autoComplete="off"
      />
      <Form.Text className="d-flex justify-content-end">
        {commentsCount}/{COMMENTS_MAX_LENGTH}
      </Form.Text>
    </Form.Group>
  );
}

export default InternalNotes;
