import { Button, Modal, Stack } from 'react-bootstrap';
import ModalConfirmModel from '@th-types/confirm-model.type';

interface ModalConfirmParams {
  modalConfirmModel: ModalConfirmModel;
  setShowModal: (show: boolean) => void;
}

function ModalConfirm({ modalConfirmModel, setShowModal }: ModalConfirmParams) {
  const handleClose = () => setShowModal(false);

  const handleConfirm = () => {
    modalConfirmModel.confirmFunction();
    handleClose();
  };

  return (
    <Modal
      show
      onHide={handleClose}
      centered
      className={modalConfirmModel.styleClass || ''}
    >
      <Modal.Header className="text-center" closeButton>
        {modalConfirmModel.titleMessage && (
          <Modal.Title>{modalConfirmModel.titleMessage}</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>{modalConfirmModel.message}</Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Stack direction="horizontal" gap={4}>
          <Button variant="primary" size="sm" onClick={handleConfirm}>
            {modalConfirmModel.confirmMessage ?? 'Confirm'}
          </Button>

          <Button variant="light" size="sm" onClick={handleClose}>
            Close
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConfirm;
