import { useEffect, useState } from 'react';
import { supportsPassiveOnEventListener } from '@utils/Polyfill';

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });
  useEffect(() => {
    const supportsPassive = supportsPassiveOnEventListener();
    const updateMousePosition = (ev: any) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };
    window.addEventListener(
      'click',
      updateMousePosition,
      supportsPassive
        ? {
            passive: true,
          }
        : false
    );
    return () => {
      window.removeEventListener('click', updateMousePosition);
    };
  }, []);
  return mousePosition;
};
export default useMousePosition;
