import { useJobWorkerShiftContext } from '@company/state/jobWorkerShiftContext';
import { JobUpdatableFields } from '@company/types/job.updatable.fields';
import { ActionButtonsMobile } from '@company/components/th/ThWorkerRow';
import { useJobShiftContext } from '@company/state/jobShiftContext';
import { JobWorkerSideModal } from '@company/components/JobWorker';
import { AiTwotoneCalendar } from '@react-icons/all-files/ai/AiTwotoneCalendar';
import { useJobAssignmentContext } from '@company/state/jobAssignmentContext';
import { AiOutlineDollar } from '@react-icons/all-files/ai/AiOutlineDollar';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useJobContext } from '@company/state/jobContext';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import { BsPeopleCircle } from '@react-icons/all-files/bs/BsPeopleCircle';
import { useJobAssignmentStatus } from '@hooks/useJobAssignmentStatus';
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft';
import * as api from '@company/services/job/api';
import BullhornIcon from '@assets/bullhorn/bullhorn.webp';
import { IoMdPin } from '@react-icons/all-files/io/IoMdPin';
import { useChatContext } from '@company/state/chatContext';
import IActionButton from '@th-types/action-button.type';
import { useUserContext } from '@state/userContext';
import { useSearchParams } from 'react-router-dom';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { UserRole } from '@constants/userRoles';
import { isMobile } from 'react-device-detect';
import { Panel } from '@components/elements';
import DOMPurify from 'dompurify';
import {
  Badge,
  Button,
  Col,
  Form,
  Image,
  OverlayTrigger,
  Row,
  Stack,
  Tooltip,
} from 'react-bootstrap';
import HiresAndCandidates from './HiresAndCandidates';
import ModalDeleteJob from './Modal/ModalDeleteJob';
import ModalShareJobLink from './ModalShareJobLink';
import JobQuestions from './JobQuestions';
import JobSkills from './JobSkills';
import './style.css';

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

export default function Detail(): ReactElement {
  const {
    job,
    setActiveJobId,
    refetch,
    isInternalMember,
    getLocalStartDate,
    getLocalEndDate,
    getJobYear,
  } = useJobContext();
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(false);
  const [disableBullhornBtn, setDisableBullhornBtn] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const jobAssignmentId = searchParams.get('jobAssignmentId');
  const workerIdParameter = searchParams.get('workerId');
  const openConvo = searchParams.get('openConvo');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { id, hasRole } = useUserContext();
  const [currentWorkerProfileQueryKey, setCurrentWorkerProfileQueryKey] =
    useState('');
  const { openChat } = useChatContext();
  const jobAssignmentStatus = useJobAssignmentStatus();
  const {
    selectedWorker,
    setStatusFilter,
    setSelectedWorker,
    setSelectedShifts,
  } = useJobShiftContext();
  const { showOfferShiftModal, setIsJobWorkerSideModalOpen } =
    useJobWorkerShiftContext();

  const {
    queryKey: workerProfileQueryKey,
    workerId,
    workerJobData,
    isLoadingWorkerProfileData,
    setWorkerId,
    refetch: refetchWorkerProfile,
  } = useJobAssignmentContext();
  const { worker, jobAssignment } = workerJobData ?? {};

  useEffect(() => {
    if (workerIdParameter && job?.id) {
      const workerIdNumber = Number.parseInt(workerIdParameter, 10);
      setWorkerId(workerIdNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?.id]);

  const shouldRefetchWorkerProfile = (isSameQueryKey: boolean) => {
    // when isSameQueryKey is true, that means the workerId is the same and won't be updated by context,
    // if OfferShiftModal is not shown, so we need to refetch worker profile manually
    return (
      selectedWorker &&
      workerId === selectedWorker.id &&
      isSameQueryKey &&
      !showOfferShiftModal
    );
  };

  useEffect(() => {
    const isSameQueryKey =
      currentWorkerProfileQueryKey === workerProfileQueryKey;
    setCurrentWorkerProfileQueryKey(workerProfileQueryKey);
    if (shouldRefetchWorkerProfile(isSameQueryKey)) {
      refetchWorkerProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorker, refetchWorkerProfile]);

  useEffect(() => {
    if (worker?.id) {
      if (workerIdParameter && worker?.id && !openConvo) {
        setSelectedShifts([]);
        setIsJobWorkerSideModalOpen(true);
        setSelectedWorker(worker);
      } else if (
        jobAssignmentId &&
        worker?.id &&
        jobAssignment?.id &&
        openConvo
      ) {
        const { isHiredOrApplicant } = jobAssignmentStatus(
          jobAssignment.status
        );
        openChat(
          worker,
          Number.parseInt(jobAssignmentId, 10),
          isHiredOrApplicant()
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingWorkerProfileData]);

  const resizeTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  useEffect(resizeTextArea, [job?.description, seeMore]);

  const sortedJobs = useMemo(
    () => (job ? job.event.eventJobIdsByAddress.sort() : []),
    [job]
  );

  const numberOfJobs = useMemo(
    () => (job ? job.event.eventJobIdsByAddress.length : 0),
    [job]
  );

  const getInternalMembersFullName = useMemo(() => {
    return job?.members
      ?.filter((member) => member.isInternal)
      ?.map((member) => `${member.firstName} ${member.lastName}`)
      ?.join(', ');
  }, [job?.members]);

  const countInternalMembers = useMemo(() => {
    let numberOfInternalMembers = 0;
    if (job?.members) {
      numberOfInternalMembers = job.members.filter(
        (member) => member.isInternal
      ).length;
    }
    return `${
      numberOfInternalMembers > 1
        ? ` ${numberOfInternalMembers} Internal members`
        : '1 Internal member'
    } `;
  }, [job?.members]);

  if (!job) {
    return <div />;
  }

  const nextJob = () => {
    if (sortedJobs) {
      const index = sortedJobs.indexOf(job.id);
      const nextId = sortedJobs[index + 1] || sortedJobs[0];
      setActiveJobId(nextId);
    }
  };

  const previousJob = () => {
    if (sortedJobs) {
      const index = sortedJobs.indexOf(job.id);
      const previousId =
        sortedJobs[index - 1] || sortedJobs[sortedJobs.length - 1];
      setActiveJobId(previousId);
    }
  };

  const getActiveJobIndex = () => {
    const index = sortedJobs?.indexOf(job.id);
    return index && index >= 0 ? index + 1 : 1;
  };

  const jobPositionElement = () => (
    <>
      {numberOfJobs && numberOfJobs > 1 && (
        <FiChevronLeft
          className="cursor-point"
          size={22}
          onClick={previousJob}
        />
      )}
      <b className="th-fs-14">
        POSITION {getActiveJobIndex()} OF {numberOfJobs}
      </b>
      {numberOfJobs && numberOfJobs > 1 && (
        <FiChevronRight className="cursor-point" size={22} onClick={nextJob} />
      )}
    </>
  );

  const handlePrivateJobChange = async (jobId: number) => {
    setDisabled(true);

    const { isJobPrivate } = job;

    try {
      const payload: JobUpdatableFields = {
        privateJob: !isJobPrivate,
      };

      await api.patchJob(jobId, payload);
    } catch (err) {
      console.log(err);
    } finally {
      refetch();
      setDisabled(false);
    }
  };

  const handleCloseWorkerProfile = () => {
    setIsJobWorkerSideModalOpen(false);
    if (searchParams.has('jobAssignmentId')) {
      searchParams.delete('jobAssignmentId');
      searchParams.delete('workerId');
      setSearchParams(searchParams);
    }
    setStatusFilter([]);
    setSelectedWorker(undefined);
  };

  const shouldRenderActionButton = () => {
    return isInternalMember(id) || hasRole(UserRole.ROLE_COMPANY_ADMIN);
  };

  const getStatusBadge = () => {
    if (!job.datePublished) {
      return (
        <Badge className="ms-3" bg="secondary" text="dark">
          Draft
        </Badge>
      );
    }
    if (!job.isActive) {
      return (
        <Badge className="ms-3" bg="warning" text="dark">
          Past
        </Badge>
      );
    }
    return null;
  };

  const redirectEditPage = () => {
    window.location.href = job.datePublished
      ? `${SERVER_URL}/job/editJob/${job.id}`
      : `${SERVER_URL}/job/editEvent?eventId=${job.event.id}`;
  };

  const getActions = () => {
    const actions: IActionButton[] = [];

    if (shouldRenderActionButton()) {
      actions.push({
        label: 'EDIT JOB',
        variant: 'light',
        size: 'sm',
        action: () => redirectEditPage(),
      });
      actions.push({
        label: 'DELETE JOB',
        variant: 'light',
        size: 'sm',
        action: () => setShowDeleteModal(true),
      });
    }
    return actions;
  };

  const refreshBullhornJobOrder = async (
    jobId: number,
    integrationId: number | undefined
  ) => {
    setDisableBullhornBtn(true);

    try {
      await api.refreshBullhornJob(jobId, integrationId);
    } catch (err) {
      console.log(err);
    } finally {
      refetch();
      setDisableBullhornBtn(false);
    }
  };

  return (
    <div>
      <Row>
        <Col lg={6} sm={12} className="mb-3">
          <Panel>
            <Row>
              {isMobile && (
                <Col md={12} sm={12}>
                  <Stack
                    direction="horizontal"
                    className="justify-content-center"
                    gap={3}
                  >
                    {jobPositionElement()}
                  </Stack>
                  <hr />
                </Col>
              )}
              <Col md={12} sm={12}>
                <div>
                  <Row>
                    <Col>
                      <b className="fs-5">
                        {job.eventName}
                        {getStatusBadge()}
                      </b>
                      <br />
                    </Col>
                    <Col>
                      <Stack
                        direction="horizontal"
                        className="justify-content-end"
                      >
                        {isMobile ? (
                          <ActionButtonsMobile actions={getActions()} />
                        ) : (
                          jobPositionElement()
                        )}
                      </Stack>
                    </Col>
                  </Row>
                  {job.nationalJob
                    ? 'National Job'
                    : job?.address?.googleAddress}
                  <br />
                  {job?.bullhornStaffingBoard?.id && (
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                      }}
                    >
                      <Image
                        style={{
                          width: '30px',
                          height: '30px',
                          padding: '5px',
                        }}
                        src={BullhornIcon}
                      />
                      <div style={{ display: 'flex', padding: '5px' }}>
                        <span style={{ color: '#9B9B9B', fontSize: '9pt' }}>
                          Bullhorn Job Order ID:
                          {job.bullhornStaffingBoard?.externalId.split('-')[1]}
                          ID: {job.bullhornStaffingBoard?.id}
                        </span>
                      </div>
                      <div style={{ display: 'flex', padding: '5px' }}>
                        <Button
                          onClick={() =>
                            refreshBullhornJobOrder(
                              job.id,
                              job?.bullhornStaffingBoard?.id
                            )
                          }
                          variant="primary ms-auto"
                          size="sm"
                          style={{ fontSize: '9pt' }}
                          disabled={disableBullhornBtn}
                        >
                          Refresh Job
                        </Button>
                      </div>
                    </div>
                  )}
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>{getInternalMembersFullName}</Tooltip>}
                  >
                    <span className="text-gray">{countInternalMembers}</span>
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: '2%', marginBottom: '1%' }}>
              <b>
                <Form.Check
                  type="checkbox"
                  id={`checkbox-${!job.isJobPrivate}`}
                  checked={!job.isJobPrivate}
                  onChange={() => handlePrivateJobChange(job.id)}
                  label="Show this on Public Job Boards?"
                  disabled={disabled}
                />
              </b>
            </Row>
            <Row>
              <Stack
                direction="horizontal"
                className="align-items-center"
                gap={1}
              >
                {shouldRenderActionButton() && !isMobile && (
                  <Stack
                    direction="horizontal"
                    className="align-items-center"
                    gap={1}
                  >
                    <div>
                      <Button
                        variant="ms-auto"
                        size="sm"
                        href={`${
                          job.datePublished
                            ? `${SERVER_URL}/job/editJob/${job.id}`
                            : `${SERVER_URL}/job/editEvent?eventId=${job.event.id}`
                        }`}
                      >
                        <FiEdit2 size={15} className="me-2" />
                        <b>EDIT JOB</b>
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="ms-auto"
                        size="sm"
                        className="btn-border"
                        href={`${SERVER_URL}/job/enhance/${job.event.id}?jobId=${job.id}&isEditingQuestions=true`}
                      >
                        <FiEdit2 size={15} className="me-2" />
                        <b>EDIT QUESTIONS</b>
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="ms-auto"
                        size="sm"
                        onClick={() => setShowDeleteModal(true)}
                      >
                        <FiTrash2 size={15} className="me-2" />
                        <b>DELETE JOB</b>
                      </Button>
                    </div>
                  </Stack>
                )}
                <ModalDeleteJob
                  showModal={showDeleteModal}
                  setShowModal={setShowDeleteModal}
                  jobId={job.id}
                  jobPosition={job.position}
                />
                <div className={`ms-auto ${isMobile && 'w-100'}`}>
                  <ModalShareJobLink
                    jobLink={job.jobShareLink}
                    jobDescription={job.description}
                    firstRowJob={job.firstRow}
                    companyName={job.company.name}
                    companyLogo={job.company.profileImageUrl}
                    jobPosition={job.position}
                  />
                </div>
              </Stack>
            </Row>

            <hr />

            <Stack direction="horizontal" gap={5}>
              <Stack
                direction="horizontal"
                gap={3}
                style={{ flexBasis: '50%' }}
              >
                <div>
                  <Image
                    roundedCircle
                    height={150}
                    width={150}
                    src={job.company.profileImageUrl}
                  />
                </div>
                <b>
                  {job.position}
                  <hr className="my-2" />
                  {job.brandRepresented}
                </b>
              </Stack>

              <Stack gap={2} className="th-fs-14 job-offer-data">
                <Stack direction="horizontal" gap={2}>
                  <AiOutlineDollar size={20} />
                  <span>
                    ${job.payRate} per{' '}
                    {job.payRatePeriod?.toString().toLowerCase()}
                  </span>
                </Stack>
                {job.localizedStart && (
                  <Stack direction="horizontal" gap={2}>
                    <AiTwotoneCalendar size={20} />
                    <div>
                      {getJobYear()}
                      {getLocalStartDate()}
                      &nbsp;-&nbsp;
                      {job.localizedEnd && getLocalEndDate()}
                      {job.ongoingJob && 'on-going'}
                    </div>
                  </Stack>
                )}
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="align-items-start"
                >
                  <IoMdPin size={20} style={{ minWidth: '20px' }} />
                  <div>
                    {job.nationalJob
                      ? 'National Job'
                      : job?.address?.googleAddress}
                  </div>
                </Stack>
                <Stack direction="horizontal" gap={2}>
                  <BsPeopleCircle size={20} />
                  <div>
                    {job.totalPositions > 1
                      ? `${job.totalPositions} people`
                      : `${job.totalPositions} person`}
                  </div>
                </Stack>
              </Stack>
            </Stack>

            <hr />

            <Row className="justify-content-end">
              <Col xs="auto" className="d-block d-sm-none">
                <b
                  className="cursor-point"
                  aria-hidden="true"
                  onClick={() => setSeeMore(!seeMore)}
                >
                  {seeMore ? 'See less' : 'See more'}
                </b>
              </Col>
            </Row>
            <Stack className={seeMore ? '' : 'd-none d-sm-block'}>
              <Row>
                <div
                  className="text-area job-description-text max-size"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(job.description),
                  }}
                />
                <div className="d-flex justify-content-center">
                  {job.pictures?.map((image) => (
                    <Col sm={4} key={image} className="">
                      <Image
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: 'cover',
                        }}
                        src={image}
                      />
                    </Col>
                  ))}
                </div>
              </Row>
              <JobSkills />
              <JobQuestions />
            </Stack>
          </Panel>
        </Col>
        <Col lg={6} sm={12} className="mt-sm">
          <Panel>
            <Row>
              <p>
                {/* list applicants panel */}
                <b>{job.title.name}</b>
              </p>
            </Row>
            <HiresAndCandidates />
          </Panel>
        </Col>
      </Row>
      {jobAssignmentId && selectedWorker && jobAssignment && worker && (
        <JobWorkerSideModal
          handleCloseWorkerProfile={handleCloseWorkerProfile}
          worker={worker}
          isJobRelated
        />
      )}
    </div>
  );
}
