export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  ANY_GENDER = 'ANY_GENDER',
}

export const GENDERS = [
  Gender.MALE.valueOf(),
  Gender.FEMALE.valueOf(),
  Gender.ANY_GENDER.valueOf(),
];
