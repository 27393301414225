import { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Alert, Card, Image, Stack } from 'react-bootstrap';
import useAvatarImageFallback from '@hooks/useAvatarImageFallback';
import { ThLoading } from '@components/elements';
import { FiEdit, FiPlusSquare } from 'react-icons/fi';

import useAlert from '@hooks/useAlert';
import {
  Education,
  NonPromoWorkExperience,
  PromoWorkExperience,
} from '@th-types/workerProfile.type';
import Picture from '@th-types/picture.type';
import Skills from '@components/elements/WorkerProfile/Skills/Skills';
import Badges from '@components/elements/WorkerProfile/Badges/Badges';
import Endorsements from '@components/elements/WorkerProfile/Endorsements/Endorsements';
import WorkerStats from '@components/elements/WorkerProfile/WorkerStats/WorkerStats';
import MediaGallery from '@components/elements/MediaGallery/MediaGallery';
import { useWorkerProfileContext } from '@worker/state/workerProfileContext';
import YoutubeEmbed from '@components/elements/YoutubeEmbed';
import ListItem from '@worker/components/WorkerProfile/ListItem/ListItem';
import SkillsModal from '@worker/components/WorkerProfile/Modals/SkillsModal/SkillsModal';
import EducationModal from '@worker/components/WorkerProfile/Modals/EducationModal/EducationModal';
import EventExperienceModal from '@worker/components/WorkerProfile/Modals/EventExperienceModal/EventExperienceModal';
import NonEventExperienceModal from '@worker/components/WorkerProfile/Modals/NonEventExperienceModal/NonEventExperienceModal';
import MediaModal from '@worker/components/WorkerProfile/Modals/MediaModal/MediaModal';

import './styles.scss';
import LeftPanel from './LeftPanel/LeftPanel';

function ProfileDetails() {
  const imageRef = useRef<HTMLImageElement>(null);
  useAvatarImageFallback(imageRef);

  const {
    isLoadingWorkerProfile,
    nonPromoWorkExperience,
    promoWorkExperience,
    workerEducation,
    workerMediaFiles,
    workerProfile,
    workerSkills,
  } = useWorkerProfileContext();
  const { alertMessage } = useAlert();

  const [showEducationModal, setShowEducationModal] = useState(false);
  const [showEventExpModal, setShowEventExpModal] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [showGallerySrcIndx, setShowGallerySrcIndx] = useState(0);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [showNonEventExpModal, setShowNonEventExpModal] = useState(false);
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const [createModeOn, setCreateModeOn] = useState(false);

  const openSkillsModal = () => setShowSkillsModal(true);
  const closeSkillsModal = () => setShowSkillsModal(false);
  const openEducationModal = () => setShowEducationModal(true);
  const createEducationModal = () => {
    setCreateModeOn(true);
    setShowEducationModal(true);
  };
  const closeEducationModal = () => {
    setCreateModeOn(false);
    setShowEducationModal(false);
  };
  const openEventExpModal = () => setShowEventExpModal(true);
  const createEventExpModal = () => {
    setCreateModeOn(true);
    setShowEventExpModal(true);
  };
  const closeEventExpModal = () => {
    setCreateModeOn(false);
    setShowEventExpModal(false);
  };
  const openNonEventExpModal = () => setShowNonEventExpModal(true);
  const createNonEventExpModal = () => {
    setCreateModeOn(true);
    setShowNonEventExpModal(true);
  };
  const closeNonEventExpModal = () => {
    setCreateModeOn(false);
    setShowNonEventExpModal(false);
  };
  const openMediaModal = () => setShowMediaModal(true);
  const closeMediaModal = () => setShowMediaModal(false);
  const openGallery = (srcIndex: number) => {
    setShowGallerySrcIndx(srcIndex);
    setShowGallery(true);
  };

  return workerProfile ? (
    <Stack className={`profile-details ${isMobile ? 'mobile' : ''}`}>
      {isLoadingWorkerProfile && <ThLoading />}

      {/* MODALS */}
      {showSkillsModal && <SkillsModal onCloseModal={closeSkillsModal} />}
      {showMediaModal && <MediaModal onCloseModal={closeMediaModal} />}
      {showEducationModal && (
        <EducationModal
          onCloseModal={closeEducationModal}
          openAddItem={createModeOn}
        />
      )}
      {showEventExpModal && (
        <EventExperienceModal
          onCloseModal={closeEventExpModal}
          openAddItem={createModeOn}
        />
      )}
      {showNonEventExpModal && (
        <NonEventExperienceModal
          onCloseModal={closeNonEventExpModal}
          openAddItem={createModeOn}
        />
      )}

      <Stack
        direction={isMobile ? 'vertical' : 'horizontal'}
        gap={4}
        className="align-items-stretch mb-4"
      >
        {/* LEFT PANEL */}
        <LeftPanel />

        <Stack gap={4}>
          <WorkerStats
            confirmedJobsWorked={workerProfile.stats?.confirmedJobsWorked ?? 0}
            confirmedJobsWorkedUniqueCompany={
              workerProfile.stats?.confirmedJobUniqueCompanies ?? 0
            }
            notConfirmedJobsWorked={
              workerProfile.stats?.nonConfirmedJobsWorked ?? 0
            }
            notConfirmedJobsWorkedUniqueCompany={
              workerProfile.stats?.nonConfirmedJobUniqueCompanies ?? 0
            }
          />
          {workerProfile.skills && (
            <Skills skills={workerSkills} onEditSkills={openSkillsModal} />
          )}
          {workerProfile && <Badges />}
          {workerProfile && <Endorsements />}
        </Stack>
      </Stack>

      <Stack gap={4}>
        <Card className="p-3">
          <Card.Title className="d-flex justify-content-between">
            <span>
              Media
              <span className="ms-2 section-percentages">
                (1% each, max 9%)
              </span>
            </span>
            <Stack direction="horizontal" gap={2}>
              <FiPlusSquare
                size={20}
                className="cursor-point"
                onClick={openMediaModal}
              />
              <FiEdit
                size={18}
                className="cursor-point"
                onClick={openMediaModal}
              />
            </Stack>
          </Card.Title>
          <Card.Text as="div">
            <MediaGallery
              showGallery={showGallery}
              onClose={() => setShowGallery(false)}
              srcIndex={showGallerySrcIndx}
              sources={workerMediaFiles?.map((file) => file.url) || []}
            />
            <Stack direction="horizontal" gap={3} style={{ overflowX: 'auto' }}>
              {workerProfile.presentationVideoURL && (
                <YoutubeEmbed url={workerProfile.presentationVideoURL} />
              )}
              {workerMediaFiles?.length ? (
                workerMediaFiles?.map((picture: Picture, index: number) => (
                  <Image
                    key={picture.id}
                    src={picture.url}
                    width={150}
                    height={150}
                    rounded
                    className="object-fit-cover media-aditional-picture cursor-point"
                    onClick={() => openGallery(index)}
                  />
                ))
              ) : (
                <span className="text-gray fst-italic">
                  You have not added media files, click on the + to add yours
                </span>
              )}
            </Stack>
          </Card.Text>
        </Card>
        <Card className="p-3">
          <Card.Title className="d-flex justify-content-between">
            <span>
              Education
              <span className="ms-2 section-percentages">(2%)</span>
            </span>
            <Stack direction="horizontal" gap={2}>
              <FiPlusSquare
                size={20}
                className="cursor-point"
                onClick={createEducationModal}
              />
              <FiEdit
                size={18}
                className="cursor-point"
                onClick={openEducationModal}
              />
            </Stack>
          </Card.Title>
          <Card.Text as="div">
            {workerEducation?.length ? (
              workerEducation
                .sort((eduA, eduB) =>
                  eduA.degreeDate < eduB.degreeDate ? 1 : -1
                )
                .map((education: Education) => (
                  <ListItem
                    key={education.id}
                    title={education.university}
                    subTitle={education.degree}
                    date={education.degreeDate}
                    hideEndDate
                  />
                ))
            ) : (
              <span className="text-gray fst-italic">
                You have not added education, click on the + to add yours
              </span>
            )}
          </Card.Text>
        </Card>
        <Card className="p-3">
          <Card.Title className="d-flex justify-content-between">
            <span>
              Event Experience
              <span className="ms-2 section-percentages">(5%)</span>
            </span>
            <Stack direction="horizontal" gap={2}>
              <FiPlusSquare
                size={20}
                className="cursor-point"
                onClick={createEventExpModal}
              />
              <FiEdit
                size={18}
                className="cursor-point"
                onClick={openEventExpModal}
              />
            </Stack>
          </Card.Title>
          <Card.Text as="div">
            {promoWorkExperience?.length ? (
              promoWorkExperience
                .sort((expA, expB) =>
                  !expB.endDate || expA.endDate < expB.endDate ? 1 : -1
                )
                .map((exp: PromoWorkExperience) => (
                  <ListItem
                    key={exp.id}
                    title={exp.hiringCompany}
                    subTitle={`${exp.position} for ${exp.brandRepresented}`}
                    description={exp.duties}
                    date={exp.startDate}
                    endDate={exp.endDate}
                  />
                ))
            ) : (
              <span className="text-gray fst-italic">
                You have not added event experience, click on the + to add yours
              </span>
            )}
          </Card.Text>
        </Card>
        <Card className="p-3">
          <Card.Title className="d-flex justify-content-between">
            <span>
              Work Experience
              <span className="ms-2 section-percentages">(2%)</span>
            </span>
            <Stack direction="horizontal" gap={2}>
              <FiPlusSquare
                size={20}
                className="cursor-point"
                onClick={createNonEventExpModal}
              />
              <FiEdit
                size={18}
                className="cursor-point"
                onClick={openNonEventExpModal}
              />
            </Stack>
          </Card.Title>
          <Card.Text as="div">
            {nonPromoWorkExperience?.length ? (
              nonPromoWorkExperience
                .sort((expA, expB) =>
                  !expB.endDate || expA.endDate < expB.endDate ? 1 : -1
                )
                .map((exp: NonPromoWorkExperience) => (
                  <ListItem
                    key={exp.id}
                    title={`${exp.position} on ${exp.hiringCompany}`}
                    date={exp.startDate}
                    endDate={exp.endDate}
                    description={exp.duties}
                  />
                ))
            ) : (
              <span className="text-gray fst-italic">
                You have not added work experience, click on the + to add yours
              </span>
            )}
          </Card.Text>
        </Card>
      </Stack>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </Stack>
  ) : null;
}

export default ProfileDetails;
