import { Button } from 'react-bootstrap';
import { GoDotFill } from 'react-icons/go';
import { RiCheckDoubleFill } from 'react-icons/ri';

import './styles.css';

interface MessageBubbleProps {
  messageContent: string;
  messageSeen?: boolean;
  messageSent: boolean | undefined;
  onResendMessage: () => void;
  creationDate: Date;
}

function MessageBubble({
  creationDate,
  messageContent,
  messageSeen,
  messageSent,
  onResendMessage,
}: MessageBubbleProps) {
  const creationDateLabel = new Date(creationDate).toLocaleString('en-US');

  return (
    <div className="message-bubble">
      <p>{messageContent}</p>
      {messageSeen !== undefined && messageSeen && (
        <RiCheckDoubleFill className="seen" data-testid="seen-icon" />
      )}
      {messageSeen !== undefined && !messageSeen && (
        <GoDotFill className="unseen" data-testid="unseen-icon" />
      )}
      <div className="creation-date">
        {messageSent === undefined || messageSent ? (
          creationDateLabel
        ) : (
          <Button
            as="a"
            size="sm"
            variant="link"
            className="ms-auto me-2 p-0 justify-content-end text-capitalize resend-btn"
            onClick={onResendMessage}
          >
            resend message
          </Button>
        )}
      </div>
    </div>
  );
}

export default MessageBubble;
