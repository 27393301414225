import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

const containerId = import.meta.env.VITE_GOOGLE_MANAGER_CODE;

export const googleManager = containerId
  ? Analytics({
      app: 'trustedherd.com',
      plugins: [
        googleTagManager({
          containerId,
        }),
      ],
    })
  : {
      page: () => {},
    };
