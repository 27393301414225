import { useEffect } from 'react';
import { ThLoading } from '@components/elements';

const SERVER_BASE_URL = import.meta.env.VITE_SERVER_BASE_URL;

export default function LoginRedirect() {
  useEffect(() => {
    window.location.href = `${SERVER_BASE_URL}/login/auth`;
  }, []);

  return <ThLoading />;
}
