import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form, Stack, Image, Row, Col, Alert } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Cropper, { Area, Point } from 'react-easy-crop';
import {
  WorkerProfileSchemaType,
  workerProfileInfoValidationSchema,
} from '@th-types/workerProfile.type';
import { Country } from '@th-types/address.type';
import { TravelPeriods } from '@th-types/worker.type';
import useAvatarImageFallback from '@hooks/useAvatarImageFallback';
import { FaRegTrashCan } from 'react-icons/fa6';
import { FaRegEdit } from 'react-icons/fa';
import { useWorkerProfileContext } from '@worker/state/workerProfileContext';
import { AutoCompleteAddress, ThLoading } from '@components/elements';
import useAlert from '@hooks/useAlert';
import { updateWorkerProfile } from '@worker/services/worker/api';
import { convertFileToBase64 } from '@utils/FileUtils';
import { getCroppedImgBase64, getCroppedImgBlob } from '@utils/ImageUtils';
import defaultAvatar from '@assets/default-avatar.png';

import './styles.css';

interface ProfileInfoFormProps {
  onCancel: () => void;
  onSuccess: () => void;
}

function ProfileInfoForm({ onCancel, onSuccess }: ProfileInfoFormProps) {
  const filePickerRef = useRef<HTMLInputElement>(null);
  const profilePictureRef = useRef<HTMLImageElement>(null);
  useAvatarImageFallback(profilePictureRef);
  const [isLoading, setIsLoading] = useState(false);
  const [imageForCropping, setImageForCropping] = useState<
    string | undefined
  >();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImageBase64, setCroppedImageBase64] = useState<
    string | undefined
  >();
  const [croppedImageBlobUrl, setCroppedImageBlobUrl] = useState<string>();

  const { workerProfile, workerProfileAllowedOptions, setWorkerProfile } =
    useWorkerProfileContext();
  const { showErrorAlert, alertMessage } = useAlert();

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm<WorkerProfileSchemaType>({
    resolver: zodResolver(workerProfileInfoValidationSchema),
    defaultValues: workerProfile && {
      id: workerProfile.id, // use the worker id as hidden input for the form
      firstName: workerProfile.firstName,
      lastName: workerProfile.lastName,
      address: {
        line1: workerProfile.address?.line1,
        city: workerProfile.address?.city,
        postalCode: workerProfile.address?.postalCode,
        state: workerProfile.address?.state.unnabreviated,
        country: (workerProfile.address?.country as Country).unnabreviated, // fix IWorkerData type
        googleAddress: workerProfile.address.googleAddress,
      },
      cellPhone: workerProfile.cellPhone,
      yearsInIndustry: workerProfile.yearsInIndustry,
      monthsInIndustry: workerProfile.monthsInIndustry,
      birthday: workerProfile.birthday
        ? new Date(workerProfile.birthday)
        : undefined,
      gender: workerProfile.gender,
      heightFeet: workerProfile.heightFeet,
      heightInches: workerProfile.heightInches,
      facebookUser: workerProfile.facebookUser,
      instagramUser: workerProfile.instagramUser,
      twitterUser: workerProfile.twitterUser,
      linkedinUser: workerProfile.linkedinUser,
      presentationVideoURL: workerProfile.presentationVideoURL,
      willingToTravel: workerProfile.willingToTravel,
      travelPeriod: workerProfile.travelPeriod?.value,
    },
  });

  const onSubmit: SubmitHandler<WorkerProfileSchemaType> = (
    data: WorkerProfileSchemaType
  ) => {
    setIsLoading(true);
    updateWorkerProfile(data)
      .then((response) => {
        setWorkerProfile(response.data.worker);
        onSuccess();
      })
      .catch((error) => {
        showErrorAlert(
          error.response?.data?.message ||
            'An error occurred submitting the form.'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAddressComponent = (
    place: google.maps.places.PlaceResult,
    component: string
  ) => {
    return (
      place.address_components?.find((el) => el.types.includes(component))
        ?.long_name || ''
    );
  };

  const handleChangeAddress = (
    place: google.maps.places.PlaceResult | string
  ) => {
    if (typeof place === 'string') {
      setValue('address.line1', place);
    } else {
      const postalCode = getAddressComponent(place, 'postal_code');
      const country = getAddressComponent(place, 'country');
      const state = getAddressComponent(place, 'administrative_area_level_1');
      const street = getAddressComponent(place, 'route');
      const streetNumber = getAddressComponent(place, 'street_number');
      let city = getAddressComponent(place, 'locality');
      // try different fields for city if locality is not set
      if (!city) {
        city = getAddressComponent(place, 'sublocality_level_1');
      }
      if (!city) {
        city = getAddressComponent(place, 'administrative_area_level_3');
      }
      if (!city) {
        city = getAddressComponent(place, 'neighborhood');
      }
      const line1 = `${street} ${streetNumber}`;
      setValue('address', {
        line1,
        city,
        postalCode,
        state,
        country,
        googleAddress: place.formatted_address!,
      });
    }
  };

  const openFilePicker = () => {
    filePickerRef.current!.click();
  };

  const handleProfileImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setImageForCropping(URL.createObjectURL(event.currentTarget.files![0]));
  };

  const onCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
    try {
      const processedBlob = await getCroppedImgBlob(
        imageForCropping!,
        croppedAreaPixels
      );
      const processedBase64 = await getCroppedImgBase64(
        imageForCropping!,
        croppedAreaPixels
      );
      setCroppedImageBlobUrl(processedBlob);
      setCroppedImageBase64(processedBase64);
    } catch (error) {
      showErrorAlert('An error ocurred trying to crop the image.');
    }
  };

  const finishCrop = async () => {
    try {
      setValue('profileImage.contentType', 'image/jpeg');
      setValue('profileImage.content', croppedImageBase64 as string);
      setImageForCropping(undefined);
    } catch (error) {
      showErrorAlert('An error ocurred processing the image.');
    }
  };

  const onCancelCrop = () => {
    setImageForCropping(undefined);
    setCroppedImageBlobUrl(undefined);
    setCroppedImageBase64(undefined);
  };

  const onDeleteProfilePicture = async () => {
    try {
      // Convert default avatar to base64
      const response = await fetch(defaultAvatar);
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result as string;
        // Remove data:image/png;base64, prefix if present
        const base64Content = base64String.split(',')[1] || base64String;

        setValue('profileImage.contentType', 'image/png');
        setValue('profileImage.content', base64Content);
        setCroppedImageBlobUrl(defaultAvatar);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      showErrorAlert('An error occurred deleting the profile picture.');
    }
  };

  const handleResumeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const pdfFile = event.currentTarget.files![0];
    try {
      const pdfBase64 = await convertFileToBase64(pdfFile);
      setValue('resume.contentType', pdfFile.type);
      setValue('resume.content', pdfBase64 as string);
    } catch {
      showErrorAlert('An error ocurred processing the resume.');
    }
  };

  useEffect(() => {
    // SHOW TOAST WITH FORM VALIDATION ERROR MESAGE
    if (!isEmpty(errors)) {
      if (errors.address) {
        showErrorAlert(
          (Object.values(errors.address)[0] as { message: string }).message
        );
      } else {
        showErrorAlert(Object.values(errors)[0].message!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={`profile-info-form ${isMobile ? 'mobile' : ''}`}
      autoComplete="off"
    >
      {isLoading && <ThLoading />}
      <Stack gap={3}>
        <Form.Control {...register('id', { valueAsNumber: true })} hidden />
        <Form.Group>
          <Form.Label>
            <b>Profile Picture *</b>{' '}
            <small className="ms-2" style={{ color: 'var(--silver)' }}>
              (10%)
            </small>
          </Form.Label>

          <Stack
            direction={isMobile ? 'vertical' : 'horizontal'}
            gap={3}
            className="align-items-center"
          >
            {imageForCropping ? (
              <div className="cropper-container">
                <Cropper
                  image={imageForCropping}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
                <Stack className="cropper-actions">
                  <Button size={isMobile ? 'sm' : 'lg'} onClick={finishCrop}>
                    Accept
                  </Button>
                  <Button
                    size={isMobile ? 'sm' : 'lg'}
                    variant="warning"
                    onClick={onCancelCrop}
                  >
                    Cancel
                  </Button>
                </Stack>
              </div>
            ) : (
              <>
                <div style={{ width: '200px', height: '200px' }}>
                  <Image
                    ref={profilePictureRef}
                    src={croppedImageBlobUrl || workerProfile?.profileImageUrl}
                    width={200}
                    height={200}
                    className="object-fit-cover"
                  />
                </div>
                <Stack gap={2} className="justify-content-end">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="cursor-point"
                    onClick={openFilePicker}
                  >
                    <Form.Control
                      type="file"
                      hidden
                      accept="image/*"
                      {...register('profileImage')}
                      onChange={handleProfileImageChange}
                      ref={filePickerRef}
                    />
                    <FaRegEdit size={20} />
                    <span>Edit Profile Picture</span>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="cursor-point"
                    onClick={onDeleteProfilePicture}
                  >
                    <FaRegTrashCan color="var(--red)" size={18} />
                    <span>Delete Profile Picture</span>
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
        </Form.Group>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>
                <b>First Name *</b>
                <small className="ms-2" style={{ color: 'var(--silver)' }}>
                  (5%)
                </small>
              </Form.Label>
              <Form.Control
                {...register('firstName')}
                placeholder="Enter first name"
                className={errors.firstName ? 'border-danger' : ''}
              />
              {errors.firstName && (
                <span className="form-field-error-message">
                  {errors.firstName.message}
                </span>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>
                <b>Last Name *</b>
                <small className="ms-2" style={{ color: 'var(--silver)' }}>
                  (5%)
                </small>
              </Form.Label>
              <Form.Control
                {...register('lastName')}
                placeholder="Enter last name"
                className={errors.lastName ? 'border-danger' : ''}
              />
              {errors.lastName && (
                <span className="form-field-error-message">
                  {errors.lastName.message}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>
            <b>Location *</b>
            <small
              className="ms-2 label-notation"
              style={{ color: 'var(--silver)' }}
            >
              (16% for address, city, state, zip/postal)
            </small>
          </Form.Label>
          {/* add red border class */}
          <AutoCompleteAddress
            initialValue={workerProfile?.address?.googleAddress}
            placeholder="Enter your address"
            name="address"
            resultEvent={handleChangeAddress}
          />

          {errors.address &&
            Object.values(errors.address).map((fieldError: any) => (
              <span
                key={fieldError.message}
                className="form-field-error-message"
              >
                {fieldError.message}
              </span>
            ))}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b>Phone Number *</b>
            <small className="ms-2" style={{ color: 'var(--silver)' }}>
              (4%)
            </small>
          </Form.Label>
          <Form.Control
            {...register('cellPhone')}
            placeholder="Enter yout phone number"
            className={errors.cellPhone ? 'border-danger' : ''}
          />
          {errors.cellPhone && (
            <span className="form-field-error-message">
              {errors.cellPhone.message}
            </span>
          )}
        </Form.Group>
        <Stack>
          <Form.Label>
            <b>How long have you worked in the industry? *</b>
            <small className="ms-2" style={{ color: 'var(--silver)' }}>
              (2%)
            </small>
          </Form.Label>
          <Stack direction="horizontal" gap={3} className="number-picker">
            <Form.Group
              className={`d-inline-flex align-items-center gap-2 ${
                isMobile ? 'flex-grow-1' : ''
              }`}
            >
              <Form.Select
                {...register('yearsInIndustry', { valueAsNumber: true })}
              >
                {workerProfileAllowedOptions?.yearsInIndustry?.map((year) => (
                  <option key={year} value={year}>
                    {year} {isMobile ? 'Years' : ''}
                  </option>
                ))}
              </Form.Select>
              {!isMobile && <Form.Label>Years</Form.Label>}
              {errors.yearsInIndustry && (
                <span className="form-field-error-message">
                  {errors.yearsInIndustry.message}
                </span>
              )}
            </Form.Group>
            <Form.Group
              className={`d-inline-flex align-items-center gap-2 ${
                isMobile ? 'flex-grow-1' : ''
              }`}
            >
              <Form.Select
                {...register('monthsInIndustry', { valueAsNumber: true })}
              >
                {workerProfileAllowedOptions?.monthsInIndustry?.map((month) => (
                  <option key={month} value={month}>
                    {month} {isMobile ? 'Months' : ''}
                  </option>
                ))}
              </Form.Select>
              {!isMobile && <Form.Label>Months</Form.Label>}
              {errors.monthsInIndustry && (
                <span className="form-field-error-message">
                  {errors.monthsInIndustry.message}
                </span>
              )}
            </Form.Group>
          </Stack>
        </Stack>
        <Stack
          direction={isMobile ? 'vertical' : 'horizontal'}
          gap={isMobile ? 3 : 5}
          className="align-items-start"
        >
          <Form.Group className={isMobile ? 'w-100' : ''}>
            <Form.Label className="d-block">
              <b>Date of Birth *</b>
              <small className="ms-2" style={{ color: 'var(--silver)' }}>
                (2%)
              </small>
            </Form.Label>
            <Controller
              control={control}
              name="birthday"
              render={({ field }) => (
                <ReactDatePicker
                  className={`form-control ${
                    errors.birthday?.message ? 'border-danger' : ''
                  }`}
                  selected={field.value}
                  onChange={(date) => field.onChange(date as Date)}
                  placeholderText="MM/DD/YYYY"
                  maxDate={new Date()}
                />
              )}
            />
            {errors.birthday && (
              <span className="form-field-error-message">
                {errors.birthday.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className={isMobile ? 'w-100' : ''}>
            <Form.Label>
              <b>Gender *</b>
              <small className="ms-2" style={{ color: 'var(--silver)' }}>
                (2%)
              </small>
            </Form.Label>
            <Form.Select {...register('gender')}>
              <option key="MALE" value="MALE">
                MALE
              </option>
              <option key="FEMALE" value="FEMALE">
                FEMALE
              </option>
            </Form.Select>
            {errors.gender && (
              <span className="form-field-error-message">
                {errors.gender.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className={isMobile ? 'w-100' : ''}>
            <Form.Label>
              <b>Height *</b>
              <small className="ms-2" style={{ color: 'var(--silver)' }}>
                (2%)
              </small>
            </Form.Label>
            <Stack direction="horizontal" gap={3} className="number-picker">
              <Form.Group
                className={`d-inline-flex align-items-center gap-2 ${
                  isMobile ? 'flex-grow-1' : ''
                }`}
              >
                <Form.Select
                  {...register('heightFeet', { valueAsNumber: true })}
                >
                  {workerProfileAllowedOptions?.heightFeet.map((feet) => (
                    <option key={feet} value={feet}>
                      {feet} {isMobile ? 'Feet' : null}
                    </option>
                  ))}
                </Form.Select>
                {!isMobile && <Form.Label>Feet</Form.Label>}

                {errors.heightFeet && (
                  <span className="form-field-error-message">
                    {errors.heightFeet.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group
                className={`d-flex align-items-center gap-2 ${
                  isMobile ? 'flex-grow-1' : ''
                }
                `}
              >
                <Form.Select
                  {...register('heightInches', { valueAsNumber: true })}
                >
                  {workerProfileAllowedOptions?.heightInches.map((inches) => (
                    <option key={inches} value={inches}>
                      {inches} {isMobile ? 'Inches' : null}
                    </option>
                  ))}
                </Form.Select>
                {!isMobile && <Form.Label>Inches</Form.Label>}
                {errors.heightInches && (
                  <span className="form-field-error-message">
                    {errors.heightInches.message}
                  </span>
                )}
              </Form.Group>
            </Stack>
          </Form.Group>
        </Stack>

        <Stack gap={2} className="social-media-fields">
          <Form.Label>
            <b className="d-inline">Social Media</b>
            <small
              className="ms-2 label-notation"
              style={{ color: 'var(--silver)' }}
            >
              (10%, Need 2, Highly Recommended)
            </small>
          </Form.Label>
          <Form.Group className="social-media-form-group">
            <Form.Label>facebook.com/</Form.Label>
            <Form.Control
              placeholder="username"
              {...register('facebookUser')}
              className={errors.facebookUser ? 'border-danger' : ''}
            />
            {errors.facebookUser && (
              <span className="form-field-error-message">
                {errors.facebookUser.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className="social-media-form-group">
            <Form.Label>instagram.com/</Form.Label>
            <Form.Control
              placeholder="username"
              {...register('instagramUser')}
              className={errors.instagramUser ? 'border-danger' : ''}
            />
            {errors.instagramUser && (
              <span className="form-field-error-message">
                {errors.instagramUser.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className="social-media-form-group">
            <Form.Label>x.com/</Form.Label>
            <Form.Control
              placeholder="username"
              {...register('twitterUser')}
              className={errors.twitterUser ? 'border-danger' : ''}
            />
            {errors.twitterUser && (
              <span className="form-field-error-message">
                {errors.twitterUser.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className="social-media-form-group">
            <Form.Label>linkedin.com/in/</Form.Label>
            <Form.Control
              placeholder="username"
              {...register('linkedinUser')}
              className={errors.linkedinUser ? 'border-danger' : ''}
            />
            {errors.linkedinUser && (
              <span className="form-field-error-message">
                {errors.linkedinUser.message}
              </span>
            )}
          </Form.Group>
        </Stack>

        <Form.Group>
          <Form.Label>
            <b className="d-inline">Upload your Resume</b>
            <small className="ms-2" style={{ color: 'var(--silver)' }}>
              .pdf only (10%, highly recommended)
            </small>
          </Form.Label>
          {workerProfile?.resumeUrl && (
            <Form.Text id="fileHelp" muted className="d-block">
              <b>Currently</b>: {workerProfile.resumeUrl?.split('/').pop()}
            </Form.Text>
          )}
          <Form.Control
            type="file"
            accept="application/pdf"
            onChange={handleResumeChange}
            aria-describedby="fileHelp"
          />
          {errors.resume && (
            <span className="form-field-error-message">
              {errors.resume.message}
            </span>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <b>Video Resume</b>
            <small className="ms-2" style={{ color: 'var(--silver)' }}>
              (2%, highly recommended)
            </small>
          </Form.Label>
          <Form.Control
            {...register('presentationVideoURL')}
            placeholder="youtube.com/your-video-id"
            className={errors.presentationVideoURL ? 'border-danger' : ''}
          />
          {errors.presentationVideoURL && (
            <span className="form-field-error-message">
              {errors.presentationVideoURL.message}
            </span>
          )}
        </Form.Group>

        <Stack>
          <Form.Label>
            <b>Travel Preferences</b>
            <small className="ms-2" style={{ color: 'var(--silver)' }}>
              (2%)
            </small>
          </Form.Label>
          <Stack
            direction={isMobile ? 'vertical' : 'horizontal'}
            gap={isMobile ? 3 : 5}
            className="travel-preferences align-items-start"
          >
            <Form.Check
              label="Are you willing to travel nationally?"
              {...register('willingToTravel')}
              className="m-0"
            />
            {errors.willingToTravel && (
              <span className="form-field-error-message">
                {errors.willingToTravel.message}
              </span>
            )}
            <Form.Group>
              <Form.Label>
                <span>Are you willing to travel for longer periods?</span>
              </Form.Label>
              <Form.Select {...register('travelPeriod')}>
                <option value="">I&apos;m not willing to travel</option>
                {workerProfileAllowedOptions?.travelPeriods?.map(
                  ({ label }) => (
                    <option
                      key={label}
                      value={
                        Object.keys(TravelPeriods)[
                          Object.values(TravelPeriods).indexOf(
                            label as TravelPeriods
                          )
                        ]
                      }
                    >
                      {label}
                    </option>
                  )
                )}
              </Form.Select>
              {errors.travelPeriod && (
                <span className="form-field-error-message">
                  {errors.travelPeriod.message}
                </span>
              )}
            </Form.Group>
          </Stack>
        </Stack>

        <Stack
          direction={isMobile ? 'vertical' : 'horizontal'}
          className="justify-content-end"
          gap={isMobile ? 2 : 3}
        >
          <Button variant="light" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Save Changes</Button>
        </Stack>
      </Stack>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </Form>
  );
}

export default ProfileInfoForm;
