import { Pagination } from 'react-bootstrap';
import './styles.css';

interface PaginationProps {
  currentOffset: number;
  itemsPerPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}

function ThPagination({
  currentOffset,
  itemsPerPage,
  totalItems,
  onPageChange,
}: PaginationProps) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentPage = Math.floor(currentOffset / itemsPerPage) + 1;
  const pageNumbers: (number | string)[] = [];

  const addPage = (page: number) => {
    pageNumbers.push(page);
  };

  /**
   * ADD ELLIPSIS IN THE PAGE SELECTOR FOR MORE THAN 7 PAGES
   */
  if (totalPages <= 7) {
    for (let i = 1; i <= totalPages; i += 1) addPage(i);
  } else if (currentPage <= 4) {
    for (let i = 1; i <= Math.min(7, totalPages); i += 1) addPage(i);
    if (totalPages > 7) pageNumbers.push('...');
  } else if (currentPage >= totalPages - 3) {
    pageNumbers.push('...');
    for (let i = totalPages - 6; i <= totalPages; i += 1) addPage(i);
  } else {
    pageNumbers.push('...');
    for (let i = currentPage - 3; i <= currentPage + 3; i += 1) addPage(i);
    pageNumbers.push('...');
  }

  return (
    <Pagination className="d-flex justify-content-center mt-auto th-pagination">
      <Pagination.First
        onClick={() => onPageChange(0)}
        disabled={currentPage === 1}
        data-testid="first-page"
      />
      <Pagination.Prev
        onClick={() => onPageChange(Math.max(0, currentOffset - itemsPerPage))}
        disabled={currentPage === 1}
        data-testid="prev-page"
      />
      {pageNumbers.map((page) =>
        typeof page === 'number' ? (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => onPageChange((page - 1) * itemsPerPage)}
          >
            {page}
          </Pagination.Item>
        ) : (
          <Pagination.Ellipsis key={page} disabled data-testid="ellipsis" />
        )
      )}
      <Pagination.Next
        onClick={() =>
          onPageChange(
            Math.min(
              currentOffset + itemsPerPage,
              (totalPages - 1) * itemsPerPage
            )
          )
        }
        disabled={currentPage === totalPages}
        data-testid="next-page"
      />
      <Pagination.Last
        onClick={() => onPageChange((totalPages - 1) * itemsPerPage)}
        disabled={currentPage === totalPages}
        data-testid="last-page"
      />
    </Pagination>
  );
}

export default ThPagination;
