import { useRef, useState } from 'react';
import { GrLink } from '@react-icons/all-files/gr/GrLink';
import Stack from 'react-bootstrap/esm/Stack';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { ImCopy } from '@react-icons/all-files/im/ImCopy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';

interface ModalParams {
  jobLink: string;
  jobDescription: string;
  firstRowJob: string;
  companyName: string;
  companyLogo: string;
  jobPosition: string;
}

export default function ModalShareJobLink({
  jobLink,
  jobDescription,
  firstRowJob,
  companyName,
  companyLogo,
  jobPosition,
}: ModalParams) {
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const jobCopyText = useRef(null);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleVisible = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  };

  const jobDescriptionFormatted = `<b>${firstRowJob}</b> \n\n ${jobDescription}
    \n\nApply here w/ a complete profile -->\n\n`;

  const jobDescriptionFormattedWithLink = `${jobDescriptionFormatted}${jobLink}`;

  const onClickCopyJobLink = (isJustUrl: boolean, message: string) => {
    navigator.clipboard.writeText(
      // @ts-ignore
      isJustUrl ? jobLink : jobCopyText.current.textContent
    );
    setAlertMessage(message);
    handleVisible();
  };

  return (
    <>
      <Button
        variant="primary ms-auto"
        size="sm"
        className="w-100 th-ls-05"
        onClick={handleShow}
        style={{ padding: '10px' }}
      >
        <Stack
          direction="horizontal"
          className="justify-content-center"
          gap={2}
        >
          <GrLink size={20} />
          SHARE JOB LINK
        </Stack>
      </Button>

      <Modal size="xl" show={showModal} onHide={handleClose}>
        <Modal.Body className="modal-body-space">
          <Container>
            <Row>
              <Col lg={6}>
                <Row className="row-title">
                  <Stack direction="horizontal" gap={3}>
                    <div className="mb-2">
                      <FontAwesomeIcon
                        color="#F2D032"
                        className="rotate-icon"
                        icon={faLink}
                        aria-hidden
                      />
                    </div>
                    <h4>
                      <b>Share Job Link!</b>
                    </h4>
                  </Stack>
                </Row>
                <Stack direction="vertical" gap={1}>
                  <div className="mt-2">
                    <p>
                      Put this job anywhere on the internet, emails or messages.
                    </p>
                  </div>
                  <div className="mt-2">
                    <p>
                      Non Trusted Herd members can sign up and apply immediately
                      after!
                    </p>
                  </div>
                </Stack>
                <Stack direction="vertical" gap={0} className="mt-2">
                  <b>Share job link only</b>
                  <Row>
                    <InputGroup className="mb-3">
                      <Form.Control
                        className="job-link-field"
                        value={jobLink}
                        disabled
                      />
                      <InputGroup.Text
                        className="job-click-copy"
                        onClick={() => {
                          onClickCopyJobLink(true, 'Link copied to clipboard');
                        }}
                      >
                        <Stack direction="horizontal" gap={1}>
                          <ImCopy />
                        </Stack>
                      </InputGroup.Text>
                    </InputGroup>
                  </Row>
                </Stack>
                <Stack direction="vertical" gap={0}>
                  <b>Share job link & info</b>
                  <Row>
                    <InputGroup size="sm" className="mb-3 mt-1 min-size">
                      <div
                        ref={jobCopyText}
                        className="text-area job-description-text max-size"
                      >
                        {jobDescriptionFormattedWithLink}
                      </div>

                      <InputGroup.Text
                        className="job-click-copy mt-3"
                        onClick={() => {
                          onClickCopyJobLink(false, 'Text copied to clipboard');
                        }}
                      >
                        <Stack direction="horizontal" gap={1}>
                          <ImCopy style={{ fontSize: 20 }} />
                        </Stack>
                      </InputGroup.Text>
                    </InputGroup>
                  </Row>
                </Stack>
              </Col>
              <Col className="d-none d-sm-block" lg={6}>
                <p className="text-fb-class">
                  {
                    'This is what your job posting will look like once shared on Facebook:'
                  }{' '}
                </p>
                <Row>
                  <Card className="min-size">
                    <Card.Title>
                      <Row>
                        <Col lg={2} className="mt-2">
                          <img
                            width={80}
                            src={companyLogo}
                            alt="Trusted Herd Logo"
                          />
                        </Col>
                        <Col lg={10} className="mt-3">
                          <h6>
                            <b>{companyName}</b> shared a link
                          </h6>
                          <h6>Just now</h6>
                        </Col>
                      </Row>
                    </Card.Title>
                    <Card.Text>
                      <div
                        className="text-area job-description-text max-size"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(jobDescriptionFormatted),
                        }}
                      />
                      <Row className="footer-class">
                        <Col lg={3} className="mt-2 mb-2">
                          <img
                            width={120}
                            src={companyLogo}
                            alt="Trusted Herd Logo"
                          />
                        </Col>
                        <Col lg={9} className="mt-2 mb-2">
                          <h6>TRUSTEDHERD.COM</h6>
                          <p>
                            <b>{jobPosition}</b>
                          </p>
                          <p>
                            New promo job posted near your area. Open the link
                            to view the details and apply.
                          </p>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={5} />
              <Col lg={2} className="col-close-button">
                <Button variant="primary" size="sm" onClick={handleClose}>
                  Close
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Alert
        show={showAlert}
        variant="success"
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage}</Alert.Heading>
      </Alert>
    </>
  );
}
