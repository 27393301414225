import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Placement } from 'react-bootstrap/esm/types';

interface TooltipParams {
  children: React.ReactNode;
  text: string;
  position?: Placement;
  delay?: number; // in seconds
}

function TooltipOverlay({
  children,
  text,
  position = 'top',
  delay,
}: TooltipParams) {
  return (
    <OverlayTrigger
      delay={delay !== undefined ? delay * 1000 : 0}
      placement={position}
      overlay={<Tooltip>{text}</Tooltip>}
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
}
export default TooltipOverlay;
