import Picture from '@th-types/picture.type';

export const isPDF = (picture: Picture) => {
  const fileType = picture.name.split('.').pop();
  return fileType?.toLocaleLowerCase() === 'pdf';
};

export const convertFileToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result!.toString().replace(/^data:(.*,)?/, ''));
    reader.onerror = reject;
  });
};
