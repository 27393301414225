import { UpdateWorkerActions } from '@company/state/useUpdateWorker/actions';
import { IWorkerJobData } from '@th-types/workerProfile.type';

export interface UpdateWorkerModel {
  workerId?: number;
  jobId?: number;
  workerProfile?: IWorkerJobData | null;
  isLoadingUpdateWorker?: boolean;
}

export interface UpdateWorkerModelAction {
  type: UpdateWorkerActions;
  value?: UpdateWorkerModel;
}

export const updateWorkerInitialState: UpdateWorkerModel = {
  workerId: 0,
  jobId: 0,
  workerProfile: null,
  isLoadingUpdateWorker: false,
};

const reducer = (state: UpdateWorkerModel, action: UpdateWorkerModelAction) => {
  let newState;
  switch (action.type) {
    case UpdateWorkerActions.UPDATE:
      newState = {
        ...state,
        workerId: action?.value?.workerId,
        jobId: action?.value?.jobId,
        workerProfile: action?.value?.workerProfile,
      };
      break;
    case UpdateWorkerActions.UPDATING:
      newState = {
        ...state,
        isLoadingUpdateWorker: true,
      };
      break;
    case UpdateWorkerActions.UPDATED:
      newState = {
        ...state,
        isLoadingUpdateWorker: false,
      };
      break;
    case UpdateWorkerActions.UPDATE_FAILED:
      newState = {
        ...state,
        isLoadingUpdateWorker: false,
      };
      break;
    default:
      throw new Error(`There is no action with type: ${action.type}`);
  }
  return newState;
};

export default reducer;
