export enum CountryName {
  UNITED_STATES = 'UNITED_STATES',
  CANADA = 'CANADA',
}

export interface State {
  ansiAbbreviation: string;
  name: string;
  unnabreviated: string;
}

export interface Country {
  ansiAbbreviation: string;
  name: string;
  unnabreviated: string;
}

export default interface Address {
  city: string;
  country: Country | string;
  googleAddress: string;
  latitude: number;
  line1: string;
  line2?: string;
  longitude: number;
  postalCode: string;
  state: State;
  timezoneId: string;
  timezoneName: string;
}
