import { useEffect } from 'react';
import defaultAvatar from '@assets/default-avatar.png';

export default function useAvatarImageFallback(
  imageRef: React.RefObject<HTMLImageElement>
) {
  useEffect(() => {
    const handleImageError = () => {
      if (imageRef.current && imageRef.current.src !== defaultAvatar) {
        // eslint-disable-next-line no-param-reassign
        imageRef.current.src = defaultAvatar;
      }
    };

    if (imageRef.current) {
      imageRef.current.addEventListener('error', handleImageError);

      return () => {
        if (imageRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          imageRef.current.removeEventListener('error', handleImageError);
        }
      };
    }

    return undefined;
  }, [imageRef]);
}
