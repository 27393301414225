import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { StarRatingButton } from '@components/elements';

import './styles.scss';
import { ActivityDetail } from '@th-types/daily-activity.type';

function FeedbackModal({
  activity,
  show,
  handleCloseFeedbackModal,
}: {
  activity: ActivityDetail;
  show: boolean;
  handleCloseFeedbackModal: (value: boolean) => void;
}) {
  // TODO: Add API call for feedback
  // const queryClient = useQueryClient();
  // const mutation = useMutation({
  //   mutationFn: createJobShifts,
  //   onSuccess: () => {
  //     // Invalidate and refetch
  //     queryClient.invalidateQueries({
  //       queryKey: [QueryKeys.COMPANY_JOB_SHIFTS_OFFER],
  //     });
  //   },
  // });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // mutation.mutate(e);
    // HTTP request to create shift
  };

  return (
    <Modal
      size={isMobile ? 'sm' : undefined}
      show={show}
      centered
      onHide={() => handleCloseFeedbackModal(true)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold" id="example-modal-sizes-title-lg">
          Would you like to leave a review for{' '}
          {activity.shiftPosition.jobAssignment?.worker.fullName}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="offer-form" onSubmit={(e) => onSubmit(e)}>
          <Row>
            <Col className="d-flex justify-content-evenly">
              <StarRatingButton gap={2} value={0} hideLabels />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2 fw-bold modal-titles">Comment</Col>
            <InputGroup>
              <Form.Control as="textarea" rows={5} aria-label="With textarea" />
            </InputGroup>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-top-0 justify-content-between">
        <Button
          variant="outline-danger fw-bold border-0"
          className="text-start"
          onClick={() => handleCloseFeedbackModal(true)}
        >
          SKIP REVIEW
        </Button>
        <Button variant="primary" form="offer-form" type="submit">
          SAVE REVIEW
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FeedbackModal;
