import { differenceInDays } from 'date-fns';
import IPeriod from '@th-types/period.type';
import { z } from 'zod';

export default interface INonTHJobCreation {
  eventName: string;
  publicFlow: boolean;
  companyName: string;
  brandRepresented: string;
  title: INonTHJobTitleCreation;
  payRate: number;
  payRatePeriod: string;
  totalHoursWorked: number;
  approximatelyDaysWorked: number;
  periods: IPeriod[];
  otherJobTitleDescription: string;
}

interface INonTHJobTitleCreation {
  id: number;
}

export const INonTHJobValidationSchema = z
  .object({
    eventName: z.string().min(1, { message: 'Event name is required.' }),
    publicFlow: z.boolean().optional(),
    companyName: z.string(),
    brandRepresented: z
      .string()
      .min(1, { message: 'Brand represented is required.' }),
    title: z.object({
      id: z.number(),
    }),
    payRate: z
      .number({ invalid_type_error: 'Pay rate is required.' })
      .min(1, 'Minimum pay rate is 1.'),
    payRatePeriod: z.string(),
    totalHoursWorked: z.number({
      invalid_type_error: 'Total hours worked is required.',
    }),
    approximatelyDaysWorked: z.number({
      invalid_type_error: 'Total days worked is required.',
    }),
    periods: z.array(
      z.object({
        start: z.string(),
        end: z.string(),
      })
    ),
    otherJobTitleDescription: z.string(),
  })
  .superRefine((values, context) => {
    const { approximatelyDaysWorked, periods, totalHoursWorked } = values;
    const daysWorkedFromPeriods =
      differenceInDays(new Date(periods[0].end), new Date(periods[0].start)) +
      1;

    /**
     * CHECK THE ENTERED DAYS ARE LESS THAN PERIODS DIFFERENCE
     */
    if (approximatelyDaysWorked > daysWorkedFromPeriods) {
      context.addIssue({
        message: 'The number of days cannot exceed the date range.',
        code: z.ZodIssueCode.custom,
        path: ['approximatelyDaysWorked'],
      });
    }

    /**
     * CHECK TOTAL HOURS ENTERED ARE LESS THAN WORKED DAYS TIMES 24 (hs per day)
     */
    if (totalHoursWorked > approximatelyDaysWorked * 24) {
      context.addIssue({
        message:
          'The sum of hours worked cannot be greater than the days worked',
        code: z.ZodIssueCode.custom,
        path: ['totalHoursWorked'],
      });
    }
  });

export type INonTHJobValidationSchemaType = z.infer<
  typeof INonTHJobValidationSchema
>;
