import React from 'react';
import ContentLoader from 'react-content-loader';

interface LoaderCatalogMagicProps {
  heading?: { width: number; height: number };
  row?: number;
  column?: number;
  padding?: number;
  borderRadius?: number;
}

export default function LoaderImageGrid({
  heading = { width: 140, height: 24 },
  row = 1,
  column = 6,
  padding = 12,
  borderRadius = 4,
  ...props
}: LoaderCatalogMagicProps): JSX.Element {
  const list: JSX.Element[] = [];
  let height: number | undefined;

  const width = window.innerWidth;
  for (let i = 1; i <= row; i += 1) {
    for (let j = 0; j < column; j += 1) {
      const itemWidth = (width - padding * (column + 1)) / column;
      const x = padding + j * (itemWidth + padding);
      const height1 = itemWidth;
      const height2 = 20;
      const height3 = 20;
      const space =
        padding + height1 + (padding / 2 + height2) + height3 + padding * 4;
      const y1 = padding + heading.height + padding * 2 + space * (i - 1);
      const y2 = y1 + padding + height1;
      const y3 = y2 + padding / 2 + height2;

      list.push(
        <React.Fragment key={`item-${i}-${j}`}>
          <rect
            x={x}
            y={y1}
            rx={borderRadius}
            ry={borderRadius}
            width={itemWidth}
            height={height1}
          />
          <rect x={x} y={y2} rx={0} ry={0} width={itemWidth} height={height2} />
          <rect
            x={x}
            y={y3}
            rx={0}
            ry={0}
            width={itemWidth * 0.6}
            height={height3}
          />
        </React.Fragment>
      );

      if (i === row) {
        height = y3 + height3;
      }
    }
  }

  return (
    <ContentLoader
      viewBox={`0 0 ${window.innerWidth} ${height}`}
      width="100%"
      height="100%"
      {...props}
    >
      {list}
    </ContentLoader>
  );
}
