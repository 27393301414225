interface BuildingIconProps {
  fillColor: string;
}
function BuildingIcon({ fillColor }: BuildingIconProps) {
  return (
    <svg
      width="35"
      height="28"
      viewBox="0 0 35 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="building-icon"
    >
      <g clipPath="url(#clip0_19912_1721)">
        <path
          d="M33.9364 26.8325H31.4202V3.66355C31.4202 3.34148 31.1585 3.0798 30.8364 3.0798H29.3469V0.583753C29.367 0.261682 29.1053 0 28.7631 0H16.2627C15.9407 0 15.679 0.261682 15.679 0.583753V3.0798H14.1894C13.8673 3.0798 13.6057 3.34148 13.6057 3.66355V10.5277H4.08445C3.76238 10.5277 3.5007 10.7894 3.5007 11.1114V26.8325H1.02478C0.70271 26.8124 0.420898 27.074 0.420898 27.3961C0.420898 27.7182 0.662451 28 1.00465 28H33.9162C34.2383 28 34.5 27.7584 34.5 27.4364C34.5201 27.0942 34.2584 26.8325 33.9364 26.8325ZM13.6057 26.8325H4.6682V11.7153H13.6057V26.8325ZM16.8465 1.16751H28.1793V3.0798H16.8465V1.16751ZM25.8443 26.8325H19.1815V21.3976H25.8443V26.8325ZM30.2527 26.8325H27.032V20.7937C27.032 20.4716 26.7703 20.2099 26.4482 20.2099H18.5977C18.2757 20.2099 18.014 20.4716 17.9939 20.7937V26.8325H14.7732V4.2473H30.2527V26.8325Z"
          fill="#232223"
        />
        <path
          d="M4.66821 11.7153V26.8325H13.6057V11.7153H4.66821ZM11.4518 24.1553C11.4518 24.4774 11.1901 24.7391 10.8681 24.7391H7.42594C7.08374 24.7391 6.82206 24.4774 6.82206 24.1553V20.8541C6.82206 20.5119 7.08374 20.2502 7.42594 20.2502H10.8681C11.1901 20.2502 11.4518 20.5119 11.4518 20.8541V24.1553ZM11.4518 17.6938C11.4518 18.0158 11.1901 18.2775 10.8681 18.2775H7.42594C7.08374 18.2775 6.82206 18.0158 6.82206 17.6938V14.3724C6.82206 14.0503 7.08374 13.7887 7.42594 13.7887H10.8681C11.1901 13.7887 11.4518 14.0503 11.4518 14.3724V17.6938Z"
          fill={fillColor}
        />
        <path
          d="M10.868 13.7887H7.4259C7.0837 13.7887 6.82202 14.0504 6.82202 14.3724V17.6938C6.82202 18.0159 7.0837 18.2776 7.4259 18.2776H10.868C11.1901 18.2776 11.4518 18.0159 11.4518 17.6938V14.3724C11.4518 14.0504 11.1901 13.7887 10.868 13.7887ZM10.2641 17.11H8.00966V14.9562H10.2641V17.11Z"
          fill="#232223"
        />
        <path
          d="M10.868 20.2501H7.4259C7.0837 20.2501 6.82202 20.5118 6.82202 20.8339V24.1552C6.82202 24.4773 7.0837 24.739 7.4259 24.739H10.868C11.1901 24.739 11.4518 24.4773 11.4518 24.1552V20.854C11.4518 20.5118 11.1901 20.2501 10.868 20.2501ZM10.2641 23.5715H8.00966V21.4378H10.2641V23.5715Z"
          fill="#232223"
        />
        <path
          d="M10.2641 21.4379H8.00964V23.5716H10.2641V21.4379Z"
          fill="white"
        />
        <path
          d="M10.2641 14.9562H8.00964V17.11H10.2641V14.9562Z"
          fill="white"
        />
        <path
          d="M25.8444 21.3976H19.1815V26.8325H25.8444V21.3976Z"
          fill={fillColor}
        />
        <path
          d="M14.7732 4.24731V26.8325H17.9939V20.7937C18.014 20.4716 18.2757 20.2099 18.5978 20.2099H26.4482C26.7703 20.2099 27.032 20.4716 27.032 20.7937V26.8325H30.2527V4.24731H14.7732ZM28.119 17.3113C28.119 17.6334 27.8573 17.8951 27.5352 17.8951H17.4907C17.1686 17.8951 16.9069 17.6132 16.9069 17.2912V13.9899C16.9069 13.6679 17.1686 13.3861 17.4907 13.3861H27.5352C27.8573 13.3861 28.119 13.6679 28.119 13.9899V17.3113ZM28.119 10.8296C28.119 11.1517 27.8573 11.4134 27.5352 11.4134H17.4907C17.1686 11.4134 16.9069 11.1517 16.9069 10.8296V7.50828C16.9069 7.20634 17.1283 6.96478 17.4303 6.92453H27.5352C27.8573 6.92453 28.119 7.18621 28.119 7.50828V10.8296Z"
          fill="white"
        />
        <path
          d="M27.5352 6.92456H17.4302C17.1283 6.96482 16.9069 7.20637 16.9069 7.50831V10.8297C16.9069 11.1517 17.1685 11.4134 17.4906 11.4134H27.5352C27.8573 11.4134 28.1189 11.1517 28.1189 10.8297V7.50831C28.1189 7.18624 27.8573 6.92456 27.5352 6.92456ZM26.9514 10.2459H18.0744V8.1122H26.9514V10.2459Z"
          fill="#232223"
        />
        <path
          d="M27.5352 13.3861H17.4906C17.1685 13.3861 16.9069 13.6679 16.9069 13.99V17.2912C16.9069 17.6133 17.1685 17.8951 17.4906 17.8951H27.5352C27.8573 17.8951 28.1189 17.6334 28.1189 17.3113V13.99C28.1189 13.6679 27.8573 13.3861 27.5352 13.3861ZM26.9514 16.7075H18.0744V14.5737H26.9514V16.7075Z"
          fill="#232223"
        />
        <path
          d="M26.9514 14.5737H18.0743V16.7074H26.9514V14.5737Z"
          fill={fillColor}
        />
        <path
          d="M26.9514 8.11218H18.0743V10.2459H26.9514V8.11218Z"
          fill={fillColor}
        />
        <path
          d="M28.1793 1.16748H16.8464V3.07977H28.1793V1.16748Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_19912_1721">
          <rect
            width="34.0791"
            height="28"
            fill="white"
            transform="translate(0.420898)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BuildingIcon;
