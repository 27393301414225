import { isMobile } from 'react-device-detect';
import { IBatchRateWorker } from '@company/types/batch-rate-worker.type';

interface Props {
  worker: IBatchRateWorker;
}
function WorkerName({ worker }: Props) {
  return (
    <span className={`fw-bold ${isMobile ? 'fs-6 mb-1' : 'fs-5'}`}>
      {worker.name}
    </span>
  );
}

export default WorkerName;
