const getNextPage = (lastPage: any, allPages: any) => {
  if (lastPage?.pagination) {
    const totalFetched = allPages.reduce(
      (currentTotal: number, current: any) =>
        currentTotal + current.results.length,
      0
    );
    const nextPage =
      totalFetched < lastPage.pagination.count ? allPages.length : undefined;
    return nextPage;
  }
  return undefined;
};

export default getNextPage;
