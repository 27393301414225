import { useJobAssignmentContext } from '@company/state/jobAssignmentContext';
import { useJobShiftContext } from '@company/state/jobShiftContext';
import IJobDetailData, { ShiftPosition } from '@th-types/job.detail.type';
import { getValidationSchema } from '@services/shift/shiftService';
import * as jobApi from '@company/services/job/api';
import { ModalConfirm, ThLoading } from '@components/elements';
import IModalConfirmModel from '@th-types/confirm-model.type';
import {
  createDateTimeFromDifferentDates,
  isSameDateOrAfter,
} from '@utils/DateUtils';
import { PayRatePeriod } from '@th-types/payRatePeriod';
import 'react-datepicker/dist/react-datepicker.css';
import DATE_FORMATS from '@constants/dateFormat';
import { ShiftStatus } from '@th-types/jobs.type';
import { FiAlertTriangle } from 'react-icons/fi';
import { SubmitHandler } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import { addDays, format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import useAlert from '@hooks/useAlert';
import { Alert } from 'react-bootstrap';
import { useState } from 'react';
import { z } from 'zod';
import CreateShiftTable from './CreateShiftTable';
import './style.css';

export interface CreateShiftModalProps {
  job: IJobDetailData;
  isWorkerSideModalOpen?: boolean;
  shift?: ShiftPosition;
  handleClose: () => void;
  shiftCreatedCloseModal: (message?: string) => void;
}

export default function CreateShiftModal({
  job,
  shift,
  isWorkerSideModalOpen,
  handleClose,
  shiftCreatedCloseModal,
}: CreateShiftModalProps) {
  const { shifts, refetch } = useJobShiftContext();
  const { refetch: refetchWorkerProfile } = useJobAssignmentContext();
  const [isLoadingAction, setLoadingAction] = useState(false);
  const { showWarningAlert, alertMessage } = useAlert();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalConfirmModel, setModalConfirmModel] =
    useState<IModalConfirmModel>();

  const validationSchema = getValidationSchema(job.timezone);
  type ValidationShiftSchema = z.infer<typeof validationSchema>;

  const processSuccessResult = async (messageSuccess: string) => {
    await refetch();
    shiftCreatedCloseModal(messageSuccess);
  };

  const formatShiftValues = (data: ValidationShiftSchema) => {
    const formData: ValidationShiftSchema = { shifts: [] };
    data.shifts.forEach((item) => {
      const shiftItem = item;

      // Set start and end times based on date from item.date + selected start/end times
      const startTime = createDateTimeFromDifferentDates(
        item.date,
        item.startTime
      );

      let endTime = createDateTimeFromDifferentDates(item.date, item.endTime);

      shiftItem.start = format(startTime, DATE_FORMATS.DATE_TIME_FORMAT);
      if (isSameDateOrAfter(startTime, endTime)) {
        endTime = addDays(endTime, 1);
      }

      shiftItem.end = format(endTime, DATE_FORMATS.DATE_TIME_FORMAT);
      shiftItem.payRatePeriod =
        shiftItem.payRatePeriod === 'daily'
          ? PayRatePeriod.DAY
          : PayRatePeriod.HOUR;
      shiftItem.payRate = shiftItem.payRate.replace('$', '').trim();
      formData.shifts.push(shiftItem);
    });
    return formData;
  };

  const processResult = (
    success: boolean,
    messageSuccess: string,
    warningMessage: string
  ) => {
    if (success) {
      processSuccessResult(messageSuccess);
      handleClose();
    } else {
      showWarningAlert(warningMessage);
    }
  };

  const updateShift = async (formData: any) => {
    if (shift) {
      try {
        let messageSuccess = '';
        let warningMessage = '';
        let success = false;
        const updateResult = await jobApi.updateJobShifts(
          job.id,
          shift.id,
          formData.shifts[0]
        );
        success = updateResult?.success;
        if (success) {
          messageSuccess = updateResult.message;
          if (isWorkerSideModalOpen && shift.jobAssignment) {
            await refetchWorkerProfile();
          }
        } else {
          warningMessage = updateResult.errorMessage;
        }
        processResult(success, messageSuccess, warningMessage);
      } catch (err) {
        console.log('Occurred an error trying to update a job shift', err);
      } finally {
        setShowConfirmModal(false);
      }
    }
  };

  const setConfirmModalData = (formData: any) => {
    setModalConfirmModel({
      message: (
        <div className="text-center">
          <p>
            <b>Are you sure?</b>
          </p>
          <p>
            This is a confirmed job shift. The worker who has confirmed it, has
            only this one confirmed job shift. By this update action, the worker
            will be moved to offered status and will be needed a new accept to
            get hired back again
          </p>
        </div>
      ),
      titleMessage: (
        <div className="text-center">
          <FiAlertTriangle className="pb-1 me-2" />
          <span>Update shift</span>
        </div>
      ),
      confirmFunction: () => updateShift(formData),
      show: showConfirmModal,
    });
  };

  const createJobShifts = async (
    data: ValidationShiftSchema,
    formData: any
  ) => {
    let success = false;
    let messageSuccess = '';
    let warningMessage = '';
    const result = await jobApi.addJobShifts(job.id, formData.shifts);
    success = result?.results?.length > 0;
    if (success) {
      messageSuccess = `Job shift${
        data.shifts.length > 1 || data.shifts[0].openPositions > 1 ? 's' : ''
      } successfully created`;
    } else {
      warningMessage = result.message;
    }
    processResult(success, messageSuccess, warningMessage);
  };

  const hasMoreConfirmedShifts = () => {
    return (
      shift?.jobAssignment &&
      shifts?.some(
        (item) =>
          item.id !== shift.id &&
          item.jobAssignment &&
          item.jobAssignment.id === shift.jobAssignment?.id &&
          item.shiftStatus === ShiftStatus.CONFIRMED
      )
    );
  };

  const onSubmit: SubmitHandler<ValidationShiftSchema> = async (
    data: ValidationShiftSchema
  ) => {
    try {
      setLoadingAction(true);
      const formData = formatShiftValues(data);
      if (shift) {
        if (
          shift.shiftStatus === ShiftStatus.CONFIRMED &&
          !hasMoreConfirmedShifts()
        ) {
          setConfirmModalData(formData);
          setShowConfirmModal(true);
        } else {
          await updateShift(formData);
        }
      } else {
        await createJobShifts(data, formData);
      }
    } catch (err) {
      console.log(
        `Occurred an error trying to ${
          shift ? 'update a shift' : 'create'
        } shift${!shift && data.shifts.length > 1 ? 's' : ''}`,
        err
      );
    } finally {
      setLoadingAction(false);
    }
  };

  return (
    <Modal
      size="xl"
      className="modal-xl-update"
      show
      centered
      onHide={() => handleClose()}
    >
      {isLoadingAction && <ThLoading />}
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold" id="create-shift-modal">
          {!shift ? 'Create Shift' : 'Update Shift'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateShiftTable job={job} onSubmit={onSubmit} shift={shift} />
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button variant="light" onClick={handleClose}>
          CANCEL
        </Button>
        <Button
          variant="primary"
          form="shift-form"
          type="submit"
          disabled={isLoadingAction}
        >
          {shift ? 'UPDATE' : 'CREATE'}
        </Button>
      </Modal.Footer>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
      {showConfirmModal && modalConfirmModel && (
        <ModalConfirm
          modalConfirmModel={modalConfirmModel}
          setShowModal={setShowConfirmModal}
        />
      )}
    </Modal>
  );
}
