import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Stack } from 'react-bootstrap';
import { HiArrowLeft } from 'react-icons/hi';
import GivenEndorsement from '@company/components/CommunicationHub/GivenEndorsement/GivenEndorsement';
import { getGivenEndorsement } from '@company/services/com-hub/api';
import ThLoading from '@components/elements/ThLoading';
import QueryKeys from '@constants/queryKeys';
import useAlert from '@hooks/useAlert';
import { isMobile } from 'react-device-detect';
import { IoMdClose } from 'react-icons/io';
import '../styles.css';

interface Props {
  endorsementCaseId: number;
  closeModal: () => void;
}

function GivenEndorsementModal({ endorsementCaseId, closeModal }: Props) {
  const { showErrorAlert } = useAlert();
  const {
    data: endorsementData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryKeys.ComHub.GIVEN_ENDORSEMENT, endorsementCaseId],
    queryFn: () => getGivenEndorsement(endorsementCaseId),
  });

  useEffect(() => {
    if (isError) {
      showErrorAlert('Something went wrong!');
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <Stack gap={2}>
      {isMobile ? (
        <Stack direction="horizontal" className="justify-content-between">
          <span className="received-review-title">Endorsement left</span>
          <IoMdClose size={20} onClick={closeModal} />
        </Stack>
      ) : (
        <Stack>
          <Stack
            direction="horizontal"
            gap={2}
            className="align-items-center p-3 cursor-point fw-bold"
            onClick={closeModal}
          >
            <HiArrowLeft size={20} />
            GO BACK
          </Stack>
          <span className="mb-3 received-review-title">Endorsement left</span>
        </Stack>
      )}
      {isLoading && <ThLoading />}
      {endorsementData && <GivenEndorsement endorsement={endorsementData} />}
    </Stack>
  );
}

export default GivenEndorsementModal;
