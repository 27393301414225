import { useQuery } from '@tanstack/react-query';
import { Card, Image, Stack } from 'react-bootstrap';
import capitalTalentLogo from '@assets/capital-talent-partners.jpg';
import { markPageView } from '@services/api/userStats';
import providedServices from './providedServices';

import './styles.css';

interface PayrollServicesPage {
  isActive: boolean;
}

function PayrollServices({ isActive }: PayrollServicesPage) {
  const markPageAsViewed = async (eventType: string) => {
    await markPageView(eventType);
  };

  useQuery({
    queryKey: ['markPageAsViewed_payroll'],
    queryFn: () => {
      markPageAsViewed('OPEN_PAYROLL_TAB');
      return true;
    },
    enabled: isActive,
  });

  return (
    <Stack className="payroll-services-container">
      <b className="fs-4">
        Want to manage staff A-Z, but need a payroll partner?
      </b>
      <p className="fs-6 mt-3">
        Trusted Herd is a job board, reputation, and workforce management site,
        we do not offer employment or payment services. However,{' '}
        <b>Capital Talent Partners (CTP)</b> is a preferred Employer of Record
        (EOR) vendor with extremely competitive rates exclusively for Trusted
        Herd members.
      </p>
      <Image className="logo-img mt-3" src={capitalTalentLogo} />
      <b className="fs-5 mt-5">
        CTP specialize in supporting live event companies, and make it easier
        for you by handling the following:
      </b>

      <Stack
        direction="horizontal"
        className="flex-wrap justify-content-center my-5 mx-auto"
        style={{ maxWidth: '1000px' }}
        gap={5}
      >
        {providedServices.map((service) => (
          <Card body key={service.label} className="service-item">
            <div className="icon-container">{service.icon}</div>
            <b className="border-bottom d-block mb-2 pb-2">{service.label}</b>
            <p>{service.description}</p>
          </Card>
        ))}
      </Stack>
      <b className="fs-5">Get onboarded with CTP in as little as 24 hours!</b>

      <p className="fs-6 mt-3">
        To start, contact founder and CEO <b>Geoffrey Alexander</b> directly at
        geoffrey.alexander@capitaltalent.net or call 704-684-9852.
      </p>

      <b className="fs-6">
        Account managers available 24/7 to support your team!
      </b>
      <p className="fs-6">www.capitaltalent.net</p>
    </Stack>
  );
}

export default PayrollServices;
