import { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { debounce } from 'lodash';
import { format } from 'date-fns';
import { zodResolver } from '@hookform/resolvers/zod';
import { Panel } from '@components/elements';
import SelectOption from '@th-types/selectOption.type';
import { ISuggestion } from '@th-types/suggestions.type';
import INonTHJobCreation, {
  INonTHJobValidationSchema,
  INonTHJobValidationSchemaType,
} from '@worker/types/non-th-job-creation.type';
import { IJobOptionsData, IJobTitle } from '@th-types/jobs.type';
import { CompanyOption } from '@company/types/rate-worker-creation.type';
import { PaymentPeriodType } from '@th-types/paymentPeriods.enum';
import { suggest } from '@services/api/companyApi';
import ThDateRangePicker from '@components/elements/Datepicker';
import useAlert from '@hooks/useAlert';
import DATE_FORMATS from '@constants/dateFormat';

import './styles.scss';
import { dateDaysAgo } from '@utils/DateUtils';

interface Props {
  stepTitle?: string;
  nextStep?: () => void;
  isActive?: boolean;
  setSelectedCompany: (selectedCompany: ISuggestion) => void;
  companyNameParam?: string | null;
  setNonTHJobCreation: (value: INonTHJobCreation) => void;
  jobOptions: IJobOptionsData | null;
  setCompanyOptions: (value: ISuggestion[]) => void;
  companyOptions: ISuggestion[];
}

function AddNewGig({
  nextStep,
  stepTitle,
  isActive,
  companyNameParam,
  setNonTHJobCreation,
  jobOptions,
  setCompanyOptions,
  companyOptions,
}: Props) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<INonTHJobValidationSchemaType>({
    resolver: zodResolver(INonTHJobValidationSchema),
    defaultValues: {
      otherJobTitleDescription: '',
      companyName: companyNameParam || '',
    },
  });
  const { alertMessage } = useAlert();
  const [showOtherPosition, setShowOtherPosition] = useState(false);

  const newCompanyString = 'This is a first! - Create profile for: ';

  const mountSelect = (
    arr: CompanyOption[] | IJobTitle[] | PaymentPeriodType[] | undefined,
    field1: string,
    field2: string
  ): { value: number | string; label: string }[] => {
    return !arr
      ? []
      : arr.map((item: CompanyOption | IJobTitle | PaymentPeriodType) => {
          return {
            value: item[field1 as keyof typeof item],
            label: item[field2 as keyof typeof item],
          };
        });
  };

  const onSubmit = async (dataRequest: INonTHJobValidationSchemaType) => {
    setNonTHJobCreation(dataRequest as INonTHJobCreation);

    if (nextStep) {
      nextStep();
    }
  };

  const onChangeCompany = debounce((value: string) => {
    if (value?.length >= 2) {
      suggest(value).then((r) => {
        if (r.suggestions.length === 0) {
          setCompanyOptions([{ id: 0, name: newCompanyString + value }]);
        } else {
          setCompanyOptions(r.suggestions);
        }
      });
    }
  }, 400);

  useEffect(() => {
    if (!showOtherPosition) {
      setValue('otherJobTitleDescription', '');
    }
  }, [setValue, showOtherPosition]);

  const periodsType: PaymentPeriodType[] = [
    { id: 'HOUR', title: 'Per hour' },
    { id: 'DAY', title: 'Per day' },
  ];

  return (
    <Row key={stepTitle} className={isActive ? 'd-block' : 'd-none'}>
      <Col md={{ span: 8, offset: 2 }}>
        <Panel>
          <Form
            id="rateGigForm"
            className="rate-gig-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col xs={12}>
                <b>Rate Your Gig Experience</b>
              </Col>
              <Col xs={12} className="mt-3">
                Fellow workers want to know, plus we work with companies to make
                our industry better using your reviews!
              </Col>
              <Col xs={12} className="mt-3">
                <Form.Group controlId="rateGigForm.eventName">
                  <Form.Label className="fw-bold">Event Name*</Form.Label>
                  <Form.Control
                    placeholder="Enter event name"
                    className="mt-2"
                    {...register('eventName')}
                    required
                    autoComplete="off"
                  />
                  {errors.eventName && (
                    <small>{errors.eventName.message}</small>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} className="mt-3">
                <Form.Group controlId="rateGigForm.companyName">
                  <Form.Label className="fw-bold">Hiring Company*</Form.Label>
                  <Controller
                    control={control}
                    {...register('companyName')}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        name={name}
                        ref={ref}
                        options={mountSelect(companyOptions, 'name', 'name')}
                        value={mountSelect(companyOptions, 'name', 'name').find(
                          (c: SelectOption) => c.value === value
                        )}
                        defaultValue={
                          companyNameParam
                            ? {
                                label: companyNameParam,
                                value: companyNameParam,
                              }
                            : null
                        }
                        onChange={(val) => onChange(val?.value)}
                        onInputChange={(val) => onChangeCompany(val)}
                        required
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="mt-3">
                <Form.Group controlId="rateGigForm.brandRepresented">
                  <Form.Label className="fw-bold">
                    Brand Represented*
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Brand Represented"
                    className="mt-2"
                    {...register('brandRepresented')}
                    required
                    autoComplete="off"
                  />
                  {errors.brandRepresented && (
                    <small>{errors.brandRepresented.message}</small>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} className="mt-3">
                <Form.Group controlId="rateGigForm.jobPosition">
                  <Form.Label>
                    <b>Job Position*</b>
                  </Form.Label>
                  <Controller
                    control={control}
                    {...register('title.id')}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        name={name}
                        ref={ref}
                        required
                        options={mountSelect(
                          jobOptions?.jobTitles,
                          'id',
                          'name'
                        )}
                        value={mountSelect(
                          jobOptions?.jobTitles,
                          'id',
                          'name'
                        ).find((c: SelectOption) => c.value === value)}
                        onChange={(val) => {
                          onChange(val?.value);
                          setShowOtherPosition(
                            val?.label.toLowerCase() === 'other'
                          );
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              {showOtherPosition && (
                <Col xs={12} className="mt-3">
                  <Form.Group controlId="rateGigForm.otherJobTitleDescription">
                    <Form.Label className="fw-bold">Other*</Form.Label>
                    <Form.Control
                      placeholder="Enter Other Job Position"
                      className="mt-2"
                      {...register('otherJobTitleDescription')}
                      required
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              )}
              <Col xs={12} className="mt-3">
                <Form.Group controlId="rateGigForm.payRate">
                  <Form.Label>
                    <b>Pay Rate*</b>
                  </Form.Label>
                  <Row className="mt-2">
                    <Col>
                      <Controller
                        control={control}
                        {...register('payRatePeriod')}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            name={name}
                            ref={ref}
                            options={mountSelect(periodsType, 'id', 'title')}
                            value={mountSelect(periodsType, 'id', 'title').find(
                              (c: SelectOption) => c.value === value
                            )}
                            onChange={(val) => onChange(val?.value)}
                            required
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        placeholder="Pay Rate"
                        {...register('payRate', {
                          valueAsNumber: true,
                        })}
                        type="number"
                        step=".01"
                        required
                        className={errors.payRate ? 'border-error' : ''}
                      />
                      {errors.payRate && (
                        <small>{errors.payRate.message}</small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col xs={12} className="mt-3">
                <Form.Group controlId="rateGigForm.periods">
                  <Form.Label className="fw-bold">Dates Worked*</Form.Label>
                  <Row className="mt-2">
                    <Col>
                      <Controller
                        control={control}
                        {...register('periods')}
                        render={({ field }) => (
                          <ThDateRangePicker
                            placeholder="Select Date Range"
                            setStart={() => undefined} // it fails if not passed but is not needed here
                            setEnd={() => undefined} // it fails if not passed but is not needed here
                            minDate={dateDaysAgo(365)}
                            onChange={(period) => {
                              field.onChange([
                                {
                                  start: format(
                                    period[0].startDate,
                                    DATE_FORMATS.DATE_FORMAT
                                  ),
                                  end: format(
                                    period[0].endDate,
                                    DATE_FORMATS.DATE_FORMAT
                                  ),
                                },
                              ]);
                            }}
                          />
                        )}
                      />
                      {errors.periods && (
                        <small>{errors.periods.message}</small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col xs={6} className="mt-3">
                <Form.Group controlId="rateGigForm.approximatelyDaysWorked">
                  <Form.Label className="fw-bold">
                    Total Days Worked*
                  </Form.Label>
                  <Form.Control
                    {...register('approximatelyDaysWorked', {
                      valueAsNumber: true,
                    })}
                    type="number"
                    placeholder="Enter # of days"
                    className="mt-2"
                    required
                  />
                  {errors.approximatelyDaysWorked && (
                    <small>{errors.approximatelyDaysWorked.message}</small>
                  )}
                </Form.Group>
              </Col>
              <Col xs={6} className="mt-3">
                <Form.Group controlId="rateGigForm.totalHoursWorked">
                  <Form.Label className="fw-bold">
                    Total Hours Worked*
                  </Form.Label>
                  <Form.Control
                    {...register('totalHoursWorked', {
                      valueAsNumber: true,
                    })}
                    type="number"
                    placeholder="Enter # of hours"
                    className="mt-2"
                    required
                  />
                  {errors.totalHoursWorked && (
                    <small>{errors.totalHoursWorked.message}</small>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Col>
      <Col xs={12} className="mt-3 mb-5 d-flex justify-content-center">
        <Button type="submit" form="rateGigForm" size="sm">
          <b>Continue</b>
        </Button>
      </Col>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </Row>
  );
}

export default AddNewGig;
