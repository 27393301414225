import Button from 'react-bootstrap/esm/Button';
import { BiBlock } from 'react-icons/bi';
import { isMobile } from 'react-device-detect';
import './style.css';
import { useState } from 'react';
import * as workerApi from '@company/services/worker/api';
import useAlert from '@hooks/useAlert';
import { ThLoading } from '@components/elements';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  workerId: number;
  isBlockedByDefault: boolean;
  disable: boolean;
  isSmallButton?: boolean;
  iconSize?: number;
  className?: string;
  handleToggleState?: (state: boolean) => void;
}

export default function BlockButton({
  workerId,
  isBlockedByDefault,
  disable,
  isSmallButton,
  iconSize = 14,
  className = '',
  handleToggleState,
}: Props) {
  const { alertMessage, showWarningAlert } = useAlert();
  const [isLoadingAction, setLoadingAction] = useState(false);
  const [isBlockedWorker, setIsBlockedWorker] = useState(isBlockedByDefault);

  const blockWorker = async () => {
    setLoadingAction(true);
    try {
      const result = await workerApi.blockWorker(workerId);
      if (result && result.data.success) {
        setIsBlockedWorker(true);
      } else if (result) {
        showWarningAlert(result.message);
      }
    } finally {
      setLoadingAction(false);
    }
  };

  const unblockWorker = async () => {
    setLoadingAction(true);
    try {
      const result = await workerApi.unblockWorker(workerId);
      if (result && result.data.success) {
        setIsBlockedWorker(false);
      } else if (result) {
        showWarningAlert(result.message);
      }
    } finally {
      setLoadingAction(false);
    }
  };

  const handleBlockWorker = () => {
    if (isBlockedWorker) {
      unblockWorker();
    } else {
      blockWorker();
    }
    if (handleToggleState) {
      handleToggleState(isBlockedWorker);
    }
  };

  return (
    <>
      {isLoadingAction && <ThLoading />}

      <OverlayTrigger
        flip
        overlay={<Tooltip id="button-tooltip">Block worker</Tooltip>}
      >
        <Button
          size={isSmallButton ? 'sm' : 'lg'}
          className={`text-danger fw-bolder ${className}`}
          disabled={disable}
          variant={isBlockedWorker ? 'warning' : 'light'}
          onClick={handleBlockWorker}
        >
          <BiBlock
            size={iconSize}
            className={isMobile ? 'mobile-icon-style' : ''}
            color={`${isBlockedWorker ? 'red' : 'black'}`}
          />
        </Button>
      </OverlayTrigger>

      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}
