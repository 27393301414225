const QueryKeys = {
  WORKER_PROFILE: 'worker_profile',
  WORKER_ASSIGNMENT_JOB: 'worker_assignment_job',
  WORKER_ACTIVITY_COMPANY: 'worker_activity_company',
  COMPANY_JOBS: 'company_jobs',
  COMPANY_JOB_DETAIL: 'company_job_detail',
  COMPANY_JOB_APPLICATION: 'company_job_application',
  COMPANY_JOB_SHIFTS: 'company_job_shifts',
  COMPANY_JOB_ACTIVITY_TRACKER: 'company_job_activity_tracker',
  COMPANY_JOB_SHIFTS_CREATE: 'create_job_shifts',
  COMPANY_JOB_CAN_CANCEL: 'company_job_can_cancel',
  COMPANY_TALENTS: 'company_talents',
  COMPANY_RATING: 'company_rating',
  COM_HUB_COMPANY_JOB_DETAIL: 'company_job_detail',
  JOB_TITLES: 'job_titles',
  WORKER_NOTES: 'worker_notes',
  WORKER_FILTERS: 'worker_filters',
  SHIFT_WORKERS_TO_OFFER: 'shift_workers_to_offer',
  WORKER_AVAILABILITIES: 'worker_availabilities',
  COMPANY_JOB_SHIFTS_OFFER: 'offer_job_shifts',
  MESSAGE_CONVERSATIONS: 'message_conversations',
  MESSAGE_MESSAGES: 'message_messages',
  REVIEW_JOBS: 'review_jobs',
  SEARCH_JOBS_REVIEW: 'search_jobs_review',
  SURVEY_HISTORY: 'survey_history',
  SURVEY_PROFILING_DATA: 'survey_profiling_data',

  // COM HUB QueryKeys
  ComHub: {
    RECEIVED_REVIEWS: 'comunication-hub-received-reviews',
    REVIEW_REQUESTS: 'comunication-hub-review-requests',
    GIVEN_REVIEWS: 'comunication-hub-given-reviews',
    GIVEN_ENDORSEMENT: 'comunication-hub-given-endorsement',
  },
};

export default QueryKeys;
