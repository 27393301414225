import { z } from 'zod';

export enum IRatingVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export const reviewWorkerValidationSchema = z.object({
  jobAssignmentId: z.number(),
  jobId: z.number().optional(),
  workerId: z.number(),
  performance: z.number().min(1).max(5),
  photoIsRepresentative: z.boolean(),
  title: z.object({
    id: z.number(),
    name: z.string().optional(),
  }),
  approximatelyDaysWorked: z.number(),
  comments: z.string(),
  noCallNoShowed: z.boolean(),
  canceledUnexcused: z.boolean(),
  improvementComments: z.string(),
  visibility: z.nativeEnum(IRatingVisibility),
  internalNotes: z.string(),
});

export type ReviewWorkerSchemaType = z.infer<
  typeof reviewWorkerValidationSchema
>;

export type RateWorkerPayload = {
  jobAssignmentId: number;
  workerId: number;
  performance: number;
  photoIsRepresentative: boolean;
  title: {
    id: number;
    name?: string;
  };
  approximatelyDaysWorked: number;
  comments: string;
  noCallNoShowed: boolean;
  canceledUnexcused: boolean;
  improvementComments: string;
  visibility: string;
  internalNotes: string;
};

export interface Reviewer {
  name: string;
  imageUrl: string;
}

export interface WorkerRating {
  id: number;
  dateCreated: string;
  lastUpdated: string;
  verified: boolean;
  comments: string | null;
  performance: number;
  avgRating: number | null;
  submittedBy: number;
  visibility: IRatingVisibility;
  ratingVersion: number | null;
  improvementComments: string | null;
  internalNotes: string;
  noCallNoShowed: boolean;
  canceledUnexcused: boolean;
  photoIsRepresentative: boolean;
  reviewer: Reviewer;
}

export interface RatingReply {
  id: number;
  comments: string;
}

export interface IJobAssignmentData {
  id: number;
  canAskForRating: boolean;
  isValidatedCompany: boolean;
  companyRating: CompanyRating;
}

export interface CompanyRating {
  id: number;
  atEventManagement: number;
  avgRating: number;
  comments: string;
  communication: number;
  improvementComments?: string;
  payment: number | null;
  professionalism: number;
  reviewer: Reviewer;
  visibility: IRatingVisibility;
  ratingReply?: RatingReply;
  // new fields for com-hub
  canceledUnexcused: boolean;
  dateCreated: string;
  lastUpdated: string;
  noCallNoShowed: boolean;
  performance: number;
  ratingVersion: number | null;
  verified: boolean;
  wouldWorkAgain: boolean;
}

export const RATINGS_CURRENT_VERSION = 2;
