import { Nav, Stack } from 'react-bootstrap';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { IBatchRateWorker } from '@company/types/batch-rate-worker.type';

interface Props {
  workerOptions: IBatchRateWorker[];
  completedWorkerIds: number[];
}

function WorkerList({ workerOptions, completedWorkerIds }: Props) {
  const workerIsCompleted = (workerId: number) => {
    return completedWorkerIds.includes(workerId);
  };

  return (
    <Stack>
      {workerOptions.length > 1 &&
        workerOptions.map((worker, index) => (
          <Nav key={worker.id} variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey={index} className="mb-4 cursor-pointer">
                <Stack key={worker.id}>
                  <div className="small">
                    {index + 1} of {workerOptions.length}
                  </div>
                  <Stack direction="horizontal" gap={1}>
                    <div
                      className={`fw-bold 
                    ${workerIsCompleted(worker.id) ? 'completed' : ''}`}
                    >
                      {worker.name}
                    </div>
                    {workerIsCompleted(worker.id) && (
                      <BsFillCheckCircleFill
                        className="m-1"
                        size={14}
                        color="var(--green)"
                      />
                    )}
                  </Stack>
                </Stack>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        ))}
    </Stack>
  );
}

export default WorkerList;
