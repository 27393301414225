import { Image } from 'react-bootstrap';
import { OptionProps } from 'react-select';
import { IWorkerSuggestion } from '@th-types/suggestions.type';

function ThWorkerSelectOption(props: OptionProps<IWorkerSuggestion>) {
  const { innerProps, innerRef, data } = props;
  const { id, name, image } = data;
  return (
    <div
      key={id}
      ref={innerRef}
      {...innerProps}
      className="m-2 d-flex align-items-center"
    >
      <Image
        width={40}
        height={40}
        src={image}
        roundedCircle
        className="mb-2"
      />
      <div className="m-2 fw-bold">{name} </div>
    </div>
  );
}

export default ThWorkerSelectOption;
