import {
  AlertVariantEnum,
  alertInitialState,
  AlertModelAttributesEnum,
  reducerAlertMessage,
} from '@th-types/alert-model.type';
import { useEffect, useReducer } from 'react';

export default function useAlert() {
  const [alertMessage, dispatch] = useReducer(
    reducerAlertMessage,
    alertInitialState
  );

  useEffect(() => {
    if (alertMessage.show) {
      setTimeout(() => {
        dispatch({ type: AlertModelAttributesEnum.SHOW, value: false });
      }, 5000);
    }
  }, [alertMessage]);

  const showMessage = (message: string) => {
    dispatch({ type: AlertModelAttributesEnum.MESSAGE, value: message });
    dispatch({ type: AlertModelAttributesEnum.SHOW, value: true });
  };

  const showSuccessAlert = (messageAlert: string) => {
    dispatch({
      type: AlertModelAttributesEnum.VARIANT,
      value: AlertVariantEnum.SUCCESS,
    });
    showMessage(messageAlert);
  };

  const showWarningAlert = (messageAlert: string) => {
    dispatch({
      type: AlertModelAttributesEnum.VARIANT,
      value: AlertVariantEnum.WARNING,
    });
    showMessage(messageAlert);
  };

  const showErrorAlert = (messageAlert: string) => {
    dispatch({
      type: AlertModelAttributesEnum.VARIANT,
      value: AlertVariantEnum.DANGER,
    });
    showMessage(messageAlert);
  };

  return {
    showSuccessAlert,
    showWarningAlert,
    showErrorAlert,
    alertMessage,
  };
}
