import {
  ComHubDismissTypeEnum,
  ComHubQueryParamsEnum,
  Review,
} from '@company/types/comm-hub.type';

export const dismissItems = async (
  items: Review[],
  associatedType: ComHubDismissTypeEnum,
  mutateFunction: (itemsToDismiss: {
    items: Array<{
      associatedType: ComHubDismissTypeEnum;
      associatedIds: number[];
    }>;
  }) => Promise<void>
) => {
  const itemsToDismiss = {
    items: [
      {
        associatedType,
        associatedIds: items.map((item) => item.userState.associatedId),
      },
    ],
  };

  try {
    await mutateFunction(itemsToDismiss);
  } catch (error) {
    console.error('Error dismissing review:', error);
  }
};

export interface ComHubSearchParams {
  searchText: string;
  dateRange: { startDate: Date | undefined; endDate: Date | undefined };
}

export interface ComHubSearchOptions {
  itemsPerPage?: number;
  setCurrentOffset?: (offset: number) => void;
}

/**
 * Utility function to handle search in CommunicationHub components
 * @param search The search parameters
 * @param setSearchState Function to update the search state
 * @param updateSearchParams Function to update the URL search parameters
 * @param options Additional options like itemsPerPage and setCurrentOffset
 */
export const handleComHubSearch = (
  search: ComHubSearchParams,
  setSearchState: (search: ComHubSearchParams) => void,
  updateSearchParams: (params: URLSearchParams) => void,
  options: ComHubSearchOptions = {}
) => {
  const { itemsPerPage = 20, setCurrentOffset } = options;

  setSearchState(search);
  const newSearchParams = new URLSearchParams();

  if (search.searchText) {
    newSearchParams.set(ComHubQueryParamsEnum.SEARCH_TEXT, search.searchText);
  }
  if (search.dateRange.startDate) {
    newSearchParams.set(
      ComHubQueryParamsEnum.DATE_FROM,
      search.dateRange.startDate.toISOString()
    );
  }
  if (search.dateRange.endDate) {
    newSearchParams.set(
      ComHubQueryParamsEnum.DATE_TO,
      search.dateRange.endDate.toISOString()
    );
  }
  newSearchParams.set(ComHubQueryParamsEnum.OFFSET, '0');
  newSearchParams.set(ComHubQueryParamsEnum.MAX, itemsPerPage.toString());
  updateSearchParams(newSearchParams);

  if (setCurrentOffset) {
    setCurrentOffset(0);
  }
};

/**
 * Utility function to clear search in CommunicationHub components
 * @param setSearchState Function to update the search state
 * @param updateSearchParams Function to update the URL search parameters
 * @param options Additional options like itemsPerPage and setCurrentOffset
 */
export const handleComHubClear = (
  setSearchState: (search: ComHubSearchParams | undefined) => void,
  updateSearchParams: (params: URLSearchParams) => void,
  options: ComHubSearchOptions = {}
) => {
  const { itemsPerPage = 20, setCurrentOffset } = options;

  setSearchState(undefined);
  const newSearchParams = new URLSearchParams();
  newSearchParams.set(ComHubQueryParamsEnum.OFFSET, '0');
  newSearchParams.set(ComHubQueryParamsEnum.MAX, itemsPerPage.toString());
  updateSearchParams(newSearchParams);

  if (setCurrentOffset) {
    setCurrentOffset(0);
  }
};

/**
 * Interface for the result of extracting URL parameters
 */
export interface ComHubUrlParams {
  searchState?: ComHubSearchParams;
  showOnlyNew?: boolean;
  offset?: number;
}

/**
 * Utility function to extract URL parameters for CommunicationHub components
 * @param searchParams The URL search parameters
 * @returns An object containing the extracted parameters
 */
export const extractComHubUrlParams = (
  searchParams: URLSearchParams
): ComHubUrlParams => {
  const searchText = searchParams.get(ComHubQueryParamsEnum.SEARCH_TEXT);
  const dateFrom = searchParams.get(ComHubQueryParamsEnum.DATE_FROM);
  const dateTo = searchParams.get(ComHubQueryParamsEnum.DATE_TO);
  const showNew = searchParams.get(ComHubQueryParamsEnum.NEW);
  const offset = searchParams.get(ComHubQueryParamsEnum.OFFSET);

  const result: ComHubUrlParams = {};

  if (searchText || dateFrom || dateTo) {
    result.searchState = {
      searchText: searchText || '',
      dateRange: {
        startDate: dateFrom ? new Date(dateFrom) : undefined,
        endDate: dateTo ? new Date(dateTo) : undefined,
      },
    };
  }

  if (showNew !== null) {
    result.showOnlyNew = !!showNew;
  }

  if (offset !== null) {
    result.offset = Number(offset) || 0;
  }

  return result;
};
