import { privateApi, getRequestConfig } from '@services/api';
import { IActiveJobData, ISuggestedJobData } from '@th-types/active-jobs.type';
import IJobsData, { IJobOptionsData } from '@th-types/jobs.type';
import IJobDetailData, {
  IJobShiftData,
  ShiftPositions,
} from '@th-types/job.detail.type';
import IResultResponse from '@th-types/resultResponse.type';
import { JobUpdatableFields } from '@company/types/job.updatable.fields';
import { ActivityDetailList } from '@th-types/daily-activity.type';
import { AxiosError } from 'axios';

export const MAIN_URL = 'jobs';

export const fetchJobDetail = async (jobId: number) => {
  const url = `${MAIN_URL}/${jobId}`;
  const result = await privateApi.get<IJobDetailData>(url);
  return result.data;
};

export const fetchJobList = async (page = 0, max = 10) => {
  const result = await privateApi.get<IJobsData>(
    MAIN_URL,
    getRequestConfig(page, max)
  );
  return result.data;
};

export const fetchJobPositionList = async () => {
  const result = await privateApi.get<IJobOptionsData>('search/options');
  return result.data;
};

export const canDeleteJob = async (jobId: number) => {
  const url = `${MAIN_URL}/${jobId}/validate-edition`;
  const result = await privateApi.get<IResultResponse>(url);
  return result.data;
};

export const deleteJob = async (jobId: number) => {
  const url = `${MAIN_URL}/${jobId}`;
  const result = await privateApi.delete<IResultResponse>(url);
  return result.data;
};

export const patchJob = async (jobId: number, fields: JobUpdatableFields) => {
  const url = `${MAIN_URL}/${jobId}`;
  const result = await privateApi.patch<boolean>(url, fields);
  return result.data;
};

export const refreshBullhornJob = async (
  jobId: number,
  integrationId: number | undefined
) => {
  const url = `${MAIN_URL}/${jobId}/integrations/${integrationId}/refresh`;
  const result = await privateApi.post<boolean>(url);
  return result.data;
};

export const addJobShifts = async (jobId: number, data: any) => {
  const result = await privateApi
    .post<IResultResponse>(`${MAIN_URL}/${jobId}/shifts`, data)
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried create a new job shift. jobId: ${jobId}`
      );
      console.log(error);
      return error.response;
    });

  return result.data;
};

export const cancelJobShifts = async (jobId: number, shiftIds: number[]) => {
  const result = await privateApi
    .post<IResultResponse>(`${MAIN_URL}/${jobId}/shifts/cancel`, { shiftIds })
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried to cancel assigned shift. jobId: ${jobId}`
      );
      return error.response;
    });
  return result;
};

export const deleteJobShifts = async (jobId: number, shiftIds: number[]) => {
  const result = await privateApi
    .delete<IResultResponse>(`${MAIN_URL}/${jobId}/shifts`, {
      data: { shiftIds },
    })
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried to cancel assigned shift. jobId: ${jobId}`
      );
      console.log(error);
      return error.response;
    });

  return result.data;
};

export const updateJobShifts = async (
  jobId: number,
  shiftId: number,
  data: any
) => {
  const result = await privateApi
    .put<IResultResponse>(`${MAIN_URL}/${jobId}/shifts/${shiftId}`, data)
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried create a new job shift. jobId: ${jobId}`
      );
      console.log(error);
      return error.response;
    });

  return result.data;
};

export const fetchShiftPositions = async (
  jobId: number,
  page = 0,
  max = 100
) => {
  const result = await privateApi
    .get<ShiftPositions[]>(
      `${MAIN_URL}/${jobId}/shifts`,
      getRequestConfig(page, max)
    )
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried fetch job shift. jobId: ${jobId}`
      );
      console.log(error);
      return error.response;
    });
  return result.data;
};

// TODO: REMOVE ALL THE CODE BELOW AFTER TASK ABOUT SCHEDULING INTEGRATION IS TOTALLY FINISHED
const TABLE_BODY = [
  {
    id: 1,
    position: 'Brand Ambassador',
    status: 'Confirmed',
    scheduledShiftTime: '2:00p - 8:00p',
    fullBackUp: 'Full Shift',
    wage: '$200/hour',
    paidBreak: 'Yes',
    worker: 'Worker',
    startTime: '2023-03-28T10:42:33.488Z',
    endTime: '2023-03-28T12:42:33.488Z',
    date: '2023-03-28T00:42:33.488Z',
  },
  {
    id: 2,
    position: 'Team lead',
    status: 'Open',
    scheduledShiftTime: '2:00p - 8:00p',
    fullBackUp: 'Back Up',
    wage: '$200/hour',
    paidBreak: 'No',
    worker: 'Worker',
    startTime: '2023-03-27T09:42:33.488Z',
    endTime: '2023-03-27T09:52:33.488Z',
    date: '2023-03-27T00:42:33.488Z',
  },
  {
    id: 3,
    position: 'Brand Ambassador',
    status: 'Confirmed',
    scheduledShiftTime: '2:00PM-8:00PM',
    fullBackUp: 'Full Shift',
    wage: '$200/hour',
    paidBreak: 'No',
    worker: '',
    startTime: '2023-03-25T20:42:33.488Z',
    endTime: '2023-03-25T21:42:33.488Z',
    date: '2023-03-25T00:42:33.488Z',
  },
  {
    id: 4,
    position: 'Team lead',
    status: 'Pending',
    scheduledShiftTime: '2:00p - 8:00p',
    fullBackUp: 'Back Up',
    wage: '$200/hour',
    paidBreak: 'No',
    worker: 'Worker',
    startTime: '2023-03-21T13:42:33.488Z',
    endTime: '2023-03-21T15:42:33.488Z',
    date: '2023-03-21T15:42:33.488Z',
  },
  {
    id: 5,
    position: 'Emcee',
    status: 'Open',
    scheduledShiftTime: '2:00p - 8:00p',
    fullBackUp: 'Full Shift',
    wage: '$200/hour',
    paidBreak: 'Yes',
    worker: 'Worker',
    startTime: '2023-03-28T12:42:33.488Z',
    endTime: '2023-03-28T22:42:33.488Z',
    date: '2023-03-28T12:42:33.488Z',
  },
  {
    id: 6,
    position: 'Brand Ambassador',
    status: 'Pending',
    scheduledShiftTime: '2:00p - 8:00p',
    fullBackUp: 'Back Up',
    wage: '$200/hour',
    paidBreak: 'No',
    worker: '',
    startTime: '2023-03-27T06:42:33.488Z',
    endTime: '2023-03-27T07:42:33.488Z',
    date: '2023-03-27T00:42:33.488Z',
  },
  {
    id: 7,
    position: 'Emcee',
    status: 'Confirmed',
    scheduledShiftTime: '2:00p - 8:00p',
    fullBackUp: 'Full Shift',
    wage: '$200/hour',
    paidBreak: 'No',
    worker: 'Test',
    startTime: '2023-03-27T05:42:33.488Z',
    endTime: '2023-03-27T10:42:33.488Z',
    date: '2023-03-27T05:42:33.488Z',
  },
  {
    id: 7,
    position: 'Brand Ambassador #2',
    status: 'Confirmed',
    scheduledShiftTime: '2:00PM-8:00PM',
    fullBackUp: 'Back Up',
    wage: '$200/hour',
    paidBreak: 'No',
    worker: 'Test',
    startTime: '2023-04-07T05:42:33.488Z',
    endTime: '2023-04-07T10:42:33.488Z',
    date: '2023-04-07T05:42:33.488Z',
  },
];

export const fetchJobShiftsMock = async (): Promise<IJobShiftData[]> => {
  return TABLE_BODY;
};

export const fetchActivityTrackerList = async (jobId: number) => {
  const url = `${MAIN_URL}/${jobId}/activity-tracker`;
  const result = await privateApi.get<ActivityDetailList>(url);
  return result.data;
};

export const markShiftsPaid = async (jobId: number, shiftIds: number[]) => {
  const result = await privateApi
    .post<IResultResponse>(`${MAIN_URL}/${jobId}/shifts/mark-paid`, {
      shiftIds,
    })
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried to mark a shift as paid. jobId: ${jobId} - shifts: ${shiftIds}`
      );
      console.log(error);
      return error.response;
    });

  return result.data;
};

export const fetchActiveJobs = async (
  workerId?: number | null,
  searchFilter?: string | null
): Promise<IActiveJobData> => {
  const result = await privateApi.get<IActiveJobData>(MAIN_URL, {
    params: { jobSearchType: 'ACTIVE_ALL', workerId, searchFilter, max: 999 },
  });
  return result.data;
};

export const fetchSuggestedJobs = async (
  searchFilter?: string | null,
  page = 0,
  max = 10,
  sort = 'start',
  order = 'desc'
): Promise<ISuggestedJobData> => {
  const result = await privateApi.get<ISuggestedJobData>(MAIN_URL, {
    params: {
      jobSearchType: 'ALL',
      searchFilter,
      offSet: page * max,
      max,
      sort,
      order,
    },
  });
  return result.data;
};

export const markShiftsUnPaid = async (
  jobId: number,
  shiftIds: number[]
): Promise<IResultResponse> => {
  try {
    const result = await privateApi.post<IResultResponse>(
      `${MAIN_URL}/${jobId}/shifts/mark-unpaid`,
      {
        shiftIds,
      }
    );
    return result.data;
  } catch (error) {
    console.log(
      `Occurred an error when Trusted Herd tried to mark a shift as unpaid. jobId: ${jobId} - shifts: ${shiftIds}`
    );
    console.log(error);
    return (error as AxiosError<IResultResponse>).response
      ?.data as IResultResponse;
  }
};
