import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IWorkerData } from '@th-types/worker.type';
import * as api from '@company/services/chat/api';
import IMessageData, { ISystemMessageData } from '@th-types/message.type';
import { ISortableMessages } from '@components/Chat/MessagesChat';

interface IChatContext {
  chatPerson: IWorkerData | null;
  chatAssignmentId: number | null;
  showPrivateInfo: boolean;
  messages: IMessageData[];
  systemMessages: ISystemMessageData[];
  unsentMessages: IMessageData[];
  orderedMessages: ISortableMessages[];
  setMessages: (messages: IMessageData[]) => void;
  setSystemMessages: (messages: ISystemMessageData[]) => void;
  setUnsentMessages: (messages: IMessageData[]) => void;
  setChatAssignmentId: (chatAssignmentId: number | null) => void;
  openChat: (
    person: IWorkerData,
    assignmentId: number | null,
    showWorkerInfo: boolean
  ) => void;
  clearChatData: () => void;
}

interface ProviderProps {
  children: React.ReactNode;
}

export const ChatContext = createContext<IChatContext>({
  chatPerson: null,
  chatAssignmentId: null,
  showPrivateInfo: false,
  messages: [],
  systemMessages: [],
  unsentMessages: [],
  orderedMessages: [],
  setMessages: () => {},
  setSystemMessages: () => {},
  setUnsentMessages: () => {},
  setChatAssignmentId: () => {},
  openChat: () => {},
  clearChatData: () => {},
});

export function ChatProvider({ children }: ProviderProps) {
  const [chatPerson, setChatPerson] = useState<IWorkerData | null>(null);
  const [chatAssignmentId, setChatAssignmentId] = useState<number | null>(null);
  const [showPrivateInfo, setShowPrivateInfo] = useState<boolean>(false);
  const [messages, setMessages] = useState<IMessageData[]>([]);
  const [systemMessages, setSystemMessages] = useState<ISystemMessageData[]>(
    []
  );
  const [unsentMessages, setUnsentMessages] = useState<IMessageData[]>([]);
  const [orderedMessages, setOrderedMessages] = useState<ISortableMessages[]>(
    []
  );

  useEffect(() => {
    let chatMsgs: ISortableMessages[] = messages.map((m) => ({
      id: m.id,
      created: m.created,
      createdTimestamp: new Date(m.created.toLocaleString()).valueOf(),
      message: m,
      sent: m.sent === undefined || m.sent === true,
    }));

    chatMsgs = chatMsgs.concat(
      systemMessages.map((m) => ({
        id: m.id,
        created: m.created,
        createdTimestamp: new Date(m.created.toLocaleString()).valueOf(),
        systemMessage: m,
        sent: true,
      }))
    );

    chatMsgs = chatMsgs.concat(
      unsentMessages.map((m) => ({
        id: m.id,
        created: m.created,
        createdTimestamp: new Date(m.created.toLocaleString()).valueOf(),
        message: m,
        sent: false,
      }))
    );
    const unsentList = chatMsgs
      .filter((x) => !x.sent)
      .sort(
        (a, b) =>
          new Date(a.createdTimestamp).getTime() -
          new Date(b.createdTimestamp).getTime()
      );

    const allSent = chatMsgs
      .filter((x) => x.sent)
      .sort(
        (a, b) =>
          new Date(a.createdTimestamp).getTime() -
          new Date(b.createdTimestamp).getTime()
      );

    const all = allSent.concat(unsentList);
    setOrderedMessages(all);
  }, [messages, setMessages, systemMessages, unsentMessages]);

  const clearChatData = useCallback(() => {
    setChatPerson(null);
    setChatAssignmentId(null);
  }, []);

  const openChat = useCallback(
    (person: IWorkerData, id: number | null, showWorkerInfo: boolean) => {
      setChatPerson(person);
      setChatAssignmentId(id);
      setShowPrivateInfo(showWorkerInfo);
      if (id) {
        api.readMessages(id);
      }
    },
    []
  );

  const contextValue: IChatContext = useMemo(() => {
    return {
      chatPerson,
      chatAssignmentId,
      showPrivateInfo,
      setChatAssignmentId,
      openChat,
      clearChatData,
      messages,
      systemMessages,
      unsentMessages,
      orderedMessages,
      setMessages,
      setSystemMessages,
      setUnsentMessages,
    };
  }, [
    chatPerson,
    chatAssignmentId,
    showPrivateInfo,
    openChat,
    clearChatData,
    messages,
    systemMessages,
    unsentMessages,
    orderedMessages,
  ]);

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
}

export function useChatContext() {
  const context: IChatContext = useContext(ChatContext);
  if (context === undefined) {
    throw new Error(
      'useChatContext must be used within a ChatProvider. Wrap a parent component in <ChatProvider> to fix this error.'
    );
  }
  return context;
}
