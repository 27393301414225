import { useRef } from 'react';
import { Form, Image } from 'react-bootstrap';
import { OptionProps } from 'react-select';
import useAvatarImageFallback from '@hooks/useAvatarImageFallback';
import { IWorkerData } from '@th-types/worker.type';

export default function ThFilterWorkerOption(props: OptionProps<IWorkerData>) {
  const { innerProps, innerRef, data, isSelected } = props;
  const { id, fullName, profileImageUrl } = data;
  const avatarRef = useRef<HTMLImageElement>(null);
  useAvatarImageFallback(avatarRef);

  return (
    <div
      key={id}
      ref={innerRef}
      {...innerProps}
      className="p-2 m-2 d-flex align-items-center cursor-point border rounded-3"
    >
      <Form.Check.Input
        type="checkbox"
        id="selected"
        checked={isSelected}
        readOnly
        className="m-2 me-3 bigger cursor-point"
      />
      <Image
        ref={avatarRef}
        width={40}
        height={40}
        src={profileImageUrl}
        rounded
        className="avatar"
      />
      <div className="m-3 fw-bold">{fullName} </div>
    </div>
  );
}
