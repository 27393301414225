import { Modal, Stack } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import SkillsForm from '@worker/components/WorkerProfile/Forms/SkillsForm/SkillsForm';

interface SkillsModalProps {
  onCloseModal: () => void;
}

function SkillsModal({ onCloseModal }: SkillsModalProps) {
  return (
    <Modal show centered className="p-3" size="lg" onHide={onCloseModal}>
      <Modal.Header>
        <Modal.Title className="d-flex align-items-center">
          Skills
          <span className="ms-2" style={{ color: 'var(--silver)' }}>
            (10%)
          </span>
        </Modal.Title>
        <Stack
          direction="horizontal"
          className="justify-content-end ms-auto"
          gap={2}
        >
          <IoMdClose
            size={20}
            className="cursor-point"
            onClick={onCloseModal}
          />
        </Stack>
      </Modal.Header>
      <Modal.Body>
        <SkillsForm onCancel={onCloseModal} />
      </Modal.Body>
    </Modal>
  );
}

export default SkillsModal;
