import { Alert } from 'react-bootstrap';
import './style.css';

interface BodyProps {
  isDemo?: boolean;
  children: React.ReactNode;
}

export default function Body({ isDemo = false, children }: BodyProps) {
  const variant = 'info';
  return (
    <div className="body-app">
      {isDemo && (
        <Alert key={variant} variant={variant}>
          This section is under development, data shown is just a demo!
        </Alert>
      )}
      {children}
    </div>
  );
}
