import { Modal, Stack } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { useWorkerProfileContext } from '@worker/state/workerProfileContext';
import ProfileInfoForm from '@worker/components/WorkerProfile/Forms/ProfileInfoForm/ProfileInfoForm';

import './styles.css';

interface ProfileInfoModalProps {
  onCloseModal: () => void;
}

function ProfileInfoModal({ onCloseModal }: ProfileInfoModalProps) {
  const { workerProfile } = useWorkerProfileContext();
  return (
    <Modal show centered className="p-3" size="lg" onHide={onCloseModal}>
      <Modal.Header>
        <Modal.Title className="d-flex align-items-center">
          Profile Information
          <span className="fs-5 ms-2" style={{ color: 'var(--silver)' }}>
            {workerProfile?.profileCompletedPercentage}%
          </span>
        </Modal.Title>
        <Stack
          direction="horizontal"
          className="justify-content-end ms-auto"
          gap={2}
        >
          <IoMdClose
            size={20}
            className="cursor-point"
            onClick={onCloseModal}
          />
        </Stack>
      </Modal.Header>
      <Modal.Body>
        <ProfileInfoForm onCancel={onCloseModal} onSuccess={onCloseModal} />
      </Modal.Body>
    </Modal>
  );
}

export default ProfileInfoModal;
