import { JobAssignmentStatus } from '@th-types/job-assignment-status.type';
import { JobAssignment } from '@th-types/job-assignment.type';
import { PayRatePeriod } from '@th-types/payRatePeriod';
import { Pagination } from '@th-types/pageable.type';
import { IWorkerData } from '@th-types/worker.type';
import { ShiftStatus } from '@th-types/jobs.type';

export default interface IJobPlacementData {
  pagination: Pagination;
  results: JobPlacement[];
}

export interface JobPlacement {
  id: number;
  updatedBy: number;
  comments: any;
  jobPositionTitle: JobPositionTitle;
  jobPositionType: string;
  payRate: number;
  payRatePeriod: PayRatePeriod;
  status: JobAssignmentStatus;
  worker: IWorkerData;
  jobAssignment: JobAssignment;
  jobId: number;
  companyId: number;
}

export interface JobPlacementHistory {
  id: number;
  dateCreated: Date;
  jobPlacementId: number;
  status: JobPlacementStatus;
  updatedBy: string;
  shiftItems: ShiftItem[] | undefined;
}

export interface ShiftItem {
  id: number;
  position: string;
  payRate: number;
  payRatePeriod: PayRatePeriod;
  startTime: string;
  endTime: string;
  jobAssignmentId: number;
  action: string;
  status: ShiftStatus;
}

export enum JobPlacementStatus {
  OFFERED = 'OFFERED',
  OFFER_UPDATED = 'OFFER_UPDATED',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  DECLINED_ADDITIONAL = 'DECLINED_ADDITIONAL',
  HIRED = 'HIRED',
  CANCELLED = 'CANCELLED',
  SHIFTS_UPDATED = 'SHIFTS_UPDATED',
}

export interface JobPositionTitle {
  id: number;
  name: string;
}
