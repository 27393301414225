import { Form } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import IBatchRateWorkerSession, {
  IBatchRateWorker,
} from '@company/types/batch-rate-worker.type';
import { IRatingVisibility } from '@th-types/rating.type';

interface Props {
  control: Control<IBatchRateWorkerSession, null>;
  worker: IBatchRateWorker;
}

function Visibility({ control, worker }: Props) {
  return (
    <Form.Group controlId="rateWorkerForm.visibility">
      <Controller
        control={control}
        name={`workers.${worker.id}.workerRating.visibility`}
        render={({ field: { onChange, value, name, ref } }) => (
          <Form.Check
            ref={ref}
            name={name}
            inline
            type="checkbox"
            checked={value === IRatingVisibility.PUBLIC}
            className="d-flex align-items-center"
          >
            <Form.Check.Input
              className="bigger"
              onChange={(val) =>
                onChange(
                  val.target.checked
                    ? IRatingVisibility.PUBLIC
                    : IRatingVisibility.PRIVATE
                )
              }
            />
            <Form.Check.Label className="fw-bold">
              Agree to share improvement public with company name and logo
            </Form.Check.Label>
          </Form.Check>
        )}
      />
    </Form.Group>
  );
}

export default Visibility;
