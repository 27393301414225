import { BrowserView, MobileView } from 'react-device-detect';
import { Offcanvas } from 'react-bootstrap';
import { ModalProps } from 'react-bootstrap/Modal';
import './style.css';

interface Props extends ModalProps {
  width?: string;
}

export default function SideEndModal(props: Props) {
  const { children, width = '85%' } = props;
  return (
    <>
      <BrowserView>
        <Offcanvas {...props} placement="end" style={{ width }}>
          <Offcanvas.Body>{children}</Offcanvas.Body>
        </Offcanvas>
      </BrowserView>
      <MobileView>
        <Offcanvas
          {...props}
          placement="bottom"
          style={{
            height: 'calc(100vh - 63px)', // 63px is the height of the navbar
            margin: '0',
          }}
        >
          <Offcanvas.Body>{children}</Offcanvas.Body>
        </Offcanvas>
      </MobileView>
    </>
  );
}
