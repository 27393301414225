import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { initialApplicantFilters } from '@company/state/jobContext';
import { ApplicantsType } from '@constants/applicantsType';
import ApplicantFilter from '@company/types/applicant-filter.type';

export enum ShiftOfferFilterEnum {
  GENDER = 'Gender',
  COUNTRY = 'Country',
  TRAVEL_NATIONALLY = 'Travel Nationally',
  TRAVEL_LONGER_PERIOD = 'Travel Longer Period',
}

interface ApplicantFiltersParam {
  applicantFilters: ApplicantFilter;
  setApplicantFilters: (filter: ApplicantFilter) => void;
}

export default function ApplicantFilters({
  applicantFilters,
  setApplicantFilters,
}: ApplicantFiltersParam) {
  const handleFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    if (type === 'radio') {
      setApplicantFilters({
        ...applicantFilters,
        [name]: JSON.parse(value),
      });
      return;
    }

    let updatedList = [...applicantFilters[name]];
    if (checked && !updatedList.includes(value)) {
      updatedList = [...applicantFilters[name], value];
    } else {
      updatedList.splice(applicantFilters[name].indexOf(value), 1);
    }
    setApplicantFilters({ ...applicantFilters, [name]: updatedList });
  };

  const handleClearAllFilters = () => {
    setApplicantFilters({
      ...initialApplicantFilters,
    });
  };

  return (
    <Card className="mt-3 p-3 pb-0 applicant-filters">
      <Row className="pb-0" style={{ fontSize: '0.9rem' }}>
        <Col md={5} xs={12}>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="mb-3">
                <Form.Label className="me-3 col-12">
                  <b>{ShiftOfferFilterEnum.GENDER}</b>
                </Form.Label>
                <Form.Check
                  inline
                  label="Male"
                  name="gender"
                  type="checkbox"
                  value="MALE"
                  className="col-12"
                  checked={applicantFilters.gender?.includes('MALE')}
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${ApplicantsType.APPLIED}-1`}
                />
                <Form.Check
                  inline
                  label="Female"
                  name="gender"
                  type="checkbox"
                  value="FEMALE"
                  checked={applicantFilters.gender?.includes('FEMALE')}
                  className="col-12"
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${ApplicantsType.APPLIED}-1`}
                />
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group className="mb-3">
                <Form.Label className="me-3 col-12">
                  <b>{ShiftOfferFilterEnum.COUNTRY}</b>
                </Form.Label>
                <Form.Check
                  inline
                  label="USA"
                  name="country"
                  type="checkbox"
                  value="UNITED_STATES"
                  checked={applicantFilters.country?.includes('UNITED_STATES')}
                  className="col-12"
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${ApplicantsType.APPLIED}-1`}
                />
                <Form.Check
                  inline
                  label="Canada"
                  name="country"
                  type="checkbox"
                  value="CANADA"
                  checked={applicantFilters.country?.includes('CANADA')}
                  className="col-12"
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${ApplicantsType.APPLIED}-1`}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col md={7} xs={12}>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="mb-3">
                <Form.Label className="me-3 col-12">
                  <b>{ShiftOfferFilterEnum.TRAVEL_NATIONALLY}</b>
                </Form.Label>
                <Form.Check
                  inline
                  label="Yes"
                  name="willingToTravel"
                  type="radio"
                  value="true"
                  checked={applicantFilters.willingToTravel}
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${ApplicantsType.APPLIED}-1`}
                />
                <Form.Check
                  inline
                  label="No"
                  name="willingToTravel"
                  type="radio"
                  value="false"
                  checked={applicantFilters.willingToTravel === false}
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${ApplicantsType.APPLIED}-1`}
                />
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label className={`${isMobile ? 'me-3' : ''} col-12`}>
                  <b>{ShiftOfferFilterEnum.TRAVEL_LONGER_PERIOD}</b>
                </Form.Label>
                <Form.Check
                  inline
                  label="Yes"
                  name="travelLongerPeriod"
                  type="radio"
                  value="true"
                  checked={applicantFilters.travelLongerPeriod}
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${ApplicantsType.APPLIED}-1`}
                />
                <Form.Check
                  inline
                  label="No"
                  name="travelLongerPeriod"
                  type="radio"
                  value="false"
                  checked={applicantFilters.travelLongerPeriod === false}
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${ApplicantsType.APPLIED}-1`}
                />
                <Row className="col-12 clear-all-filters">
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={() => handleClearAllFilters()}
                    style={{
                      color: 'unset',
                      fontSize: '0.75rem',
                    }}
                  >
                    Clear All
                  </Button>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
