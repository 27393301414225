import { Image, Modal } from 'react-bootstrap';
import './styles.css';
import { useState } from 'react';

interface CheckInOutImageParams {
  url: string;
}

export default function CheckInOutImage({ url }: CheckInOutImageParams) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        className="align-items-center div-image-style"
        onClick={() => setShowModal(true)}
        style={{ cursor: 'pointer' }}
      >
        <Image
          rounded
          src={url}
          style={{
            objectFit: 'contain',
            maxHeight: '100%',
            maxWidth: '100%',
          }}
        />
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="sm"
      >
        <Modal.Body className="text-center">
          <Image
            src={url}
            style={{
              maxHeight: '70vh',
              objectFit: 'contain',
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
