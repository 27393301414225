import { useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as api from '@company/services/job/api';
import { FiCheckCircle } from '@react-icons/all-files/fi/FiCheckCircle';
import { JobCompletedEnum } from '@constants/jobCompletedEnum';
import { JobUpdatableFields } from '@company/types/job.updatable.fields';
import ModalActionCompleted from './ModalActionCompleted';

interface ModalParams {
  jobId: number;
  jobPosition: string;
  jobStatus: JobCompletedEnum;
  showIcon: boolean;
  iconSize: number;
}

function ModalCompleteJob({
  jobId,
  jobPosition,
  jobStatus,
  showIcon,
  iconSize,
}: ModalParams) {
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleCompletion = async () => {
    setDisabled(true);

    try {
      const payload: JobUpdatableFields = {
        jobStatus: JobCompletedEnum.COMPLETED,
      };

      await api.patchJob(jobId, payload);
      setShowModal(false);
      setShowSuccessModal(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <ModalActionCompleted
        message="Job was marked as completed!"
        showModal={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
      />
      {JobCompletedEnum.COMPLETED !== jobStatus ? (
        <Button
          variant="ms-auto"
          size="sm"
          onClick={handleShow}
          style={{ alignItems: 'center' }}
        >
          <FiCheckCircle
            size={iconSize}
            style={{
              marginRight: '8px',
            }}
          />
          {showIcon !== true ? <b>COMPLETE JOB</b> : ''}
        </Button>
      ) : (
        <div style={{ display: 'inline-flex' }}>
          <div style={{ alignItems: 'center' }}>
            {showIcon === true ? (
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={<Tooltip id="tooltip-top">Job Completed</Tooltip>}
              >
                <b>
                  <FiCheckCircle
                    color="green"
                    size={iconSize}
                    style={{ marginRight: '8px' }}
                  />
                </b>
              </OverlayTrigger>
            ) : (
              <>
                <FiCheckCircle
                  color="green"
                  size={iconSize}
                  style={{ marginRight: '8px' }}
                />
                <b>COMPLETED</b>
              </>
            )}
          </div>
        </div>
      )}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Complete Job {jobPosition} - {jobStatus}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Upon clicking complete, this job and all related actions will be
            moved to the Completed tab of this section.
          </p>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="primary"
            size="lg"
            onClick={handleCompletion}
            disabled={disabled}
          >
            Complete
          </Button>

          <Button variant="light" size="lg" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCompleteJob;
