import { useJobWorkerShiftContext } from '@company/state/jobWorkerShiftContext';
import { fetchAllCandidates } from '@company/services/application/api';
import { useJobShiftContext } from '@company/state/jobShiftContext';
import IApplicationData, { JobAssignment } from '@th-types/job-assignment.type';
import { useJobContext } from '@company/state/jobContext';
import { OFFER_SHIFT, offerShifts } from '@services/shift/shiftService';
import ThLoading from '@components/elements/ThLoading';
import { isDateAfterOrNow } from '@utils/DateUtils';
import { NullableString } from '@th-types/common.type';
import { useEffect, useMemo, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { ShiftStatus } from '@th-types/jobs.type';
import { useQuery } from '@tanstack/react-query';
import QueryKeys from '@constants/queryKeys';
import { Country } from '@th-types/address.type';
import { Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AxiosError } from 'axios';
import {
  canShiftsBeOfferedToAWorker,
  removeDuplicatedShifts,
} from '@utils/ShiftsUtils';
import { formatOptionLabel } from './FormatOptionLabel';
import CustomSingleValue from './CustomSingleValue';
import ControlComponent from './ControlComponent';
import OfferShiftTable from './OfferShiftTable';
import './styles.scss';

interface WorkerOptions {
  id: number;
  jobAssignmentId: number;
  label: string;
  avatar: string;
  location: string;
  gender: string;
  country: string | Country;
  travelNationally: boolean;
  travelLongPeriod: boolean;
}

interface OfferShiftModalProps {
  handleClose: () => void;
  shiftOfferedCloseModal: (isSuccess: boolean, message: string) => void;
}

function OfferShiftModal({
  handleClose,
  shiftOfferedCloseModal,
}: OfferShiftModalProps) {
  const [isEditingShift, setIsEditingShift] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isLoadingAction, setLoadingAction] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [note, setNote] = useState<NullableString>(null);
  const [selectedWorkerInList, setSelectedWorkerInList] =
    useState<SingleValue<WorkerOptions> | null>();
  const [filteredApplicants, setFilteredApplicants] = useState<WorkerOptions[]>(
    []
  );
  const { activeJobId, jobTimezone, key, fetchApplicantCounters } =
    useJobContext();
  const { selectedJobWorkerShifts, isJobWorkerSideModalOpen } =
    useJobWorkerShiftContext();
  const { shifts, selectedShifts, selectedWorker, applicantFilters } =
    useJobShiftContext();

  const { data: candidates } = useQuery<
    IApplicationData,
    AxiosError,
    IApplicationData
  >({
    queryKey: [`${QueryKeys.SHIFT_WORKERS_TO_OFFER}_${activeJobId}_${key}`],
    queryFn: () => fetchAllCandidates(activeJobId, applicantFilters, 0, 1000),
    enabled: activeJobId > 0 && selectedWorker === undefined,
  });

  const selectedOfferableShiftList = useMemo(() => {
    const shiftList = isJobWorkerSideModalOpen
      ? removeDuplicatedShifts(selectedJobWorkerShifts)
      : removeDuplicatedShifts(selectedShifts);
    return shiftList.filter(
      (shiftItem) =>
        shiftItem.shiftStatus === ShiftStatus.OPEN &&
        isDateAfterOrNow(shiftItem.start, jobTimezone)
    );
  }, [
    isJobWorkerSideModalOpen,
    selectedJobWorkerShifts,
    selectedShifts,
    jobTimezone,
  ]);

  useEffect(() => {
    const potentialCandidates = [];
    if (selectedWorker) {
      const worker: WorkerOptions = {
        id: selectedWorker.id,
        label: `${selectedWorker.firstName} ${selectedWorker.lastName}`,
        avatar: selectedWorker.profileImageUrl,
        gender: selectedWorker.gender ?? '',
        country: selectedWorker.address.country,
        travelNationally: selectedWorker.willingToTravel,
        travelLongPeriod: selectedWorker.travelLonger?.value === 'true',
        jobAssignmentId: 0,
        location: '', // FIXME: this causes bug that the distance is not properly rendered
      };
      // selected worker is still just a potential candidate, don't auto select him
      // this is for the case when the offer modal is opened from scheduling selections
      potentialCandidates.push(worker);
    } else {
      const workers: WorkerOptions[] =
        candidates?.results?.map((jobAssignment: JobAssignment) => {
          return {
            id: jobAssignment.worker.id,
            jobAssignmentId: jobAssignment.id,
            label: `${jobAssignment.worker.firstName} ${jobAssignment.worker.lastName}`,
            avatar: jobAssignment.worker.profileImageUrl,
            location: jobAssignment.distance?.toString(),
            gender: jobAssignment?.worker?.gender ?? '',
            country: jobAssignment.worker.address.country,
            travelNationally: jobAssignment.worker.willingToTravel,
            travelLongPeriod:
              jobAssignment.worker.travelLonger?.value === 'true',
          };
        }) ?? [];

      potentialCandidates.push(...workers);
    }

    if (isJobWorkerSideModalOpen && selectedWorker) {
      setFilteredApplicants(potentialCandidates);
      setSelectedWorkerInList(
        potentialCandidates.find((worker) => worker.id === selectedWorker.id)
      );
    } else {
      const filteredCandidates = potentialCandidates.filter((worker) =>
        canShiftsBeOfferedToAWorker(worker.id, shifts!, selectedShifts)
      );
      setFilteredApplicants(filteredCandidates);
      // Set the selected worker if it's eligible to offer
      if (
        selectedWorker &&
        filteredCandidates
          .map((worker) => worker.id)
          .includes(selectedWorker.id)
      ) {
        setSelectedWorkerInList(
          filteredCandidates.find((worker) => worker.id === selectedWorker.id)
        );
      }
    }

    setIsLoadingPage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidates, selectedWorker]);

  const submitShiftOffers = async () => {
    if (selectedWorkerInList) {
      setLoadingAction(true);
      const shiftIds = selectedOfferableShiftList.reduce<number[]>(
        (acc: number[], shiftItem) => {
          if (shiftItem.shiftStatus === ShiftStatus.OPEN) {
            acc.push(shiftItem.id);
          }
          return acc;
        },
        []
      );

      const result = await offerShifts(
        activeJobId,
        selectedWorkerInList.id,
        shiftIds,
        note
      );
      const resultMessage = result.success
        ? result.message
        : result.errorMessage;
      shiftOfferedCloseModal(result.success, resultMessage);
      fetchApplicantCounters();
      setLoadingAction(false);
    }
  };

  const renderCustomComponent = (props: any) => (
    <ControlComponent {...props} isShown={isShown} setIsShown={setIsShown} />
  );

  const renderSingleValue = (optionProps: any) => (
    <CustomSingleValue
      {...optionProps}
      workerName={selectedWorkerInList?.label}
      avatar={selectedWorkerInList?.avatar}
      location={selectedWorkerInList?.location}
    />
  );

  const closeOfferShiftModal = () => {
    setFilteredApplicants([]);
    setSelectedWorkerInList(undefined);
    handleClose();
  };

  return (
    <Modal size="xl" show centered onHide={closeOfferShiftModal}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Offer a Shift</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoadingAction && <ThLoading />}
        <>
          <Row className="m-1 fw-bold">To</Row>
          <Row>
            <Select
              options={filteredApplicants}
              placeholder="Select worker"
              value={selectedWorkerInList}
              blurInputOnSelect
              isSearchable={selectedWorker === undefined}
              isClearable
              menuIsOpen={isOpen}
              isLoading={isLoadingPage}
              defaultValue={selectedWorker ? selectedWorkerInList : null}
              onFocus={() => {
                setIsOpen(true);
              }}
              onBlur={() => {
                setIsOpen(false);
              }}
              openMenuOnClick
              formatOptionLabel={formatOptionLabel}
              components={{
                SingleValue: renderSingleValue,
                Control: renderCustomComponent,
              }}
              onChange={(workerOptions: SingleValue<WorkerOptions>) => {
                setSelectedWorkerInList(workerOptions);
                setIsOpen(false);
              }}
              classNames={{
                option: (state) =>
                  state.isSelected ? 'var(--white)' : 'var(--white)',
              }}
              styles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                  color: 'var(--black)',
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: 'none',
                }),
                control: (provided) => ({
                  ...provided,
                  width: '86%',
                }),
                menu: (provided) => ({
                  ...provided,
                  width: '98%',
                }),
              }}
            />
            <OfferShiftTable
              onEditShift={(isEditing) => setIsEditingShift(isEditing)}
            />
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="note">
              <Form.Label>
                <b>Offer note (optional)</b>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Group>
          </Row>
        </>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button variant="light" onClick={closeOfferShiftModal}>
          CANCEL
        </Button>
        <Button
          variant="primary"
          disabled={!selectedWorkerInList?.label || isEditingShift}
          onClick={submitShiftOffers}
        >
          {OFFER_SHIFT}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OfferShiftModal;
