import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import { Button, Stack, Collapse } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { GoBell } from 'react-icons/go';
import ThDateRangePicker from '@components/elements/Datepicker';
import ThSearchInput from '@components/elements/ThSearchInput/ThSearchInput';
import { ComHubQueryParamsEnum } from '@company/types/comm-hub.type';

interface FilterBarProps {
  onSearch: (searchObject: {
    searchText: string;
    dateRange: { startDate: Date | undefined; endDate: Date | undefined };
  }) => void;
  onClear: () => void;
  onShowOnlyNew?: () => void;
  showOnlyNew?: boolean;
}

function FilterBar({
  onSearch,
  onClear,
  onShowOnlyNew,
  showOnlyNew,
}: FilterBarProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(ComHubQueryParamsEnum.SEARCH_TEXT) || ''
  );
  const [pickedDateRange, setPickedDateRange] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({
    startDate: searchParams.get(ComHubQueryParamsEnum.DATE_FROM)
      ? new Date(searchParams.get(ComHubQueryParamsEnum.DATE_FROM)!)
      : undefined,
    endDate: searchParams.get(ComHubQueryParamsEnum.DATE_TO)
      ? new Date(searchParams.get(ComHubQueryParamsEnum.DATE_TO)!)
      : undefined,
  });
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleShowOnlyNew = () => {
    if (searchParams.get(ComHubQueryParamsEnum.NEW)) {
      searchParams.delete(ComHubQueryParamsEnum.NEW);
    } else {
      searchParams.set(ComHubQueryParamsEnum.NEW, 'true');
    }
    setSearchParams(searchParams);
    if (onShowOnlyNew) {
      onShowOnlyNew();
    }
  };

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchValue(inputValue);
  };

  const handleDateRangeChange = (
    dateRange: Array<{ startDate: Date; endDate: Date }>
  ) => {
    const { startDate, endDate } = dateRange[0];

    setPickedDateRange({
      startDate,
      endDate,
    });
  };

  const clearFilters = () => {
    setSearchValue('');
    setPickedDateRange({ startDate: undefined, endDate: undefined });
    onClear();
  };

  const handleSearch = () => {
    onSearch({
      searchText: searchValue,
      dateRange: {
        startDate: pickedDateRange.startDate,
        endDate: pickedDateRange.endDate,
      },
    });
  };

  return (
    <div>
      {isMobile && (
        <div
          className="d-flex justify-content-between align-items-center mb-3"
          onClick={() => setIsCollapsed(!isCollapsed)}
          style={{ cursor: 'pointer' }}
        >
          <span className="fw-bold">Filters</span>
          <span className="pe-2">
            {isCollapsed ? (
              <FaChevronDown size={20} />
            ) : (
              <FaChevronUp size={20} />
            )}
          </span>
        </div>
      )}
      <Collapse in={!isCollapsed || !isMobile}>
        <div>
          <Stack
            direction={isMobile ? 'vertical' : 'horizontal'}
            gap={isMobile ? 2 : 4}
            className="mb-3"
          >
            <div style={{ maxWidth: '500px', width: '100%' }}>
              <ThSearchInput
                value={searchValue}
                name="received-reviews"
                placeholder="Search by event, name or position"
                onChange={handleSearchOnChange}
              />
            </div>
            <ThDateRangePicker
              placeholder="Select Date Range"
              start={pickedDateRange.startDate}
              end={pickedDateRange.endDate}
              setStart={() => undefined}
              setEnd={() => undefined}
              onChange={handleDateRangeChange}
            />
            <Stack direction="horizontal" gap={3}>
              <Button
                onClick={handleSearch}
                className={isMobile ? 'flex-grow-1' : ''}
              >
                SEARCH
              </Button>
              <Button
                variant="light"
                onClick={clearFilters}
                className={isMobile ? 'flex-grow-1' : ''}
              >
                Clear
              </Button>
            </Stack>
            {onShowOnlyNew && (
              <Button
                variant="light"
                className={`ms-auto fw-bold ${isMobile ? 'w-100' : ''}`}
                onClick={handleShowOnlyNew}
              >
                <GoBell className="me-2 bell-icon" />
                {showOnlyNew ? 'See all' : 'See new'}
              </Button>
            )}
          </Stack>
        </div>
      </Collapse>
    </div>
  );
}

export default FilterBar;
