import { useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { UseFormRegister } from 'react-hook-form';
import IBatchRateWorkerSession, {
  IBatchRateWorker,
} from '@company/types/batch-rate-worker.type';

const COMMENTS_MAX_LENGTH = 300;

interface Props {
  register: UseFormRegister<IBatchRateWorkerSession>;
  worker: IBatchRateWorker;
}
function ImprovementComments({ register, worker }: Props) {
  const [commentsCount, setCommentsCount] = useState<number>(0);
  return (
    <Form.Group controlId="rateWorkerForm.improvementComments">
      <Row>
        <Form.Label className="fw-bold">
          Optional - What Can Worker Improve On
        </Form.Label>
        <Form.Text>
          Share improvements privately with the worker so they can improve,{' '}
          <span className="fw-bold">or</span> have this appear publicly on the
          worker profile along with your name and company picture.
        </Form.Text>
      </Row>

      <Form.Label className="fw-bold mt-3">
        What can this worker improve?
      </Form.Label>
      <span className="mx-2 optional-badge text-uppercase small">
        (OPTIONAL)
      </span>
      <Form.Control
        {...register(`workers.${worker.id}.workerRating.improvementComments`)}
        as="textarea"
        maxLength={COMMENTS_MAX_LENGTH}
        placeholder=""
        className="mt-2"
        onChange={(e) => setCommentsCount(e.target.value.length || 0)}
        autoComplete="off"
      />
      <Form.Text className="d-flex justify-content-end">
        {commentsCount}/{COMMENTS_MAX_LENGTH}
      </Form.Text>
    </Form.Group>
  );
}

export default ImprovementComments;
