import { z } from 'zod';
import {
  JobPlacement,
  JobPlacementHistory,
} from '@company/types/job-placement.type';
import { IRatingVisibility } from '@th-types/rating.type';
import { compareDesc, isPast, isToday } from 'date-fns';
import { JobAssignment, ThJobAssignmentLog } from './job-assignment.type';
import { ICertificatesData } from './certificates.type';
import { JobApplication } from './job-application.type';
import { IWorkerData } from './worker.type';
import { JobQuestionResponseModel } from './job-question-response-model.type';

export interface IWorkerJobData {
  worker: IWorkerData;
  workerEndorsements: IWorkerEndorsementsData;
  promoWorkExperiences: PromoWorkExperiences;
  nonPromoWorkExperiences: NonPromoWorkExperiences;
  educations: Educations;
  certificates: ICertificatesData;
  comments: Comment[];
  jobAssignment?: JobAssignment;
  jobAssignmentLogs?: ThJobAssignmentLog[];
  jobApplication: JobApplication;
  jobPlacement?: JobPlacement;
  jobPlacementHistoryList: JobPlacementHistory[];
  jobQuestionList: JobQuestionResponseModel[];
  isFavoritedByCompany: boolean;
  isBlockedByCompany: boolean;
  isEndorsedByUser: boolean;
}

export const workerProfileInfoValidationSchema = z.object({
  id: z.number(),
  profileImage: z
    .object({
      contentType: z.string(),
      content: z.string(),
    })
    .optional(),
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  address: z.object(
    {
      line1: z.string(),
      city: z.string(),
      postalCode: z.string().min(1, { message: 'Postal code is required' }),
      state: z.string(),
      country: z.string(),
      googleAddress: z.string(),
    },
    { required_error: 'Location is required' }
  ),
  cellPhone: z.string().min(1, { message: 'Phone number is required' }),
  yearsInIndustry: z.number(),
  monthsInIndustry: z.number(),
  birthday: z
    .date({ required_error: 'Birdth date is required.' })
    .refine((date) => isPast(date) || isToday(date), {
      message: 'Birdth date must be in the past.',
    }),
  gender: z.string().min(1, { message: 'Gender is required' }),
  heightFeet: z.number(),
  heightInches: z.number(),
  facebookUser: z.string().nullable(),
  instagramUser: z.string().nullable(),
  twitterUser: z.string().nullable(),
  linkedinUser: z.string().nullable(),
  resume: z
    .object({
      contentType: z.string(),
      content: z.string(),
    })
    .optional(),
  presentationVideoURL: z.string().nullable(),
  willingToTravel: z.boolean().nullable(),
  travelPeriod: z.string().optional(), // optional until redesign
});

export type WorkerProfileSchemaType = z.infer<
  typeof workerProfileInfoValidationSchema
>;

export interface PromoWorkExperiences {
  apiName: string;
  values: PromoWorkExperience[];
}

export interface PromoWorkExperience {
  id: number;
  brandRepresented: string;
  duties: string;
  hiringCompany: string;
  position: string;
  startDate: Date;
  endDate: Date;
}

/**
 * EVENT EXPERIENCE === PromoWorkExperience ?????
 */
export const eventExperienceValidationSchema = z
  .object({
    id: z.number().optional(),
    hiringCompany: z
      .string()
      .min(1, { message: 'Hiring company name is required.' }),
    brandRepresented: z
      .string()
      .min(1, { message: 'Brand represented is required.' }),
    position: z.string().min(1, { message: 'Job title is required.' }),
    duties: z.string().min(1, { message: 'Duties is required.' }),
    startDate: z
      .date({
        required_error: 'Start date is required.',
        invalid_type_error: 'Start date is required.',
      })
      .refine((date) => isPast(date) || isToday(date), {
        message: 'Start date must be in the past.',
      }),
    endDate: z
      .date()
      .optional()
      .nullable()
      .refine(
        (date) => {
          return date ? isPast(date) || isToday(date) : true;
        },
        {
          message: 'End date must be in the past.',
        }
      ),
  })
  .superRefine((values, context) => {
    const { startDate, endDate } = values;

    if (endDate && compareDesc(startDate, endDate) === -1) {
      context.addIssue({
        message: 'Start date must be before end date',
        code: z.ZodIssueCode.custom,
        path: ['startDate'],
      });
      context.addIssue({
        message: 'End date must be after start date',
        code: z.ZodIssueCode.custom,
        path: ['endDate'],
      });
    }
  });

export type EventExperienceSchemaType = z.infer<
  typeof eventExperienceValidationSchema
>;

/**
 * SKILLS SCHEMA VALIDATION AND SCHEMA TYPE FOR EDITING WORKER PROFILE SKILLS
 * this can be moved to react-app/src/types/worker.type.ts
 */
export const skillValidationSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(1, { message: 'Skill is required.' }),
});

export type SkillSchemaType = z.infer<typeof skillValidationSchema>;

export enum PersonType {
  WORKER = 'WORKER',
  COMPANY_MEMBER = 'COMPANY_MEMBER',
}

export interface NonPromoWorkExperiences {
  apiName: string;
  values: NonPromoWorkExperience[];
}

export interface NonPromoWorkExperience {
  id: number;
  brandRepresented: string;
  duties: string;
  hiringCompany: string;
  position: string;
  startDate: Date;
  endDate: Date;
}

/**
 * NON EVENT EXPERIENCE === NonPromoWorkExperience ?????
 */
export const nonEventExperienceValidationSchema = z
  .object({
    id: z.number().optional(),
    hiringCompany: z
      .string()
      .min(1, { message: 'Hiring company name is required.' }),
    position: z.string().min(1, { message: 'Job title is required.' }),
    duties: z.string().min(1, { message: 'Duties is required.' }),
    startDate: z
      .date({
        required_error: 'Start date is required.',
        invalid_type_error: 'Start date is required.',
      })
      .refine((date) => isPast(date) || isToday(date), {
        message: 'Start date must be in the past.',
      }),
    endDate: z
      .date()
      .optional()
      .nullable()
      .refine(
        (date) => {
          return date ? isPast(date) || isToday(date) : true;
        },
        {
          message: 'End date must be in the past.',
        }
      ),
  })
  .superRefine((values, context) => {
    const { startDate, endDate } = values;

    if (endDate && compareDesc(startDate, endDate) === -1) {
      context.addIssue({
        message: 'Start date must be before end date',
        code: z.ZodIssueCode.custom,
        path: ['startDate'],
      });
      context.addIssue({
        message: 'End date must be after start date',
        code: z.ZodIssueCode.custom,
        path: ['endDate'],
      });
    }
  });

export type NonEventExperienceSchemaType = z.infer<
  typeof nonEventExperienceValidationSchema
>;

export interface Educations {
  apiName: string;
  values: Education[];
}

export interface Education {
  id: number;
  degree: string;
  degreeDate: Date;
  university: string;
}

export const educationValidationSchema = z.object({
  id: z.number().optional(),
  degree: z
    .string()
    .min(1, { message: 'Type of Educational Degree is required.' }),
  degreeDate: z
    .date({ required_error: 'Degree date is required.' })
    .refine((date) => isPast(date) || isToday(date), {
      message: 'Degree date must be in the past.',
    }),
  university: z
    .string()
    .min(1, { message: 'School/University Name is required.' }),
});

export type EducationSchemaType = z.infer<typeof educationValidationSchema>;

export interface IWorkerEndorsementsData {
  totalEndorsements: number;
  totalAsManager: number;
  totalFromCompany: number;
  eventsNumbers?: EventsNumber[];
  eventTypes?: EventType[];
  personalityTraits?: PersonalityTrait[];
}

export interface EventsNumber {
  label: string;
  size?: number;
  percentage?: number;
  percentageRounded?: number;
}

export interface Endorsement {
  title: string;
  endorsers: Endorser[];
}

export type EventType = Endorsement;

export type PersonalityTrait = Endorsement;

export interface Endorser {
  endorserId: number;
  icon: string;
  endorserName: string;
  companyId?: number;
  companyName?: string;
  address?: string;
  isEndorsedBack?: boolean;
}

export interface Comment {
  comments: string;
  improvementComments?: string;
  avgRating: number;
  sharedEventsNumber: string;
  personType: PersonType;
  isEndorsedManager: boolean | null;
  companyId: number;
  companyName: string;
  authorName: string;
  type: string;
  authorId: number;
  dateCreated: Date;
  id: number;
  ratingVersion: number | null;
  profileImageUrl: string;
  workedDays: number;
  position?: string;
  visibility: IRatingVisibility;
  isSameCompany: boolean;
  noCallNoShowed?: boolean;
}
