import { Card, Col, Row } from 'react-bootstrap';
import { Comment } from '@th-types/workerProfile.type';
import { useWorkerProfileContext } from '@worker/state/workerProfileContext';
import RatingComment from './RatingComment/RatingComment';
import EndorsementComment from './EndorsementComment/EndorsementComment';

import './styles.scss';

function Reviews() {
  const { workerReviews } = useWorkerProfileContext();
  // TODO: - add MobileView and BrowserView separated components
  return (
    <Card className="p-3 reviews-and-endorsements">
      <Card.Title>Reviews & Endorsements</Card.Title>
      <Card.Text as="div">
        <Row>
          {workerReviews?.map((review: Comment) => (
            <Col key={review.id} md={6} className="p-4 my-3 comment">
              {review.type === 'rating' ? (
                <RatingComment comment={review} />
              ) : (
                <EndorsementComment comment={review} />
              )}
            </Col>
          ))}
        </Row>

        <p className="text-gray fst-italic mt-3">
          Let’s build up your reputation, this helps companies see a positive
          track record when you apply to their jobs! Ask fellow workers or
          companies who know you well to add an endorsement for you on Trusted
          Herd, or rate a company and ask them to review you back.
        </p>
      </Card.Text>
    </Card>
  );
}

export default Reviews;
