import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

interface Params {
  items: any;
}

function THCarousel({ items }: Params) {
  const renderPrevButton = () => {
    return (
      <FiChevronLeft
        style={{ position: 'absolute', left: -40, top: '33%' }}
        className="cursor-point"
        size={30}
      />
    );
  };

  const renderNextButton = () => {
    return (
      <FiChevronRight
        style={{ position: 'absolute', right: -40, top: '40%' }}
        className="cursor-point"
        size={30}
      />
    );
  };

  return (
    <div style={{ paddingLeft: 50, paddingRight: 50 }}>
      <AliceCarousel
        mouseTracking
        items={items}
        disableDotsControls
        autoWidth
        renderPrevButton={renderPrevButton}
        renderNextButton={renderNextButton}
        controlsStrategy="responsive"
      />
    </div>
  );
}

export default THCarousel;
