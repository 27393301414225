import Spinner from 'react-bootstrap/Spinner';
import './style.css';

export default function ThLoading() {
  return (
    <div className="th-loading" data-testid="th-loading">
      <Spinner
        animation="grow"
        variant="primary"
        className="position-absolute bottom-50 end-50"
      />
    </div>
  );
}
