import {
  GroupBase,
  SingleValue,
} from 'react-select/dist/declarations/src/types';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import IJobDetailData from '@th-types/job.detail.type';
import { useState } from 'react';
import ThJobSelectOption, { formatJobName } from './ThJobSelectOption';

interface Params extends StateManagerProps {
  isFetching: boolean;
  loadOptions: LoadOptions<IJobDetailData, GroupBase<IJobDetailData>, unknown>;
  handleChangeJob: (value: SingleValue<IJobDetailData>) => void;
}

function ThJobSelect({
  isFetching,
  loadOptions,
  handleChangeJob,
  ...props
}: Params) {
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<IJobDetailData>>();

  const handleChange = (newValue: SingleValue<IJobDetailData>) => {
    setSelectedOption(newValue);
    handleChangeJob(newValue);
  };

  return (
    <AsyncPaginate
      placeholder="Search posted jobs"
      inputId={props.name}
      name={props.name}
      required
      isMulti={false}
      isClearable
      closeMenuOnSelect
      isLoading={isFetching}
      value={selectedOption}
      defaultOptions
      loadOptions={loadOptions}
      onChange={handleChange}
      getOptionLabel={(e) => formatJobName(e)}
      getOptionValue={(e) => e.id.toString()}
      components={{ Option: ThJobSelectOption }}
    />
  );
}

export default ThJobSelect;
