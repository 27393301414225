import { useState } from 'react';
import { isEqual, sortBy } from 'lodash';
import { Alert, Button, Col, Form, Row, Stack, Image } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { Panel, ThLoading } from '@components/elements';
import { LoadPersonCircle } from '@components/elements/ComponentLoader';
import useAlert from '@hooks/useAlert';
import { workerRatingsAskForRatingCompany } from '@company/services/rating/api';
import IBatchRateWorkerSession, {
  IBatchInviteWorkers,
} from '@company/types/batch-rate-worker.type';
import defaultAvatar from '@assets/default-avatar.png';
import './style.css';

interface Props {
  batchRateWorkerSession?: IBatchRateWorkerSession;
  stepTitle?: string;
  isActive?: boolean;
}

function InviteRate({ batchRateWorkerSession, stepTitle, isActive }: Props) {
  const { handleSubmit } = useForm<IBatchInviteWorkers>({
    defaultValues: { ratingsIds: [] },
  });
  const { showErrorAlert, showSuccessAlert, alertMessage } = useAlert();
  const [loading, setLoading] = useState(false);
  const [isAllWorkersSelected, setIsAllWorkerSelected] = useState(false);
  const [workerRatingIds, setWorkerRatingIds] = useState<number[]>([]);

  const ratingIds =
    batchRateWorkerSession?.workers?.map(
      (worker) => worker.workerRating?.ratingId as number
    ) || [];

  const handleSelectAllWorkers = () => {
    if (isAllWorkersSelected) {
      setWorkerRatingIds([]);
    } else {
      setWorkerRatingIds(ratingIds);
    }
    setIsAllWorkerSelected(!isAllWorkersSelected);
  };

  const handleWorkerSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (workerRatingIds.includes(+event.target.id)) {
      setWorkerRatingIds((prevState) =>
        prevState.filter((ratingId) => ratingId !== +event.target.id)
      );
      setIsAllWorkerSelected(false);
    } else {
      const newState = [...workerRatingIds, +event.target.id];
      setWorkerRatingIds(newState);
      if (isEqual(sortBy(ratingIds), sortBy(newState))) {
        setIsAllWorkerSelected(true);
      }
    }
  };

  const backToDashboard = async () => {
    setTimeout(() => window.location.reload(), 1000);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const ratingIdsBody: IBatchInviteWorkers = {
        ratingsIds: workerRatingIds,
      };
      if (workerRatingIds.length > 0) {
        const result = await workerRatingsAskForRatingCompany(ratingIdsBody);
        if (result?.success) {
          showSuccessAlert('Workers successfully invited');
          await backToDashboard();
        }
      }
    } catch {
      showErrorAlert(
        'Occurred an error when Trusted Herd tried to invite workers to rate company'
      );
    } finally {
      setLoading(false);
    }
  };

  const onExit = () => {
    setLoading(true);
    try {
      backToDashboard();
    } finally {
      setLoading(true);
    }
  };

  return (
    <>
      {loading && <ThLoading />}
      <Row key={stepTitle} className={isActive ? 'd-block' : 'd-none'}>
        <Col md={{ span: 6, offset: 3 }}>
          <Panel className={isMobile ? 'shadow-none' : 'panel'}>
            <Form id="inviteWorkerToRate" onSubmit={handleSubmit(onSubmit)}>
              <Stack className="mb-3">
                <div className="fw-bold fs-5">Invite to Rate</div>
                <div className="mt-3 fw-bold">
                  Would you like to send an invite for them to review your
                  company?
                </div>
                <div className="mt-2">
                  Select which workers you would like to invite to review your
                  company.
                </div>
              </Stack>
              {batchRateWorkerSession?.workers &&
                batchRateWorkerSession?.workers.length > 1 && (
                  <Stack direction="horizontal" className="mb-2">
                    <Form.Check
                      inline
                      type="checkbox"
                      aria-label="ALL WORKERS"
                      name="allWorkersSelected"
                      checked={isAllWorkersSelected}
                      onChange={handleSelectAllWorkers}
                      className="fs-4"
                    />
                    <span className="small">ALL WORKERS</span>
                  </Stack>
                )}

              {batchRateWorkerSession?.workers?.map((worker) => (
                <Stack direction="horizontal" key={worker.id}>
                  <Form.Check
                    inline
                    type="checkbox"
                    className="fs-4"
                    aria-label={worker?.name}
                    disabled={worker?.workerRating?.assignment?.companyRated}
                    id={String(worker.workerRating?.ratingId)}
                    checked={workerRatingIds.includes(
                      Number(worker.workerRating?.ratingId)
                    )}
                    onChange={handleWorkerSelection}
                  />
                  {worker?.image ? (
                    <Image
                      width={40}
                      height={40}
                      src={worker.image || defaultAvatar}
                      roundedCircle
                    />
                  ) : (
                    <LoadPersonCircle width="40" height="40" />
                  )}
                  <p
                    className={`p-2 my-2 fw-bold ${
                      worker?.workerRating?.assignment?.companyRated
                        ? 'text-gray'
                        : ''
                    }`}
                  >
                    {worker?.name}
                  </p>
                  {worker?.workerRating?.assignment?.companyRated && (
                    <span>
                      <small className="text-gray">(already rated you)</small>
                    </span>
                  )}
                </Stack>
              ))}
            </Form>
          </Panel>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col md={{ span: 6, offset: 3 }}>
          <Stack
            direction="horizontal"
            className="d-flex justify-content-center"
            gap={3}
          >
            <Button
              type="button"
              variant="light"
              className={`fw-bold mt-4 ${isMobile ? 'w-50' : 'w-25'}`}
              onClick={onExit}
            >
              No thanks
            </Button>
            <Button
              type="submit"
              form="inviteWorkerToRate"
              className={`fw-bold mt-4 ${isMobile ? 'w-50' : 'w-auto'}`}
              disabled={loading}
            >
              {loading ? 'Submitting' : 'Request Rating'}
            </Button>
          </Stack>
        </Col>
      </Row>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default InviteRate;
