import {
  Badge,
  Card,
  Col,
  Form,
  Image,
  InputGroup,
  Row,
} from 'react-bootstrap';
import IConversationData from '@company/types/conversation.type';
import { isMobile } from 'react-device-detect';
import { Panel } from '@components/elements';
import { Chat } from '@components/Chat';
import { BiSearch } from 'react-icons/bi';
import { debounce } from 'lodash';
import { LoaderPersonCard } from '@components/elements/ComponentLoader';
import { useJobAssignmentStatus } from '@hooks/useJobAssignmentStatus';
import useOnScreen from '@hooks/useIntersectionObserver';
import { useEffect, useRef } from 'react';

interface Params {
  selectedConversation: IConversationData | null;
  setSelectedConversation: (defaultValue: IConversationData) => void;
  conversations?: IConversationData[];
  setWorkerNameFilter: (value: string) => void;
  isLoading: boolean;
  refetchConversations: () => void;
}

export default function Conversations({
  selectedConversation,
  setSelectedConversation,
  conversations,
  setWorkerNameFilter,
  isLoading,
  refetchConversations,
}: Params) {
  const handleTextSearch = debounce((value: string) => {
    setWorkerNameFilter(value);
  }, 800);
  const ref = useRef(null);
  const isOnScreen = useOnScreen(ref);

  const jobAssignmentStatus = useJobAssignmentStatus();
  const { isHiredOrApplicant } = jobAssignmentStatus(
    selectedConversation?.jobAssignmentStatus
  );

  function isReadOrSelected(
    conversation: IConversationData
  ): string | undefined {
    if (
      selectedConversation &&
      conversation.jobAssignmentId === selectedConversation.jobAssignmentId
    ) {
      return 'secondary';
    }
    if (conversation.unreadCount > 0) {
      return 'third';
    }
    return undefined;
  }

  useEffect(() => {
    if (isOnScreen) {
      refetchConversations();
    }
  }, [isOnScreen, refetchConversations]);

  return (
    <>
      <Row>
        <Col xs={12} className="pb-3">
          <b ref={ref}>Messaging</b>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Row className="pe-2 pb-3">
            <Col>
              <InputGroup>
                <InputGroup.Text>
                  <BiSearch size={22} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Filter conversation"
                  name="workerName"
                  onKeyUp={(e) =>
                    handleTextSearch((e.target as HTMLTextAreaElement).value)
                  }
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            {isLoading ? (
              <LoaderPersonCard />
            ) : (
              <div style={{ height: '80vh', overflow: 'auto' }}>
                {conversations?.map((conversation, index) => (
                  <Card
                    key={`message_conversation_${conversation.jobAssignmentId}`}
                    border={
                      conversation.jobAssignmentId ===
                      selectedConversation?.jobAssignmentId
                        ? 'primary'
                        : undefined
                    }
                    bg={isReadOrSelected(conversation)}
                    className={`${index !== 0 && 'mt-3'} p-2 pe-4 cursor-point`}
                    onClick={() => setSelectedConversation(conversation)}
                  >
                    <Row>
                      <Col md={3}>
                        <Image
                          rounded
                          style={{ width: '100%' }}
                          src={conversation.worker.profileImageUrl}
                        />
                      </Col>
                      <Col md={9}>
                        <Row>
                          <Col>
                            <b>
                              {conversation.worker.firstName}{' '}
                              {conversation.worker.lastName}
                              {conversation.unreadCount > 0 &&
                                ` (${conversation.unreadCount})`}
                            </b>
                          </Col>
                        </Row>
                        <Row>
                          {/* TODO here we will put the Badges */}
                          <Col>
                            <Badge className="text-dark" bg="light">
                              {conversation.job.jobTitle}
                            </Badge>
                          </Col>
                        </Row>
                        <Row className="mt-1">
                          <Col>{conversation.message}</Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </div>
            )}
          </Row>
        </Col>
        <Col md={isMobile ? 12 : 8}>
          <Panel
            style={{
              height: selectedConversation ? '80vh' : '',
              overflow: 'auto',
            }}
          >
            {selectedConversation ? (
              <Chat
                showWorkerInfo={isHiredOrApplicant()}
                worker={selectedConversation.worker}
              />
            ) : (
              'No messages/conversations selected.'
            )}
          </Panel>
        </Col>
      </Row>
    </>
  );
}
