import { privateApi } from '@services/api';
import { IThJobAssignmentData } from '@worker/types/th-job-assignment.type';

export const WORKER_JOBS_URL = 'worker/jobs';

export const getJobAssignment = async (id: number) => {
  const result = await privateApi.get<IThJobAssignmentData>(
    `${WORKER_JOBS_URL}/${id}`
  );
  return result.data;
};
