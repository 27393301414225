import { FilterType } from '@constants/jobFilterType';

export default interface IFilterData {
  jobId?: number;
  filterType: FilterType;
  address?: string;
  addressLatitude?: number;
  addressLongitude?: number;
  workerStatus?: string;
  miles?: number | string;
  name?: string;
  email?: string;
  filterWorkers: string;
  badges?: number[];
  countries?: string[];
  eventTypes?: string[];
  gender?: string;
  profileStatus?: number;
  skills?: number[];
  traits?: string[];
  travelNationally?: string;
  travelLonger?: string;
  sortBy?: ISortData;
  sortOrder?: ISortOrder;
}

export enum ISortData {
  NAME = 'NAME',
}

export enum ISortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}
