import { GrLocation } from '@react-icons/all-files/gr/GrLocation';
import { isMobile } from 'react-device-detect';
import Avatar from 'react-avatar';
import { Col, Row } from 'react-bootstrap';

export const formatOptionLabel = ({
  avatar,
  label,
  location,
}: {
  avatar: string;
  label: string;
  location: string;
}) => {
  return !isMobile ? (
    <Row>
      <Col md={6}>
        <Avatar size="30" round src={avatar} className="me-2" />
        <span className="fw-bold">{label}</span>
      </Col>

      <Col className="align-items-sm-end">
        <GrLocation className="me-1" />
        {location}
      </Col>
    </Row>
  ) : (
    <div
      className="d-flex"
      style={{
        alignItems: 'center',
      }}
    >
      <div>
        <Avatar size="30" round src={avatar} className="me-2" />
      </div>
      <div>
        <span className="fw-bold">{label}</span>
        <div>
          <GrLocation className="me-1" />
          {location}
        </div>
      </div>
    </div>
  );
};
