import { useState } from 'react';
import { Stack } from 'react-bootstrap';
import { HiArrowLeft } from 'react-icons/hi';
import ModalConfirm from '@components/elements/ModalConfirm/ModalConfirm';
import IModalConfirmModel from '@th-types/confirm-model.type';

interface Props {
  confirmation: () => void;
  showTitle?: boolean;
}

function BackToDashboard({ confirmation, showTitle = true }: Props) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalConfirmModel, setModalConfirmModel] =
    useState<IModalConfirmModel>();

  const handleBackNavigation = () => {
    setModalConfirmModel({
      message: (
        <div className="text-center">
          <p>You&apos;ll lose all the gig info entered</p>
        </div>
      ),
      titleMessage: (
        <div>
          <span>Are you sure?</span>
        </div>
      ),
      confirmFunction: confirmation,
      show: showConfirmModal,
    });
    setShowConfirmModal(true);
  };

  return (
    <>
      {showConfirmModal && modalConfirmModel && (
        <ModalConfirm
          modalConfirmModel={modalConfirmModel}
          setShowModal={setShowConfirmModal}
        />
      )}
      <Stack
        direction="horizontal"
        gap={2}
        className="cursor-point"
        onClick={handleBackNavigation}
      >
        <HiArrowLeft size={20} />
        {showTitle && <b>Back to Dashboard</b>}
      </Stack>
    </>
  );
}

export default BackToDashboard;
