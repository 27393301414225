import { Form } from 'react-bootstrap';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import IBatchRateWorkerSession, {
  IBatchRateWorker,
} from '@company/types/batch-rate-worker.type';
import { REQUIRED_FIELD_MESSAGE } from '@constants/validation';

interface Props {
  worker: IBatchRateWorker;
  errors: FieldErrors<IBatchRateWorkerSession>;
  register: UseFormRegister<IBatchRateWorkerSession>;
}
function OtherJobTitle({ worker, errors, register }: Props) {
  return (
    <Form.Group
      controlId={`workers.${worker.id}.workerRating.otherJobTitleDescription`}
    >
      <Form.Label className="fw-bold">Other*</Form.Label>
      <Form.Control
        placeholder="Enter Other Job Position"
        className="mt-2"
        {...register(
          `workers.${worker.id}.workerRating.otherJobTitleDescription`,
          {
            required: REQUIRED_FIELD_MESSAGE,
          }
        )}
        required
        autoComplete="off"
        isInvalid={
          errors?.workers &&
          !!errors?.workers[worker.id]?.workerRating?.otherJobTitleDescription
        }
      />
      {errors?.workers &&
        !!errors?.workers[worker.id]?.workerRating
          ?.otherJobTitleDescription && (
          <Form.Control.Feedback type="invalid">
            {
              errors?.workers[worker.id]?.workerRating?.otherJobTitleDescription
                ?.message
            }
          </Form.Control.Feedback>
        )}
    </Form.Group>
  );
}

export default OtherJobTitle;
