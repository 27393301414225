import { useJobShiftContext } from '@company/state/jobShiftContext';
import { useJobContext } from '@company/state/jobContext';
import { getFormattedTime } from '@services/shift/shiftService';
import { JobAssignment } from '@th-types/job-assignment.type';
import { BiEdit } from '@react-icons/all-files/bi/BiEdit';
import { ShiftPosition } from '@th-types/job.detail.type';
import { Alert, Button, Form } from 'react-bootstrap';
import { toNatural } from '@utils/StringUtils';
import { isMobile } from 'react-device-detect';
import useAlert from '@hooks/useAlert';
import Table from 'react-bootstrap/Table';
import { useId, useState } from 'react';
import { capitalize } from 'lodash';
import { CreateShiftModal } from '@company/pages/JobOverview/Scheduling/CreateShift';
import './style.scss';

interface ShiftTableProps {
  date: string;
  shifts: ShiftPosition[];
  handleSelecting: (id: number) => void;
  openWorkerSideModal?: (jobAssignment: JobAssignment) => void;
  isSelectable: boolean;
  showResumed?: boolean;
  isEditable?: boolean;
}

function ShiftTable({
  date,
  shifts,
  handleSelecting,
  openWorkerSideModal,
  isSelectable,
  showResumed,
  isEditable,
}: ShiftTableProps) {
  const [showModal, setShowModal] = useState(false);
  const [shiftSelected, setShiftSelected] = useState<ShiftPosition | undefined>(
    undefined
  );
  const handleShow = (shift: ShiftPosition) => {
    setShiftSelected(shift);
    setShowModal(true);
  };
  const handleClose = () => {
    setShiftSelected(undefined);
    setShowModal(false);
  };
  const { selectedShifts } = useJobShiftContext();
  const { job, jobTimezone } = useJobContext();
  const { showSuccessAlert, alertMessage } = useAlert();
  const divId = useId();
  const jobKey = useId();

  const shiftCreatedCloseModal = (message?: string) => {
    showSuccessAlert(
      message ?? `Job shift${isMobile ? 's' : ''} successfully created`
    );
    handleClose();
  };

  if (!job) {
    return <div />;
  }

  return (
    <div key={divId}>
      <div className="table-date fw-bold">{date}</div>
      <hr className="table-horizontal-line" />
      <Table responsive borderless>
        <thead className="table-header fw-bold">
          <tr>
            {isSelectable && (
              <th style={{ width: '3%' }} aria-label="checkbox" />
            )}
            <th style={{ width: '21%' }} className="text-start">
              Position
            </th>
            {!showResumed && <th style={{ width: '22%' }}>Worker</th>}
            {!showResumed && <th style={{ width: '7%' }}>Status</th>}
            <th style={{ width: '15%' }}>Scheduled Shift Time</th>
            <th style={{ width: '10%' }}>Full/Back Up</th>
            <th style={{ width: '10%' }}>Wage</th>
            <th style={{ width: '10%' }}>Paid Break</th>
            {isEditable && <th style={{ width: '2%' }} aria-label="edit" />}
          </tr>
        </thead>
        <tbody className="table-body">
          {shifts?.map((shift) => (
            <tr key={`${jobKey}-${shift.id}-${shift.positionIndex}`}>
              {isSelectable && (
                <td>
                  {/* THIS TABLE IS IN SCHEDULING TAB */}
                  <Form.Check
                    type="checkbox"
                    id={`checkbox-${shift.id}`}
                    checked={
                      selectedShifts &&
                      selectedShifts.some((x) => x.id === shift.id)
                    }
                    onChange={() => handleSelecting(shift.id)}
                  />
                </td>
              )}
              <td className="text-start">{shift.title.name}</td>
              {!showResumed && (
                <td
                  className={
                    shift.jobAssignment ? '' : 'fst-italic table-data__worker'
                  }
                >
                  {shift.jobAssignment?.worker ? (
                    <Button
                      variant="link"
                      size="sm"
                      className="text-capitalize"
                      onClick={() => {
                        if (openWorkerSideModal) {
                          openWorkerSideModal(shift.jobAssignment!);
                        }
                      }}
                    >
                      {shift.jobAssignment.worker.fullName}
                    </Button>
                  ) : (
                    'Unassigned'
                  )}
                </td>
              )}
              {!showResumed && (
                <td
                  className={`fw-bold status_colors status_colors__${shift.shiftStatus}`}
                >
                  {toNatural(shift.shiftStatus)}
                </td>
              )}
              <td>{getFormattedTime(shift, jobTimezone)}</td>
              <td>{capitalize(shift.type)}</td>
              <td>{`$${shift.wage.payRate}/${capitalize(
                shift.wage.payRatePeriod
              )}`}</td>
              <td>{shift.breakPaid ? 'Yes' : 'No'}</td>
              {isEditable && (
                <td className="text-end">
                  <BiEdit
                    color="var(--black)"
                    className="cursor-point"
                    onClick={() => handleShow(shift)}
                    size={24}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <hr className="table-horizontal-line" />
      {showModal && shiftSelected && (
        <CreateShiftModal
          job={job}
          shift={shiftSelected}
          handleClose={handleClose}
          shiftCreatedCloseModal={shiftCreatedCloseModal}
        />
      )}
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </div>
  );
}

export default ShiftTable;
