import Body from '@company/components/layout/Body';

function Dashboard() {
  return (
    <Body>
      <h1>Dashboard</h1>
    </Body>
  );
}

export default Dashboard;
