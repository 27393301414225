const DATE_FORMATS = {
  DATE_FORMAT: 'MM/dd/yyyy',
  MONTH_YEAR_FORMAT: 'MM/yyyy',
  DATE_FORMAT_SHORT: 'MM/dd/yy',
  DATE_TIME_FORMAT: 'MM/dd/yyyy hh:mm a',
  DATE_TIME_FORMAT_24H: 'MM/dd/yyyy HH:mm',
  SHORT_DATE_FORMAT: 'MMM d',
  MONTH_AND_DAY: 'MM/dd',
  TIME_FORMAT: 'h:mm a',
  TIME_FORMAT_24H: 'HH:mm',
  DATEPICKER_FORMAT: 'MM/DD/YYYY',
  ISO8601_FORMAT: "yyyy-MM-dd'T'HH:mm:ss'Z'",
  ISO8601_DATE_ONLY_FORMAT: 'yyyy-MM-dd',
  DEFAULT_TIME_ZONE: 'America/Chicago',
  DATE_FORMAT_MONTH_NAME: 'MMMM d, YYYY',
  DATE_TIME_FORMAT_ATTACHMENT: 'MM-dd-yyyy-hh-mm-a',
  LIST_BY_DATE_FORMAT: 'MMMM d, yyyy',
  DAY_MONTH_DATE_FORMAT: 'EEEE, MMMM dd',
  DASH_DATE_FORMAT: 'MM-dd-yyyy',
  CALENDAR_DAY_FORMAT: 'EEE dd',
  JOB_DESCRIPTION_DAY_FORMAT: 'dd MMM',
  SURVEY_CURRENT_DATE_FORMAT: 'd MMMM',
};

export default DATE_FORMATS;
