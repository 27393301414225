import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Endorsement } from '@th-types/workerProfile.type';
import EndorsementImageList from './EndorsementImageList';

interface Params {
  endorsements: Endorsement[];
}

const displayed = 3;

export default function EndorsementList({ endorsements }: Params) {
  const [showMore, setShowMore] = useState(displayed);

  return (
    <Col xs={12} className="mt-3">
      <Row>
        {endorsements.slice(0, showMore).map((endorsement) => (
          <Col key={endorsement.title} md={4} xs={12} className="mb-3">
            <span>{endorsement.title}</span>
            <EndorsementImageList endorsers={endorsement.endorsers} />
          </Col>
        ))}
      </Row>
      {endorsements.length > displayed && (
        <Row>
          {showMore === displayed ? (
            <span
              onClick={() => setShowMore(endorsements.length)}
              className="cursor-point"
            >
              <b>View more ({endorsements.length - displayed})</b>
            </span>
          ) : (
            <span
              onClick={() => setShowMore(displayed)}
              className="cursor-point"
            >
              <b>View less</b>
            </span>
          )}
        </Row>
      )}
    </Col>
  );
}
