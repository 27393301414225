import { useJobContext } from '@company/state/jobContext';
import { ThTabs } from '@company/components/th';
import { ApplicantsType } from '@constants/applicantsType';
import { Tab } from 'react-bootstrap';
import { ReactElement } from 'react';
import {
  fetchApplicantAppliedList,
  fetchApplicantHiddenList,
  fetchApplicantHiredList,
  fetchApplicantOfferedList,
} from '@company/services/application/api';
import ListApplicants from './ListApplicants';

export default function HiresAndCandidates(): ReactElement {
  const {
    job,
    activeJobId,
    hiredCount,
    hiddenCount,
    appliedCount,
    offeredCount,
    applicantFilters,
    key: contextKey,
    setKey,
  } = useJobContext();

  const fetchHiredWorkers = async (page: number = 0) => {
    delete applicantFilters.sortBy;
    return await fetchApplicantHiredList(activeJobId, applicantFilters, page);
  };

  const fetchOfferedWorkers = async (page: number = 0) => {
    delete applicantFilters.sortBy;
    return await fetchApplicantOfferedList(activeJobId, applicantFilters, page);
  };

  const fetchAppliedWorkers = async (page: number = 0) => {
    return await fetchApplicantAppliedList(activeJobId, applicantFilters, page);
  };

  const fetchHiddenWorkers = async (page: number = 0) => {
    delete applicantFilters.sortBy;
    return await fetchApplicantHiddenList(activeJobId, applicantFilters, page);
  };

  const tabSelectCallback = (eventKey: string | null) => {
    setKey(eventKey as ApplicantsType);
  };

  return (
    <ThTabs fill activeKey={contextKey} onSelect={tabSelectCallback}>
      <Tab
        eventKey={ApplicantsType.HIRED}
        title={`Hired (${hiredCount}/${job?.totalPositions})`}
      >
        <ListApplicants
          tabType={ApplicantsType.HIRED}
          queryFn={fetchHiredWorkers}
        />
      </Tab>
      <Tab
        eventKey={ApplicantsType.OFFERED}
        title={`Offered (${offeredCount})`}
      >
        <ListApplicants
          tabType={ApplicantsType.OFFERED}
          queryFn={fetchOfferedWorkers}
        />
      </Tab>
      <Tab
        eventKey={ApplicantsType.APPLIED}
        title={`Applied (${appliedCount})`}
      >
        <ListApplicants
          tabType={ApplicantsType.APPLIED}
          queryFn={fetchAppliedWorkers}
        />
      </Tab>
      <Tab eventKey={ApplicantsType.HIDDEN} title={`Hidden (${hiddenCount})`}>
        <ListApplicants
          tabType={ApplicantsType.HIDDEN}
          queryFn={fetchHiddenWorkers}
        />
      </Tab>
    </ThTabs>
  );
}
