import { Button } from 'react-bootstrap';

interface DisabledLayerProps {
  title: string;
  body: string;
  buttonText: string;
  onButtonClick: () => void;
}

function DisabledLayer({
  title,
  body,
  buttonText,
  onButtonClick,
}: DisabledLayerProps) {
  return (
    <div className="disabled-layer">
      <div className="disabled-layer-content">
        <h3>{title}</h3>
        <p>{body}</p>
        <Button variant="light" onClick={onButtonClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

export default DisabledLayer;
