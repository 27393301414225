import { Carousel, Image, Modal } from 'react-bootstrap';

import './styles.css';

interface MediaGalleryProps {
  showGallery: boolean;
  sources: string[];
  srcIndex?: number;
  onClose: () => void;
}

function MediaGallery({
  showGallery,
  sources,
  onClose,
  srcIndex,
}: MediaGalleryProps) {
  return (
    <Modal show={showGallery} onHide={onClose} className="media-gallery-modal">
      <Modal.Body>
        <Carousel defaultActiveIndex={srcIndex} interval={null}>
          {sources?.map((src) => (
            <Carousel.Item key={src}>
              <div className="img-container">
                <Image src={src} rounded />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
}

export default MediaGallery;
