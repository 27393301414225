import { useParams } from 'react-router-dom';
import { Col, Row, Stack } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ReviewWorkerForm from '@company/components/ReviewSidePopup/ReviewWorkerForm/ReviewWorkerForm';
import ReceivedReview from '@company/components/ReviewSidePopup/ReceivedReview/ReceivedReview';
import { JobAssignmentRatingType } from '@company/types/job-assignment-rating.type';
import { getJobRatingByJobAssignment } from '@company/services/rating/api';
import { IWorkerAssignment } from '@company/types/workerAssignment';
import * as workerApi from '@company/services/worker/api';
import QueryKeys from '@constants/queryKeys';
import ThLoading from '@components/elements/ThLoading';
import React from 'react';

import './styles.scss';

interface Props {
  isModal?: boolean;
}

function LeaveSingeReview({ isModal = false }: Props) {
  const { jobAssignmentId } = useParams();

  const { data: jobAssignmentData, status: assignmentLoadingStatus } = useQuery<
    IWorkerAssignment,
    AxiosError
  >({
    queryKey: [QueryKeys.WORKER_ASSIGNMENT_JOB, jobAssignmentId],
    queryFn: () =>
      workerApi.getWorkerAssignment(
        Number.parseInt(jobAssignmentId ?? '-1', 10)
      ),
  });

  const { data: ratingAssignmentData } = useQuery<
    JobAssignmentRatingType,
    AxiosError
  >({
    queryKey: [
      QueryKeys.WORKER_ASSIGNMENT_JOB,
      jobAssignmentData?.thJobAssignment.id,
    ],
    queryFn: () =>
      getJobRatingByJobAssignment(jobAssignmentData?.thJobAssignment.id || 0),
    enabled: jobAssignmentData?.thJobAssignment.id !== undefined,
  });

  const onCloseReviewForm = () => {
    window.top?.postMessage('onFormSubmitted', '*');
  };

  if (assignmentLoadingStatus !== 'success') {
    return <ThLoading />;
  }
  if (jobAssignmentData.thJobAssignment.workerRated) {
    setTimeout(() => {
      window.top?.postMessage('onWorkerAlreadyRated', '*');
    }, 2000);
    return (
      <div className="already-rated">
        <strong className="fs-2"> Already Rated </strong>
        <br /> Closing ...
      </div>
    );
  }

  return (
    <Row className="single-review-page">
      {ratingAssignmentData && (
        <ReceivedReview
          rating={ratingAssignmentData}
          workedDates={jobAssignmentData.thJobAssignment.datesWorked}
          worker={jobAssignmentData.thJobAssignment.worker}
          isModal={isModal}
        />
      )}
      <Col>
        <Stack style={{ maxWidth: '650px' }}>
          <span
            className={`fw-bold fs-5 review-worker-title ${
              isModal ? 'p-4' : 'mb-3'
            }`}
          >
            Review this Worker
          </span>
          <ReviewWorkerForm
            worker={jobAssignmentData.thJobAssignment.worker}
            jobAssignment={jobAssignmentData.thJobAssignment!}
            onSuccess={onCloseReviewForm}
            isModal={isModal}
          />
        </Stack>
      </Col>
    </Row>
  );
}

export default LeaveSingeReview;
