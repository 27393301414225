import { Route, Routes, useLocation } from 'react-router-dom';
import DashboardWorker from '@worker/pages/DashboardWorker';
import { isDev, isProduction } from '@utils/Environment';
import { LoginRedirect, ThLoading } from '@components/elements';
import { googleManager } from '@utils/GoogleTagManager';
import { useEffect } from 'react';
import NotFound from '@components/elements/NotFound';
import { isMobile } from 'react-device-detect';
import { LeaveReview } from '@worker/pages/LeaveReview';
import WorkerProfilePage from '@worker/pages/WorkerProfilePage/WorkerProfilePage';
import Survey from '@worker/pages/Survey/Survey';
import SurveySuccess from '@worker/pages/Survey/SurveySuccess';
import SurveyFailure from '@worker/pages/Survey/SurveyFailure';
import SurveyMobile from '@worker/pages/SurveyMobile/SurveyMobile';
import { useWorkerProfileContext } from '@worker/state/workerProfileContext';
import THNavbar from '@worker/components/layout/THNavbar/THNavbar';
import { SurveyProvider } from '@worker/state/surveyContext';

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

export default function WorkerRoutes() {
  const { isLoadingWorkerProfile, surveysEnabled } = useWorkerProfileContext();
  const location = useLocation();

  useEffect(() => {
    if (isProduction() && location?.pathname !== undefined) {
      googleManager.page({
        url: location.pathname,
      });
    }
  }, [location.pathname]);

  if (isLoadingWorkerProfile) {
    return <ThLoading />;
  }

  return (
    <>
      <THNavbar />
      <Routes>
        <>
          {isDev() && (
            <Route path={`${BASE_PATH}`} element={<DashboardWorker />} />
          )}
          <Route path={`${BASE_PATH}login`} element={<LoginRedirect />} />
          <Route
            path={`${BASE_PATH}reviews/:jobAssignmentId?`}
            element={<LeaveReview />}
          />
          {surveysEnabled && (
            <Route
              path={`${BASE_PATH}survey`}
              element={
                <SurveyProvider>
                  {isMobile ? <SurveyMobile /> : <Survey />}
                </SurveyProvider>
              }
            />
          )}
          <Route
            path={`${BASE_PATH}survey/success`}
            element={<SurveySuccess />}
          />
          <Route
            path={`${BASE_PATH}survey/failure`}
            element={<SurveyFailure />}
          />
          <Route
            path={`${BASE_PATH}workerProfile/view`}
            element={<WorkerProfilePage />}
          />
          <Route path="*" element={<NotFound />} />
        </>
      </Routes>
    </>
  );
}
