import { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, Stack } from 'react-bootstrap';
import { AxiosError } from 'axios';
import { NOT_ACCEPTABLE } from '@constants/httpStatus';
import useAlert from '@hooks/useAlert';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { ThLoading } from '@components/elements';
import { calculateRemainingTime } from '@utils/DateUtils';
import { getSurveyLink } from '@worker/services/survey/api';
import { useSurveyContext } from '@worker/state/surveyContext';
import DisabledLayer from '@worker/pages/Survey/DisabledLayer';
import { lastClickTime } from '@worker/pages/Survey/constants';
import SurveyList from '@worker/pages/Survey/SurveyList';
import SurveyBank from './SurveyBank';
import '@worker/pages/Survey/styles.scss';

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

function SurveyMobile() {
  const [surveyAttemptLimit, setSurveyAttemptLimit] = useState(false);
  const [isDisabledLocationLayerVisible, setIsDisabledLocationLayerVisible] =
    useState(false);
  const [
    isDisabledLimitReachedLayerVisible,
    setIsDisabledLimitReachedLayerVisible,
  ] = useState(false);
  const {
    error,
    errorProfile,
    isLoading,
    isLoadingProfile,
    isRefetching,
    profileData,
    setStartSurveyDisabled,
    startSurveyDisabled,
    surveyHistory,
  } = useSurveyContext();
  const { showWarningAlert, alertMessage } = useAlert();

  useEffect(() => {
    // Check if the error indicates a 406 status and show the layer
    if (profileData?.totalSurveysReached) {
      setIsDisabledLimitReachedLayerVisible(true);
    } else {
      setIsDisabledLimitReachedLayerVisible(false);
    }
    if (errorProfile?.response?.status === NOT_ACCEPTABLE) {
      setIsDisabledLocationLayerVisible(true);
    } else {
      setIsDisabledLocationLayerVisible(false);
    }
    setSurveyAttemptLimit(
      !!profileData?.dailyAttemptsReached ||
        !!profileData?.hourlyAttemptsReached
    );
  }, [errorProfile, profileData]);

  const fetchSurveyLink = async () => {
    try {
      setStartSurveyDisabled(true);
      localStorage.setItem('lastSurveyStartButtonClick', Date.now().toString());
      const result = await getSurveyLink();
      if (result?.link) {
        window.location.href = result.link;
      }
      // re-enable the button after 15 seconds
      setTimeout(() => setStartSurveyDisabled(false), 15000);
    } catch (err) {
      let errorMessage;
      if (err instanceof AxiosError) {
        if (
          err.response &&
          err.response.data &&
          'message' in err.response.data
        ) {
          errorMessage = err.response.data.message;
        } else {
          errorMessage = 'Unknown error';
        }
      } else if (err instanceof Error) {
        errorMessage = err.message;
      } else {
        errorMessage = 'Unknown error';
      }
      console.error('Error getting link:', errorMessage);
      showWarningAlert(errorMessage);
    }
  };

  useEffect(() => {
    if (lastClickTime) {
      const timeSinceLastClick = Date.now() - parseInt(lastClickTime, 10);
      if (timeSinceLastClick < 15000) {
        setStartSurveyDisabled(true);
        if (!errorProfile) {
          // Set a timeout to re-enable the button after the remaining time
          setTimeout(
            () => setStartSurveyDisabled(false),
            15000 - timeSinceLastClick
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToHomePage = () => {
    window.location.href = SERVER_URL;
  };

  const hasProfileData = Object.keys(profileData?.profileData || {}).length > 0;

  if (isLoading && !surveyHistory && isRefetching) {
    return <ThLoading />;
  }

  return (
    <>
      {isDisabledLocationLayerVisible && (
        <DisabledLayer
          title="Currently not available in your area!"
          body="Surveys will be available for our Canadian members starting in early 2025."
          buttonText="Go to Dashboard"
          onButtonClick={goToHomePage}
        />
      )}
      {isDisabledLimitReachedLayerVisible && (
        <DisabledLayer
          title="Daily survey limit reached!"
          body="You have reached the daily survey limit. Please try again tomorrow."
          buttonText="Go to Dashboard"
          onButtonClick={goToHomePage}
        />
      )}
      <Stack className="survey-section-mobile" gap={3}>
        <div>
          <h3 className="survey-title fw-bold m-0">
            <span className="survey-title">Earn Money</span>
          </h3>
          <span className="fs-2 fw-bold">Taking Online Surveys</span>
        </div>
        <Card className="survey-bank">
          <Card.Text className="p-2">
            <SurveyBank
              surveyAttemptLimit={surveyAttemptLimit}
              fetchSurveyLink={fetchSurveyLink}
            />
          </Card.Text>
        </Card>
        <p className="body-text m-0">
          In our quest to bring you more opportunities to{' '}
          <b>earn money on Trusted Herd</b>, we’re working with leaders of the
          online survey industry to do just that! While Trusted Herd is not
          responsible for the content of the surveys, nor do we facilitate them,
          each completed survey can be worth <b>$0.50</b> to <b>$5</b> if you
          match the persona they’re seeking.
        </p>
        <b className="mt-2">
          <i className="fs-6">
            Start by filling out the initial questionnaire to get matched with
            the right surveys.
          </i>
        </b>

        <Button
          className=""
          onClick={fetchSurveyLink}
          disabled={
            isLoadingProfile || surveyAttemptLimit || startSurveyDisabled
          }
        >
          <b>Go to Surveys!</b>
        </Button>
        {error && (
          <p className="ms-4 mt-2 text-danger">
            Survey service is not available. Please try again later.
          </p>
        )}
        {profileData?.dailyAttemptsReached && (
          <p className="ms-4 mt-2 text-danger">
            Please try again later, you can only complete 50 surveys in a
            rolling 24 hour period. Please retry in{' '}
            {calculateRemainingTime(profileData.lastSurveyTimeDay)}.
          </p>
        )}
        {!profileData?.dailyAttemptsReached &&
          profileData?.hourlyAttemptsReached && (
            <p className="ms-4 mt-2 text-danger">
              Please try again later, you can only complete 15 surveys in a
              rolling 60 minute period. Retry in{' '}
              {calculateRemainingTime(profileData.lastSurveyTimeHour)}.
            </p>
          )}
        <Stack direction="horizontal" className="justify-content-between">
          <b className="fs-4">Initial Questionnaire</b>
          {hasProfileData && (
            <Badge
              bg="light"
              style={{ color: 'var(--green)' }}
              className="d-flex align-items-center gap-2"
            >
              <IoMdCheckmarkCircleOutline fill="var(--green)" size={20} />
              Completed
            </Badge>
          )}
        </Stack>
        <p>
          To improve your survey experience, we store profiling data. If you
          wish to opt-out, please reach out to us at:{' '}
          <b>
            <a href="mailito:support@trustedherd.com">
              support@trustedherd.com
            </a>
          </b>
          .
        </p>
        <b className="fs-4">Available Survey Opportunities</b>
        <p className="m-0">
          Take time to truthfully answer questions. Since each survey looks for
          a certain persona, not every attempt will be successful, keep trying!
        </p>
        <b>
          <i className="th-fs-14">
            Sometimes companies who initiate the survey can disqualify your
            survey due to various reasons based on responses.
          </i>
        </b>
        <Button
          className=""
          onClick={fetchSurveyLink}
          disabled={
            isLoadingProfile || surveyAttemptLimit || startSurveyDisabled
          }
        >
          <b>Go to Surveys!</b>
        </Button>

        <div>
          <h3 className="title-section">
            Completed Surveys, Status & Redemptions
          </h3>
          <p>
            Surveys can take the facilitator up to 60 days to approve. Once
            approved you can instantly redeem for 200+ online gift cards to well
            known businesses, including Apple, Costco, Disney, and more, or
            redeem for Trusted Herd In-Network membership! You must have at
            least $5 in your survey bank to redeem.
          </p>
          <SurveyList />
        </div>
      </Stack>

      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed alert-shift-create"
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default SurveyMobile;
