import { InitialSearchState } from '@constants/jobInitialSearch';
import * as api from '@company/services/job/api';
import { ApplicantsType } from '@constants/applicantsType';
import IJobDetailData from '@th-types/job.detail.type';
import DATE_FORMATS from '@constants/dateFormat';
import { UTC_TIMEZONE } from '@utils/DateUtils';
import QueryKeys from '@constants/queryKeys';
import { AxiosError } from 'axios';
import {
  QueryObserverResult,
  RefetchOptions,
  useQuery,
} from '@tanstack/react-query';
import {
  getJobFormattedLocalEndDate,
  getJobFormattedLocalStartDate,
} from '@utils/Job';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { fetchApplicationCounters } from '@company/services/application/api';
import ApplicantFilter from '@company/types/applicant-filter.type';

interface IJobContext {
  jobTimezone: string;
  newHired: boolean;
  hiredCount: number;
  isLoading?: boolean;
  key: ApplicantsType;
  hiddenCount: number;
  newOffered: boolean;
  activeJobId: number;
  offeredCount: number;
  appliedCount: number;
  openPositions: number;
  job: IJobDetailData | undefined;
  initialSearch: InitialSearchState;
  applicantFilters: ApplicantFilter;
  error?: AxiosError<unknown, any> | null;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<IJobDetailData, AxiosError<unknown, any>>>;
  getJobYear: () => string;
  setJobParamId(id: string): void;
  setActiveJobId(id: number): void;
  fetchApplicantCounters: () => void;
  setNewHired: (value: boolean) => void;
  setHiredCount: (value: number) => void;
  setHiddenCount: (value: number) => void;
  setNewOffered: (value: boolean) => void;
  setKey: (newKey: ApplicantsType) => void;
  setOfferedCount: (value: number) => void;
  setAppliedCount: (value: number) => void;
  getLocalEndDate: (format?: string) => string;
  getLocalStartDate: (format?: string) => string;
  isInternalMember: (memberId: number) => boolean;
  setApplicantFilters: (filter: ApplicantFilter) => void;
  setInitialSearch: (initialSearchState: InitialSearchState) => void;
}

export const initialApplicantFilters = {
  order: 'desc',
  sortBy: '',
  gender: [],
  country: [],
  database: [],
  nameOrEmail: '',
  willingToTravel: undefined,
  travelLongerPeriod: undefined,
};

const JobContext: IJobContext | any = createContext({});

interface ProviderProps {
  children: React.ReactNode;
}

export function JobProvider({ children }: ProviderProps) {
  const [activeJobId, setActiveJobId] = useState<number>(0);

  const setJobParamId = useCallback((rawParamId: string) => {
    const sanitizedId = rawParamId ? rawParamId.split('--').pop() : '';
    const parsedId = sanitizedId ? parseInt(sanitizedId, 10) : 0;
    setActiveJobId(parsedId);
  }, []);

  const {
    isLoading,
    error,
    data: job,
    refetch,
  } = useQuery<IJobDetailData, AxiosError, IJobDetailData>({
    queryKey: [`${QueryKeys.COMPANY_JOB_DETAIL}_${activeJobId}`, activeJobId], // add activeJobId as a dependency
    queryFn: () => api.fetchJobDetail(activeJobId),
    enabled: activeJobId > 0,
  });

  const [key, setKey] = useState<ApplicantsType>(ApplicantsType.APPLIED);
  const [hiredCount, setHiredCount] = useState(0);
  const [offeredCount, setOfferedCount] = useState(0);
  const [appliedCount, setAppliedCount] = useState(0);
  const [hiddenCount, setHiddenCount] = useState(0);
  const [openPositions, setOpenPositions] = useState(0);
  const [newHired, setNewHired] = useState(false);
  const [newOffered, setNewOffered] = useState(false);
  const [initialSearch, setInitialSearch] = useState<InitialSearchState>(
    InitialSearchState.NOT_STARTED
  );
  const [applicantFilters, setApplicantFilters] = useState<ApplicantFilter>(
    initialApplicantFilters
  );

  useEffect(() => {
    if (job) {
      setOpenPositions(job.openPositions - hiredCount);
    }
  }, [hiredCount, job]);

  const jobTimezone = useMemo(() => {
    return job?.address?.timezoneId ?? UTC_TIMEZONE;
  }, [job?.address?.timezoneId]);

  const fetchApplicantCounters = useCallback(async () => {
    const counters = await fetchApplicationCounters(activeJobId);
    setHiredCount(counters.hiredAssignments);
    setOfferedCount(counters.jobOffers);
    setHiddenCount(counters.declinedAssignments);
    setAppliedCount(counters.jobApplicants);
  }, [activeJobId]);

  const isInternalMember = useCallback(
    (memberId: number): boolean => {
      return (
        job?.members?.some(
          (member) => member.id === memberId && member.isInternal
        ) || false
      );
    },
    [job]
  );

  const getLocalStartDate = useCallback(
    (format: string = DATE_FORMATS.SHORT_DATE_FORMAT): string => {
      return getJobFormattedLocalStartDate(job!, format);
    },
    [job]
  );

  // Does not handle ongoing job logic here. Do that in the caller of this function
  const getLocalEndDate = useCallback(
    (format: string = DATE_FORMATS.SHORT_DATE_FORMAT): string => {
      return getJobFormattedLocalEndDate(job!, format);
    },
    [job]
  );

  const getJobYear = useCallback(() => {
    const currentYear = new Date().getFullYear();
    const jobYear = job?.start ? new Date(job.start).getFullYear() : undefined;
    return jobYear !== undefined && currentYear !== jobYear
      ? `${jobYear.toString().slice(-2)}' `
      : '';
  }, [job]);

  const contextValue: IJobContext = useMemo(() => {
    return {
      job,
      key,
      error,
      newHired,
      isLoading,
      hiredCount,
      newOffered,
      jobTimezone,
      activeJobId,
      hiddenCount,
      offeredCount,
      appliedCount,
      openPositions,
      initialSearch,
      applicantFilters,
      setKey,
      refetch,
      getJobYear,
      setNewHired,
      setJobParamId,
      setNewOffered,
      setHiredCount,
      setHiddenCount,
      setActiveJobId,
      getLocalEndDate,
      setOfferedCount,
      setAppliedCount,
      setInitialSearch,
      isInternalMember,
      getLocalStartDate,
      setApplicantFilters,
      fetchApplicantCounters,
    };
  }, [
    job,
    key,
    error,
    newHired,
    isLoading,
    hiredCount,
    newOffered,
    jobTimezone,
    activeJobId,
    hiddenCount,
    offeredCount,
    appliedCount,
    openPositions,
    initialSearch,
    applicantFilters,
    setKey,
    refetch,
    getJobYear,
    setNewHired,
    setJobParamId,
    setNewOffered,
    setHiredCount,
    setHiddenCount,
    setActiveJobId,
    getLocalEndDate,
    setOfferedCount,
    setAppliedCount,
    setInitialSearch,
    isInternalMember,
    getLocalStartDate,
    setApplicantFilters,
    fetchApplicantCounters,
  ]);
  return (
    <JobContext.Provider value={contextValue}>{children}</JobContext.Provider>
  );
}

export function useJobContext() {
  const context: IJobContext = useContext(JobContext);
  if (context === undefined) {
    throw new Error(
      'useJobContext must be used within a JobProvider. Wrap a parent component in <JobProvider> to fix this error.'
    );
  }
  return context;
}
