import { isPast, isToday, isFuture } from 'date-fns';
import { z } from 'zod';

export enum CertificateType {
  'INDUSTRY_CERTIFICATION' = 'Industry Certification',
  'EDUCATION' = 'Education',
}

export interface ICertificatesData {
  apiName: string;
  templates: Template[];
  certificates: Certificate[];
  certificateTypes: CertificateType[];
}

export interface BadgeImage {
  id: number;
  name: string;
  url: string;
}
export interface Template {
  id: number;
  type: string;
  name: string;
  description: string;
  imageUrl: string;
  certificateId?: number;
  badgeImage: BadgeImage;
}

export interface CertificateFile {
  id: string;
  name: string;
  url: string;
}

export interface Certificate {
  id: number;
  issuingCompany: string;
  issueDate: string;
  expireDate: string;
  certificateFile: CertificateFile;
  certificateCode: string;
  templateId: number;
  template: Template;
}

export const certificateValidationSchema = z.object({
  id: z.number().optional(),
  issuingCompany: z
    .string()
    .min(1, { message: 'Issuing Company/Entity is required.' }),
  issueDate: z
    .date({ required_error: 'Date issued is required.' })
    .refine((date) => isPast(date) || isToday(date), {
      message: 'Degree date must be in the past.',
    }),
  expireDate: z
    .date({ required_error: 'Expiration date is required.' })
    .refine((date) => isFuture(date), {
      message: 'Expire date must be in the future.',
    }),
  certificateCode: z
    .string()
    .min(1, { message: 'Certification code is required.' }),
  certificateTemplateId: z.number(),
  certificateFile: z.any(),
});

export type CertificateSchemaType = z.infer<typeof certificateValidationSchema>;
