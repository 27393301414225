import React from 'react';
import ContentLoader from 'react-content-loader';

interface LoaderApplicationInfoProps {
  screen: 'mobile' | 'desktop' | 'large-screen';
}

function LoaderApplicationInfo(props: LoaderApplicationInfoProps) {
  let width;
  const { screen } = props;
  switch (screen) {
    case 'mobile': {
      width = '400';
      break;
    }
    case 'desktop': {
      width = '1060';
      break;
    }
    case 'large-screen': {
      width = '1920';
      break;
    }
    default: {
      width = '1060';
      break;
    }
  }

  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} 500`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="20" rx="4" ry="4" width="300" height="20" />
      <rect x="0" y="50" rx="3" ry="3" width="250" height="10" />

      <rect x="0" y="70" rx="2" ry="2" width="960" height="1" />

      <rect x="0" y="90" rx="3" ry="3" width="200" height="15" />
      <rect x="0" y="115" rx="3" ry="3" width="180" height="15" />
      <rect x="0" y="140" rx="3" ry="3" width="220" height="15" />

      <rect x="0" y="170" rx="2" ry="2" width="960" height="1" />

      <rect x="0" y="190" rx="4" ry="4" width="160" height="20" />

      <rect x="0" y="220" rx="3" ry="3" width="960" height="15" />
      <rect x="0" y="245" rx="3" ry="3" width="920" height="15" />
      <rect x="0" y="270" rx="3" ry="3" width="940" height="15" />

      <rect x="0" y="300" rx="3" ry="3" width="960" height="15" />
      <rect x="0" y="325" rx="3" ry="3" width="920" height="15" />
      <rect x="0" y="350" rx="3" ry="3" width="940" height="15" />

      <rect x="0" y="380" rx="2" ry="2" width="960" height="1" />
    </ContentLoader>
  );
}

export default LoaderApplicationInfo;
