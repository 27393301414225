import { IPersonData } from '@th-types/person.type';
import { IWorkerData } from '@th-types/worker.type';

export const getPersonByWorker = (personId: number, worker: IWorkerData) => {
  const result: IPersonData = {
    id: personId,
    address: worker.address,
    cellPhone: worker.cellPhone,
    email: worker.email,
    firstName: worker.firstName,
    lastName: worker.lastName,
    fullName: `${worker.firstName} ${worker.lastName}`,
    profileImageUrl: worker.profileImageUrl,
  };
  return result;
};
