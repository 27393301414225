import { useRef, useState } from 'react';
import QueryKeys from '@constants/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { DayHeaderContentArg, EventSourceFuncArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { isMobile } from 'react-device-detect';
import { addDays, differenceInDays, format, isEqual } from 'date-fns';
import * as workerApi from '@company/services/worker/api';
import { useCompanyContext } from '@company/state/companyContext';
import {
  AvailabilityType,
  IAvailabilityData,
} from '@company/types/workerAvailability.type';
import { IWorkerData } from '@th-types/worker.type';
import DATE_FORMATS from '@constants/dateFormat';
import defaultAvatar from '@assets/default-avatar.png';
import useOnScreen from '@hooks/useIntersectionObserver';
import renderEventContent from './renderEventContent';

import './style.css';

interface CalendarViewParams {
  worker: IWorkerData;
}

export default function CalendarView({ worker }: CalendarViewParams) {
  const { id: companyId } = useCompanyContext();
  const [startDateQuery, setStartDateQuery] = useState<Date | undefined>();
  const [endDateQuery, setEndDateQuery] = useState<Date | undefined>();

  const ref = useRef(null);
  const isOnScreen = useOnScreen(ref);

  const getTitleByKey = (type: string) => {
    return Object.values(AvailabilityType)[
      Object.keys(AvailabilityType).indexOf(type)
    ];
  };

  const getTitleByValue = (value: AvailabilityType) => {
    return Object.keys(AvailabilityType)[
      Object.values(AvailabilityType).indexOf(value)
    ];
  };

  const convertToEvent = (workerAvailabilities: IAvailabilityData[]) =>
    workerAvailabilities
      .sort((a, b) => new Date(a.from).getTime() - new Date(b.from).getTime())
      .map((availability) => {
        const { jobAssignment: { job } = {} } = availability;
        const {
          jobAssignment: {
            job: { address, company } = {
              address: {},
              company: { id: null, name: '', profileImageUrl: '' },
            },
          } = {},
        } = availability;
        const { profileImageUrl } = company;

        const unavailableType = getTitleByValue(AvailabilityType.NOT_AVAILABLE);
        const workType = getTitleByValue(AvailabilityType.WORK);
        const isWorkDay = availability.type === workType;

        const isSameCompany = companyId === company?.id;
        const bgColor = isSameCompany ? 'var(--light-gray)' : 'var(--lavender)';
        const classNames = isSameCompany ? ['same-company'] : ['other-company'];
        const avatar = isSameCompany ? profileImageUrl : defaultAvatar;

        const otherTitle = getTitleByKey(availability.type);
        const workJobTitle = isWorkDay ? `${job?.position}` : otherTitle;
        const workerUnavailable =
          availability.allDay || availability.type === unavailableType;

        const getEventTitle = () => {
          if (availability.type === unavailableType) {
            return 'Unavailable per worker';
          }
          if (!isSameCompany) {
            return availability.allDay
              ? 'Booked on TH, hours not specified'
              : 'Booked on TH';
          }
          return workJobTitle;
        };

        return {
          id: availability.id.toString(),
          title: getEventTitle(),
          start: new Date(availability.from),
          end: new Date(availability.to),
          allDay: availability.allDay,
          textColor: 'var(--black)',
          backgroundColor: workerUnavailable ? 'var(--light-silver)' : bgColor,
          borderColor: workerUnavailable ? 'var(--transparent)' : bgColor,
          classNames: workerUnavailable ? ['diagonal-stripes'] : classNames,
          extendedProps: {
            company,
            isSameCompany,
            avatar,
            job,
            address,
            workerUnavailable: availability.type === unavailableType,
          },
        };
      });

  const rangeOfDateToChunkDays = (
    workerAvailabilities: IAvailabilityData[]
  ) => {
    const result: IAvailabilityData[] = [];
    workerAvailabilities.map((availability) => {
      const startDate = new Date(
        new Date(availability.from).toISOString().slice(0, -1)
      );
      const endDate = new Date(
        new Date(availability.to).toISOString().slice(0, -1)
      );
      const diffInDays = differenceInDays(endDate, startDate);
      if (diffInDays > 0) {
        let i = 0;
        while (i < diffInDays) {
          const newFromDate = addDays(startDate, i);
          const newToDate = addDays(startDate, i);
          newToDate.setHours(endDate.getHours());
          newToDate.setMinutes(endDate.getMinutes());
          newToDate.setSeconds(endDate.getSeconds());
          const newEvent: IAvailabilityData = {
            ...availability,
            allDay: false,
            from: format(newFromDate, DATE_FORMATS.ISO8601_FORMAT),
            to: format(newToDate, DATE_FORMATS.ISO8601_FORMAT),
          };
          result.push(newEvent);
          i += 1;
        }
        return {};
      }
      result.push(availability);
      return availability;
    });
    return result;
  };

  const fetchAvailabilities = async (start?: Date, end?: Date) => {
    const today = new Date();
    const weekFromToday = addDays(new Date(), 7);

    const formattedFromDate = format(start ?? today, DATE_FORMATS.DATE_FORMAT);
    const formattedEndDate = format(
      end ?? weekFromToday,
      DATE_FORMATS.DATE_FORMAT
    );

    const { workerAvailabilities } = await workerApi.fetchWorkerAvailability(
      worker.id,
      formattedFromDate,
      formattedEndDate
    );
    const availabilitiesWithoutChangedAddress = workerAvailabilities.filter(
      (availabilities) =>
        availabilities.type !== AvailabilityType.ADDRESS_CHANGE
    );
    const splicedDaysAvailabilities = rangeOfDateToChunkDays(
      availabilitiesWithoutChangedAddress
    );
    return splicedDaysAvailabilities;
  };

  const queryKey = `${QueryKeys.WORKER_AVAILABILITIES}_${worker.id}`;

  const { data } = useQuery({
    queryKey: [queryKey, startDateQuery, endDateQuery],
    staleTime: 10000,
    enabled: isOnScreen && !!startDateQuery && !!endDateQuery,
    queryFn: async () => {
      const result = await fetchAvailabilities(startDateQuery, endDateQuery);
      return convertToEvent(result);
    },
  });

  const renderDayHeaderContent = (args: DayHeaderContentArg) => {
    const { date, text, view, isToday } = args;
    const { type } = view;
    const formattedDate = format(date, DATE_FORMATS.CALENDAR_DAY_FORMAT);
    const dateParts = formattedDate.split(' ');
    const formattedContent = `${dateParts[0]} <span class="fc-${
      isToday ? 'today' : 'other-day'
    }-header">${dateParts[1]}</span>`;
    return type === 'dayGridMonth' ? text : { html: formattedContent };
  };

  const handleEvents = async ({ start, end }: EventSourceFuncArg) => {
    if (!startDateQuery || !isEqual(start, startDateQuery)) {
      setStartDateQuery(start);
    }

    if (!endDateQuery || !isEqual(end, endDateQuery)) {
      setEndDateQuery(end);
    }

    return data || [];
  };

  return (
    <div ref={ref}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, bootstrap5Plugin]}
        headerToolbar={{
          left: isMobile ? 'prev' : 'prev next',
          center: 'title',
          right: isMobile ? 'next' : 'timeGridWeek,dayGridMonth',
        }}
        allDaySlot
        height="auto"
        initialView={isMobile ? 'dayGridDay' : 'dayGridMonth'}
        selectable
        selectMirror
        dayMaxEvents
        themeSystem="bootstrap5"
        titleFormat={{ year: 'numeric', month: 'long' }}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'narrow',
          separator: '-',
        }}
        slotLabelFormat={{
          hour: 'numeric',
          minute: 'numeric',
          meridiem: 'narrow',
        }}
        views={{
          dayGridMonth: {
            dayMaxEvents: 2,
          },
        }}
        dayHeaderContent={renderDayHeaderContent}
        displayEventEnd
        events={handleEvents}
        eventContent={(event) => renderEventContent(event)}
      />
    </div>
  );
}
