import { BsThreeDots } from '@react-icons/all-files/bs/BsThreeDots';
import React from 'react';
import { Dropdown } from 'react-bootstrap';

/* eslint-disable */
// @ts-ignore
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    // @ts-ignore
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export default function ActionButtonsMobile({ actions }: any) {
  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
        <BsThreeDots
          size={20}
          role="button"
          className="reset-link"
          color="black"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {actions &&
          actions.map((action: any) => (
            <Dropdown.Item key={action.label} onClick={action.action}>
              {action.label}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
