import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Alert, Badge, Button, Form, Stack } from 'react-bootstrap';
import { zodResolver } from '@hookform/resolvers/zod';
import { JobAssignmentRatingType } from '@company/types/job-assignment-rating.type';
import { rateWorker } from '@company/services/rating/api';
import { useCompanyContext } from '@company/state/companyContext';
import { StarRatingButton, ThLoading } from '@components/elements';
import ShowImage from '@components/elements/ShowImage';
import defaultAvatar from '@assets/default-avatar.png';
import useAlert from '@hooks/useAlert';
import {
  IRatingVisibility,
  ReviewWorkerSchemaType,
  reviewWorkerValidationSchema,
} from '@th-types/rating.type';
import { isMobile } from 'react-device-detect';

const COMMENTS_MAX_LENGTH = 300;

interface ComHubReviewWorkerFormProps {
  ratingAssignmentData: JobAssignmentRatingType;
  onSuccess: () => void;
  isReadOnly?: boolean;
}

function ComHubReviewWorkerForm({
  isReadOnly = false,
  ratingAssignmentData,
  onSuccess,
}: ComHubReviewWorkerFormProps) {
  const { premium: isPremiumCompany } = useCompanyContext();
  const { workerRating, worker, jobTitle, totalWorkedDays, jobAssignmentId } =
    ratingAssignmentData;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
  } = useForm<ReviewWorkerSchemaType>({
    resolver: zodResolver(reviewWorkerValidationSchema),
    mode: 'onChange',
    defaultValues: {
      jobAssignmentId,
      workerId: worker?.id,
      performance: workerRating?.performance || 0,
      photoIsRepresentative: workerRating?.photoIsRepresentative ?? undefined,
      title: {
        id: jobTitle?.id,
        name: jobTitle?.name,
      },
      approximatelyDaysWorked: totalWorkedDays || 0,
      visibility: workerRating?.visibility || IRatingVisibility.PRIVATE,
      canceledUnexcused: workerRating?.canceledUnexcused || false,
      noCallNoShowed: workerRating?.noCallNoShowed || false,
      comments: workerRating?.comments || '',
      improvementComments: workerRating?.improvementComments || '',
      internalNotes: workerRating?.internalNotes || '',
    },
  });
  const { showWarningAlert, alertMessage } = useAlert();
  const [isLoading, setIsLoading] = useState(false);

  const [commentsCount, setCommentsCount] = useState<number>(0);
  const [internalNotesCount, setInternalNotesCount] = useState<number>(0);
  const [improvementCommentsCount, setImprovementCommentsCount] =
    useState<number>(0);

  const onSubmit: SubmitHandler<ReviewWorkerSchemaType> = async (
    data: ReviewWorkerSchemaType
  ) => {
    try {
      setIsLoading(true);
      const response = await rateWorker(data);
      if (response.status === 200) {
        onSuccess();
      }
    } catch (err: unknown) {
      if (err instanceof Error && 'response' in err) {
        const errorResponse = (err as any).response; // Type assertion to access response safely
        showWarningAlert(errorResponse.data.message);
      } else {
        console.error('Unexpected error:', err);
        showWarningAlert('An unexpected error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (totalWorkedDays) {
    setValue('approximatelyDaysWorked', totalWorkedDays);
  }

  return (
    <>
      {isLoading && <ThLoading />}
      <Form
        className="review-worker"
        style={{ maxWidth: 'unset' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset
          disabled={isReadOnly}
          data-testid="review-worker-form-fieldset"
        >
          <Stack
            direction={isMobile ? 'vertical' : 'horizontal'}
            gap={isMobile ? 2 : 3}
          >
            <Stack direction="horizontal" gap={3}>
              <ShowImage
                className="avatar rounded cursor-point"
                src={worker?.profileImageUrl || defaultAvatar}
                style={{
                  width: 60,
                  height: 60,
                  objectFit: 'cover',
                }}
              />
              <span className="fw-bold">{worker?.fullName}</span>
            </Stack>
            <Controller
              control={control}
              name="performance"
              render={({ field }) => (
                <StarRatingButton
                  value={field.value}
                  setValue={(val) => field.onChange(Number(val))}
                  hideLabels
                  allowChange={!isReadOnly}
                  size={35}
                  gap={isMobile ? 2 : 3}
                />
              )}
            />
          </Stack>
          <hr />
          <Stack gap={3}>
            {ratingAssignmentData.isAnonReview && (
              <Badge bg="secondary" className="w-100 p-3 fw-bold fs-6">
                This review will appear as anonymous to the worker.
              </Badge>
            )}
            <Controller
              control={control}
              name="photoIsRepresentative"
              render={({ field }) => {
                return (
                  <Stack
                    direction={isMobile ? 'vertical' : 'horizontal'}
                    gap={3}
                    className="justify-content-between"
                  >
                    <Form.Label className={isMobile ? 'w-100' : 'w-50'}>
                      Does this picture look like them?*
                    </Form.Label>
                    <Stack>
                      <div className={isMobile ? 'w-100' : 'w-50'}>
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          data-testid="check-yes"
                          checked={field.value}
                          onChange={() => field.onChange(true)}
                          isInvalid={!!errors.photoIsRepresentative}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          data-testid="check-no"
                          checked={field.value !== undefined && !field.value}
                          onChange={() => field.onChange(false)}
                          isInvalid={!!errors.photoIsRepresentative}
                        />
                      </div>

                      {errors.photoIsRepresentative && (
                        <span className="rate-error-message">
                          You must select one of these options
                        </span>
                      )}
                    </Stack>
                  </Stack>
                );
              }}
            />

            <Stack direction={isMobile ? 'vertical' : 'horizontal'} gap={3}>
              <Stack className={isMobile ? 'w-100' : 'w-50'} gap={2}>
                <Form.Label>Job Title*</Form.Label>
                <Form.Control value={jobTitle?.name} readOnly />
                <Form.Control
                  {...register('title.id', { valueAsNumber: true })}
                  type="hidden"
                  value={jobTitle?.id}
                />
              </Stack>
              <Stack className={isMobile ? 'w-100' : 'w-50'} gap={2}>
                <Form.Label>Approx. Days Worked*</Form.Label>
                <Form.Control
                  {...register('approximatelyDaysWorked', {
                    valueAsNumber: true,
                  })}
                  placeholder="Enter # of days"
                  className={
                    errors.approximatelyDaysWorked ? 'border-error' : ''
                  }
                  readOnly={!!totalWorkedDays}
                  type="number"
                />
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Form.Label htmlFor="comments">
                What does this worker do well?{' '}
                <span className="optional-badge text-uppercase small">
                  (OPTIONAL)
                </span>
              </Form.Label>
              <Form.Control
                id="comments"
                {...register('comments')}
                as="textarea"
                maxLength={COMMENTS_MAX_LENGTH}
                onChange={(e) => setCommentsCount(e.target.value.length || 0)}
                autoComplete="off"
              />
              <Form.Text className="d-flex justify-content-end">
                {commentsCount}/{COMMENTS_MAX_LENGTH}
              </Form.Text>
            </Stack>
            <Stack gap={2}>
              <Form.Label>
                Did the worker do any of the following?{' '}
                <span className="optional-badge text-uppercase small">
                  (OPTIONAL)
                </span>
              </Form.Label>
              <Stack direction={isMobile ? 'vertical' : 'horizontal'} gap={3}>
                <Controller
                  control={control}
                  name="noCallNoShowed"
                  defaultValue={false}
                  render={({ field }) => {
                    const { value, ...rest } = field;
                    return (
                      <Button
                        variant={field.value === true ? 'primary' : 'light'}
                        className={`worker-behavior fw-bold text-uppercase ${
                          isMobile ? 'w-100' : 'w-50'
                        }`}
                        {...rest}
                        onClick={() => {
                          const newValue = !field.value;
                          field.onChange(newValue);
                          setValue('noCallNoShowed', newValue);
                        }}
                      >
                        No Call/No Show
                      </Button>
                    );
                  }}
                />

                <Controller
                  control={control}
                  name="canceledUnexcused"
                  render={({ field }) => {
                    const { value, ...rest } = field;

                    return (
                      <Button
                        variant={field.value === true ? 'primary' : 'light'}
                        className={`worker-behavior fw-bold text-uppercase ${
                          isMobile ? 'w-100' : 'w-50'
                        }`}
                        {...rest}
                        onClick={() => {
                          const newValue = !field.value;
                          field.onChange(newValue);
                          setValue('canceledUnexcused', newValue);
                        }}
                      >
                        Canceled within 24 hours
                      </Button>
                    );
                  }}
                />
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Form.Label>Optional - What Can Worker Improve On</Form.Label>
              <p className="optional-badge small">
                Share improvements privately with the worker so they can
                improve, or have this appear publicly on the worker profile
                along with your name and company picture.
              </p>
            </Stack>
            <Stack gap={2}>
              <Controller
                control={control}
                name="visibility"
                render={({ field }) => {
                  return (
                    <div className="form-check d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="form-check-input bigger"
                        checked={field.value === IRatingVisibility.PUBLIC}
                        onChange={(e) => {
                          const newValue = e.currentTarget.checked
                            ? IRatingVisibility.PUBLIC
                            : IRatingVisibility.PRIVATE;
                          field.onChange(newValue);
                        }}
                      />
                      {/* eslint-disable jsx-a11y/label-has-associated-control */}
                      <label className="form-check-label fw-bold ms-1 mt-1">
                        Agree to share this info publicly with your name?*
                      </label>
                    </div>
                  );
                }}
              />
            </Stack>
            <Stack gap={2}>
              <Form.Label>
                What can this worker improve?{' '}
                <span className="optional-badge text-uppercase small">
                  (OPTIONAL)
                </span>
              </Form.Label>
              <Form.Control
                {...register('improvementComments')}
                as="textarea"
                maxLength={COMMENTS_MAX_LENGTH}
                onChange={(e) =>
                  setImprovementCommentsCount(e.target.value.length || 0)
                }
                autoComplete="off"
              />
              <Form.Text className="d-flex justify-content-end">
                {improvementCommentsCount}/{COMMENTS_MAX_LENGTH}
              </Form.Text>
            </Stack>
            <Stack>
              <hr className="table-horizontal-line" />
            </Stack>
            {isPremiumCompany && (
              <Stack gap={2}>
                <Form.Label>
                  Internal Note on Worker Profile{' '}
                  <span className="optional-badge text-uppercase small">
                    (OPTIONAL, ONLY YOUR COMPANY CAN SEE THESE)
                  </span>
                </Form.Label>
                <Form.Control
                  {...register('internalNotes')}
                  as="textarea"
                  maxLength={COMMENTS_MAX_LENGTH}
                  onChange={(e) =>
                    setInternalNotesCount(e.target.value.length || 0)
                  }
                  autoComplete="off"
                />
                <Form.Text className="d-flex justify-content-end">
                  {internalNotesCount}/{COMMENTS_MAX_LENGTH}
                </Form.Text>
              </Stack>
            )}

            {!isReadOnly && (
              <div className="d-flex justify-content-end">
                <Button type="submit" disabled={!isValid}>
                  Submit Review
                </Button>
              </div>
            )}
          </Stack>
          <Form.Control
            {...register('jobAssignmentId', { valueAsNumber: true })}
            type="hidden"
            readOnly
            value={jobAssignmentId}
          />
          <Form.Control
            {...register('workerId', { valueAsNumber: true })}
            type="hidden"
            readOnly
            value={worker?.id}
          />
        </fieldset>
      </Form>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default ComHubReviewWorkerForm;
