export enum IconPosition {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
}
export type IconPositionType = keyof typeof IconPosition;

export enum IconSize {
  'xs' = '2em',
  'sm' = '3em',
  'md' = '5em',
  'lg' = '6em',
  'xl' = '8em',
}

export type IconSizeType = keyof typeof IconSize;
