import { EventContentArg } from '@fullcalendar/core';
import { AiOutlineDollar } from '@react-icons/all-files/ai/AiOutlineDollar';
import { AiTwotoneCalendar } from '@react-icons/all-files/ai/AiTwotoneCalendar';
import { BsPeopleCircle } from '@react-icons/all-files/bs/BsPeopleCircle';
import { IoMdOpen } from '@react-icons/all-files/io/IoMdOpen';
import { IoMdPin } from '@react-icons/all-files/io/IoMdPin';
import { format } from 'date-fns';
import {
  Button,
  Col,
  Figure,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DATE_FORMATS from '@constants/dateFormat';
import { formatLocalDate } from '@utils/DateUtils';

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

export default function renderEventContent(eventContent: EventContentArg) {
  const { backgroundColor, title, allDay, id, startStr, endStr } =
    eventContent.event;
  const { type } = eventContent.view;
  const { company, isSameCompany, avatar, job, workerUnavailable } =
    eventContent.event.extendedProps;

  const renderContent = (): JSX.Element => {
    return (
      <div style={{ cursor: isSameCompany ? 'pointer' : 'default' }}>
        <strong>{title}</strong>
        <br />
        {!allDay && <small>{eventContent.timeText}</small>}
      </div>
    );
  };

  return (
    <div
      className={`text-start p-2 w-100 event-content ${
        type === 'timeGridWeek' ? 'event-content-week' : ''
      }`}
      style={{
        backgroundColor: `var(${backgroundColor})`,
      }}
    >
      {isSameCompany && (
        <OverlayTrigger
          flip
          trigger="click"
          placement="right"
          overlay={
            <Popover id={id} style={{ maxWidth: '400px' }}>
              <Popover.Header className="bg-white">
                <Link
                  style={{ textDecoration: 'none', color: 'unset' }}
                  to={`${BASE_PATH}jobs/${job?.id}`}
                >
                  <span className="fw-bold fs-5">
                    {job.eventName} - {job.brandRepresented}
                  </span>
                </Link>{' '}
                <Button
                  variant="link"
                  className="event-title-link"
                  onClick={() =>
                    window.open(`${BASE_PATH}jobs/${job?.id}`, '_blank')
                  }
                >
                  <IoMdOpen size={20} />
                </Button>
                <div>
                  <span className="me-3">
                    {format(
                      new Date(startStr),
                      DATE_FORMATS.DAY_MONTH_DATE_FORMAT
                    )}
                  </span>
                  <span>
                    {`${format(
                      new Date(startStr),
                      DATE_FORMATS.TIME_FORMAT
                    )} - ${format(new Date(endStr), DATE_FORMATS.TIME_FORMAT)}`}
                  </span>
                </div>
              </Popover.Header>
              <Popover.Body>
                <Row>
                  <Col xs={1}>
                    <AiOutlineDollar size={20} />
                  </Col>
                  <Col xs={11}>
                    ${job.payRate} per{' '}
                    {job.payRatePeriod?.toString().toLowerCase()}
                  </Col>
                </Row>
                <Row>
                  <Col xs={1}>
                    <AiTwotoneCalendar size={20} />
                  </Col>
                  <Col xs={11}>
                    {formatLocalDate(
                      job.start as Date,
                      DATE_FORMATS.SHORT_DATE_FORMAT,
                      job.nationalJob ? job.address.timezoneId : undefined
                    )}
                    &nbsp;-&nbsp;
                    {formatLocalDate(
                      job.end as Date,
                      DATE_FORMATS.SHORT_DATE_FORMAT,
                      job.nationalJob ? job.address.timezoneId : undefined
                    )}
                    {job.ongoingJob && 'on-going'}
                  </Col>
                </Row>
                <Row>
                  <Col xs={1}>
                    <IoMdPin size={20} />
                  </Col>
                  <Col xs={11}>
                    {job.nationalJob
                      ? 'National Job'
                      : job?.address?.googleAddress}
                  </Col>
                </Row>
                <Row>
                  <Col xs={1}>
                    <BsPeopleCircle size={20} />
                  </Col>
                  <Col xs={11}>
                    {job.totalPositions > 1
                      ? `${job.totalPositions} people`
                      : `${job.totalPositions} person`}
                  </Col>
                </Row>
              </Popover.Body>
            </Popover>
          }
        >
          {renderContent()}
        </OverlayTrigger>
      )}
      {!isSameCompany && renderContent()}
      {!allDay && !workerUnavailable && (
        <Figure.Image
          title={!isSameCompany ? '' : company.name}
          className={`avatar rounded-circle event-company-avatar ${
            isSameCompany ? 'cursor-point' : ''
          }`}
          src={avatar}
        />
      )}
    </div>
  );
}
