import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { Button, ListGroup, Table, Image, Stack } from 'react-bootstrap';
import { ImNewTab } from 'react-icons/im';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import defaultAvatar from '@assets/default-avatar.png';
import DeleteRowIcon from '@components/Icons/DeleteRowIcon/DeleteRowIcon';
import { formatTimeOrDateNow } from '@utils/DateUtils';
import QueryKeys from '@constants/queryKeys';
import DATE_FORMATS from '@constants/dateFormat';
import { STALE_ONE_MINUTE } from '@constants/comhub';
import { getReviewRequestsNewCounter } from '@company/services/com-hub/api';
import { ComHubHeadersEnum, Review } from '@company/types/comm-hub.type';
import { ReviewRequestModel } from '@company/models/ReviewRequestModel';

import './styles.css';

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

interface ReviewRequestsTableProps {
  reviewRequests: Review[];
  title: string;
  titleCallback?: () => void;
  onShowWorkerProfile?: (workerId: number) => void;
  onSeeReviewRequest: (review: Review) => void;
  onDismissItems?: (item: Review[]) => void;
}

function ReviewRequestsTable({
  title,
  titleCallback,
  reviewRequests,
  onShowWorkerProfile,
  onSeeReviewRequest,
  onDismissItems,
}: ReviewRequestsTableProps) {
  const headers = [
    ComHubHeadersEnum.EVENT_NAME,
    ComHubHeadersEnum.WORKER_NAME,
    ComHubHeadersEnum.POSITION,
    ComHubHeadersEnum.DATES_WORKED,
    ComHubHeadersEnum.RESPONDED,
    ComHubHeadersEnum.ACTIONS_COLUMN,
  ];

  const newReviewRequests = reviewRequests.filter(
    (reviewRequest) => reviewRequest.userState.new
  );

  // FETCH COUNTERS
  const { data: reviewRequestsCounterData } = useQuery({
    queryKey: [QueryKeys.ComHub.REVIEW_REQUESTS],
    queryFn: getReviewRequestsNewCounter,
    staleTime: STALE_ONE_MINUTE,
  });

  const handleDismissItem = (itemsToDismiss: Review[]) => {
    if (onDismissItems) {
      onDismissItems(itemsToDismiss);
    }
  };

  return (
    <div className="th-table">
      <header>
        <button
          type="button"
          onClick={titleCallback}
          className={`${titleCallback ? 'cursor-point' : 'cursor-default'}`}
        >
          <h4 className="text-start">
            {title}{' '}
            {reviewRequestsCounterData && (
              <b data-testid="new-counter">
                ({reviewRequestsCounterData.count})
              </b>
            )}
          </h4>
        </button>
        {newReviewRequests.length ? (
          <Button
            type="button"
            variant="light"
            className="ms-auto fw-bold d-flex align-items-center"
            style={{ fontSize: isMobile ? '12px' : '' }}
            onClick={() => handleDismissItem(newReviewRequests)}
          >
            <DeleteRowIcon className="me-2" size={isMobile ? 18 : 24} />
            Dismiss {newReviewRequests.length} new
          </Button>
        ) : null}
      </header>
      <hr style={{ margin: '10px 0' }} />
      {reviewRequests.length ? (
        <>
          <BrowserView>
            <Table>
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* RENDER COLUMS BY ROW */}
                {reviewRequests.map((reviewRequest) => {
                  const request = new ReviewRequestModel(reviewRequest);
                  return (
                    <tr
                      key={request.getUniqueKey()}
                      className={`${request.isNew() ? 'new' : ''}`}
                    >
                      <td>
                        {reviewRequest.jobId ? (
                          <a
                            href={`${BASE_PATH}jobs/${request.jobId}`}
                            target="_blank"
                            rel="noreferrer"
                            className="cursor-point event-name-link"
                            style={{ color: 'currentcolor' }}
                          >
                            {request.eventName}
                            <ImNewTab
                              color="var(--yellow-dark-20)"
                              className="ms-2 event-name-link-icon"
                            />
                          </a>
                        ) : (
                          <span>{request.eventName}</span>
                        )}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            if (onShowWorkerProfile && request.getWorkerId()) {
                              onShowWorkerProfile!(request.getWorkerId()!);
                            }
                          }}
                          className={`${request.isNew() ? 'new' : ''}`}
                        >
                          {request.getWorkerName()}
                        </button>
                      </td>
                      <td>{request.getPosition()}</td>
                      <td>{request.getWorkedDateRange()}</td>
                      <td>{request.completed ? 'YES' : 'NO'}</td>
                      {/* BUTTONS FOR ROW ACTIONS */}
                      <td className="actions">
                        <span className="me-3">
                          {request.companyRating &&
                            formatTimeOrDateNow(request.getDateCreated())}
                        </span>
                        <Button
                          onClick={() => onSeeReviewRequest(request)} // if fails put back reviewRequest
                          className="action-btn me-2"
                        >
                          open
                        </Button>
                        <button
                          type="button"
                          disabled={!request.isNew()}
                          style={{
                            cursor: !request.isNew()
                              ? 'not-allowed'
                              : 'pointer',
                          }}
                        >
                          <DeleteRowIcon
                            onClick={() => handleDismissItem([request])} // if fails put back reviewRequest
                            color={!request.isNew() ? 'var(--silver)' : ''}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </BrowserView>
          <MobileView>
            <ListGroup variant="flush">
              {reviewRequests.map((reviewRequest) => {
                const request = new ReviewRequestModel(reviewRequest);
                return (
                  <ListGroup.Item
                    key={request.getUniqueKey()}
                    className="ps-0 pe-0 border-0 d-flex gap-2 align-items-center"
                    onClick={(e) => {
                      // Check if the click target is not within the Button or DeleteRowIcon
                      const dismissButton =
                        e.currentTarget.querySelector('.dismiss-button');
                      if (!dismissButton?.contains(e.target as Node)) {
                        onSeeReviewRequest(request); // if fails put back reviewRequest
                      }
                    }}
                  >
                    <Image
                      src={
                        reviewRequest.companyRating.reviewer?.imageUrl ||
                        defaultAvatar
                      }
                      alt="reviewer profile picture"
                      width={40}
                      height={40}
                      roundedCircle
                    />
                    <Stack>
                      <span
                        className="text-truncate"
                        style={{ maxWidth: '200px' }}
                      >
                        {reviewRequest.eventName}
                      </span>
                      <span>{request.getWorkerName()}</span>
                      <span>{request.getPosition()}</span>
                    </Stack>
                    <Stack className="ms-auto justify-content-between align-items-end">
                      <span>
                        {format(
                          new Date(request.getDateCreated()),
                          DATE_FORMATS.SHORT_DATE_FORMAT
                        )}
                      </span>
                      <Button
                        variant="light"
                        size="sm"
                        className="p-1 dismiss-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDismissItem([reviewRequest]); // if fails put back reviewRequest
                        }}
                        disabled={!request.isNew()}
                      >
                        <DeleteRowIcon
                          color={!request.isNew() ? 'var(--silver)' : ''}
                        />
                      </Button>
                    </Stack>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </MobileView>
        </>
      ) : (
        <p>No new notifications.</p>
      )}
      {titleCallback && ( // change this for more accurate prop
        <Button variant="link" onClick={titleCallback} className="show-all-cta">
          Show All
        </Button>
      )}
    </div>
  );
}

export default ReviewRequestsTable;
