import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { Panel } from '@components/elements';
import { ratingRequest } from '@worker/services/rating/api';
import useAlert from '@hooks/useAlert';
import { Fragment, useState } from 'react';
import IRatingRequestCreation from '@worker/types/rating-request-creation.type';
import { INonThJobAssignmentData } from '@worker/types/non-th-job-assignment.type';

interface Props {
  jobAssignment: INonThJobAssignmentData | undefined;
  finishReview: () => void;
}

function RiseInStatus({ jobAssignment, finishReview }: Props) {
  const { register, handleSubmit } = useForm<IRatingRequestCreation>();
  const { showWarningAlert, alertMessage } = useAlert();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (dataRequest: IRatingRequestCreation) => {
    if (!jobAssignment) {
      return;
    }

    if (
      !jobAssignment.isValidatedCompany &&
      !dataRequest.hirerEmail &&
      !dataRequest.phoneNumber
    ) {
      showWarningAlert('Enter email or cell phone');
      return;
    }

    setLoading(true);
    const result = await ratingRequest(jobAssignment.id, dataRequest);

    if (result && result.success) {
      finishReview();
    } else {
      showWarningAlert('Error when sending review request');
    }
    setLoading(false);
  };

  function handleSkip(): void {
    finishReview();
  }

  return (
    <>
      <Col
        md={{ span: 6, offset: 3 }}
        xs={12}
        className="d-flex justify-content-center"
      >
        <Panel>
          <Form id="riseInStatusForm" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12}>
                <h4>
                  <b>Rise in Status</b>
                </h4>
              </Col>

              {jobAssignment?.isValidatedCompany ? (
                <>
                  <Col xs={12} className="mt-3">
                    <b>Would you like to request a rating from this company?</b>
                  </Col>
                  <Col xs={12} className="mt-3">
                    We will share the request with your name, email, event name,
                    brand name, dates worked, and positive comment about them.
                    No other information about your review will be shared.
                    It&apos;ll be their choice if they&apos;d like to leave a
                    review.
                  </Col>
                </>
              ) : (
                <>
                  <Col xs={12} className="mt-3">
                    <b>What does this mean?</b>
                  </Col>
                  <Col xs={12} className="mt-3">
                    We&apos;ll ask them to review your performance. This will
                    help you build your reputation on your profile to get more
                    gigs!
                  </Col>
                  <Col xs={12} className="mt-3">
                    <Form.Group controlId="riseInStatusForm.hirerEmail">
                      <Form.Label className="fw-bold">
                        Manager Employed at Company Email
                      </Form.Label>
                      <Form.Control
                        placeholder="Enter email"
                        type="email"
                        className="mt-2"
                        {...register('hirerEmail')}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} className="mt-3">
                    <Form.Group controlId="riseInStatusForm.phoneNumber">
                      <Form.Label className="fw-bold">
                        Manager Employed at Company Cell Number
                      </Form.Label>
                      <Form.Control
                        placeholder="Enter cell number"
                        className="mt-2"
                        {...register('phoneNumber')}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Panel>
      </Col>
      <Col
        md={{ span: 6, offset: 3 }}
        xs={12}
        className="d-flex justify-content-center mt-3"
      >
        <Row className="w-100">
          <Col md={6} xs={12} className="d-flex justify-content-end">
            <Button
              variant="light"
              onClick={() => handleSkip()}
              className={isMobile ? 'w-100' : ''}
            >
              <b>No thanks</b>
            </Button>
          </Col>
          <Col md={6} xs={12} className={isMobile ? 'mt-3' : ''}>
            <Button
              form="riseInStatusForm"
              variant="primary"
              type="submit"
              className={isMobile ? 'w-100' : ''}
              disabled={loading}
            >
              <b>
                {jobAssignment?.isValidatedCompany
                  ? 'Request Rating'
                  : 'Ask For Rating'}
              </b>
            </Button>
          </Col>
        </Row>
      </Col>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default RiseInStatus;
