import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  addWorkerCertificate,
  deleteWorkerCertificate,
} from '@worker/services/worker/api';
import {
  Certificate,
  CertificateSchemaType,
  certificateValidationSchema,
  Template,
} from '@th-types/certificates.type';
import useAlert from '@hooks/useAlert';
import ReactDatePicker from 'react-datepicker';
import { useState } from 'react';
import ThLoading from '@components/elements/ThLoading';
import { useWorkerProfileContext } from '@worker/state/workerProfileContext';

interface CertificateFormProps {
  certificateTemplate: Template;
  certificateForEdition?: Certificate | undefined;
  onComplete: () => void;
}
function CertificateForm({
  certificateTemplate,
  certificateForEdition,
  onComplete,
}: CertificateFormProps) {
  const { showErrorAlert, showSuccessAlert, alertMessage } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const { setWorkerCertificates } = useWorkerProfileContext();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    register,
  } = useForm<CertificateSchemaType>({
    resolver: zodResolver(certificateValidationSchema),
    defaultValues: {
      id: certificateForEdition?.id,
      issuingCompany: certificateForEdition?.issuingCompany,
      issueDate: certificateForEdition?.issueDate
        ? new Date(certificateForEdition?.issueDate)
        : undefined,
      expireDate: certificateForEdition?.expireDate
        ? new Date(certificateForEdition?.expireDate)
        : undefined,
      certificateCode: certificateForEdition?.certificateCode,
    },
  });

  const onSubmit: SubmitHandler<CertificateSchemaType> = async (
    data: CertificateSchemaType
  ) => {
    try {
      setIsLoading(true);
      const addedCertificate = await addWorkerCertificate(data);
      showSuccessAlert('Certificate added succesfully!');
      setWorkerCertificates((prevState) => {
        if (prevState) {
          const newState = { ...prevState };
          const certIndex = newState.certificates.findIndex(
            (cert) => cert.id === addedCertificate.id
          );
          if (certIndex === -1) {
            newState.certificates?.push(addedCertificate);
          } else {
            newState.certificates[certIndex] = addedCertificate;
          }
          return newState;
        }
        return prevState;
      });
      onComplete();
    } catch (error) {
      showErrorAlert('An error ocurred submitting the form.');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteCertificate = async () => {
    try {
      setIsLoading(true);
      await deleteWorkerCertificate(certificateForEdition!.id);
      showSuccessAlert('Certificate added succesfully!');
      setWorkerCertificates((prevState) => {
        if (prevState) {
          const newState = { ...prevState };
          newState.certificates = newState.certificates?.filter(
            (cert) => cert.id !== certificateForEdition!.id
          );
          return newState;
        }
        return prevState;
      });
    } catch (error) {
      showErrorAlert('An error ocurred deleting the certificate.');
    } finally {
      setIsLoading(false);
      onComplete();
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <ThLoading />}
      <Form.Control
        {...register('id', { valueAsNumber: true })}
        value={0}
        hidden
      />
      <Form.Control
        {...register('certificateTemplateId', { valueAsNumber: true })}
        value={certificateTemplate.id}
        hidden
      />
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>
            <b>Issuing Company/Entity *</b>
          </Form.Label>
          <Form.Control
            {...register('issuingCompany')}
            className={errors.issuingCompany ? 'border-danger' : ''}
          />
          {errors.issuingCompany && (
            <span className="form-field-error-message">
              {errors.issuingCompany.message}
            </span>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b>Date Issued *</b>
          </Form.Label>
          <Controller
            control={control}
            name="issueDate"
            render={({ field }) => (
              <Stack>
                <ReactDatePicker
                  className={`form-control ${
                    errors.issueDate ? 'border-danger' : ''
                  }`}
                  selected={field.value}
                  onChange={(date) => field.onChange(date as Date)}
                  placeholderText="MM/DD/YYY"
                  maxDate={new Date()}
                />
              </Stack>
            )}
          />
          {errors.issueDate && (
            <span className="form-field-error-message">
              {errors.issueDate.message}
            </span>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b>Date Expires *</b>
          </Form.Label>
          <Controller
            control={control}
            name="expireDate"
            render={({ field }) => (
              <Stack>
                <ReactDatePicker
                  className={`form-control ${
                    errors.expireDate ? 'border-danger' : ''
                  }`}
                  selected={field.value}
                  onChange={(date) => field.onChange(date as Date)}
                  placeholderText="MM/DD/YYY"
                  minDate={new Date()}
                />
              </Stack>
            )}
          />
          {errors.expireDate && (
            <span className="form-field-error-message">
              {errors.expireDate.message}
            </span>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b>Certification/License *</b>
          </Form.Label>
          <Form.Control
            {...register('certificateCode')}
            className={errors.certificateCode ? 'border-danger' : ''}
          />
          {errors.certificateCode && (
            <span className="form-field-error-message">
              {errors.certificateCode.message}
            </span>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b>Attach Picture/File</b>
          </Form.Label>
          <Controller
            control={control}
            name="certificateFile"
            render={({ field: { value, onChange, ...field } }) => {
              return (
                <input
                  {...field}
                  value={value?.fileName}
                  onChange={(event) => {
                    onChange(event.target.files![0]);
                  }}
                  accept="application/pdf, image/png, image/jpeg"
                  type="file"
                  id="certificateFile"
                />
              );
            }}
          />
          {certificateForEdition?.certificateFile && (
            <Form.Text id="fileHelp" muted className="d-block">
              <b>Currently</b>:{' '}
              <a
                href={certificateForEdition.certificateFile.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {certificateForEdition?.certificateFile.name}
              </a>
            </Form.Text>
          )}
          {errors.certificateFile && (
            <span className="form-field-error-message">
              {errors.certificateFile.message as string}
            </span>
          )}
        </Form.Group>
        <Row direction="horizontal">
          <Col>
            <Button type="submit" className="w-100" disabled={!isDirty}>
              Save
            </Button>
          </Col>
          {certificateForEdition && (
            <Col>
              <Button
                type="button"
                variant="danger"
                className="w-100"
                onClick={deleteCertificate}
              >
                Delete
              </Button>
            </Col>
          )}
        </Row>
      </Stack>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </Form>
  );
}

export default CertificateForm;
