import { ShiftSortAttributes } from '@company/components/ShiftTables/ShiftTables';
import IJobDetailData, { ShiftPosition } from '@th-types/job.detail.type';
import { format, isAfter, isEqual, isSameDay, isToday } from 'date-fns';
import IResultResponse from '@th-types/resultResponse.type';
import { NullableString } from '@th-types/common.type';
import DATE_FORMATS from '@constants/dateFormat';
import { ShiftStatus } from '@th-types/jobs.type';
import { groupBy } from 'lodash';
import { z } from 'zod';
import {
  parseToDate,
  UTC_TIMEZONE,
  formatLocalDate,
  convertToTimezone,
  getDateNowByTimezone,
  getTodayDateByTimezone,
} from '@utils/DateUtils';
import { privateApi } from '@services/api';

export const MAIN_URL = 'jobs';

export const CANCEL_SHIFT = 'CANCEL SHIFT';
export const CREATE_SHIFT = 'CREATE SHIFT';
export const OFFER_SHIFT = 'OFFER SHIFT';
export const DELETE_SHIFT = 'DELETE SHIFT';

export const getValidationSchema = (timezone?: string) => {
  const now = getDateNowByTimezone(timezone);
  const todayDate = getTodayDateByTimezone(timezone || UTC_TIMEZONE);
  const payRatePeriodValues = ['hourly', 'daily'];
  const breakPaidValues = ['yes', 'no'];
  return z
    .object({
      shifts: z
        .array(
          z
            .object({
              id: z.number().optional(),
              date: z
                .date({
                  required_error: 'Date is required.',
                  invalid_type_error: 'Invalid date',
                })
                .min(todayDate, 'Date must be today or later.')
                .refine(
                  (date) =>
                    isAfter(date, todayDate) || isEqual(date, todayDate),
                  {
                    message: 'Date must be today or later.',
                  }
                ),
              position: z.number().min(1, 'Position is required.'),
              payRate: z.coerce
                .string()
                .refine(
                  (payRate) =>
                    payRate.substring(2) !== '' &&
                    !Number.isNaN(payRate.substring(2).trim()) &&
                    Number(payRate.substring(2).trim()) > 0,
                  {
                    message: 'Wage must be a valid value and bigger than 0.',
                  }
                ),
              payRatePeriod: z
                .string()
                .refine(
                  (payRatePeriod) =>
                    payRatePeriodValues.includes(payRatePeriod),
                  {
                    message: 'Hourly/Daily is required.',
                  }
                ),
              openPositions: z
                .number()
                .min(0, 'Positions must be bigger than or equal to 0.'),
              openBackups: z
                .number()
                .min(0, 'Backups must be bigger than or equal to 0.')
                .max(5, 'Backups must be smaller than 5.'),
              localizedStart: z.string(),
              localizedEnd: z.string(),
              startTime: z.coerce.date({
                required_error: 'Start Time is required.',
                invalid_type_error: 'Invalid Start Time',
              }),
              endTime: z.coerce.date({
                required_error: 'End Time is required.',
                invalid_type_error: 'Invalid End Time',
              }),
              start: z.string().optional(),
              end: z.string().optional(),
              isNextDay: z.boolean().default(false),
              breakPaid: z
                .string()
                .refine(
                  (payRatePeriod) => breakPaidValues.includes(payRatePeriod),
                  {
                    message: 'Paid break is required.',
                  }
                ),
            })
            .refine(
              ({ openPositions, openBackups }) =>
                openPositions + openBackups > 0,
              {
                message: 'A position or a backup is required.',
                path: ['openPositions'],
              }
            )
            .refine(
              ({ date, startTime }) =>
                !isToday(date) || isAfter(startTime, now),
              {
                message: 'Start time is in the past.',
                path: ['startTime'],
              }
            )
        )
        .min(1),
    })
    .required();
};

// if job start is in present/future,use that as default
// else use current day (as it is now)
// Get the default start date for a shift
export const getStartDefaultDateTime = (job: IJobDetailData) => {
  const now = getDateNowByTimezone(job.timezone);
  const jobStart = new Date(job.start);
  if (isAfter(jobStart, now) && !isSameDay(jobStart, now)) {
    // shift starts by default at 7am
    jobStart.setHours(7);
    return jobStart;
  }
  const advancedNow = new Date(now);
  advancedNow.setMinutes(now.getMinutes() + 5);
  return advancedNow;
};

export const shiftDefaultValues = (
  job: IJobDetailData,
  currentShift?: ShiftPosition
) => {
  if (currentShift) {
    const isPosition = currentShift.type === 'POSITION';
    return {
      shifts: [
        {
          id: currentShift.id,
          date: parseToDate(currentShift.start, job.address.timezoneId),
          position: currentShift.title.id,
          payRate: `$ ${currentShift.wage.payRate}`,
          payRatePeriod:
            currentShift.wage.payRatePeriod === 'DAY' ? 'daily' : 'hourly',
          openPositions: isPosition ? 1 : 0,
          openBackups: !isPosition ? 1 : 0,
          startTime: parseToDate(currentShift.start, job.address.timezoneId),
          localizedStart: formatLocalDate(
            new Date(currentShift.start),
            DATE_FORMATS.DATE_TIME_FORMAT,
            job.address?.timezoneId
          ),
          endTime: parseToDate(currentShift.end, job.address.timezoneId),
          localizedEnd: formatLocalDate(
            new Date(currentShift.end),
            DATE_FORMATS.DATE_TIME_FORMAT,
            job.address?.timezoneId
          ),
          breakPaid: currentShift.breakPaid ? 'yes' : 'no',
        },
      ],
    };
  }
  const initialDate = getStartDefaultDateTime(job);
  const payRateValue = job ? `$ ${job.payRate}` : '$ 0';
  return {
    shifts: [
      {
        id: undefined,
        date: initialDate,
        position: job?.title.id,
        payRate: payRateValue,
        payRatePeriod:
          job?.payRatePeriod.toString() === 'DAY' ? 'daily' : 'hourly',
        openPositions: 0,
        openBackups: 0,
        localizedStart: '',
        localizedEnd: '',
        breakPaid: undefined,
      },
    ],
  };
};

export const getFormattedTime = (shift: ShiftPosition, timezoneId: string) => {
  const startTimeFormatted = format(
    new Date(
      new Date(shift.start).toLocaleString('en-US', { timeZone: timezoneId })
    ),
    DATE_FORMATS.TIME_FORMAT
  );
  const endTimeFormatted = format(
    new Date(
      new Date(shift.end).toLocaleString('en-US', { timeZone: timezoneId })
    ),
    DATE_FORMATS.TIME_FORMAT
  );
  return `${startTimeFormatted} - ${endTimeFormatted}`;
};

export const offerShifts = async (
  jobId: number,
  workerId: number,
  shiftIds: number[],
  offerNote?: NullableString
) => {
  const offers = {
    shiftIds,
    workerId,
    offerNote,
  };
  const url = `${MAIN_URL}/${jobId}/shifts/offer`;
  try {
    const result = await privateApi.post<IResultResponse>(url, offers);
    return result.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export function areAllFilteredShiftsSelected(
  selectedShifts: ShiftPosition[],
  filteredShifts: ShiftPosition[]
): boolean {
  return (
    selectedShifts.length > 0 &&
    selectedShifts.length === filteredShifts?.length &&
    selectedShifts.every((selected) =>
      filteredShifts.map((filtered) => filtered.id).includes(selected.id)
    )
  );
}

export const isShiftConfirmed = (shiftPosition: ShiftPosition) =>
  shiftPosition.shiftStatus.toLowerCase() ===
  ShiftStatus.CONFIRMED.toLowerCase();

export const getFormattedShiftTimes = (
  shiftPosition: ShiftPosition,
  timezone?: string
): string =>
  `${format(
    convertToTimezone(new Date(shiftPosition.start), timezone || ''),
    DATE_FORMATS.TIME_FORMAT
  )} - ${format(
    convertToTimezone(new Date(shiftPosition.end), timezone || ''),
    DATE_FORMATS.TIME_FORMAT
  )}`;

export const getSelectedShiftIdsToCancelOrDelete = (
  selectedShifts: ShiftPosition[],
  isCancelAction: boolean
): number[] => {
  return selectedShifts.reduce((shiftIdList: number[], item: ShiftPosition) => {
    if (
      item.shiftStatus.toLowerCase() === ShiftStatus.OPEN.toLowerCase() &&
      !isCancelAction
    )
      shiftIdList.push(item.id);
    else {
      shiftIdList.push(item.id);
    }
    return shiftIdList;
  }, []);
};

export const totalDeletableShifts = (
  selectedShifts: ShiftPosition[]
): number => {
  return selectedShifts.reduce(
    (total, shift) =>
      shift.shiftStatus === ShiftStatus.OPEN ? total + 1 : total,
    0
  );
};

export const totalCancelableShifts = (
  selectedShifts: ShiftPosition[]
): number => {
  return selectedShifts.reduce(
    (total, x) => (x.shiftStatus !== ShiftStatus.OPEN ? total + 1 : total),
    0
  );
};

export const canOfferSelectedShifts = (shiftList: ShiftPosition[]): boolean => {
  const setWorkers = [
    ...new Set(shiftList.map((x) => x.jobAssignment?.worker.id)),
  ];

  return (
    shiftList?.some(
      (x) => x.shiftStatus.toLowerCase() === ShiftStatus.OPEN.toLowerCase()
    ) && setWorkers.length <= 1
  );
};

export const canCancelSelectedShifts = (
  shiftList: ShiftPosition[]
): boolean => {
  return shiftList?.some(
    (x) => x.shiftStatus.toLowerCase() !== ShiftStatus.OPEN.toLowerCase()
  );
};

export const canDeleteSelectedShifts = (
  shiftList: ShiftPosition[]
): boolean => {
  return shiftList?.some(
    (x) => x.shiftStatus.toLowerCase() === ShiftStatus.OPEN.toLowerCase()
  );
};

export const sortFunction = (properties: ShiftSortAttributes[]) => {
  return (a: ShiftPosition, b: ShiftPosition): number => {
    for (let i = 0; i < properties.length; i += 1) {
      const { key, order } = properties[i];
      if (a[key]! < b[key]!) return order === 'asc' ? -1 : 1;
      if (a[key]! > b[key]!) return order === 'asc' ? 1 : -1;
    }
    return 0;
  };
};

export const sortByTypeAndStart: ShiftSortAttributes[] = [
  { key: 'type', order: 'desc' },
  { key: 'start', order: 'asc' },
];

export const filterShiftsByDate = (shifts: ShiftPosition[]) => {
  return Object.entries(
    groupBy(shifts.sort(sortFunction(sortByTypeAndStart)), 'date')
  ).map(([date, data]) => ({ date, data }));
};
