import { Alert } from 'react-bootstrap';
import IMessageData, { ISystemMessageData } from '@th-types/message.type';
import { useUserContext } from '@state/userContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import useLocalFormatDate from '@hooks/useLocalFormatDate';
import QueryKeys from '@constants/queryKeys';
import Messages from '@th-types/messages.type';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import * as api from '@company/services/chat/api';
import { useChatContext } from '@company/state/chatContext';
import { useJobContext } from '@company/state/jobContext';
import { MINUTE, TEN_MINUTES } from '@constants/various';
import { MessageObject } from '@th-types/message-request.type';
import useAlert from '@hooks/useAlert';
import ChatMessage from '@company/components/ChatMessage/ChatMessage';
import { IWorkerData } from '@th-types/worker.type';

import './styles.css';
import ApplicationMessage from '@company/components/ChatMessage/ApplicationMessage';

export interface ISortableMessages {
  id: number;
  created: Date;
  createdTimestamp: number;
  message?: IMessageData;
  systemMessage?: ISystemMessageData;
  sent: boolean;
}

interface Params {
  messageRecentlySent: MessageObject | null;
  setMessageRecentlySent: (value: MessageObject | null) => void;
}

export const MESSAGE_UNSENT = `MESSAGE UNSENT: we couldn’t send your message, please try again`;

export enum SystemMessagesTypesEnum {
  COMPANY_UPDATED_SUBMITTED_TIME = 'COMPANY_UPDATED_SUBMITTED_TIME',
  COMPANY_ADDED_TIMESHEET = 'COMPANY_ADDED_TIMESHEET',
}

export default function MessagesChat({
  messageRecentlySent,
  setMessageRecentlySent,
}: Params) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { id: userId, profileImageUrl, name } = useUserContext();
  const { job } = useJobContext();
  const {
    chatAssignmentId,
    messages,
    setMessages,
    setSystemMessages,
    unsentMessages,
    orderedMessages,
    setUnsentMessages,
  } = useChatContext();
  const formatDate = useLocalFormatDate();

  const { showErrorAlert, alertMessage } = useAlert();
  const [refetchInterval, setRefetchInterval] = useState(TEN_MINUTES);
  const [personList, setPersonList] = useState<IWorkerData[] | undefined>([]);

  const fetchMessages = useCallback(() => {
    if (!chatAssignmentId) {
      return new Promise<Messages>((resolve) => {
        const emptyResponse: Messages = {} as Messages;
        resolve(emptyResponse);
      });
    }
    return api.fetchMessages(chatAssignmentId);
  }, [chatAssignmentId]);

  const { data, error, refetch } = useQuery<Messages, AxiosError>({
    queryKey: [`${QueryKeys.MESSAGE_CONVERSATIONS}_${chatAssignmentId}`],
    queryFn: fetchMessages,
    enabled: chatAssignmentId !== null,
    refetchInterval,
    refetchIntervalInBackground: true,
    retry: (failureCount) => failureCount < 3,
    retryDelay: MINUTE / 2,
  });

  useEffect(() => {
    if (error) {
      setRefetchInterval(0);
    }
  }, [error]);

  useEffect(() => {
    setMessages(data?.messages || []);
    setSystemMessages(data?.systemMessages || []);
    setPersonList(data?.personList);
    setTimeout(() => {
      if (containerRef.current && 'scrollTop' in containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }, 100);
  }, [data, setMessages, setSystemMessages]);

  useEffect(() => {
    async function fetchData() {
      await refetch();
      if (containerRef.current && 'scrollTop' in containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }
    fetchData();
  }, [chatAssignmentId, refetch]);

  useEffect(() => {
    // It adds a new message on the message list
    const saveNewSentMessage = async (newMessage: IMessageData) => {
      await setMessages([...(messages || []), newMessage]);
      if (
        unsentMessages &&
        unsentMessages.some((x) => x.id === newMessage.id)
      ) {
        await setUnsentMessages(
          unsentMessages.filter((x) => x.id !== newMessage.id)
        );
      }
    };

    // Updates an existing message on the list replacing the sent field
    async function updateExistingMessage(newMessage: IMessageData) {
      if (messages && messageRecentlySent) {
        const newMessageList = messages.filter((msg) => {
          return msg.id !== newMessage.id;
        });
        newMessageList.push({
          ...newMessage,
          sent: messageRecentlySent.sent,
        });
        await setMessages(newMessageList);
        await setUnsentMessages(
          unsentMessages?.filter((x) => x.id !== newMessage.id)
        );
      }
    }

    const setNewMessage = async () => {
      if (messageRecentlySent) {
        const newMessage: IMessageData = {
          id: messageRecentlySent.id,
          authorId: userId,
          created: new Date(),
          jobId: job?.id || messageRecentlySent.id,
          text: messageRecentlySent.message,
          sent: messageRecentlySent.sent,
          readBy: [userId],
          sending: true,
        };
        if (messages) {
          if (newMessage.sent) {
            saveNewSentMessage(newMessage);
          } else {
            const messageExists = messages.some((msg) => {
              return msg.id === newMessage.id;
            });
            if (messageExists) {
              updateExistingMessage(newMessage);
            } else {
              await setUnsentMessages([...(unsentMessages || []), newMessage]);
              await setMessages([...messages, newMessage]);
            }
          }
        } else {
          setMessages([newMessage]);
        }
        if (newMessage.sent !== true) {
          showErrorAlert(MESSAGE_UNSENT);
        }
        setMessageRecentlySent(null);
        setTimeout(() => {
          if (containerRef.current && 'scrollTop' in containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
          }
        }, 100);
      }
    };
    setNewMessage();
  }, [
    job?.id,
    messageRecentlySent,
    messages,
    setMessageRecentlySent,
    setMessages,
    setUnsentMessages,
    showErrorAlert,
    unsentMessages,
    userId,
  ]);

  const resendMessage = async (message: IMessageData | undefined) => {
    if (!messages || !message) {
      return;
    }
    const resultMessageSent = await api.sendMessage(chatAssignmentId, {
      message: message.text,
    });
    if (resultMessageSent?.success) {
      const newMessageList = messages.filter((msg) => {
        return msg.id !== message.id;
      });
      newMessageList.push({
        ...message,
        created: new Date(),
        sent: true,
      });
      setMessages(newMessageList);
    } else {
      showErrorAlert(MESSAGE_UNSENT);
    }
  };

  const getAvatarImageUrl = (authorId: number | undefined): string => {
    if (authorId === userId) {
      return profileImageUrl;
    }
    const author = personList?.find((person) => person.id === authorId);
    return author ? author.profileImageUrl : '';
  };

  const getAuthorName = (authorId: number | undefined): string => {
    if (authorId === userId) {
      return name;
    }
    const author = personList?.find((person) => person.id === authorId);
    return author ? author.fullName : '';
  };

  const isFirstMessageInBlock = (index: number) => {
    if (index === 0) {
      return true;
    }

    const actualMessage = orderedMessages[index];
    const previousMessage = orderedMessages[index - 1];
    const actualDate = new Date(actualMessage.message?.created as Date);
    const previousDate = new Date(previousMessage.message?.created as Date);

    return (
      actualMessage.message?.authorId !== previousMessage.message?.authorId ||
      (actualDate.getTime() - previousDate.getTime()) / 1000 / 60 > 1
    );
  };

  const renderSystemMessage = (systemMessage: ISystemMessageData) => {
    let message = '';

    const timeSheetSystemMessages = [
      SystemMessagesTypesEnum.COMPANY_UPDATED_SUBMITTED_TIME,
      SystemMessagesTypesEnum.COMPANY_ADDED_TIMESHEET,
    ];

    if (
      timeSheetSystemMessages.includes(
        systemMessage.type as SystemMessagesTypesEnum
      ) &&
      systemMessage.text
    ) {
      message = systemMessage.text;
    } else if (
      systemMessage?.eventText &&
      !timeSheetSystemMessages.includes(
        systemMessage.type as SystemMessagesTypesEnum
      )
    ) {
      message = systemMessage.eventText;
    }
    return (
      <i key={systemMessage.id} style={{ fontSize: '12px' }}>
        {formatDate(systemMessage.created.toString())} {message}
        <br />
      </i>
    );
  };

  return (
    <div
      ref={containerRef}
      style={{
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingTop: '10px',
        paddingRight: '10px',
      }}
    >
      {orderedMessages &&
        orderedMessages.map((messageData, index) => {
          const { id, message, systemMessage } = messageData;
          return (
            <>
              {message && (
                <ChatMessage
                  key={id}
                  authorName={getAuthorName(message.authorId)}
                  message={message}
                  sentByMe={userId === messageData.message?.authorId}
                  avatarImageUrl={getAvatarImageUrl(message?.authorId)}
                  resendMessage={resendMessage}
                  firstInBlock={isFirstMessageInBlock(index)}
                />
              )}
              {systemMessage && (
                <div key={id} className="text-center">
                  {renderSystemMessage(systemMessage)}
                </div>
              )}
              {systemMessage?.type === 'WORKER_INTERESTED_IN_JOB' &&
                systemMessage?.text && (
                  <ApplicationMessage text={systemMessage?.text as string} />
                )}
            </>
          );
        })}
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </div>
  );
}
