import { useCallback, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import type { GroupBase, OptionsOrGroups } from 'react-select';
import { ThJobSelect } from '@company/components/th';
import * as jobApi from '@company/services/job/api';
import { IRateWorkerCreationModal } from '@company/types/rate-worker-creation.type';
import IJobDetailData from '@th-types/job.detail.type';

interface Props {
  show: boolean;
  handleClose: () => void;
  handleContinue: (data: IRateWorkerCreationModal) => void;
}
function AddReviewModal({ show = false, handleClose, handleContinue }: Props) {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isThJob, setIsThJob] = useState<boolean>(true);
  const [selectedThJob, setSelectedThJob] = useState<IJobDetailData | null>(
    null
  );

  const fetchThJobs = useCallback(async ({ query = '', pageParam = 0 }) => {
    setIsFetching(true);
    try {
      return await jobApi.fetchSuggestedJobs(query, pageParam);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const loadOptions = async (
    search: string,
    prevOptions: OptionsOrGroups<IJobDetailData, GroupBase<IJobDetailData>>
  ) => {
    const result = await fetchThJobs({ query: search });
    const { results, pagination } = result;
    return {
      options: results,
      hasMore: pagination.count > prevOptions.length + results.length,
    };
  };

  const onHide = () => {
    setIsThJob(true);
    setSelectedThJob(null);
    handleClose();
  };

  const handleSubmit = () => {
    const data = {
      isThJob,
      selectedThJob: isThJob ? selectedThJob : null,
    };
    handleContinue(data);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Worker Rating</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="startRating">
          <Row>
            <Col md={12} xs={12}>
              <Form.Group className="mb-3" controlId="thJob-yes">
                <Form.Label className="me-3 col-12">
                  <b>Was this job posted through Trusted Herd?</b>
                </Form.Label>
                <Form.Check
                  key="isThJob.yes"
                  inline
                  required
                  label="Yes"
                  type="radio"
                  value="true"
                  checked={isThJob}
                  onChange={(e) => setIsThJob(JSON.parse(e.target.value))}
                  id="thJob-yes"
                />
                <Form.Check
                  key="isThJob.no"
                  inline
                  required
                  label="No"
                  type="radio"
                  value="false"
                  checked={!isThJob}
                  onChange={(e) => setIsThJob(JSON.parse(e.target.value))}
                  id="thJob-no"
                />
              </Form.Group>
            </Col>
          </Row>
          {isThJob && (
            <Row>
              <Col md={12} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="selectedThJob" className="me-3 col-12">
                    <b>Please select a job from your list of posted jobs:</b>
                  </Form.Label>
                  <ThJobSelect
                    name="selectedThJob"
                    isFetching={isFetching}
                    loadOptions={loadOptions}
                    handleChangeJob={setSelectedThJob}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          <b>CANCEL</b>
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          form="startRating"
          disabled={isThJob && !selectedThJob}
        >
          <b>CONTINUE</b>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddReviewModal;
