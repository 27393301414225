import { Button, Modal } from 'react-bootstrap';
import { useUserContext } from '@state/userContext';

function AuthFailedModal() {
  const { loggedIn } = useUserContext();

  const goToLogin = () => {
    window.location.href = `${import.meta.env.VITE_SERVER_BASE_URL}/login/auth`;
  };

  return (
    <Modal show={!loggedIn} centered>
      <Modal.Header style={{ justifyContent: 'center' }}>
        <Modal.Title>You have been logged out.</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>
        Your session has expired. Please log in again to continue.
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button variant="primary" onClick={goToLogin}>
          GO TO LOGIN
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AuthFailedModal;
