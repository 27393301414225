import { Badge } from 'react-bootstrap';

interface Params {
  indicatorValue: number | undefined;
  showSmall?: boolean;
  addClass?: string;
  anyIndicatorValue?: boolean;
}

const renderIndicator = (
  addClass: string | undefined,
  showSmall: boolean | undefined,
  indicatorValue: number | undefined
) => {
  const classNames = (): string => {
    let classesToAdd = 'text-dark';
    if (addClass) {
      classesToAdd = `${classesToAdd} ${addClass}`;
    }
    return classesToAdd;
  };

  return showSmall ? (
    <small>
      <Badge
        pill
        bg="primary"
        className={classNames()}
      >{`${indicatorValue}`}</Badge>
    </small>
  ) : (
    <Badge
      pill
      bg="primary"
      className={classNames()}
    >{`${indicatorValue}`}</Badge>
  );
};
export default function ThBadgeIndicator({
  indicatorValue,
  showSmall,
  addClass,
  anyIndicatorValue = false,
}: Params) {
  // Don't render 0 values
  if (anyIndicatorValue || (!anyIndicatorValue && indicatorValue)) {
    return renderIndicator(addClass, showSmall, indicatorValue);
  }
  return null;
}
