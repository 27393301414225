import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { IWorkerData, Skill } from '@th-types/worker.type';
import {
  Comment,
  Education,
  IWorkerEndorsementsData,
  NonPromoWorkExperience,
  PromoWorkExperience,
} from '@th-types/workerProfile.type';
import Picture from '@th-types/picture.type';
import { ICertificatesData } from '@th-types/certificates.type';
import { CountryName } from '@th-types/address.type';
import * as workerApi from '@worker/services/worker/api';
import { WorkerProfileAllowedOptions } from '@worker/types/worker-profile-types.type';

interface IWorkerProfileContext {
  isLoadingWorkerProfile: boolean;
  nonPromoWorkExperience: NonPromoWorkExperience[] | undefined;
  promoWorkExperience: PromoWorkExperience[] | undefined;
  setNonPromoWorkExperience: React.Dispatch<
    React.SetStateAction<NonPromoWorkExperience[] | undefined>
  >;
  setPromoWorkExperience: React.Dispatch<
    React.SetStateAction<PromoWorkExperience[] | undefined>
  >;
  setWorkerCertificates: React.Dispatch<
    React.SetStateAction<ICertificatesData | undefined>
  >;
  setWorkerEducation: React.Dispatch<
    React.SetStateAction<Education[] | undefined>
  >;
  setWorkerEndorsements: React.Dispatch<
    React.SetStateAction<IWorkerEndorsementsData | undefined>
  >;
  setWorkerMediaFiles: React.Dispatch<
    React.SetStateAction<Picture[] | undefined>
  >;
  setWorkerProfile: React.Dispatch<
    React.SetStateAction<IWorkerData | undefined>
  >;
  setWorkerReviews: React.Dispatch<React.SetStateAction<Comment[] | undefined>>;
  setWorkerSkills: React.Dispatch<React.SetStateAction<Skill[] | undefined>>;
  workerCertificates: ICertificatesData | undefined;
  workerEducation: Education[] | undefined;
  workerEndorsements: IWorkerEndorsementsData | undefined;
  workerMediaFiles: Picture[] | undefined;
  workerProfile: IWorkerData | undefined;
  workerProfileAllowedOptions: WorkerProfileAllowedOptions | undefined;
  workerReviews: Comment[] | undefined;
  workerSkills: Skill[] | undefined;
  surveysEnabled: boolean;
}

interface ProviderProps {
  children: React.ReactNode;
}

export const WorkerProfileContext = createContext<IWorkerProfileContext | null>(
  null
);

export function WorkerProfileProvider({ children }: ProviderProps) {
  const [nonPromoWorkExperience, setNonPromoWorkExperience] = useState<
    NonPromoWorkExperience[] | undefined
  >();
  const [promoWorkExperience, setPromoWorkExperience] = useState<
    PromoWorkExperience[] | undefined
  >();
  const [workerCertificates, setWorkerCertificates] = useState<
    ICertificatesData | undefined
  >();
  const [workerEducation, setWorkerEducation] = useState<
    Education[] | undefined
  >([]);
  const [workerEndorsements, setWorkerEndorsements] = useState<
    IWorkerEndorsementsData | undefined
  >();
  const [workerMediaFiles, setWorkerMediaFiles] = useState<
    Picture[] | undefined
  >([]);
  const [workerProfile, setWorkerProfile] = useState<IWorkerData | undefined>();
  const [workerProfileAllowedOptions, setWorkerProfileAllowedOptions] =
    useState<WorkerProfileAllowedOptions>();
  const [workerReviews, setWorkerReviews] = useState<Comment[] | undefined>([]);
  const [workerSkills, setWorkerSkills] = useState<Skill[] | undefined>([]);
  const [surveysEnabled, setSurveysEnabled] = useState(false);

  const { data, isLoading: isLoadingWorkerProfile } = useQuery({
    queryKey: ['worker-profile'],
    queryFn: workerApi.getWorker,
  });

  const { data: workerOptionsData, isLoading: isLoadingWorkerOptions } =
    useQuery({
      queryKey: ['worker-options'],
      queryFn: workerApi.getWorkerOptions,
    });

  const { data: workerCertificatesData } = useQuery({
    queryKey: ['worker-profile-certificates'],
    queryFn: workerApi.getAvailableCertificates,
  });

  const isUSABased = useCallback(() => {
    const country = workerProfile?.address?.country;
    // I think this is valid only for mock data, from server the country is always an object
    if (typeof country === 'string') {
      return country === 'USA';
    }
    // If it's a Country object
    if (country && typeof country === 'object') {
      return country.name === CountryName.UNITED_STATES;
    }
    return false;
  }, [workerProfile?.address]);

  useEffect(() => {
    if (!isLoadingWorkerProfile && !isLoadingWorkerOptions) {
      setSurveysEnabled(
        isUSABased() && !!workerProfileAllowedOptions?.surveysEnabled
      );
    }
  }, [
    isUSABased,
    isLoadingWorkerProfile,
    isLoadingWorkerOptions,
    workerProfileAllowedOptions?.surveysEnabled,
  ]);

  useEffect(() => {
    setNonPromoWorkExperience(data?.nonPromoWorkExperiences?.values);
    setPromoWorkExperience(data?.promoWorkExperiences?.values);
    setWorkerEducation(data?.educations?.values);
    setWorkerEndorsements(data?.workerEndorsements);
    setWorkerMediaFiles(data?.worker?.additionalPictures?.pictures);
    setWorkerReviews(data?.comments);
    setWorkerProfile(data?.worker); // CHECK TYPES
    setWorkerSkills(data?.worker?.skills);
  }, [data]);

  useEffect(() => {
    if (workerOptionsData) {
      setWorkerProfileAllowedOptions(workerOptionsData);
    }
  }, [workerOptionsData, isLoadingWorkerOptions]);

  useEffect(() => {
    if (workerCertificatesData) {
      setWorkerCertificates(workerCertificatesData);
    }
  }, [workerCertificatesData]);

  const contextValue = useMemo(
    () => ({
      isLoadingWorkerProfile,
      nonPromoWorkExperience,
      promoWorkExperience,
      setNonPromoWorkExperience,
      setPromoWorkExperience,
      setWorkerCertificates,
      setWorkerEducation,
      setWorkerEndorsements,
      setWorkerMediaFiles,
      setWorkerProfile,
      setWorkerReviews,
      setWorkerSkills,
      workerCertificates,
      workerEducation,
      workerEndorsements,
      workerMediaFiles,
      workerProfile,
      workerProfileAllowedOptions,
      workerReviews,
      workerSkills,
      surveysEnabled,
    }),
    [
      isLoadingWorkerProfile,
      nonPromoWorkExperience,
      promoWorkExperience,
      workerCertificates,
      workerEducation,
      workerEndorsements,
      workerMediaFiles,
      workerProfile,
      workerProfileAllowedOptions,
      workerReviews,
      workerSkills,
      surveysEnabled,
    ]
  );

  return (
    <WorkerProfileContext.Provider value={contextValue}>
      {children}
    </WorkerProfileContext.Provider>
  );
}

export function useWorkerProfileContext() {
  const context: IWorkerProfileContext | null =
    useContext(WorkerProfileContext);
  if (!context) {
    throw new Error(
      'useWorkerProfileContext must be used within a WorkerProfileProvider. Wrap a parent component in <WorkerProfileProvider> to fix this error.'
    );
  }
  return context;
}
