import { Container, Row, Col } from 'react-bootstrap';

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

function SurveyFailure() {
  const handleRedirect = () => {
    const fullPath = `${window.location.origin}${BASE_PATH}survey`;
    window.location.href = fullPath;
  };

  return (
    <Container
      fluid
      className="vh-100 d-flex align-items-center justify-content-center"
    >
      <Row className="text-center">
        <Col>
          <h1 className="display-6 text-danger">Survey was terminated</h1>
          <p className="lead">
            You&apos;re not qualified for this survey, but you may try again.
          </p>
          <button
            type="button"
            onClick={handleRedirect}
            className="btn btn-primary"
          >
            Try Again
          </button>
        </Col>
      </Row>
    </Container>
  );
}

export default SurveyFailure;
