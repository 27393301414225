import { EventContentArg } from '@fullcalendar/core';
import { BiEdit } from '@react-icons/all-files/bi/BiEdit';
import { format } from 'date-fns';
import {
  Button,
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from 'react-bootstrap';
import Avatar from 'react-avatar';
import { ShiftPosition } from '@th-types/job.detail.type';
import DATE_FORMAT from '@constants/dateFormat';

export default function renderEventContentMobile(
  eventContent: EventContentArg,
  shifts?: ShiftPosition[]
) {
  const { backgroundColor, id, start, end, title } = eventContent.event;
  const yellow = getComputedStyle(document.body).getPropertyValue('--yellow');
  const black = getComputedStyle(document.body).getPropertyValue('--black');

  return (
    <div
      className="text-start p-2 w-100"
      style={{
        position: 'relative',
        backgroundColor: `var(${backgroundColor})`,
        borderRadius: '4px',
        padding: '3px 10px',
        gap: '10px',
      }}
    >
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={
          <Popover id={id} className="event-content-mobile">
            <Popover.Header className="bg-white">
              <span className="fw-bold fs-5">{title}</span>
              <div>
                <span className="me-3">
                  {format(start as Date, DATE_FORMAT.DAY_MONTH_DATE_FORMAT)}
                </span>
                <div>{`${format(
                  start as Date,
                  DATE_FORMAT.TIME_FORMAT
                )} - ${format(end as Date, DATE_FORMAT.TIME_FORMAT)}`}</div>
              </div>
            </Popover.Header>
            <Popover.Body className="p-0">
              <Stack>
                {shifts?.map((shift) =>
                  shift.jobAssignment ? (
                    <Row key={shift.id} className="align-items-center p-3">
                      <Col xs={2}>
                        <Avatar
                          src={shift.jobAssignment.worker.profileImageUrl}
                          size="30"
                          round
                          maxInitials={2}
                          name={`${shift.jobAssignment.worker.firstName} ${shift.jobAssignment.worker.lastName}`}
                          color={yellow}
                          fgColor={black}
                        />
                      </Col>
                      <Col className="ps-0">
                        {shift.jobAssignment.worker.firstName}{' '}
                        {shift.jobAssignment.worker.lastName}
                      </Col>
                      <Col xs={2}>
                        <BiEdit color="var(--black)" size={20} />
                      </Col>
                    </Row>
                  ) : null
                )}
              </Stack>
            </Popover.Body>
          </Popover>
        }
      >
        <div>
          <Stack
            direction="horizontal"
            className="w-100 justify-content-between"
          >
            <div>
              <strong>{title}</strong>
              <br />
              <strong>{eventContent.timeText}</strong>
            </div>
            <Button
              className="rounded-circle bi bi-plus bi-lg"
              size="sm"
              variant="light"
              type="button"
            />
          </Stack>
        </div>
      </OverlayTrigger>
    </div>
  );
}
