import CreateShiftModal from '@company/pages/JobOverview/Scheduling/CreateShift/CreateShiftModal';
import { useJobWorkerShiftContext } from '@company/state/jobWorkerShiftContext';
import { useJobContext } from '@company/state/jobContext';
import { getFormattedTime } from '@services/shift/shiftService';
import {
  hasSameShiftAssigned,
  removeDuplicatedShifts,
} from '@utils/ShiftsUtils';
import { ShiftPosition } from '@th-types/job.detail.type';
import { BiEdit } from '@react-icons/all-files/bi/BiEdit';
import { useId, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Alert, Form } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import useAlert from '@hooks/useAlert';
import { capitalize } from 'lodash';
import { useJobShiftContext } from '@company/state/jobShiftContext';
import { ShiftStatus } from '@th-types/jobs.type';

interface JobWorkerShiftTableProps {
  date: string;
  shifts: ShiftPosition[];
  handleSelecting: (id: number) => void;
  isSelectable: boolean;
  isEditable?: boolean;
}

function JobWorkerShiftTable({
  date,
  shifts,
  handleSelecting,
  isSelectable,
  isEditable,
}: JobWorkerShiftTableProps) {
  const [showModal, setShowModal] = useState(false);
  const [shiftSelected, setShiftSelected] = useState<
    ShiftPosition | undefined
  >();
  const handleShow = (shift: ShiftPosition) => {
    setShiftSelected(shift);
    setShowModal(true);
  };
  const handleClose = () => {
    setShiftSelected(undefined);
    setShowModal(false);
  };
  const { shifts: allShifts, selectedWorker } = useJobShiftContext();
  const { selectedJobWorkerShifts: selectedShifts } =
    useJobWorkerShiftContext();
  const { job, jobTimezone } = useJobContext();
  const { showSuccessAlert, alertMessage } = useAlert();
  const divId = useId();
  const jobKey = useId();

  const shiftCreatedCloseModal = (message?: string) => {
    showSuccessAlert(
      message ?? `Job shift${isMobile ? 's' : ''} successfully created`
    );
    handleClose();
  };

  const uniqueShifts = useMemo(() => {
    const uniqueFilteredShifts = removeDuplicatedShifts(shifts);
    if (
      uniqueFilteredShifts?.some(
        (shift) => shift.shiftStatus === ShiftStatus.OPEN
      ) &&
      selectedWorker &&
      allShifts
    ) {
      return uniqueFilteredShifts.filter(
        (shift) => !hasSameShiftAssigned(selectedWorker, shift, allShifts)
      );
    }
    return uniqueFilteredShifts;
  }, [allShifts, selectedWorker, shifts]);

  if (!job) {
    return null;
  }

  if (uniqueShifts.length === 0) {
    return null;
  }

  return (
    <div key={divId}>
      <div className="table-date fw-bold">{date}</div>
      <hr className="table-horizontal-line" />
      <Table responsive borderless>
        <thead className="table-header fw-bold">
          <tr>
            {isSelectable && (
              <th style={{ width: '3%' }} aria-label="checkbox" />
            )}
            <th style={{ width: '21%' }} className="text-start">
              Position
            </th>
            <th style={{ width: '15%' }}>Scheduled Shift Time</th>
            <th style={{ width: '10%' }}>Full/Back Up</th>
            <th style={{ width: '10%' }}>Wage</th>
            <th style={{ width: '10%' }}>Paid Break</th>
            {isEditable && <th style={{ width: '2%' }} aria-label="edit" />}
          </tr>
        </thead>
        <tbody className="table-body">
          {uniqueShifts.map((shift) => (
            <tr key={`${jobKey}-${shift.id}-${shift.positionIndex}`}>
              {isSelectable && (
                <td>
                  <Form.Check
                    type="checkbox"
                    id={`checkbox-${shift.id}`}
                    checked={
                      selectedShifts &&
                      selectedShifts.some((x) => x.id === shift.id)
                    }
                    onChange={() => handleSelecting(shift.id)}
                  />
                </td>
              )}
              <td className="text-start">{shift.title.name}</td>
              <td>{getFormattedTime(shift, jobTimezone)}</td>
              <td>{capitalize(shift.type)}</td>
              <td>{`$${shift.wage.payRate}/${capitalize(
                shift.wage.payRatePeriod
              )}`}</td>
              <td>{shift.breakPaid ? 'Yes' : 'No'}</td>
              {isEditable && (
                <td className="text-end">
                  <BiEdit
                    color="var(--black)"
                    className="cursor-point"
                    onClick={() => handleShow(shift)}
                    size={24}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <hr className="table-horizontal-line" />
      {showModal && shiftSelected && (
        <CreateShiftModal
          job={job}
          isWorkerSideModalOpen
          shift={shiftSelected}
          handleClose={handleClose}
          shiftCreatedCloseModal={shiftCreatedCloseModal}
        />
      )}
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </div>
  );
}

export default JobWorkerShiftTable;
