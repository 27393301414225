import { privateApi } from '@services/api';
import IResultResponse from '@th-types/resultResponse.type';
import IBatchRateWorkerSession, {
  IBatchInviteWorkers,
  IBatchWorkerRatings,
  IBatchWorkerResponse,
  IBatchRateWorkerSessionResponse,
} from '@company/types/batch-rate-worker.type';
import { RateWorkerPayload } from '@th-types/rating.type';
import { JobAssignmentRatingType } from '@company/types/job-assignment-rating.type';

const WORKER_RATINGS_PATH = '/company/worker-ratings';

export const workerRatingsSession = async (
  dataRequest: IBatchRateWorkerSession
) => {
  try {
    const result = await privateApi.post<IBatchRateWorkerSessionResponse>(
      `${WORKER_RATINGS_PATH}/session`,
      dataRequest
    );
    return result?.data;
  } catch (error) {
    console.error(
      `Occurred an error when Trusted Herd tried to save the session for Worker Ratings`
    );
    console.error(error);
    return Promise.reject(error);
  }
};

export const workerRatingsSessionBatch = async (
  sessionId: number,
  dataRequest: IBatchWorkerRatings
) => {
  try {
    const result = await privateApi.post<IBatchWorkerResponse>(
      `${WORKER_RATINGS_PATH}/session/${sessionId}/batch-rate`,
      dataRequest
    );
    return result.data;
  } catch (error) {
    console.error(
      `Occurred an error when Trusted Herd tried to Worker Ratings Batch`
    );
    console.error(error);
    return Promise.reject(error);
  }
};

export const workerRatingsAskForRatingCompany = async (
  workersIds: IBatchInviteWorkers
) => {
  try {
    const result = await privateApi.post<IResultResponse>(
      `${WORKER_RATINGS_PATH}/ask-for-rating`,
      workersIds
    );
    return result?.data;
  } catch (error) {
    console.error(
      `Occurred an error when Trusted Herd tried to ask workers to rate company`
    );
    console.error(error);
    return Promise.reject(error);
  }
};

export const getJobRatingByJobAssignment = async (jobAssignmentId: number) => {
  try {
    const result = await privateApi.get<JobAssignmentRatingType>(
      `/assignments/${jobAssignmentId}/ratings`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const getReceivedCompanyRating = async (jobAssignmentId: number) => {
  try {
    const result = await privateApi.get<JobAssignmentRatingType>(
      `/assignments/${jobAssignmentId}/received-company-rating`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const getGivenWorkerRating = async (jobAssignmentId: number) => {
  try {
    const result = await privateApi.get<JobAssignmentRatingType>(
      `/assignments/${jobAssignmentId}/given-worker-rating`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const rateWorker = async (payload: RateWorkerPayload) => {
  try {
    return await privateApi.post('/company/worker-ratings', payload);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const fetchShiftDailyActivity = async (
  jobAssignmentId: number,
  shiftId: number
) => {
  const result = await privateApi.get(
    `/assignments/${jobAssignmentId}/shifts/${shiftId}/daily-activity`
  );
  return result.data;
};
