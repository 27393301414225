import React from 'react';
import ContentLoader from 'react-content-loader';

interface LoaderProps {
  screen: 'mobile' | 'desktop' | 'large-screen';
}

function Loader(props: LoaderProps): JSX.Element {
  let height: number;
  let width;
  const { screen } = props;
  switch (screen) {
    case 'mobile': {
      height = 60;
      width = '400';
      break;
    }
    case 'desktop': {
      height = 60;
      width = '1060';
      break;
    }
    case 'large-screen': {
      height = 60;
      width = '1920';
      break;
    }
    default: {
      height = 60;
      width = '1060';
      break;
    }
  }

  const buttonWidth = 140;
  const buttonHeight = 50;
  const spacing = 20;

  return (
    <ContentLoader
      viewBox={`0 0 ${width} ${height}`}
      height={height}
      width="100%"
      {...props}
    >
      {Array.from({ length: 6 }, (_, i) => (
        <rect
          key={i}
          x={100 + i * (buttonWidth + spacing)}
          y={(height - buttonHeight) / 2}
          rx="5"
          ry="5"
          width={buttonWidth}
          height={buttonHeight}
        />
      ))}
    </ContentLoader>
  );
}

function LoaderJobWorkerActions(): JSX.Element {
  return <Loader screen="desktop" />;
}

export default LoaderJobWorkerActions;
