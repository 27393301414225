/*
The idea for this polyfill utils is to group validations and useful functionalities that
can be used over native browser rather than react itself.

The function below is responsible for verify if browser supports the 'passive' property,
which is used, for example, on addEventListener for better scroll performance.
Right now, we're using at calendar and datepicker mousedown and click events.

This feature shipped in Chrome 51, Firefox 49 and landed in WebKit.
For further info, RTD: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
*/
const supportsPassiveOnEventListener = () => {
  let supportsPassive = false;
  try {
    const fn = () => null;
    const opts = Object.defineProperty({}, 'passive', {
      get() {
        supportsPassive = true;
        return supportsPassive;
      },
    });
    window.addEventListener('testPassive', fn, opts);
    window.removeEventListener('testPassive', fn, opts);
  } catch (e) {
    /* empty */
  }
  return supportsPassive;
};

export { supportsPassiveOnEventListener };
