import { Row } from 'react-bootstrap';
import SurveyIntro from './SurveyIntro';
import SurveyBank from './SurveyBank';
import './styles.scss';

function SurveyHeaderInfo({
  surveyAttemptLimit,
  fetchSurveyLink,
}: Readonly<{
  surveyAttemptLimit: boolean;
  fetchSurveyLink: () => void;
}>) {
  return (
    <div className="survey-section">
      <Row>
        <SurveyIntro
          surveyAttemptLimit={surveyAttemptLimit}
          fetchSurveyLink={fetchSurveyLink}
        />
        <SurveyBank
          surveyAttemptLimit={surveyAttemptLimit}
          fetchSurveyLink={fetchSurveyLink}
        />
      </Row>
    </div>
  );
}

export default SurveyHeaderInfo;
