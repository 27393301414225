export default interface AlertModel {
  message: string;
  variant: AlertVariantEnum;
  show: boolean;
}

export interface AlertModelAction {
  type: AlertModelAttributesEnum;
  value: any;
}

export enum AlertVariantEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  LIGHT = 'light',
  DARK = 'dark',
}

export enum AlertModelAttributesEnum {
  MESSAGE = 'message',
  VARIANT = 'variant',
  SHOW = 'show',
}

export function reducerAlertMessage(
  state: AlertModel,
  action: AlertModelAction
) {
  let newAlertModelState = state;
  switch (action.type) {
    case 'message':
      newAlertModelState = { ...state, message: action.value };
      break;
    case 'variant':
      newAlertModelState = { ...state, variant: action.value };
      break;
    case 'show':
      if (state.message) {
        newAlertModelState = { ...state, show: action.value };
        if (action.value === true) {
          setTimeout(() => {
            newAlertModelState = { ...state, show: false };
          });
        }
      }
      break;
    default:
      throw new Error(`There is no action with type: ${action.type}`);
  }
  return newAlertModelState;
}

export const alertInitialState: AlertModel = {
  message: '',
  variant: AlertVariantEnum.SUCCESS,
  show: false,
};
