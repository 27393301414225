import { getFilteredData } from '@services/activity-tracker/activityTrackerService';
import { useActivityContext } from '@company/state/activityContext';
import { useChatContext } from '@company/state/chatContext';
import { useJobContext } from '@company/state/jobContext';
import SideEndModal from '@components/elements/SideEndModal';
import { ActivityDetail } from '@th-types/daily-activity.type';
import { JobAssignment } from '@th-types/job-assignment.type';
import { useCallback, useEffect, useState } from 'react';
import { UTC_TIMEZONE } from '@utils/DateUtils';
import { isMobile } from 'react-device-detect';
import Chat from '@components/Chat/Chat';
import useAlert from '@hooks/useAlert';
import { Alert } from 'react-bootstrap';
import { MemberAddHoursModal } from './MemberAddHoursModal/MemberAddHoursModal';
import ActivityTrackerTableHeader from './ActivityTrackerTableHeader';
import ShiftReviewModal from './ShiftReviewModal/ShiftReviewModal';
import ActivityTrackerDesktopTable from './ActivityTrackerDesktopTable';
import FeedbackModal from './FeedbackModal';
import './styles.scss';

interface Props {
  viewType: string;
  groupedBy: string;
  activities: ActivityDetail[];
  filters: string[];
  openWorkerSideModal: (jobAssignment: JobAssignment) => void;
  approveTimeSheet: (activityDetail: ActivityDetail) => void;
  handleConfirmMarkPaid: (
    groupedBy: string,
    id: number,
    filteredActivities: ActivityDetail[],
    all?: boolean
  ) => void;
  handleConfirmMarkUnPaid: (
    groupedBy: string,
    id: number,
    filteredActivities: ActivityDetail[],
    all?: boolean
  ) => void;
}
function ActivityTrackerDesktop({
  handleConfirmMarkPaid,
  handleConfirmMarkUnPaid,
  viewType,
  groupedBy,
  activities,
  filters,
  approveTimeSheet,
  openWorkerSideModal,
}: Props) {
  const [selectedActivity, setSelectedActivity] = useState<ActivityDetail>();
  const { alertMessage, showSuccessAlert } = useAlert();
  const {
    upcoming,
    isChatOpen,
    showReviewShiftModal,
    setIsChatOpen,
    setShowReviewShiftModal,
  } = useActivityContext();
  const { job } = useJobContext();
  const [filteredActivities, setFilteredActivities] = useState(
    activities || []
  );

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showSubmitHoursModal, setShowSubmitHoursModal] = useState(false);
  const handleCloseFeedbackModal = () => setShowFeedbackModal(false);
  const handleOpenFeedbackModal = () => setShowFeedbackModal(true);
  const {
    chatPerson,
    showPrivateInfo: showWorkerPrivateInfo,
    clearChatData,
  } = useChatContext();

  const handleOpenSubmitHoursModal = (activity: ActivityDetail) => {
    setShowSubmitHoursModal(true);
    setSelectedActivity(activity);
  };

  const handleOpenReviewShiftModal = useCallback(
    (activity: ActivityDetail) => {
      setSelectedActivity(activity);
      setShowReviewShiftModal(true);
    },
    [setShowReviewShiftModal]
  );

  const handleCloseReviewShiftModal = () => {
    setShowReviewShiftModal(false);
    setSelectedActivity(undefined);
  };

  const openChatAction = () => {
    setShowReviewShiftModal(false);
    setIsChatOpen(true);
  };

  useEffect(() => {
    if (isChatOpen && !chatPerson && selectedActivity) {
      handleOpenReviewShiftModal(selectedActivity);
      setIsChatOpen(false);
    }
  }, [
    chatPerson,
    isChatOpen,
    selectedActivity,
    setIsChatOpen,
    handleOpenReviewShiftModal,
  ]);

  useEffect(() => {
    setFilteredActivities(
      getFilteredData(
        activities,
        filters,
        upcoming,
        job?.timezone ?? UTC_TIMEZONE
      )
    );
    if (selectedActivity) {
      setSelectedActivity(
        activities.find(
          (x) => x.shiftPosition.id === selectedActivity.shiftPosition.id
        )
      );
    }
  }, [activities, filters, job?.timezone, selectedActivity, upcoming]);

  const approveTimeSheetAction = async (activityDetail: ActivityDetail) => {
    approveTimeSheet(activityDetail);
    setShowReviewShiftModal(false);
  };

  const handleSubmitHoursSuccess = (message: string) => {
    showSuccessAlert(message);
    setShowSubmitHoursModal(false);
  };

  if (filteredActivities.length === 0) {
    return <div />;
  }

  return (
    <>
      {isChatOpen && !!chatPerson && (
        <SideEndModal
          show={!!chatPerson}
          width={isMobile ? '90%' : '70%'}
          onHide={() => clearChatData}
        >
          <Chat showWorkerInfo={showWorkerPrivateInfo} worker={chatPerson} />
        </SideEndModal>
      )}
      {showReviewShiftModal && selectedActivity && (
        <ShiftReviewModal
          openChatAction={openChatAction}
          handleOpenFeedbackModal={handleOpenFeedbackModal}
          activityDetail={selectedActivity}
          approveTimeSheet={approveTimeSheetAction}
          handleClose={handleCloseReviewShiftModal}
        />
      )}
      {showSubmitHoursModal && selectedActivity && (
        <MemberAddHoursModal
          handleSubmitHoursSuccess={handleSubmitHoursSuccess}
          setShowSubmitHoursModal={setShowSubmitHoursModal}
          activityDetail={selectedActivity}
        />
      )}
      {showFeedbackModal && selectedActivity && (
        <FeedbackModal
          activity={selectedActivity}
          show={showFeedbackModal}
          handleCloseFeedbackModal={handleCloseFeedbackModal}
        />
      )}
      <ActivityTrackerTableHeader
        filteredActivities={filteredActivities}
        groupedBy={groupedBy}
        handleConfirmMarkPaid={handleConfirmMarkPaid}
        viewType={viewType}
        openWorkerSideModal={openWorkerSideModal}
      />
      <ActivityTrackerDesktopTable
        handleConfirmMarkPaid={handleConfirmMarkPaid}
        handleConfirmMarkUnPaid={handleConfirmMarkUnPaid}
        handleOpenSubmitHoursModal={handleOpenSubmitHoursModal}
        handleOpenReviewShiftModal={handleOpenReviewShiftModal}
        activities={filteredActivities}
        viewType={viewType}
        groupedBy={groupedBy}
        openWorkerSideModal={openWorkerSideModal}
      />
      <hr className="table-horizontal-line" />
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default ActivityTrackerDesktop;
