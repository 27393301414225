import { useState, useEffect } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { ThLoading } from '@components/elements';
import { isMobile } from 'react-device-detect';

interface MarkerLabel {
  text: string;
  className?: string;
}

export interface Marker {
  lat: number;
  lng: number;
  label?: MarkerLabel;
}

interface GoogleMapsProps {
  lat: number;
  lng: number;
  markers: Marker[]; // Array of markers
  widthSize?: string;
  heightSize?: string;
  initialZoom?: number;
}

// Component that renders the actual map
function GoogleMapDisplay({
  lat,
  lng,
  markers,
  initialZoom,
  widthSize,
  heightSize,
}: GoogleMapsProps) {
  return (
    <GoogleMap
      mapContainerStyle={{
        width: isMobile ? widthSize || '130px' : widthSize || '335px',
        height: heightSize || '125px',
      }}
      center={{ lat, lng }}
      zoom={initialZoom || 15}
    >
      {markers.map((marker) => (
        <MarkerF
          key={`${marker.lat}-${marker.lng}-${marker.label}`}
          position={{ lat: marker.lat, lng: marker.lng }}
          label={marker.label}
          opacity={1}
        />
      ))}
    </GoogleMap>
  );
}

// Create a separate component that uses the Google Maps API loader
function MapWithLoader({
  onLoad,
  ...props
}: GoogleMapsProps & { onLoad: () => void }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    if (isLoaded) {
      onLoad();
    }
  }, [isLoaded, onLoad]);

  return isLoaded ? <GoogleMapDisplay {...props} /> : <ThLoading />;
}

export default function GoogleMapComponent(props: GoogleMapsProps) {
  const [googleLoaded, setGoogleLoaded] = useState<boolean | null>(null);

  // Check if Google Maps is already loaded
  useEffect(() => {
    if (window.google && window.google.maps) {
      setGoogleLoaded(true);
    } else {
      setGoogleLoaded(false);
    }
  }, []);

  if (googleLoaded == null) {
    return <ThLoading />;
  }
  // Render the appropriate component based on whether Google Maps is loaded
  return googleLoaded ? (
    <GoogleMapDisplay {...props} />
  ) : (
    <MapWithLoader {...props} onLoad={() => setGoogleLoaded(true)} />
  );
}
