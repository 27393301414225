import ContentLoader from 'react-content-loader';

interface Params {
  width?: string;
  height?: string;
}

function LoaderResume({
  width = '80%',
  height = '70svh',
}: Params): JSX.Element {
  return (
    <ContentLoader speed={1} width={width} height={height}>
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
    </ContentLoader>
  );
}

export default LoaderResume;
