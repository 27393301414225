import { useEffect, useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { HiArrowLeft } from '@react-icons/all-files/hi/HiArrowLeft';
import { IoMdOpen } from '@react-icons/all-files/io/IoMdOpen';
import { SideEndModal, ThLoading } from '@components/elements';
import { JobAssignment } from '@th-types/job-assignment.type';
import { IWorkerData } from '@th-types/worker.type';
import ReviewSidePopUp from '@company/components/ReviewSidePopup/ReviewSidePopup';
import { useJobAssignmentContext } from '@company/state/jobAssignmentContext';
import { WorkerProfile } from '@company/components';
import { useJobContext } from '@company/state/jobContext';
import JobWorker from './JobWorker';

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

interface JobWorkerSideModalParams {
  handleCloseWorkerProfile: () => void;
  worker: IWorkerData;
  jobAssignment?: JobAssignment;
  isJobRelated: boolean;
}

export default function JobWorkerSideModal({
  handleCloseWorkerProfile,
  worker,
  jobAssignment,
  isJobRelated = true,
}: JobWorkerSideModalParams) {
  const [showReview, setShowReview] = useState(false);
  const {
    setWorkerId,
    workerJobData,
    setWorkerRatable,
    isLoadingWorkerProfileData,
  } = useJobAssignmentContext();

  const closeWorkerProfile = () => {
    setShowReview(false);
    handleCloseWorkerProfile();
  };

  useEffect(() => {
    if (setWorkerId) {
      setWorkerId(worker.id);
    }
  }, [setWorkerId, worker.id, isJobRelated]);

  const { job, isInternalMember, fetchApplicantCounters } = useJobContext();

  const renderJobWorker = () => {
    if (!job) {
      return <ThLoading />;
    }
    const assignment = jobAssignment ?? workerJobData?.jobAssignment;
    return showReview && assignment ? (
      <ReviewSidePopUp
        jobAssignment={assignment}
        workerInfo={worker}
        onSubmitReview={() => {
          setWorkerRatable(false);
          setShowReview(false);
        }}
        onCloseReviewForm={() => {
          setShowReview(false);
        }}
      />
    ) : (
      <JobWorker
        worker={worker}
        closeWorkerProfile={closeWorkerProfile}
        openReviewModal={() => setShowReview(true)}
        job={job}
        isInternalMember={isInternalMember}
        fetchApplicantCounters={fetchApplicantCounters}
      />
    );
  };

  const modalWidth = () => {
    if (showReview || isJobRelated) return 'fit-content';
    return isMobile ? '100%' : '85%';
  };

  const headerModalNavigation = () => {
    return (
      <div>
        <Stack direction="horizontal" gap={3}>
          <div style={{ cursor: 'pointer' }} onClick={closeWorkerProfile}>
            <HiArrowLeft size={20} />
            &nbsp; <b>Back</b>
          </div>
          <div className="ms-auto">
            <Button
              variant="link"
              onClick={() =>
                window.open(
                  `${SERVER_URL}/w/${
                    workerJobData!.worker.customUrl ?? workerJobData!.worker.id
                  }`,
                  '_blank'
                )
              }
              style={{
                textDecoration: 'none',
                color: 'unset',
                fontSize: '16px',
              }}
            >
              <IoMdOpen size={20} />
              {!isMobile && <b>&nbsp;Open profile in new window</b>}
            </Button>
          </div>
        </Stack>
        <hr />
      </div>
    );
  };

  const renderWorkerProfile = () => {
    return (
      <>
        {headerModalNavigation()}
        <WorkerProfile
          workerProfileData={workerJobData}
          isLoadingWorker={isLoadingWorkerProfileData}
        />
      </>
    );
  };

  return (
    <SideEndModal show onHide={closeWorkerProfile} width={modalWidth()}>
      {isJobRelated ? renderJobWorker() : renderWorkerProfile()}
    </SideEndModal>
  );
}
