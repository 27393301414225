import Button from 'react-bootstrap/esm/Button';
import { isMobile } from 'react-device-detect';
import './style.css';
import { useState } from 'react';
import * as workerApi from '@company/services/worker/api';
import useAlert from '@hooks/useAlert';
import { ThLoading } from '@components/elements';
import { FaHeart, FaRegHeart } from 'react-icons/fa6';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  workerId: number;
  isFavoritedByDefault?: boolean;
  disable?: boolean;
  isSmallButton?: boolean;
  iconSize?: number;
  className?: string;
  handleToggleState?: (state: boolean) => void;
}

export default function FavoriteButton({
  workerId,
  isFavoritedByDefault = false,
  disable = false,
  isSmallButton = false,
  iconSize = 14,
  className = '',
  handleToggleState,
}: Props) {
  const { alertMessage, showWarningAlert } = useAlert();
  const [isLoadingAction, setLoadingAction] = useState(false);
  const [isFavoriteWorker, setIsFavoriteWorker] =
    useState(isFavoritedByDefault);

  const favoriteWorker = async () => {
    setLoadingAction(true);
    try {
      let result;
      if (isFavoriteWorker) {
        result = await workerApi.removeFavoriteWorker(workerId);
      } else {
        result = await workerApi.favoriteWorker(workerId);
      }
      if (result && result.success) {
        setIsFavoriteWorker(!isFavoriteWorker);
      } else if (result) {
        showWarningAlert(result.message);
      }
    } finally {
      setLoadingAction(false);
    }
  };

  const handleFavoriteWorker = () => {
    favoriteWorker();
    if (handleToggleState) {
      handleToggleState(isFavoriteWorker);
    }
  };

  return (
    <>
      {isLoadingAction && <ThLoading />}

      <OverlayTrigger
        flip
        overlay={<Tooltip id="button-tooltip">Add to favorites</Tooltip>}
      >
        <Button
          size={isSmallButton ? 'sm' : 'lg'}
          className={`ms-2 rounded-button ${className}`}
          disabled={disable}
          variant="light"
          onClick={handleFavoriteWorker}
        >
          {isFavoriteWorker ? (
            <FaHeart
              size={iconSize}
              className={isMobile ? 'mobile-icon-style' : ''}
              color="green"
            />
          ) : (
            <FaRegHeart
              size={iconSize}
              className={isMobile ? 'mobile-icon-style' : ''}
              color="black"
            />
          )}
        </Button>
      </OverlayTrigger>

      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}
