import { ReactElement } from 'react';

interface ConfirmationCancelShiftsMessageProps {
  isCancelAction: boolean;
}

export default function ConfirmationCancelShiftsMessage({
  isCancelAction,
}: ConfirmationCancelShiftsMessageProps): ReactElement {
  return isCancelAction ? (
    <p className="text-center m-2">
      This action will PERMANENTLY remove the shift data, you won&apos;t be able
      to get it back.
      <br />
      Additionally, the shift will appear open again
    </p>
  ) : (
    <p>Selected shifts which are on open status will be deleted permanently</p>
  );
}
