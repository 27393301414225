import { OptionProps } from 'react-select';
import { MdOutlineLocationOn } from 'react-icons/md';
import DATE_FORMATS from '@constants/dateFormat';
import { getJobFormattedLocalStartDate } from '@utils/Job';
import IJobDetailData from '@th-types/job.detail.type';

export const formatJobName = (job: IJobDetailData) => {
  const formattedName = `${getJobFormattedLocalStartDate(
    job,
    DATE_FORMATS.DATE_FORMAT_SHORT
  )} / ${job.eventName} / ${job.brandRepresented} / ${job.position}`;
  return formattedName;
};

function ThJobSelectOption(props: OptionProps<IJobDetailData>) {
  const { getStyles, isSelected, innerRef, innerProps, data } = props;
  const styles = getStyles('option', props);
  const { backgroundColor, color } = styles;
  const { id } = data;
  const formattedAddress = data.nationalJob
    ? 'National Job'
    : [
        data.address.line1,
        data.address.city,
        data.address.state.ansiAbbreviation,
      ]
        .filter((a) => a !== null)
        .join(', ');

  return (
    <div
      key={id}
      ref={innerRef}
      style={{
        backgroundColor: String(backgroundColor),
        color: String(color),
        cursor: 'pointer',
      }}
      className="px-2 py-1"
      {...innerProps}
    >
      {formatJobName(data)}
      <p>
        <MdOutlineLocationOn />{' '}
        <small
          className={`fst-italic ${!isSelected ? 'text-gray' : ''}`}
          style={{ color: String(color) }}
        >
          {formattedAddress}
        </small>
      </p>
    </div>
  );
}

export default ThJobSelectOption;
