import * as activityTrackerApi from '@company/services/activityTracker/api';
import { ActivityDetail } from '@th-types/daily-activity.type';
import { JobAssignment } from '@th-types/job-assignment.type';
import { ActivityFilters } from '@th-types/jobs.type';
import {
  convertToTimezone,
  getDateNowByTimezone,
  UTC_TIMEZONE,
} from '@utils/DateUtils';
import { isShiftConfirmed } from '@services/shift/shiftService';
import _ from 'lodash';

export const activityFilterOptions = [
  { value: ActivityFilters.ONBOARDING, label: 'Onboarding' },
  { value: ActivityFilters.MARK_PAID, label: 'Mark Paid' },
  { value: ActivityFilters.TO_APPROVE, label: 'To Approve' },
  { value: ActivityFilters.NO_CHECK_IN_OUT, label: 'No Check In/Out' },
  { value: ActivityFilters.NO_SUBMITTED_TIME, label: 'No Submitted Time' },
];

const isUpcomingActivity = (item: ActivityDetail, timezone: string) => {
  return (
    isShiftConfirmed(item.shiftPosition) &&
    convertToTimezone(new Date(item.shiftPosition.start), timezone) >
      getDateNowByTimezone(timezone)
  );
};

const isOnBoardingActivity = (item: ActivityDetail) => {
  return (
    isShiftConfirmed(item.shiftPosition) &&
    !item.shiftPosition.jobAssignment?.onBoardingCompleted
  );
};

const isNoSubmittedTimeActivity = (item: ActivityDetail, timezone: string) => {
  return (
    isShiftConfirmed(item.shiftPosition) &&
    convertToTimezone(new Date(item.shiftPosition.end), timezone) <
      getDateNowByTimezone(timezone) &&
    !item.timeSheet
  );
};

const hasCheckInOut = (item: ActivityDetail, timezone: string) => {
  return (
    convertToTimezone(
      new Date(item.shiftPosition.start),
      timezone ?? UTC_TIMEZONE
    ) < getDateNowByTimezone(timezone) &&
    item.dailyActivity &&
    item.dailyActivity.checkInTime &&
    item.dailyActivity.checkOutTime
  );
};

export const isToApproveActivity = (item: ActivityDetail) => {
  return item.timeSheet && !item.timeSheet.isApproved;
};

export const getFilteredData = (
  listActivities: ActivityDetail[],
  filters: string[],
  upcoming: boolean,
  timezone: string
) => {
  if (listActivities) {
    const filteredData: ActivityDetail[] = [];
    if (!filters || filters.length === 0) {
      return listActivities;
    }
    if (upcoming || filters.includes(ActivityFilters.UPCOMING)) {
      filteredData.push(
        ...listActivities.filter(
          (item) =>
            isUpcomingActivity(item, timezone) &&
            !filteredData.some(
              (filtered) => filtered.shiftPosition.id === item.shiftPosition.id
            )
        )
      );
    }
    if (filters.includes(ActivityFilters.ONBOARDING)) {
      filteredData.push(
        ...listActivities.filter(
          (item) =>
            isOnBoardingActivity(item) &&
            !filteredData.some(
              (filtered) => filtered.shiftPosition.id === item.shiftPosition.id
            )
        )
      );
    }
    if (filters.includes(ActivityFilters.MARK_PAID)) {
      filteredData.push(
        ...listActivities.filter(
          (item) =>
            !item.shiftPosition.isPaid &&
            !filteredData.some(
              (filtered) => filtered.shiftPosition.id === item.shiftPosition.id
            )
        )
      );
    }
    if (filters.includes(ActivityFilters.TO_APPROVE)) {
      filteredData.push(
        ...listActivities.filter(
          (item) =>
            !isUpcomingActivity(item, timezone) &&
            isToApproveActivity(item) &&
            !filteredData.some(
              (filtered) => filtered.shiftPosition.id === item.shiftPosition.id
            )
        )
      );
    }
    if (filters.includes(ActivityFilters.NO_CHECK_IN_OUT)) {
      filteredData.push(
        ...listActivities.filter(
          (item) =>
            !hasCheckInOut(item, timezone) &&
            !item.dailyActivity &&
            !filteredData.some(
              (filtered) => filtered.shiftPosition.id === item.shiftPosition.id
            )
        )
      );
    }
    if (filters.includes(ActivityFilters.NO_SUBMITTED_TIME)) {
      filteredData.push(
        ...listActivities.filter(
          (item) =>
            isNoSubmittedTimeActivity(item, timezone) &&
            !item.timeSheet &&
            !filteredData.some(
              (filtered) => filtered.shiftPosition.id === item.shiftPosition.id
            )
        )
      );
    }
    return filteredData;
  }
  return [];
};

export const getJobAssignmentsForActivities = (
  activities: ActivityDetail[]
): (JobAssignment | undefined)[] => {
  if (!activities || !Array.isArray(activities) || activities.length === 0) {
    return [];
  }
  const jobAssignments = activities
    .filter((activity) => activity.shiftPosition.jobAssignment !== undefined)
    .map((activity) => activity.shiftPosition.jobAssignment);

  return _.uniqBy(jobAssignments, 'id');
};

export const getTotalActivities = (
  filter: ActivityFilters,
  activities: ActivityDetail[] | undefined,
  timezone: string
) => {
  if (!activities || !Array.isArray(activities) || activities.length === 0) {
    return 0;
  }
  switch (filter) {
    case ActivityFilters.UPCOMING:
      return activities.reduce(
        (total, item) =>
          isUpcomingActivity(item, timezone) ? total + 1 : total,
        0
      );
    case ActivityFilters.ONBOARDING:
      return getJobAssignmentsForActivities(activities).reduce(
        (total, jobAssignment) =>
          !jobAssignment?.onBoardingCompleted ? total + 1 : total,
        0
      );
    case ActivityFilters.MARK_PAID:
      return activities.reduce(
        (total, item) => (!item.shiftPosition.isPaid ? total + 1 : total),
        0
      );
    case ActivityFilters.TO_APPROVE:
      return activities.reduce(
        (total, item) =>
          !isUpcomingActivity(item, timezone) && isToApproveActivity(item)
            ? total + 1
            : total,
        0
      );
    case ActivityFilters.NO_CHECK_IN_OUT:
      return activities.reduce(
        (total, item) => (!hasCheckInOut(item, timezone) ? total + 1 : total),
        0
      );
    case ActivityFilters.NO_SUBMITTED_TIME:
      return activities.reduce(
        (total, item) =>
          isNoSubmittedTimeActivity(item, timezone) ? total + 1 : total,
        0
      );
    default:
      return activities.length;
  }
};

export const completeOnboarding = async (
  jobAssignments: JobAssignment[],
  activities: ActivityDetail[],
  completed: boolean = true
): Promise<ActivityDetail[]> => {
  try {
    const jobAssignmentIds = jobAssignments.map(({ id }) => id);
    await activityTrackerApi.onboardingCompleted(jobAssignmentIds, completed);
    const activitiesFiltered = activities.map((activity) => {
      const {
        shiftPosition: { jobAssignment },
      } = activity;
      if (jobAssignment && jobAssignmentIds.includes(jobAssignment.id)) {
        jobAssignment.onBoardingCompleted = completed;
      }
      return activity;
    });

    return activitiesFiltered;
  } catch (err) {
    console.error(
      `Error occurred while marking onboardings as completed:`,
      err
    );
    return activities;
  }
};
