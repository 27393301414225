import { Button } from 'react-bootstrap';
import TooltipOverlay from '@components/elements/Tooltip';
import { DELETE_SHIFT } from '@services/shift/shiftService';
import { isMobile } from 'react-device-detect';

interface Props {
  deleteShiftAction: () => void;
  totalOpenSelectedShifts: number;
  tooltipMessage?: string;
}

export function DeleteShiftsButton({
  deleteShiftAction,
  totalOpenSelectedShifts,
  tooltipMessage,
}: Props) {
  if (totalOpenSelectedShifts <= 0) {
    return <div />;
  }

  if (isMobile) {
    return (
      <Button variant="primary" className="fw-bold" onClick={deleteShiftAction}>
        {DELETE_SHIFT}
        {totalOpenSelectedShifts > 1 ? 'S' : ''} ({totalOpenSelectedShifts})
      </Button>
    );
  }
  return (
    <span className="offer-shifts">
      <TooltipOverlay
        delay={2}
        text={
          tooltipMessage || 'Delete selected shifts which are on open status'
        }
        position="bottom"
      >
        <Button
          variant="primary"
          className="fw-bold me-2"
          onClick={deleteShiftAction}
        >
          {DELETE_SHIFT}
          {totalOpenSelectedShifts > 1 ? 'S' : ''} ({totalOpenSelectedShifts})
        </Button>{' '}
      </TooltipOverlay>
    </span>
  );
}
