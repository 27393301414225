import { useMemo, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Stack } from 'react-bootstrap';
import { HiArrowLeft } from 'react-icons/hi';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ThLoading } from '@components/elements';
import ComHubReviewWorkerForm from '@company/components/CommunicationHub/Modals/ReviewRequestModalContent/ComHubReviewWorkerForm';
import ComHubGigInfoPreview from '@company/components/CommunicationHub/ComHubGigInfoPreview/ComHubGigInfoPreview';
import ReceivedReview from '@company/components/ReviewSidePopup/ReceivedReview/ReceivedReview';
import { JobAssignmentRatingType } from '@company/types/job-assignment-rating.type';
import { getGivenWorkerRating } from '@company/services/rating/api';
import { useCompanyContext } from '@company/state/companyContext';
import useAvatarImageFallback from '@hooks/useAvatarImageFallback';
import { formatWorkDateRange } from '@utils/DateUtils';
import DATE_FORMATS from '@constants/dateFormat';
import QueryKeys from '@constants/queryKeys';

import '../styles.css';
import { IoMdClose } from 'react-icons/io';

interface Props {
  jobAssignmentId: number;
  closeModal: () => void;
}

function GivenReviewModalContent({ jobAssignmentId, closeModal }: Props) {
  const { name } = useCompanyContext();

  const avatarRef = useRef(null);
  useAvatarImageFallback(avatarRef);

  const queryKey = `${QueryKeys.COMPANY_RATING}_${jobAssignmentId}`;

  const { data: ratingAssignmentData, isLoading } = useQuery<
    JobAssignmentRatingType,
    AxiosError
  >({
    queryKey: [queryKey],
    queryFn: () => getGivenWorkerRating(jobAssignmentId),
    enabled: jobAssignmentId > 0,
  });

  const gigInfoPreviewData = useMemo(
    () =>
      ratingAssignmentData && ratingAssignmentData.worker
        ? {
            brandRepresented: ratingAssignmentData.brandRepresented,
            companyName: ratingAssignmentData.companyName || name || '',
            eventName: ratingAssignmentData.eventName,
            jobTitle: ratingAssignmentData.jobTitle.name,
            workerName: ratingAssignmentData.worker.fullName,
            workedDays:
              ratingAssignmentData?.workedDates?.length > 1
                ? formatWorkDateRange(
                    new Date(ratingAssignmentData.workedDates[0]),
                    new Date(ratingAssignmentData.workedDates[1]),
                    DATE_FORMATS.DATE_FORMAT
                  )
                : 'n/a',
          }
        : {
            brandRepresented: '',
            companyName: '',
            eventName: '',
            jobTitle: '',
            workerName: '',
            workedDays: '',
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ratingAssignmentData]
  );

  return (
    <Stack>
      {isMobile ? (
        <>
          <Stack direction="horizontal" className="justify-content-between">
            <span className="received-review-title">
              Left Review For Worker
            </span>
            <IoMdClose size={20} onClick={closeModal} />
          </Stack>
          <hr />
        </>
      ) : (
        <Stack>
          <Stack
            direction="horizontal"
            gap={2}
            className="align-items-center p-3 ps-0 cursor-point fw-bold"
            onClick={closeModal}
          >
            <HiArrowLeft size={20} />
            GO BACK
          </Stack>
          <span className="mb-3 received-review-title">
            Left Review For Worker
          </span>
        </Stack>
      )}

      {isLoading && <ThLoading />}
      {ratingAssignmentData && (
        <Stack direction={isMobile ? 'vertical' : 'horizontal'} gap={3}>
          <Stack gap={3} className={isMobile ? 'w-100' : 'w-50'}>
            <ReceivedReview
              rating={ratingAssignmentData}
              workedDates={ratingAssignmentData.workedDates}
              worker={ratingAssignmentData.worker!}
              showSpanTitle={false}
            />
            <ComHubGigInfoPreview gigInfoPreview={gigInfoPreviewData} />
          </Stack>
          <Stack className={isMobile ? 'w-100' : 'w-50'}>
            <ComHubReviewWorkerForm
              ratingAssignmentData={ratingAssignmentData}
              onSuccess={closeModal}
              isReadOnly
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default GivenReviewModalContent;
