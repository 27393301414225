import { Button, Col, Modal, Row } from 'react-bootstrap';

interface Params {
  show: boolean;
  handleClose: (() => void) | undefined;
}

function CompleteJobModal({ show = false, handleClose }: Params): JSX.Element {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Complete Job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Row className="p-2">
          <Col>
            <p>
              There are still a few pending actions for this job that you are
              about to mark as complete. Please review the following:
            </p>
          </Col>
        </Row>
        {pendencies.map((pendency) => (
          <>
            <Row
              onClick={() =>
                setOpenPendency(
                  pendency.id === openPendency ? undefined : pendency.id
                )
              }
              className="cursor-point"
            >
              <Col md={11} xs={10}>
                {pendency.icon}
                <b className="ms-3 fs-5">{pendency.group}</b>
              </Col>
              <Col md={1} xs={2}>
                {pendency.id === openPendency ? (
                  <FiChevronUp size={20} />
                ) : (
                  <FiChevronDown size={20} />
                )}
              </Col>
            </Row>
            <Collapse in={pendency.id === openPendency}>
              <Row>
                <Col>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th align="left">WORKER NAME</th>
                        <th>SCHEDULED TIME</th>
                        <th>CHECK IN</th>
                        <th>CHECK OUT</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendency.list.map((pendencyItem) => (
                        <tr key={pendencyItem.name}>
                          <td>{pendencyItem.name}</td>
                          <td>{pendencyItem.schedule}</td>
                          <td>{pendencyItem.checkIn}</td>
                          <td>{pendencyItem.checkOut}</td>
                          <td>
                            <Stack
                              direction="horizontal"
                              className="justify-content-start"
                              gap={2}
                            >
                              <BsChat size={18} />
                              <FiEdit size={18} />
                              <AiOutlineClose size={18} />
                            </Stack>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Collapse>
            <hr />
          </>
        ))} */}
        <Row>
          <Col xs={12} className="ms-2">
            <p>
              Upon clicking complete, this job and all related actions will be
              moved to the Completed tab of this section.
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ border: 0 }}>
        <Button variant="light" onClick={handleClose}>
          <b>CANCEL</b>
        </Button>
        <Button variant="primary" onClick={() => alert('Implementing')}>
          <b>COMPLETE JOB</b>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CompleteJobModal;
