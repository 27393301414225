import { Col, Container, Row } from 'react-bootstrap';
import { IWorkerData } from '@th-types/worker.type';
import { CalendarView } from './CalendarView';

interface WorkerCalendarParams {
  worker: IWorkerData;
}

export default function JobWorkerCalendar({ worker }: WorkerCalendarParams) {
  return (
    <Row className="mt-4">
      <Col md={12}>
        <Container fluid>
          <Row>
            <CalendarView worker={worker} />
          </Row>
        </Container>
      </Col>
    </Row>
  );
}
