import { Navigate } from 'react-router-dom';
import CompanyRoutes from '@company/routes/CompanyRoutes';
import { ChatProvider } from '@company/state/chatContext';
import { CompanyProvider } from '@company/state/companyContext';
import { WorkerProvider } from '@worker/state/workerContext';
import WorkerRoutes from '@worker/routes/WorkerRoutes';
import { ThLoading } from '@components/elements';
import { UserRole } from '@constants/userRoles';
import { useUserContext } from '@state/userContext';
import AuthFailedModal from '@components/elements/AuthFailedModal';
import { DirectMessageProvider } from '@company/state/directMessageContext';
import { WorkerProfileProvider } from '@worker/state/workerProfileContext';

export default function AppRoutes() {
  const { hasRole, isLoading } = useUserContext();

  const getRoutes = () => {
    if (hasRole(UserRole.ROLE_COMPANY)) {
      return (
        <CompanyProvider>
          <ChatProvider>
            <DirectMessageProvider>
              <CompanyRoutes />
            </DirectMessageProvider>
          </ChatProvider>
        </CompanyProvider>
      );
    }
    if (hasRole(UserRole.ROLE_USER)) {
      return (
        <WorkerProfileProvider>
          <WorkerProvider>
            <WorkerRoutes />
          </WorkerProvider>
        </WorkerProfileProvider>
      );
    }

    return <Navigate to="/login" />;
  };

  if (isLoading) {
    return <ThLoading />;
  }

  return (
    <>
      <AuthFailedModal />
      {getRoutes()}
    </>
  );
}
