import { BrowserView, MobileView } from 'react-device-detect';
import { Card, Badge, Stack } from 'react-bootstrap';
import dollarSign from '@assets/survey/dollar-sign-logo.svg';
import calendar from '@assets/survey/calendar-logo.svg';
import clock from '@assets/survey/clock-logo.svg';
import { format } from 'date-fns';
import DATE_FORMATS from '@constants/dateFormat';
import { StatusBadge, GiftCardProps } from './types';
import './styles.scss';

function GiftListItem({
  title,
  voucherUrl,
  amount,
  date,
}: Readonly<GiftCardProps>) {
  return (
    <>
      <BrowserView>
        <Card className="survey-status me-3">
          <Card.Body className="d-flex justify-content-between align-items-center">
            <>
              <div className="d-flex">
                <div className="ms-3">
                  <div className="d-flex gap-5">
                    <Card.Title className="mb-0 completed-survey-title">
                      {title}
                    </Card.Title>
                    <div>
                      <img className="mb-1" src={dollarSign} alt="money" />
                      <span className="ms-1">{amount}</span>
                    </div>
                  </div>
                  <span className="d-flex align-items-center">
                    <img className="me-2" src={calendar} alt="calendar" />
                    <span className="me-2">
                      {format(
                        new Date(date),
                        DATE_FORMATS.DATE_TIME_FORMAT_24H
                      )}
                    </span>
                    <a
                      href={voucherUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ms-5 text-decoration-none text-black btn btn-light btn-sm"
                      style={{ paddingLeft: '5px' }}
                    >
                      Open Gift Card
                    </a>
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row gap-2">
                <div className="flex-grow-1 text-center">
                  <Badge
                    bg="light-success"
                    className="status-card min-width-badge"
                  >
                    <span className="text-success fw-bold">
                      {StatusBadge.REDEEMED}
                    </span>
                  </Badge>
                </div>
              </div>
            </>
          </Card.Body>
        </Card>
      </BrowserView>
      <MobileView>
        <div className="py-3 border-bottom">
          <Stack direction="horizontal" className="justify-content-between">
            <b>{title}</b>
            <Badge bg="light-success" className="status-card min-width-badge">
              <span className="text-success fw-bold">
                {StatusBadge.REDEEMED}
              </span>
            </Badge>
          </Stack>
          <Stack direction="horizontal" gap={3}>
            <Stack direction="horizontal" className="align-items-center">
              <a
                href={voucherUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none text-black btn btn-light btn-sm"
              >
                Open Gift Card
              </a>
            </Stack>

            <Stack
              direction="horizontal"
              className="align-items-center"
              gap={1}
            >
              <img src={calendar} alt="calendar" />
              {format(new Date(date), DATE_FORMATS.DATE_FORMAT)}
            </Stack>

            <Stack
              direction="horizontal"
              className="align-items-center"
              gap={1}
            >
              <img src={clock} alt="clock" />
              {format(new Date(date), DATE_FORMATS.TIME_FORMAT_24H)}
            </Stack>
          </Stack>
        </div>
      </MobileView>
    </>
  );
}

export default GiftListItem;
