export interface S3PresignedUrl {
  s3Key: string;
  preSignedUrl: string;
  validFor: {
    nano: number;
    negative: boolean;
    seconds: number;
    units: [
      {
        enumType: string;
        name: string;
      },
      {
        enumType: string;
        name: string;
      },
    ];
    zero: boolean;
  };
}

export enum FolderDesignationEnum {
  UNKNOWN_PERSON_IMAGES = 'UNKNOWN_PERSON_IMAGES',
  PERSON_IMAGES = 'PERSON_IMAGES',
  PERSON_ADDITIONAL_PICTURES = 'PERSON_ADDITIONAL_PICTURES',
  JOB_IMAGES = 'JOB_IMAGES',
  OTHER = 'OTHER',
}
