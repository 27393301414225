import { Badge, Stack } from 'react-bootstrap';
import { ITag } from '@th-types/tag.type';

interface TagProps {
  tags: ITag[];
}

export default function Tags({ tags = [] }: TagProps) {
  return (
    <Stack direction="horizontal" gap={1}>
      {tags &&
        tags.map((tag: ITag) => (
          <Badge
            key={tag.label}
            className={`${tag.className ? tag.className : ''} ${
              tag.bgText
            } mr-0 `}
            bg={tag.bg}
          >
            {tag.label}
          </Badge>
        ))}
    </Stack>
  );
}
