import { Button, Col, Form, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { TbDownload } from 'react-icons/tb';
import ThPlayer from '@components/elements/ThPlayer/ThPlayer';
import TooltipOverlay from '@components/elements/Tooltip';
import {
  JobQuestionResponseModel,
  JobQuestionType,
} from '@th-types/job-question-response-model.type';

interface JobQuestionParams {
  jobQuestionResponseModel: JobQuestionResponseModel;
}

export default function JobQuestionComponent({
  jobQuestionResponseModel,
}: JobQuestionParams) {
  const showQuestionText = () => {
    if (
      jobQuestionResponseModel.active &&
      jobQuestionResponseModel.responseId
    ) {
      return (
        <Row className="mt-4">
          <Col>
            <b>{jobQuestionResponseModel.questionText}</b>
          </Col>
        </Row>
      );
    }

    if (
      !jobQuestionResponseModel.active &&
      jobQuestionResponseModel.responseId
    ) {
      return (
        <Row className="mt-4">
          <Col>
            <TooltipOverlay
              text="This question has been removed after the user applies"
              position="bottom"
            >
              <b>{jobQuestionResponseModel.questionText}</b>
            </TooltipOverlay>
          </Col>
        </Row>
      );
    }

    return <div />;
  };

  const getUserChoice = (item: string) => {
    if (!jobQuestionResponseModel.deleted) {
      return (
        <TooltipOverlay
          text="This choice has been removed after the applicant applies to this job"
          position="bottom"
        >
          <Form.Check
            type="checkbox"
            disabled
            checked={
              jobQuestionResponseModel.response.split('#').indexOf(item) > -1
            }
            label={item}
          />
        </TooltipOverlay>
      );
    }
    return (
      <Form.Check
        type="checkbox"
        checked={
          jobQuestionResponseModel.response.split('#').indexOf(item) > -1
        }
        label={item}
      />
    );
  };

  const showResponse = () => {
    const type = JobQuestionType[jobQuestionResponseModel.questionType];
    const { responseId } = jobQuestionResponseModel;
    const result = [];

    if (
      jobQuestionResponseModel.active ||
      jobQuestionResponseModel.responseId
    ) {
      switch (type) {
        case JobQuestionType.OPEN_FIELD:
          result.push(
            <Row className="mt-2" key={`${type}-${responseId}`}>
              <Col>{jobQuestionResponseModel.response}</Col>
            </Row>
          );
          break;
        case JobQuestionType.MULTIPLE_CHOICE: {
          if (jobQuestionResponseModel.response) {
            let userResponses = jobQuestionResponseModel.response.split('#');
            userResponses = userResponses.filter(
              (x) => !jobQuestionResponseModel.choices?.includes(x)
            );

            userResponses.forEach((item) => {
              result.push(
                <Col
                  className="mt-2 me-4 d-inline-flex"
                  key={`${type}-${responseId}-${item}`}
                >
                  {getUserChoice(item)}
                </Col>
              );
            });

            jobQuestionResponseModel.choices?.forEach((item) => {
              result.push(
                <Col
                  className="mt-2 me-4 d-inline-flex"
                  key={`${type}-${responseId}-${item}`}
                >
                  <Form.Check
                    readOnly
                    type="checkbox"
                    checked={
                      jobQuestionResponseModel.response
                        .split('#')
                        .indexOf(item) > -1
                    }
                    label={item}
                  />
                </Col>
              );
            });
          }
          break;
        }
        case JobQuestionType.ATTACH_FILE:
          result.push(
            <Col key={`${type}-${responseId}`} className="mt-2">
              <Button
                className="ps-0 pe-0"
                target="_blank"
                variant="link"
                href={jobQuestionResponseModel?.s3FileUrl}
              >
                {jobQuestionResponseModel.s3FileName}
              </Button>
              &nbsp;&nbsp;
              <TbDownload color="black" />
            </Col>
          );
          break;
        case JobQuestionType.VIDEO:
          if (jobQuestionResponseModel.s3FileUrl) {
            result.push(
              <div className="mt-2">
                <ThPlayer
                  width={isMobile ? '100%' : '361'}
                  height={isMobile ? 'auto' : '188'}
                  src={jobQuestionResponseModel.s3FileUrl}
                />
              </div>
            );
          }
          break;
        default:
          return '';
      }
    }
    return result;
  };

  return (
    <>
      {showQuestionText()}
      {showResponse()}
    </>
  );
}
