import { useLayoutEffect, useRef, useState } from 'react';
import { Button, Form, Stack } from 'react-bootstrap';
import { AiOutlineSend } from 'react-icons/ai';

import './styles.css';

interface MessageInputProps {
  onSubmitMessage: (value: string) => void;
  disabled?: boolean;
}

function MessageInput({ disabled, onSubmitMessage }: MessageInputProps) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [messageText, setMessageText] = useState('');

  useLayoutEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current!.style.height = 'inherit';
      textAreaRef.current!.style.height = `${
        textAreaRef.current!.scrollHeight
      }px`;
    }
  }, [messageText, textAreaRef]);

  const handleSubmitMessage = () => {
    onSubmitMessage(messageText);
    setMessageText('');
  };

  return (
    <Stack direction="horizontal" className="chat-input">
      <Form.Control
        placeholder="Enter your message here..."
        as="textarea"
        ref={textAreaRef}
        rows={1}
        onChange={(e) => setMessageText(e.target.value)}
        value={messageText}
      />
      <Button
        type="submit"
        variant="light"
        className="submit-btn"
        onClick={handleSubmitMessage}
        data-testid="send-button"
        disabled={disabled}
      >
        <AiOutlineSend size={20} />
      </Button>
    </Stack>
  );
}

export default MessageInput;
