import * as Sentry from '@sentry/react';

const {
  VITE_SENTRY_DSN,
  VITE_SENTRY_TUNNEL_SERVICE,
  VITE_SENTRY_SAMPLE_RATE,
  VITE_SENTRY_AUTH_TOKEN,
} = import.meta.env;

if (VITE_SENTRY_AUTH_TOKEN) {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    tunnel: VITE_SENTRY_TUNNEL_SERVICE,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: VITE_SENTRY_SAMPLE_RATE,
  });
}
