import ShowImage from '@components/elements/ShowImage';
import { LoaderSquare } from '@components/elements/ComponentLoader';
import defaultAvatar from '@assets/default-avatar.png';
import { IBatchRateWorker } from '@company/types/batch-rate-worker.type';

interface Props {
  worker: IBatchRateWorker;
}
function WorkerImage({ worker }: Props) {
  return worker.image ? (
    <ShowImage
      className="avatar rounded cursor-point"
      src={worker.image || defaultAvatar}
      style={{
        width: 60,
        height: 60,
        objectFit: 'cover',
      }}
    />
  ) : (
    <LoaderSquare width="60" height="60" />
  );
}

export default WorkerImage;
