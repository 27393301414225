import * as jobAssignmentApi from '@company/services/jobAssignment/api';
import { EndorseButton } from '@company/components/WorkerParts';
import { useChatContext } from '@company/state/chatContext';
import { useJobAssignmentStatus } from '@hooks/useJobAssignmentStatus';
import { HiOutlineShare } from '@react-icons/all-files/hi/HiOutlineShare';
import ModalConfirm from '@components/elements/ModalConfirm/ModalConfirm';
import { BsPersonCheck } from '@react-icons/all-files/bs/BsPersonCheck';
import { JobAssignmentStatus } from '@th-types/job-assignment-status.type';
import HireModal from '@company/components/HireModal';
import ReviewButton from '@company/components/WorkerParts/ReviewButton';
import { Alert, Button, Col, Row, Stack } from 'react-bootstrap';
import { JobHiringType } from '@constants/jobHiringType';
import { IWorkerJobData } from '@th-types/workerProfile.type';
import { BsChat } from '@react-icons/all-files/bs/BsChat';
import IModalConfirmModel from '@th-types/confirm-model.type';
import TooltipOverlay from '@components/elements/Tooltip';
import { useUserContext } from '@state/userContext';
import { UserRole } from '@constants/userRoles';
import { MdOutlineCancel } from 'react-icons/md';
import { FiAlertTriangle } from 'react-icons/fi';
import { ThLoading } from '@components/elements';
import { useEffect, useState } from 'react';
import useAlert from '@hooks/useAlert';
import IJobDetailData from '@th-types/job.detail.type';

interface JobWorkerActionsProps {
  workerProfile: IWorkerJobData;
  openReviewModal: () => void;
  job: IJobDetailData;
  checkInternalMember: (id: number) => boolean;
  reloadJobWorker: (fetchShifts?: boolean, fetchCounters?: boolean) => void;
  isLoadingUpdateWorker: boolean;
}

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

export enum JobWorkerActionsEnum {
  OFFER_JOB,
  OPEN_CHAT,
  SHARE_JOB,
  CANCEL_HIRE,
  CANCEL_OFFER,
  BLOCK_WORKER,
  UNBLOCK_WORKER,
  RECONSIDER_OFFER,
  RECONSIDER_WORKER,
  DECLINE_APPLICATION,
}

export default function JobWorkerActions({
  workerProfile,
  openReviewModal,
  job,
  checkInternalMember,
  reloadJobWorker,
  isLoadingUpdateWorker,
}: JobWorkerActionsProps) {
  const {
    worker,
    jobAssignment,
    jobPlacement,
    jobApplication,
    jobAssignmentLogs,
  } = workerProfile;

  const { openChat } = useChatContext();
  const [openHireModal, setOpenHireModal] = useState<string | null>(null);
  const [showOfferHireButton, setShowOfferHireButton] =
    useState<boolean>(false);
  const [offerHireButtonText, setOfferHireButtonText] = useState<JobHiringType>(
    JobHiringType.OFFER
  );
  const { showSuccessAlert, showWarningAlert, alertMessage } = useAlert();
  const jobAssignmentStatus = useJobAssignmentStatus();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalConfirmModel, setModalConfirmModel] =
    useState<IModalConfirmModel>();
  const [isLoadingAction, setLoadingAction] = useState(false);
  const { id, hasRole } = useUserContext();

  const { isOffered, isApplicant, canBeReconsidered, isHired } =
    jobAssignmentStatus(jobAssignment?.status);

  const setOfferHireButtons = () => {
    setShowOfferHireButton(
      job.isActive &&
        (checkInternalMember(id) || hasRole(UserRole.ROLE_COMPANY_ADMIN)) &&
        !isHired() &&
        !isOffered()
    );

    if (jobAssignment?.id && isApplicant()) {
      setOfferHireButtonText(JobHiringType.HIRE);
    }
  };

  useEffect(() => {
    setOfferHireButtons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shareJobToWorker = async () => {
    setLoadingAction(true);
    const result = await jobAssignmentApi.shareJobToWorker(job.id, worker.id);
    setLoadingAction(false);
    if (result && result.success) {
      reloadJobWorker(false, false);
      showSuccessAlert(result.message);
    } else if (result) {
      showWarningAlert(result.message);
    }
  };

  const handleOpenChat = () => {
    const { isHiredOrApplicant } = jobAssignmentStatus(jobAssignment?.status);
    openChat(worker, jobAssignment?.id || null, isHiredOrApplicant());
  };

  const declineWorkerApplication = async () => {
    setLoadingAction(true);
    const jobAssignmentId = jobAssignment?.id || jobAssignment?.id;
    if (jobAssignmentId) {
      const result =
        await jobAssignmentApi.declineWorkerApplication(jobAssignmentId);
      if (result && result.success) {
        reloadJobWorker(false, true);
        setOfferHireButtons();
        showSuccessAlert(result.message);
      } else if (result) {
        showWarningAlert(result.message);
      }
    }
    setLoadingAction(false);
  };

  const cancelHiredWorker = async () => {
    setLoadingAction(true);
    const jobAssignmentId = jobAssignment?.id || jobAssignment?.id;
    if (jobAssignmentId) {
      const result = await jobAssignmentApi.cancelHiredWorker(jobAssignmentId);
      setShowConfirmModal(false);
      if (result && result.success) {
        reloadJobWorker(true, true);
        setOfferHireButtons();
        showSuccessAlert(result.message);
      } else if (result) {
        showWarningAlert(result.message);
      }
    }
    setLoadingAction(false);
  };

  const cancelJobOfferAction = async () => {
    setLoadingAction(true);
    const jobPlacementId = jobPlacement?.id;
    const jobId = job.id;
    if (jobPlacementId && jobId) {
      const result = await jobAssignmentApi.cancelJobOffer(
        jobId,
        jobPlacementId
      );
      if (result && result.success) {
        reloadJobWorker(true, true);
        setOfferHireButtons();
        showSuccessAlert(result.message);
      } else if (result) {
        showWarningAlert(result.message);
      }
    }
    setLoadingAction(false);
  };

  const reconsiderWorker = async () => {
    setLoadingAction(true);
    const jobAssignmentId = jobAssignment?.id || jobAssignment?.id;
    if (jobAssignmentId) {
      const result = await jobAssignmentApi.reconsiderWorker(jobAssignmentId);
      if (result && result.success) {
        reloadJobWorker(true, true);
        setOfferHireButtons();
        showSuccessAlert(result.message);
      } else if (result) {
        showWarningAlert(result.message);
      }
    }
    setLoadingAction(false);
  };

  const reconsiderJobOfferAction = async () => {
    setLoadingAction(true);
    const jobPlacementId = jobPlacement?.id;
    const jobId = job.id;
    if (jobPlacementId && jobId) {
      const result = await jobAssignmentApi.reconsiderJobOffer(
        jobId,
        jobPlacementId
      );
      if (result && result.success) {
        reloadJobWorker(true, true);
        setOfferHireButtons();
        showSuccessAlert(result.message);
      } else if (result) {
        showWarningAlert(result.message);
      }
      setShowConfirmModal(false);
    }
    setLoadingAction(false);
  };

  const showDeclineApplicationButton = () => {
    const statusOfJobAssignment = jobAssignment?.status;
    if (statusOfJobAssignment === undefined) {
      return false;
    }
    return (
      jobApplication &&
      (statusOfJobAssignment === JobAssignmentStatus.WORKER_INTERESTED ||
        statusOfJobAssignment === JobAssignmentStatus.WORKER_APPLIED)
    );
  };

  const getModalConfirmData = (action: JobWorkerActionsEnum) => {
    switch (action) {
      case JobWorkerActionsEnum.CANCEL_HIRE:
        setModalConfirmModel({
          message: (
            <div className="text-center">
              <p>
                <b>Are you sure?</b>
              </p>
              <p>This action will cancel the hiring process of this worker</p>
            </div>
          ),
          titleMessage: (
            <div className="text-center">
              <FiAlertTriangle className="pb-1 me-2" />
              <span>Cancel worker hiring</span>
            </div>
          ),
          confirmFunction: () => cancelHiredWorker(),
          show: showConfirmModal,
        });
        break;
      case JobWorkerActionsEnum.RECONSIDER_WORKER:
        setModalConfirmModel({
          message: (
            <div className="text-center">
              <p>
                <b>Are you sure?</b>
              </p>
              <p>
                This action will reconsider this worker as applicant, and you
                can hire them.
              </p>
            </div>
          ),
          titleMessage: (
            <div className="text-center">
              <FiAlertTriangle className="pb-1 me-2" />
              <span>Reconsider worker</span>
            </div>
          ),
          confirmFunction: () => reconsiderWorker(),
          show: showConfirmModal,
        });
        break;
      case JobWorkerActionsEnum.RECONSIDER_OFFER:
        setModalConfirmModel({
          message: (
            <div className="text-center">
              <p>
                <b>Are you sure?</b>
              </p>
              <p>This action will reconsider the offer for this worker.</p>
            </div>
          ),
          titleMessage: (
            <div className="text-center">
              <FiAlertTriangle className="pb-1 me-2" />
              <span>Reconsider offer</span>
            </div>
          ),
          confirmFunction: () => reconsiderJobOfferAction(),
          show: showConfirmModal,
        });
        break;
      default:
    }
  };

  const openConfirmModal = async (action: JobWorkerActionsEnum) => {
    setShowConfirmModal(true);
    getModalConfirmData(action);
  };

  const renderHireButton = () => {
    const btnRender = () => {
      return (
        <Button
          size="sm"
          variant="primary"
          className="fw-bolder"
          disabled={jobAssignment?.hired || jobAssignment?.hidden}
          onClick={() =>
            setOpenHireModal(() =>
              isApplicant() ? JobHiringType.HIRE : JobHiringType.OFFER
            )
          }
        >
          <BsPersonCheck size={18} />
          <b>&nbsp;{offerHireButtonText}</b>
        </Button>
      );
    };
    if (isApplicant() && jobAssignment?.hidden) {
      return (
        <TooltipOverlay text="Applicant is hidden. To hire, move them to applicant list">
          {btnRender()}
        </TooltipOverlay>
      );
    }
    return btnRender();
  };

  const showChatButton = () => {
    return (
      (checkInternalMember(id) || hasRole(UserRole.ROLE_COMPANY_ADMIN)) &&
      (job.isActive || jobAssignment?.id)
    );
  };

  const handleReconsiderClick = () => {
    const logs = jobAssignmentLogs;
    return logs && logs[0].fromStatus === JobAssignmentStatus.OFFERED
      ? openConfirmModal(JobWorkerActionsEnum.RECONSIDER_OFFER)
      : openConfirmModal(JobWorkerActionsEnum.RECONSIDER_WORKER);
  };

  return (
    <>
      {(isLoadingAction || isLoadingUpdateWorker) && <ThLoading />}
      {showConfirmModal && modalConfirmModel && (
        <ModalConfirm
          modalConfirmModel={modalConfirmModel}
          setShowModal={setShowConfirmModal}
        />
      )}
      <Row className="mb-2">
        <Col sm={12} className="d-flex justify-content-end">
          <Stack direction="horizontal" gap={4}>
            <EndorseButton
              endorseUrl={`${SERVER_URL}/w/${`${
                worker.customUrl ?? worker.id
              }?openEndorsementModal=true`}`}
              isEndorsedByUser={workerProfile.isEndorsedByUser}
            />
            <ReviewButton
              openReviewModal={openReviewModal}
              workerRated={jobAssignment?.workerRated}
            />
            {showDeclineApplicationButton() && (
              <Button
                size="sm"
                variant="light"
                className="fw-bolder"
                onClick={() => declineWorkerApplication()}
              >
                <MdOutlineCancel size={18} />
                <b>&nbsp;DECLINE</b>
              </Button>
            )}
            {showChatButton() && (
              <Button
                size="sm"
                variant="light"
                className="fw-bolder"
                onClick={() => handleOpenChat()}
              >
                <BsChat size={18} />
                <b>&nbsp;MESSAGE</b>
              </Button>
            )}
            {!jobAssignment && job.isActive && (
              <Button
                size="sm"
                variant="primary"
                className="fw-bolder"
                disabled={jobAssignment !== undefined}
                onClick={shareJobToWorker}
              >
                <HiOutlineShare size={20} />
                <b>&nbsp;SHARE JOB</b>
              </Button>
            )}
            {jobAssignment?.hired && (
              <Button
                size="sm"
                variant="primary"
                className="fw-bolder"
                onClick={() =>
                  openConfirmModal(JobWorkerActionsEnum.CANCEL_HIRE)
                }
              >
                <b>CANCEL</b>
              </Button>
            )}
            {jobAssignmentLogs && canBeReconsidered(jobAssignmentLogs) && (
              <Button
                size="sm"
                variant="primary"
                className="fw-bolder"
                onClick={handleReconsiderClick}
              >
                <b>RECONSIDER</b>
              </Button>
            )}
            {isOffered() && (
              <Button
                size="sm"
                variant="primary"
                className="fw-bolder"
                onClick={() => cancelJobOfferAction()}
              >
                <b>CANCEL OFFER</b>
              </Button>
            )}
            {showOfferHireButton && renderHireButton()}
          </Stack>
        </Col>
      </Row>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
      {openHireModal && (
        <HireModal
          jobAssignment={jobAssignment}
          worker={worker}
          handleSuccess={(message) => {
            if (message) {
              showSuccessAlert(message);
            }
            setOpenHireModal(null);
            reloadJobWorker(true, true);
          }}
          handleFailure={(message) => {
            if (message) {
              showWarningAlert(message);
            }
            setOpenHireModal(null);
          }}
          handleClose={() => setOpenHireModal(null)}
          hiringType={offerHireButtonText}
        />
      )}
    </>
  );
}
