import { OfferShiftsButtonWeb } from '@company/pages/JobOverview/Scheduling/OfferShiftButton';
import { OfferShiftMobileModal } from '@company/pages/JobOverview/Scheduling/OfferShift';
import { useJobWorkerShiftContext } from '@company/state/jobWorkerShiftContext';
import { useJobAssignmentContext } from '@company/state/jobAssignmentContext';
import { ConfirmationCancelShiftsMessage } from '@company/components';
import { useJobShiftContext } from '@company/state/jobShiftContext';
import * as jobApi from '@company/services/job/api';
import { ModalConfirm, ThLoading } from '@components/elements';
import {
  hasSameShiftAssigned,
  removeDuplicatedShifts,
} from '@utils/ShiftsUtils';
import IModalConfirmModel from '@th-types/confirm-model.type';
import { Alert, Button, Stack, Tab } from 'react-bootstrap';
import { isDateAfterOrNow } from '@utils/DateUtils';
import {
  initialApplicantFilters,
  useJobContext,
} from '@company/state/jobContext';
import { IWorkerData } from '@th-types/worker.type';
import Panel from '@components/elements/Panel';
import { ShiftStatus } from '@th-types/jobs.type';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import useAlert from '@hooks/useAlert';
import {
  canCancelSelectedShifts,
  CANCEL_SHIFT,
  canOfferSelectedShifts,
} from '@services/shift/shiftService';
import OfferShiftModal from '@company/pages/JobOverview/Scheduling/OfferShift/OfferShiftModal';
import ThTabs from '@company/components/th/ThTabs';
import JobWorkerShiftTables from './JobWorkerShiftTables';
import './styles.scss';

interface JobWorkerShiftsParams {
  worker: IWorkerData;
}

/**
 * Don't import shift statuses from scheduling tab since there is more
 * business logic that may differ in the future
 */
const shiftStatusOptions = [
  { value: ShiftStatus.OPEN, label: 'Open' },
  { value: ShiftStatus.PENDING, label: 'Pending' },
  { value: ShiftStatus.CONFIRMED, label: 'Confirmed' },
];

function JobWorkerShifts({ worker }: JobWorkerShiftsParams) {
  const { showSuccessAlert, showWarningAlert, alertMessage } = useAlert();
  const [activeKey, setActiveKey] = useState<ShiftStatus>(ShiftStatus.OPEN);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [modalConfirmModel, setModalConfirmModel] =
    useState<IModalConfirmModel>();

  const { shifts, refetch, setSelectedWorker, setApplicantFilters } =
    useJobShiftContext();

  const {
    showOfferShiftModal,
    selectedJobWorkerShifts,
    setShowOfferShiftModal,
    setJobWorkerStatusFilter,
    setSelectedJobWorkerShifts,
  } = useJobWorkerShiftContext();

  const { job, jobTimezone, fetchApplicantCounters } = useJobContext();
  const { refetch: refetchWorkerProfile } = useJobAssignmentContext();

  const openOfferShiftModal = () => {
    setSelectedWorker(worker);
    setShowOfferShiftModal(!showOfferShiftModal);
  };
  const closeOfferShiftModal = () => {
    setShowOfferShiftModal(!showOfferShiftModal);
  };

  useEffect(() => {
    setSelectedWorker(worker);
    setJobWorkerStatusFilter(activeKey);
  }, [activeKey, setJobWorkerStatusFilter, setSelectedWorker, worker]);

  const disableTab = (status: ShiftStatus) => {
    if (shifts) {
      let filteredData = shifts.filter(
        (d) => d.shiftStatus === status.toLowerCase()
      );
      switch (status) {
        case ShiftStatus.OPEN:
          return !filteredData || filteredData.length === 0;
        default:
          if (worker) {
            filteredData = filteredData.filter(
              (d) => d.jobAssignment?.worker.id === worker.id
            );
          }
          return !filteredData || filteredData.length === 0;
      }
    }
    return true;
  };

  const shiftOfferedCloseModal = async (
    isSuccess: boolean,
    message: string
  ) => {
    if (isSuccess) {
      await refetch();
      showSuccessAlert(message);
    } else {
      showWarningAlert(message);
    }
    setApplicantFilters({
      ...initialApplicantFilters,
    });
    setSelectedJobWorkerShifts([]);
    closeOfferShiftModal();
    if (isSuccess) {
      refetchWorkerProfile();
    }
  };

  const isWorkerAbleToReceiveOffer = () => {
    return (
      activeKey === ShiftStatus.OPEN &&
      canOfferSelectedShifts(selectedJobWorkerShifts)
    );
  };

  if (!job) {
    return <div />;
  }

  const cancelSelectedShifts = async () => {
    if (selectedJobWorkerShifts) {
      try {
        setIsLoadingAction(true);
        const shiftIds = selectedJobWorkerShifts.map((x) => x.id);
        if (shiftIds?.length > 0) {
          const result = await jobApi.cancelJobShifts(job.id, shiftIds);

          if (result.status === 200) {
            const messageSuccess = result?.data.message;
            await refetch();
            showSuccessAlert(messageSuccess);
          } else {
            showWarningAlert(result.data.message);
          }
        }
      } catch (err) {
        console.log(`Occurred an error trying to cancel shift`, err);
      } finally {
        setSelectedJobWorkerShifts([]);
        setShowConfirmModal(false);
        setActiveKey(ShiftStatus.OPEN);
        setIsLoadingAction(false);
        fetchApplicantCounters();
      }
    }
  };

  const fillModalConfirmData = () => {
    setModalConfirmModel({
      message: <ConfirmationCancelShiftsMessage isCancelAction />,
      titleMessage: (
        <div>
          <span>Are you sure?</span>
        </div>
      ),
      confirmFunction: cancelSelectedShifts,
      show: showConfirmModal,
      styleClass: 'modal-confirm-style',
    });
  };

  const openConfirmModal = async () => {
    setShowConfirmModal(true);
    fillModalConfirmData();
  };

  if (isLoadingAction) return <ThLoading />;

  const getTotalShifts = (status: ShiftStatus) => {
    if (status !== ShiftStatus.OPEN) {
      return shifts
        ? shifts.filter(
            (shift) =>
              shift.shiftStatus === status &&
              shift.jobAssignment?.worker?.id === worker.id
          ).length
        : 0;
    }

    /**
     * This is the total open shifts we show in job worker modal
     * since we don't show duplicated for the same worker
     * we need to count only different shifts
     */
    return shifts
      ? removeDuplicatedShifts(shifts).filter(
          (shift) =>
            shift.shiftStatus === status &&
            isDateAfterOrNow(shift.start, jobTimezone) &&
            !hasSameShiftAssigned(worker, shift, shifts)
        ).length
      : 0;
  };

  const showCancelButton = () => {
    return (
      activeKey !== ShiftStatus.OPEN &&
      canCancelSelectedShifts(selectedJobWorkerShifts)
    );
  };

  const getShiftStatusFromString = (status: string) => {
    return Object.values(ShiftStatus).find((value) => value === status);
  };

  const onChangeTab = (eventKey: string | null) => {
    setSelectedJobWorkerShifts([]);
    if (eventKey) {
      const newStatus = getShiftStatusFromString(eventKey);
      if (newStatus) {
        setActiveKey(newStatus);
      }
    }
  };

  return (
    <Panel>
      <ThTabs
        fill
        defaultActiveKey={ShiftStatus.OPEN.toLowerCase()}
        activeKey={activeKey.toLowerCase()}
        onSelect={(eventKey: string | null) => {
          onChangeTab(eventKey);
        }}
        borderBottom
      >
        {shiftStatusOptions.map((tab) => {
          return (
            <Tab
              eventKey={tab.value.toLowerCase()}
              key={tab.value.toLowerCase()}
              title={`${tab.label} (${getTotalShifts(tab.value)})`}
              disabled={disableTab(tab.value)}
              mountOnEnter
              unmountOnExit
            >
              <Stack className="p-3 m-3 open-shifts">
                <Stack className="justify-content-between alignt-items-center">
                  <Stack
                    direction="horizontal"
                    className="alignt-items-center justify-content-between"
                    gap={3}
                  >
                    {isWorkerAbleToReceiveOffer() && (
                      <OfferShiftsButtonWeb
                        openOfferShiftModal={openOfferShiftModal}
                        tooltipMessage="Offer selected shifts"
                        totalSelectedOfferableShifts={removeDuplicatedShifts(
                          selectedJobWorkerShifts
                        ).reduce(
                          (total, shiftItem) =>
                            shiftItem.shiftStatus === ShiftStatus.OPEN &&
                            isDateAfterOrNow(shiftItem.start, jobTimezone)
                              ? total + 1
                              : total,
                          0
                        )}
                      />
                    )}
                    {showCancelButton() && (
                      <Button
                        variant="primary fw-bold"
                        onClick={() => openConfirmModal()}
                      >
                        {CANCEL_SHIFT}
                        {selectedJobWorkerShifts.length > 1 ? 'S' : ''} (
                        {selectedJobWorkerShifts.length})
                      </Button>
                    )}
                  </Stack>
                  {/* TODOS */}
                  {/* IF THE USER HAS A PENDING/CONFIRMED SHIFT DON'T SHOW OPEN SHIFTS */}
                  <JobWorkerShiftTables showResumed />
                </Stack>
                <Alert
                  show={alertMessage.show}
                  variant={alertMessage.variant}
                  className="alert-fixed"
                  style={{ width: '20rem' }}
                >
                  <Alert.Heading>{alertMessage.message}</Alert.Heading>
                </Alert>
              </Stack>
            </Tab>
          );
        })}
      </ThTabs>
      {showConfirmModal && modalConfirmModel && (
        <ModalConfirm
          modalConfirmModel={modalConfirmModel}
          setShowModal={setShowConfirmModal}
        />
      )}
      {showOfferShiftModal && !isMobile && (
        <OfferShiftModal
          handleClose={closeOfferShiftModal}
          shiftOfferedCloseModal={shiftOfferedCloseModal}
        />
      )}
      {showOfferShiftModal && isMobile && (
        <OfferShiftMobileModal
          handleClose={closeOfferShiftModal}
          shiftOfferedCloseModal={shiftOfferedCloseModal}
        />
      )}
    </Panel>
  );
}

export default JobWorkerShifts;
