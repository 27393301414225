import React from 'react';

interface Props extends React.VideoHTMLAttributes<HTMLVideoElement> {
  src: string;
  width?: string;
  height?: string;
}

function ThPlayer({ src, width, height }: Props) {
  return (
    /* eslint-disable-next-line jsx-a11y/media-has-caption */
    <video
      width={width}
      height={height}
      controls
      className="border"
      style={{ transform: 'none' }}
      dir="ltr"
    >
      <source src={src} />
      Your browser does not support the video tag.
    </video>
  );
}

export default ThPlayer;
