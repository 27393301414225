import { backToJobList } from '@company/services/job/jobUtils';
import { useJobContext } from '@company/state/jobContext';
import * as api from '@company/services/job/api';
import IResultResponse from '@th-types/resultResponse.type';
import { useQuery } from '@tanstack/react-query';
import { Button, Modal } from 'react-bootstrap';
import QueryKeys from '@constants/queryKeys';
import { useNavigate } from 'react-router-dom';
import { isDev } from '@utils/Environment';
import { AxiosError } from 'axios';
import { useState } from 'react';
import ModalActionCompleted from './ModalActionCompleted';

interface ModalParams {
  jobId: number;
  jobPosition: string;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

function ModalDeleteJob({
  jobId,
  jobPosition,
  showModal,
  setShowModal,
}: ModalParams) {
  const REMOVABLE_DESCRIPTION =
    'There\'s no going back! All data with this job, including any workers who have applied, or given shifts, will be lost. Consider clicking "No" under "Show This on Public Job Boards" instead. Are you sure you want to delete?';
  const NOT_REMOVABLE_DESCRIPTION =
    'Job contains hired workers and is not removable. Consider clicking "No" under "Show This on Public Job Boards" and/or removing non-assigned shifts to narrow the visibility of the job.';
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => setShowModal(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const { data } = useQuery<IResultResponse, AxiosError>({
    queryKey: [`${QueryKeys.COMPANY_JOB_CAN_CANCEL}_${jobId}`],
    queryFn: () => api.canDeleteJob(jobId),
  });

  const handleDeletion = async () => {
    setDisabled(true);

    try {
      await api.deleteJob(jobId);

      setShowModal(false);
      setShowSuccessModal(true);
    } catch (err) {
      console.log(err);
    }
  };

  const { job, setActiveJobId } = useJobContext();

  const getNextJob = () => {
    if (job) {
      const sortedJobs = job?.event.eventJobIdsByAddress.sort();
      if (sortedJobs && sortedJobs.length > 1) {
        const index = sortedJobs.indexOf(job?.id);
        const nextId = sortedJobs[index + 1] || sortedJobs[0];
        setActiveJobId(nextId);
        return nextId;
      }
    }
    return null;
  };

  const handleCloseModalActionCompleted = () => {
    const nextId = getNextJob();
    if (!nextId) {
      backToJobList();
    } else {
      navigate(`${!isDev() ? BASE_PATH : '/'}jobs/${nextId}`);
      setShowSuccessModal(false);
    }
  };

  const description = data?.success
    ? REMOVABLE_DESCRIPTION
    : NOT_REMOVABLE_DESCRIPTION;

  return (
    <>
      <ModalActionCompleted
        message="Job removed successfully"
        showModal={showSuccessModal}
        handleClose={handleCloseModalActionCompleted}
      />
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Job {jobPosition}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{description}</p>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {data?.success ? (
            <Button
              variant="danger"
              size="lg"
              onClick={handleDeletion}
              disabled={disabled}
            >
              Delete
            </Button>
          ) : null}

          <Button variant="light" size="lg" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalDeleteJob;
