import { AxiosError } from 'axios';
import { ISuggestions } from '@th-types/suggestions.type';
import { privateApi } from '.';

export const MAIN_URL = 'companies';

export const suggest = async (
  query: string,
  companyType: string | null = null
) => {
  if (!query) {
    return Promise.reject(AxiosError);
  }

  const params: Record<string, string> = {
    query,
  };
  if (companyType) {
    params.companyType = companyType;
  }

  const result = await privateApi.get<ISuggestions>(`${MAIN_URL}/suggest`, {
    params,
  });

  return result.data;
};
