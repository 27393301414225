import './global.css';
import './scss/App.scss';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import { ErrorFallback } from '@components/elements/ErrorBoundary/ErrorFallback';
import AppRoutes from './routes/AppRoutes';
import 'react-toastify/dist/ReactToastify.css';
import VersionAndBuildTime from './components/elements/VersionAndBuildTime/VersionAndBuildTime';
import { isDevOrStaging } from './utils/Environment';

export default function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppRoutes />
        {isDevOrStaging() && <VersionAndBuildTime />}
      </ErrorBoundary>
      <ToastContainer theme="light" />
    </BrowserRouter>
  );
}
