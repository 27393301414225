import { useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import ThRange from '@components/elements/ThRange';

interface JobParams {
  milesRange: number | string;
  disabled: boolean;
  handleMilesRange: (event: number | string) => void;
}

export function MilesDistance({
  milesRange,
  disabled,
  handleMilesRange,
}: JobParams): JSX.Element {
  const MAX_MILES = 500;
  const ALL_MILES_TEXT = 'ALL';

  const initialValue = milesRange === ALL_MILES_TEXT ? 500 : Number(milesRange);
  const [value, setValue] = useState<number>(initialValue);

  const initialTextValue =
    milesRange === ALL_MILES_TEXT ? ALL_MILES_TEXT : String(milesRange);
  const [textValue, setTextValue] = useState<string>(initialTextValue);

  const handleChange = (val: number) => {
    if (!val || Number.isNaN(val)) {
      return;
    }
    const newValue = Number(val) === MAX_MILES ? ALL_MILES_TEXT : String(val);

    setValue(val);
    setTextValue(newValue);
    handleMilesRange(newValue);
  };

  const handleChangeText = (val: string) => {
    if (!val || val.length === 0 || val === undefined) {
      return;
    }
    const newValue = String(val) === ALL_MILES_TEXT ? MAX_MILES : Number(val);

    setValue(newValue);
    setTextValue(val);
    handleMilesRange(val);
  };

  return (
    <InputGroup>
      <Form.Label className="m-0">
        <Row>
          <Col className="text-end">
            <b>Within</b>
          </Col>
          <Col>
            <Form.Control
              size="sm"
              className="text-center fw-bold"
              name="miles-range-number"
              value={textValue}
              disabled={disabled}
              onChange={(e) => handleChangeText(e.target.value)}
            />
          </Col>
          <Col className="text-start">
            <b>miles</b>
          </Col>
        </Row>
      </Form.Label>
      <ThRange
        value={value}
        min={1}
        max={MAX_MILES}
        disabled={disabled}
        onChange={(e: any) => handleChange(e.target.value)}
        className="w-100"
      />
    </InputGroup>
  );
}
