import axios from 'axios';
import { privateApi } from '@services/api';
import {
  ComHubDismissTypeEnum,
  ComHubQueryParamsEnum,
  ReceivedReviewsResponse,
  ReviewRequestsResponse,
  ReviewsLeftResponse,
} from '@company/types/comm-hub.type';
import IResultResponse from '@th-types/resultResponse.type';

const COMM_HUB_MAIN_URL = 'company/comhub';

export async function getReceivedReviewsNewCounter() {
  const url = `${COMM_HUB_MAIN_URL}/reviews/new/count`;
  try {
    const response = await privateApi.get(url);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return error.response?.data ?? { message: 'An unknown error occurred' };
    }
    // Let the caller handle the error
    throw error;
  }
}

export async function getReviewRequestsNewCounter() {
  const url = `${COMM_HUB_MAIN_URL}/review-requests/new/count`;
  try {
    const response = await privateApi.get(url);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return error.response?.data ?? { message: 'An unknown error occurred' };
    }
    // Let the caller handle the error
    throw error;
  }
}

export async function getReceivedReviews(
  page: number,
  itemsPerPage: number,
  searchText?: string,
  dateRange?: { startDate: Date | undefined; endDate: Date | undefined },
  isNew?: boolean
): Promise<ReceivedReviewsResponse> {
  const baseUrl = `${COMM_HUB_MAIN_URL}/reviews`;
  const queryParams = new URLSearchParams();
  queryParams.append(ComHubQueryParamsEnum.OFFSET, page.toString());
  queryParams.append(ComHubQueryParamsEnum.MAX, itemsPerPage.toString());
  if (searchText) {
    queryParams.append(ComHubQueryParamsEnum.SEARCH_TEXT, searchText);
  }
  if (dateRange?.startDate && dateRange?.endDate) {
    queryParams.append(
      ComHubQueryParamsEnum.DATE_FROM,
      dateRange.startDate.toISOString()
    );
    queryParams.append(
      ComHubQueryParamsEnum.DATE_TO,
      dateRange.endDate.toISOString()
    );
  }
  if (isNew) {
    queryParams.append(ComHubQueryParamsEnum.NEW, 'true');
  }
  const url = `${baseUrl}?${queryParams}`;
  try {
    const response = await privateApi.request({
      method: 'GET',
      url,
    });
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return error.response?.data ?? { message: 'An unknown error occurred' };
    }
    // Let the caller handle the error
    throw error;
  }
}

export async function getReviewRequests(
  page: number,
  itemsPerPage: number,
  searchText?: string,
  dateRange?: { startDate: Date | undefined; endDate: Date | undefined },
  isNew?: boolean
): Promise<ReviewRequestsResponse> {
  const baseUrl = `${COMM_HUB_MAIN_URL}/review-requests`;
  const queryParams = new URLSearchParams();
  queryParams.append(ComHubQueryParamsEnum.OFFSET, page.toString());
  queryParams.append(ComHubQueryParamsEnum.MAX, itemsPerPage.toString());
  if (searchText) {
    queryParams.append(ComHubQueryParamsEnum.SEARCH_TEXT, searchText);
  }
  if (dateRange?.startDate && dateRange?.endDate) {
    queryParams.append(
      ComHubQueryParamsEnum.DATE_FROM,
      dateRange.startDate.toISOString()
    );
    queryParams.append(
      ComHubQueryParamsEnum.DATE_TO,
      dateRange.endDate.toISOString()
    );
  }
  if (isNew) {
    queryParams.append(ComHubQueryParamsEnum.NEW, 'true');
  }

  const url = `${baseUrl}?${queryParams}`;

  try {
    const response = await privateApi.request({
      method: 'GET',
      url,
    });
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return error.response?.data ?? { message: 'An unknown error occurred' };
    }
    // Let the caller handle the error
    throw error;
  }
}

export async function getReviewsAndEndorsements(
  page: number,
  itemsPerPage: number,
  searchText?: string,
  dateRange?: { startDate: Date | undefined; endDate: Date | undefined }
): Promise<ReviewsLeftResponse> {
  const baseUrl = `${COMM_HUB_MAIN_URL}/given-reviews`;
  const queryParams = new URLSearchParams();
  queryParams.append(ComHubQueryParamsEnum.OFFSET, page.toString());
  queryParams.append(ComHubQueryParamsEnum.MAX, itemsPerPage.toString());
  if (searchText) {
    queryParams.append(ComHubQueryParamsEnum.SEARCH_TEXT, searchText);
  }
  if (dateRange?.startDate && dateRange?.endDate) {
    queryParams.append(
      ComHubQueryParamsEnum.DATE_FROM,
      dateRange.startDate.toISOString()
    );
    queryParams.append(
      ComHubQueryParamsEnum.DATE_TO,
      dateRange.endDate.toISOString()
    );
  }

  const url = `${baseUrl}?${queryParams}`;
  try {
    const response = await privateApi.request({
      method: 'GET',
      url,
    });
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return error.response?.data ?? { message: 'An unknown error occurred' };
    }
    // Let the caller handle the error
    throw error;
  }
}

export async function dismissItemsFromNew(itemsToDismiss: {
  items: Array<{
    associatedType: ComHubDismissTypeEnum;
    associatedIds: number[];
  }>;
}) {
  const url = `comhub/mark-as-dismissed`;

  try {
    const response = await privateApi.post(url, itemsToDismiss);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return error.response?.data ?? { message: 'An unknown error occurred' };
    }
    // Let the caller handle the error
    throw error;
  }
}

export const replyRating = async (ratingId: number, replyComment: string) => {
  try {
    const url = `${COMM_HUB_MAIN_URL}/reviews/${ratingId}/reply`;
    return await privateApi.post<IResultResponse>(url, {
      replyComment,
    });
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export async function getGivenEndorsement(endorsementCaseId: number) {
  const url = `company/endorsements/${endorsementCaseId}`;

  try {
    const response = await privateApi.get(url);
    return response.data;
  } catch (error: unknown) {
    const errorMessage = axios.isAxiosError(error)
      ? error.message
      : 'An unknown error occurred';
    throw new Error(errorMessage);
  }
}
