const { MODE } = import.meta.env;

export const isDev = () => {
  return MODE === 'development' || MODE === 'dev' || MODE === 'prestage';
};

export const isDevOrStaging = () => {
  return MODE === 'staging' || isDev();
};

export const isBeta = () => {
  return MODE === 'beta';
};

export const isProduction = () => MODE === 'production';
