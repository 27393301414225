import { useState } from 'react';
import { Col, Row, Button, Container, Image, Form } from 'react-bootstrap';
import bisonStunned from '@assets/bison-stunned.png';
import Body from '@company/components/layout/Body';
import { Header } from '@company/components/layout';
import { sendErrorReport } from '@services/error/api';
import { ToastType } from '@th-types/toasts';
import { isDev } from '@utils/Environment';
import { useUserContext } from '@state/userContext';
import { UserRole } from '@constants/userRoles';
import THNavbarOnError from '@worker/components/layout/THNavbar/THNavbarOnError';
import Panel from '@components/elements/Panel';
import { displayToast } from '@components/elements/ToastNotification';

type ErrorProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

export function ErrorFallback({ error, resetErrorBoundary }: ErrorProps) {
  const [reportMessage, setReportMessage] = useState('');
  const handleEmailMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportMessage(event.target.value);
  };
  const { hasRole } = useUserContext();

  const dontSendReport = () => {
    resetErrorBoundary();
    window.location.href = SERVER_URL;
  };

  const sendReport = async () => {
    try {
      const messageError = `Error: ${error.name}\nMessage: ${error.message}\nStack: ${error.stack}`;
      const result = await sendErrorReport(reportMessage, messageError);
      displayToast(ToastType.SUCCESS, result.message);
    } catch {
      displayToast(ToastType.ERROR, 'The report could not be sent');
    }
    resetErrorBoundary();
    window.location.href = SERVER_URL;
  };

  return (
    <>
      {hasRole(UserRole.ROLE_USER) ? <THNavbarOnError /> : <Header />}
      <Body>
        <Container fluid>
          <Row className="d-flex justify-content-center align-items-center">
            <Panel className="panel w-75">
              <Row>
                <Col md="4">
                  <Image src={bisonStunned} fluid />
                </Col>
                <Col md="6">
                  <p
                    className="fw-bold"
                    style={{ fontSize: '28px', lineHeight: '42px' }}
                  >
                    Oh no, you&apos;ve strayed from the herd!
                  </p>
                  <p>
                    We want to make the best experience, and you found a spot we
                    need to improve. Please click “Send Report”, our whole team
                    receives it and we&apos;ll get it fixed right away!
                  </p>
                  <p>
                    To further help our team, what were you doing before this
                    screen? <i>(optional, but appreciated)</i>
                  </p>
                  <Form.Control
                    as="textarea"
                    value={reportMessage}
                    onChange={handleEmailMessage}
                    rows={4}
                  />
                  <div className="mt-4 d-flex justify-content-end">
                    <Button
                      className="fw-bold"
                      variant="outline-dark"
                      onClick={dontSendReport}
                    >
                      Don&apos;t Send
                    </Button>
                    <Button
                      className="fw-bold ms-2"
                      variant="primary"
                      onClick={sendReport}
                    >
                      Send Report
                    </Button>
                  </div>
                </Col>
              </Row>
            </Panel>
          </Row>
          {isDev() && (
            <div className="mt-5 ms-auto me-auto">
              <Row className="m-4 bg-light">
                <p>Error message: {error.message}</p>
              </Row>
              <Row className="m-4 bg-light">
                <p>Error name: {error.name}</p>
              </Row>
              <Row className="m-4 bg-light">
                <p>Stack:</p>
                <p>{error.stack}</p>
              </Row>
            </div>
          )}
        </Container>
      </Body>
    </>
  );
}
