import { useState } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BsPlusCircleFill } from 'react-icons/bs';
import Wizard from '@components/elements/Wizard';
import { ThLoading } from '@components/elements';
import * as companyJobApi from '@company/services/job/api';
import QueryKeys from '@constants/queryKeys';
import { IJobOptionsData, IJobTitle } from '@th-types/jobs.type';
import IBatchRateWorkerSession from '@company/types/batch-rate-worker.type';
import { IWorkerSuggestion } from '@th-types/suggestions.type';
import { IRateWorkerCreationModal } from '@company/types/rate-worker-creation.type';
import { IDatePeriod } from '@th-types/period.type';
import GigInfo from './GigInfo/GigInfo';
import GigInfoPreview from './GigInfo/GigInfoPreview';
import WorkerRating from './WorkerRating/WorkerRating';
import InviteRate from './InviteRate/InviteRate';
import AddReviewModal from './AddReviewModal';
import BackToDashboard from './BackToDashboard';

interface Props {
  companyName: string | null;
  sessionId: number | null;
  addReview?: boolean;
  inReview: boolean;
  setInReview: (value: boolean) => void;
}

function BatchReview({
  companyName,
  sessionId,
  addReview = false,
  inReview,
  setInReview,
}: Props) {
  const [jobOptions, setJobOptions] = useState<IJobOptionsData | null>(null);
  const [eventName, setEventName] = useState<string>();
  const [brandRepresented, setBrandRepresented] = useState<string>();
  const [showAddReviewModal, setShowAddReviewModal] = useState(addReview);
  const [period, setPeriod] = useState<IDatePeriod | undefined>();
  const [isTHJob, setIsTHJob] = useState<boolean>(false);

  const [batchRateWorkerSession, setBatchRateWorkerSession] =
    useState<IBatchRateWorkerSession>();
  const [workerOptions, setWorkerOptions] = useState<IWorkerSuggestion[]>([]);

  const { data: jobOptionsData, isLoading: isLoadingJobData } = useQuery<
    IJobOptionsData,
    AxiosError
  >({
    queryKey: [`${QueryKeys.REVIEW_JOBS}_${companyName}`],
    queryFn: () => companyJobApi.fetchJobPositionList(),
    enabled: companyName !== '' && companyName !== null,
  });

  const handleCloseAddReviewModal = () => {
    setShowAddReviewModal(false);
  };

  const handleContinueAddReviewModal = (data: IRateWorkerCreationModal) => {
    const { isThJob, selectedThJob } = data;
    setIsTHJob(isThJob);
    if (selectedThJob) {
      const jobTitle: IJobTitle = {
        id: selectedThJob.id,
        name: isThJob ? selectedThJob.position : selectedThJob.title.name,
      };
      setJobOptions({ jobTitles: [jobTitle], companyTypes: [] });

      setEventName(selectedThJob.eventName);
      setBrandRepresented(selectedThJob.brandRepresented);
      setPeriod({
        start: selectedThJob.start,
        end: selectedThJob.end,
      });
    } else {
      setJobOptions(jobOptionsData!);
      setEventName(undefined);
      setBrandRepresented(undefined);
      setPeriod(undefined);
    }

    setShowAddReviewModal(false);
    setInReview(true);
  };

  const resetReview = () => {
    setBatchRateWorkerSession(undefined);
    setInReview(false);
  };

  return (
    <>
      {isLoadingJobData && <ThLoading />}
      {inReview ? (
        <Wizard back={<BackToDashboard confirmation={resetReview} />}>
          <GigInfo
            stepTitle="Gig Info"
            company={companyName}
            jobOptions={jobOptions}
            workerOptions={workerOptions}
            setWorkerOptions={setWorkerOptions}
            setBatchRateWorkerSession={setBatchRateWorkerSession}
            eventName={eventName}
            brandRepresented={brandRepresented}
            period={period}
          />
          <GigInfoPreview
            stepTitle="Preview Gig Info"
            jobOptions={jobOptions}
            batchRateWorkerSession={batchRateWorkerSession}
            setBatchRateWorkerSession={setBatchRateWorkerSession}
          />
          <WorkerRating
            isTHJob={isTHJob}
            stepTitle="Worker Rating"
            jobOptions={jobOptions}
            workerOptions={workerOptions}
            batchRateWorkerSession={batchRateWorkerSession}
            setBatchRateWorkerSession={setBatchRateWorkerSession}
          />
          <InviteRate
            stepTitle="Invite to Rate"
            batchRateWorkerSession={batchRateWorkerSession}
          />
        </Wizard>
      ) : (
        <>
          <Row>
            <Col>
              <Button onClick={() => setShowAddReviewModal(true)}>
                <Stack
                  direction="horizontal"
                  className="justify-content-center"
                  gap={2}
                >
                  <BsPlusCircleFill size={24} />
                  ADD REVIEW(S)
                </Stack>
              </Button>
            </Col>
          </Row>
          {sessionId ? (
            <Stack>
              <div>{/* TODO: add saved drafts table */}</div>
            </Stack>
          ) : (
            <Stack>
              <div className="mt-4 fw-bold">
                No batch reviews have been started.
              </div>
              <div className="mt-1">
                Add your review on Trusted Herd with a single click on the
                &ldquo;ADD REVIEWS&ldquo; button above. Your insights contribute
                to building a <span className="fw-bold">trusted</span>{' '}
                community.
              </div>
              {/* <div className="mt-4 fw-bold"> */}
              {/*  Incomplete Batch Reviews? No Worries! */}
              {/* </div> */}
              {/* <div className="mt-1"> */}
              {/*  Don&apos;t stress if you can&apos;t finish a batch review in one */}
              {/*  go! Any non-finished reviews will be automatically saved as */}
              {/*  drafts and you can access them here. */}
              {/* </div> */}
            </Stack>
          )}

          {showAddReviewModal && (
            <AddReviewModal
              show={showAddReviewModal}
              handleClose={handleCloseAddReviewModal}
              handleContinue={handleContinueAddReviewModal}
            />
          )}
        </>
      )}
    </>
  );
}

export default BatchReview;
