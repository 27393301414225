import axios from 'axios';

const CONTEXT_PATH = import.meta.env.VITE_API_CONTEXT_PATH;

export const privateApi = axios.create({
  baseURL: CONTEXT_PATH,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

export function getRequestConfig(offset: any, max: any) {
  return {
    params: {
      offset,
      max,
    },
  };
}

privateApi.interceptors.request.use((config) => config);
