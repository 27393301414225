import { components, SingleValueProps } from 'react-select';
import Avatar from 'react-avatar';
import { GrLocation } from '@react-icons/all-files/gr/GrLocation';

export type CustomSingleValueProps = {
  workerName: string;
  avatar: string;
  location: string;
};

export default function CustomSingleValue({
  children,
  workerName,
  avatar,
  location,
  ...props
}: SingleValueProps & CustomSingleValueProps) {
  return (
    <components.SingleValue {...props}>
      <div className="d-flex align-items-center">
        <Avatar size="30" round src={avatar} className="me-2" />
        <div>
          <div className="fw-bold">{workerName}</div>
          <GrLocation className="me-1 mb-1" />
          {location}
        </div>
      </div>
    </components.SingleValue>
  );
}
