import { Stack } from 'react-bootstrap';
import './styles.css';

interface ApplicationMessageProps {
  text: string;
}

function ApplicationMessage({ text }: ApplicationMessageProps) {
  return (
    <Stack className="p3 job-info mb-3 mt-2">
      <strong>Application note:</strong>
      <hr />
      <p className="text">{text}</p>
      {/* TODO: embed application question answers if present */}
    </Stack>
  );
}

export default ApplicationMessage;
