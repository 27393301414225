export enum JobAssignmentStatus {
  WORKER_APPLIED = 'WORKER_APPLIED',
  WORKER_INTERESTED = 'WORKER_INTERESTED',
  WORKER_NOT_INTERESTED = 'WORKER_NOT_INTERESTED',
  DECLINED_BY_COMPANY = 'DECLINED_BY_COMPANY',
  OFFERED = 'OFFERED',
  SHARED = 'SHARED',
  HIRED = 'HIRED',
  DECLINED_BY_WORKER = 'DECLINED_BY_WORKER',
  CANCELLED_BY_WORKER = 'CANCELLED_BY_WORKER',
  CANCELLED_BY_COMPANY = 'CANCELLED_BY_COMPANY',
  TIMESHEET_COMPLETED = 'TIMESHEET_COMPLETED',
  TIMESHEET_APPROVED = 'TIMESHEET_APPROVED',
}
