import { Badge } from 'react-bootstrap';
import './style.scss';

interface Props {
  value: string;
  label: string;
  total?: number;
  selected?: boolean;
  onClick?: () => void;
}

export default function ThFilterBadge({
  value,
  label,
  total,
  selected,
  onClick,
}: Props) {
  const selectedStyle = `btn-th-filter-badge__${value.toLowerCase()} fw-bold`;
  return (
    <Badge
      className={`${selected ? selectedStyle : ''} btn-th-filter-badge m-1`}
      pill
      bg="light"
      text="dark"
      onClick={onClick}
    >
      {label} {Number.isInteger(total) && `(${total})`}
    </Badge>
  );
}
