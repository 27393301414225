import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { MdRefresh } from 'react-icons/md';

interface RefreshButtonProps {
  isSmall: boolean;
  className?: string;
  refreshFunction: () => void;
}

export default function RefreshButton({
  isSmall,
  className,
  refreshFunction,
}: RefreshButtonProps) {
  const getButton = () => {
    return (
      <Button
        className={`${className} ? ${className} : ''`}
        size={isSmall ? 'sm' : 'lg'}
        onClick={() => refreshFunction()}
        variant="light"
      >
        <MdRefresh size={20} />
      </Button>
    );
  };

  return isMobile ? (
    getButton()
  ) : (
    <OverlayTrigger
      flip
      placement="bottom"
      overlay={<Tooltip id="button-tooltip">Refresh</Tooltip>}
    >
      {getButton()}
    </OverlayTrigger>
  );
}
