import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Button, Form, Stack } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { ThLoading } from '@components/elements';
import {
  Education,
  EducationSchemaType,
  educationValidationSchema,
} from '@th-types/workerProfile.type';
import useAlert from '@hooks/useAlert';
import { useWorkerProfileContext } from '@worker/state/workerProfileContext';
import * as api from '@worker/services/worker/api';

import './styles.css';

interface EducationFormProps {
  educationToEdit?: Education;
  onSuccess: () => void;
  onCancel: () => void;
}

function EducationForm({
  educationToEdit,
  onCancel,
  onSuccess,
}: EducationFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { setWorkerEducation } = useWorkerProfileContext();
  const { showErrorAlert, alertMessage } = useAlert();

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<EducationSchemaType>({
    resolver: zodResolver(educationValidationSchema),
    defaultValues: {
      degree: educationToEdit?.degree,
      degreeDate: educationToEdit?.degreeDate
        ? new Date(educationToEdit.degreeDate)
        : undefined,
      id: educationToEdit?.id,
      university: educationToEdit?.university,
    },
  });

  const onSubmit: SubmitHandler<EducationSchemaType> = async (
    data: EducationSchemaType
  ) => {
    try {
      setIsLoading(true);
      const result = await api.addEducationToWorker(data);

      if (result) {
        // THIS CAN BE MOVED TO A FUNCTION IN THE CONTEXT
        setWorkerEducation((prevState) => {
          if (!prevState) {
            return [{ ...result }];
          }
          const newState = [...prevState!];
          const educationIndex = prevState.findIndex(
            (edu) => edu.id === result.id
          );
          if (educationIndex >= 0) {
            newState[educationIndex] = { ...result };
          } else {
            newState.unshift({ ...result });
          }
          return newState;
        });
        reset();
        onSuccess();
      }
    } catch (error) {
      showErrorAlert('An error ocurred submitting the form.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="education-form"
      autoComplete="off"
    >
      {isLoading && <ThLoading />}
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>
            <b>School/University Name *</b>
          </Form.Label>
          <Form.Control
            {...register('university')}
            placeholder="Enter school/university name"
            className={errors.university ? 'border-danger' : ''}
          />
          {errors.university && (
            <span className="form-field-error-message">
              {errors.university.message}
            </span>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b>Type of Educational Degree *</b>
          </Form.Label>
          <Form.Control
            {...register('degree')}
            placeholder="Educational degree type"
            className={errors.degree ? 'border-danger' : ''}
          />
          {errors.degree && (
            <span className="form-field-error-message">
              {errors.degree.message}
            </span>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b>Degree Date *</b>
          </Form.Label>
          <Controller
            control={control}
            name="degreeDate"
            render={({ field }) => (
              <ReactDatePicker
                className={`form-control ${
                  errors.degreeDate ? 'border-danger' : ''
                }`}
                selected={field.value}
                onChange={(date) => field.onChange(date as Date)}
                placeholderText="MM/DD/YYY"
                maxDate={new Date()}
              />
            )}
          />
          {errors.degreeDate && (
            <span className="form-field-error-message">
              {errors.degreeDate.message}
            </span>
          )}
        </Form.Group>
        <Stack direction="horizontal" className="justify-content-end" gap={3}>
          <Button
            variant="light"
            onClick={onCancel}
            className={isMobile ? 'flex-grow-1' : ''}
          >
            Cancel
          </Button>
          <Button type="submit">Save Changes</Button>
        </Stack>
      </Stack>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </Form>
  );
}

export default EducationForm;
