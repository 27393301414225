import { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Dropdown, Stack, Tab } from 'react-bootstrap';
import { FiChevronDown } from 'react-icons/fi';
import { useCompanyContext } from '@company/state/companyContext';
import { ThLoading } from '@components/elements';
import { ThTabs } from '@company/components/th';
import { Body } from '@company/components/layout';
import { BatchReview } from './BatchReview';
import BackToDashboard from './BatchReview/BackToDashboard';

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

function RedirectToReceivedReview() {
  useEffect(() => {
    window.location.href = `${SERVER_URL}/companyReviews/received`;
  }, []);
  return <ThLoading />;
}

function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function LeaveReview() {
  const queryParams = useQueryParams();
  const sessionIdParam = queryParams.get('sessionId');
  const addReview =
    queryParams.get('addReview')?.toLocaleLowerCase() === 'true';
  const { name: companyName } = useCompanyContext();

  const [key, setKey] = useState('batch');
  const [inReview, setInReview] = useState(false);
  const [sessionId, setSessionId] = useState<number | null>(null);

  useEffect(() => {
    setSessionId(sessionIdParam ? Number(sessionIdParam) : null);
  }, [sessionIdParam]);

  function redirectToReviewHistory() {
    window.location.reload();
  }

  const reviewTabs = [
    {
      name: 'batch',
      description: 'Batch Reviews',
      component: (
        <BatchReview
          companyName={companyName}
          sessionId={sessionId}
          addReview={addReview}
          inReview={inReview}
          setInReview={setInReview}
        />
      ),
    },
    {
      name: 'received',
      description: 'Received Reviews',
      component: key === 'received' ? <RedirectToReceivedReview /> : null,
      isDemo: true,
    },
  ];

  return isMobile ? (
    <div>
      <Stack
        direction="horizontal"
        className="d-flex mx-2 justify-content-between align-items-center mt-2"
      >
        {inReview && (
          <BackToDashboard confirmation={() => redirectToReviewHistory()} />
        )}

        {!inReview && (
          <Dropdown>
            <Dropdown.Toggle variant="">
              <Stack direction="horizontal" gap={2}>
                <b>
                  {reviewTabs.find((tab) => tab.name === key)?.description ||
                    'Tab'}
                </b>
                <FiChevronDown size={24} />
              </Stack>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {reviewTabs.map((tab) => (
                <Dropdown.Item key={tab.name} onClick={() => setKey(tab.name)}>
                  {tab.description}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Stack>
      <Body>{reviewTabs.find((tab) => tab.name === key)?.component}</Body>
    </div>
  ) : (
    <div>
      <ThTabs
        id="controlled-tab-example"
        className="job-header"
        activeKey={key}
        onSelect={(k: string | null) => setKey(k!)}
      >
        {reviewTabs.map((tab) => (
          <Tab key={tab.name} eventKey={tab.name} title={tab.description}>
            <Body>{tab.component}</Body>
          </Tab>
        ))}
      </ThTabs>
    </div>
  );
}

export default LeaveReview;
