import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { useUserContext } from '@state/userContext';
import ReactTimeAgo from 'react-time-ago';
import { useCallback, useEffect, useRef, useState } from 'react';
import QueryKeys from '@constants/queryKeys';
import IDirectChat from '@th-types/direct-messages-chat.type';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import * as api from '@company/services/directMessage/api';
import { useDirectMessageContext } from '@company/state/directMessageContext';
import { MINUTE, TEN_MINUTES } from '@constants/various';
import { MessageObject } from '@th-types/message-request.type';
import useAlert from '@hooks/useAlert';
import './styles.css';
import { IWorkerData } from '@th-types/worker.type';
import IDirectMessage from '@th-types/direct-message.type';
import { getPersonByWorker } from '@utils/Person';

export interface ISortableDirectMessages {
  id: number;
  created: Date;
  createdTimestamp: number;
  message?: IDirectMessage;
  sent: boolean;
}

interface Params {
  worker: IWorkerData;
  messageRecentlySent: MessageObject | null;
  setMessageRecentlySent: (value: MessageObject | null) => void;
}

export const MESSAGE_UNSENT = `MESSAGE UNSENT: we couldn’t send your message, please try again`;

export default function DirectMessagesChat({
  worker,
  messageRecentlySent,
  setMessageRecentlySent,
}: Params) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { id: userId } = useUserContext();
  const {
    chatId,
    setChatId,
    directMessages,
    setDirectMessages,
    unsentMessages,
    orderedMessages,
    setUnsentMessages,
  } = useDirectMessageContext();

  const { showErrorAlert, alertMessage } = useAlert();
  const [refetchInterval, setRefetchInterval] = useState(TEN_MINUTES);

  const fetchMessages = useCallback(async () => {
    let currentChatId = chatId; // store inside function to avoid async call will null on fetchDirectMessages
    if (!currentChatId) {
      try {
        const chat = await api.fetchWorkerDirectMessages(worker.id);
        currentChatId = chat.messages?.[0]?.chatId as number;
        if (currentChatId) {
          setChatId(currentChatId);
        } else {
          console.error(`No chatId found in messages for worker ${worker.id}`);
          return {} as IDirectChat;
        }
      } catch (error) {
        console.error(
          `Error fetching direct messages for worker ${worker.id}:`,
          error
        );
        return {} as IDirectChat;
      }
    }
    try {
      return await api.fetchDirectMessages(currentChatId);
    } catch (error) {
      console.error(
        `Error fetching direct messages for chatId ${currentChatId}:`,
        error
      );
      return {} as IDirectChat;
    }
  }, [chatId, setChatId, worker.id]);

  const { data, error, refetch } = useQuery<IDirectChat, AxiosError>({
    queryKey: [`${QueryKeys.MESSAGE_CONVERSATIONS}_${chatId}`],
    queryFn: fetchMessages,
    enabled: chatId !== null,
    refetchInterval,
    refetchIntervalInBackground: true,
    retry: (failureCount) => failureCount < 3,
    retryDelay: MINUTE / 2,
  });

  useEffect(() => {
    if (error) {
      setRefetchInterval(0);
    }
  }, [error]);

  useEffect(() => {
    setDirectMessages(data?.messages || []);
    setTimeout(() => {
      if (containerRef.current && 'scrollTop' in containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }, 100);
  }, [data, setDirectMessages]);

  useEffect(() => {
    async function fetchData() {
      await refetch();
      if (containerRef.current && 'scrollTop' in containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }
    fetchData();
  }, [chatId, refetch]);

  useEffect(() => {
    // It adds a new message on the message list
    const saveNewSentMessage = async (newMessage: IDirectMessage) => {
      await setDirectMessages([...(directMessages || []), newMessage]);
      if (
        unsentMessages &&
        unsentMessages.some((x) => x.id === newMessage.id)
      ) {
        await setUnsentMessages(
          unsentMessages.filter((x) => x.id !== newMessage.id)
        );
      }
    };

    // Updates an existing message on the list replacing the sent field
    async function updateExistingMessage(newMessage: IDirectMessage) {
      if (directMessages && messageRecentlySent) {
        const newMessageList = directMessages.filter((msg) => {
          return msg.id !== newMessage.id;
        });
        newMessageList.push({
          ...newMessage,
          sent: messageRecentlySent.sent,
        });
        await setDirectMessages(newMessageList);
        await setUnsentMessages(
          unsentMessages?.filter((x) => x.id !== newMessage.id)
        );
      }
    }

    const setNewMessage = async () => {
      if (messageRecentlySent && worker && chatId) {
        const newMessage: IDirectMessage = {
          id: messageRecentlySent.id,
          author: getPersonByWorker(userId, worker),
          dateCreated: new Date(),
          chatId,
          text: messageRecentlySent.message,
          sent: messageRecentlySent.sent,
        };
        if (directMessages) {
          if (newMessage.sent) {
            saveNewSentMessage(newMessage);
          } else {
            const messageExists = directMessages.some((msg) => {
              return msg.id === newMessage.id;
            });
            if (messageExists) {
              updateExistingMessage(newMessage);
            } else {
              setUnsentMessages([...(unsentMessages || []), newMessage]);
              setDirectMessages([...directMessages, newMessage]);
            }
          }
        } else {
          setDirectMessages([newMessage]);
        }
        if (newMessage.sent !== true) {
          showErrorAlert(MESSAGE_UNSENT);
        }
        setMessageRecentlySent(null);
        setTimeout(() => {
          if (containerRef.current && 'scrollTop' in containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
          }
        }, 100);
      }
    };
    setNewMessage();
  }, [
    chatId,
    messageRecentlySent,
    directMessages,
    setMessageRecentlySent,
    setDirectMessages,
    setUnsentMessages,
    showErrorAlert,
    unsentMessages,
    userId,
    worker,
  ]);

  const resendMessage = async (message: IDirectMessage | undefined) => {
    if (!directMessages || !message) {
      return;
    }
    const resultMessageSent = await api.sendDirectMessage(worker.id, {
      message: message.text,
    });
    if (resultMessageSent?.success) {
      const newMessageList = directMessages.filter((msg) => {
        return msg.id !== message.id;
      });
      newMessageList.push({
        ...message,
        dateCreated: new Date(),
      });
      setDirectMessages(newMessageList);
    } else {
      showErrorAlert(MESSAGE_UNSENT);
    }
  };

  return (
    <div
      ref={containerRef}
      style={{
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {orderedMessages &&
        orderedMessages.map((message, index) => {
          return (
            <Row
              key={`message_${message.id}`}
              className={
                userId === message.message?.author.id
                  ? 'justify-content-end'
                  : ''
              }
            >
              {message.message && (
                <Col xs="auto" className={index !== 0 ? 'mt-2' : ''}>
                  <Card
                    style={{ borderRadius: '.6rem' }}
                    bg={
                      userId === message.message?.author.id
                        ? 'primary'
                        : 'light'
                    }
                    border={
                      userId === message.message?.author.id
                        ? 'primary'
                        : 'light'
                    }
                    className="p-3 pt-1 pb-1 me-1"
                  >
                    {message.message &&
                      message.message.text.split('\n').map((line: string) => (
                        <div key={`${line}_${Math.random()}`}>
                          {line}
                          <br />
                        </div>
                      ))}
                  </Card>
                  {message.message.sent === false && (
                    <div className="d-flex">
                      <Button
                        as="a"
                        size="sm"
                        variant="link"
                        className="ms-auto me-2 p-0 justify-content-end text-capitalize resend-btn"
                        onClick={() => resendMessage(message.message)}
                      >
                        resend message
                      </Button>
                    </div>
                  )}
                  <div className="d-flex">
                    <small className="text-gray ms-auto me-2 p-0 text-capitalize justify-content-end">
                      <em>
                        <ReactTimeAgo
                          date={new Date(message.created)}
                          locale="en-US"
                        />
                      </em>
                    </small>
                  </div>
                </Col>
              )}
              <Alert
                show={alertMessage.show}
                variant={alertMessage.variant}
                className="alert-fixed"
                style={{ width: '20rem' }}
              >
                <Alert.Heading>{alertMessage.message}</Alert.Heading>
              </Alert>
            </Row>
          );
        })}
    </div>
  );
}
