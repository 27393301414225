import { Col, Form, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import IBatchRateWorkerSession, {
  IBatchRateWorker,
} from '@company/types/batch-rate-worker.type';
import { REQUIRED_FIELD_MESSAGE } from '@constants/validation';

interface Props {
  errors: FieldErrors<IBatchRateWorkerSession>;
  register: UseFormRegister<IBatchRateWorkerSession>;
  worker: IBatchRateWorker;
}
function PhotoIsRepresentative({ errors, register, worker }: Props) {
  return (
    <Form.Group controlId="rateWorkerForm.photoIsRepresentative.yes">
      <Row>
        <Col xs={isMobile ? 12 : 6}>
          <Form.Label className="fw-bold">
            Does this picture look like them?*
          </Form.Label>
        </Col>
        <Col xs={isMobile ? 12 : 6} className={`${isMobile ? 'mt-2' : ''}`}>
          <Form.Check
            key="photoIsRepresentative.yes"
            className="border-color-dark"
            inline
            required
            label="Yes"
            {...register(
              `workers.${worker.id}.workerRating.photoIsRepresentative`,
              {
                required: REQUIRED_FIELD_MESSAGE,
              }
            )}
            type="radio"
            value="true"
            id="rateWorkerForm.photoIsRepresentative.yes"
            isInvalid={
              errors?.workers &&
              !!errors?.workers[worker.id]?.workerRating?.photoIsRepresentative
            }
          />
          <Form.Check
            key="photoIsRepresentative.no"
            className="border-color-dark"
            inline
            required
            label="No"
            {...register(
              `workers.${worker.id}.workerRating.photoIsRepresentative`,
              {
                required: REQUIRED_FIELD_MESSAGE,
              }
            )}
            type="radio"
            value="false"
            id="rateWorkerForm.photoIsRepresentative.no"
            isInvalid={
              errors?.workers &&
              !!errors?.workers[worker.id]?.workerRating?.photoIsRepresentative
            }
          />
          {errors?.workers &&
            !!errors?.workers[worker.id]?.workerRating
              ?.photoIsRepresentative && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: 'block  ' }}
              >
                {
                  errors?.workers[worker.id]?.workerRating
                    ?.photoIsRepresentative?.message
                }
              </Form.Control.Feedback>
            )}
        </Col>
      </Row>
    </Form.Group>
  );
}

export default PhotoIsRepresentative;
