import React from 'react';
import ContentLoader from 'react-content-loader';

interface LoaderProps {
  screen: 'mobile' | 'desktop' | 'large-screen';
  imageType?: 'circle' | 'rectangle';
}

function Loader(props: LoaderProps): JSX.Element {
  let height;
  let width;
  const { screen, imageType = 'rectangle' } = props;
  switch (screen) {
    case 'mobile': {
      height = '100';
      width = '400';
      break;
    }
    case 'desktop': {
      height = '100';
      width = '1060';
      break;
    }
    case 'large-screen': {
      height = '150';
      width = '1920';
      break;
    }
    default: {
      height = '100';
      width = '1060';
      break;
    }
  }
  return (
    <ContentLoader
      viewBox={`0 0 ${width} ${height}`}
      height={parseInt(height, 10)}
      width={parseInt(width, 10)}
      {...props}
    >
      {imageType === 'circle' ? (
        <circle cx="60" cy="45" r="30" />
      ) : (
        <rect x="20" y="20" rx="5" ry="5" width="64" height="63" />
      )}
      <rect x="105" y="20" rx="5" ry="5" width="250" height="12" />
      <rect x="105" y="40" rx="5" ry="5" width="180" height="12" />
      <rect x="105" y="60" rx="5" ry="5" width="125" height="12" />
    </ContentLoader>
  );
}

function LoaderPersonCard(): JSX.Element {
  return (
    <>
      {Array(5)
        .fill('')
        .map((_, i) => (
          <Loader screen="desktop" key={`LoaderImageGrid_${i.toString()}`} />
        ))}
    </>
  );
}

export default LoaderPersonCard;
