import { useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Image,
  Modal,
  OverlayTrigger,
  Row,
  Stack,
  Tooltip,
} from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import {
  Endorsement,
  Endorser,
  EventType,
  EventsNumber,
  PersonalityTrait,
} from '@th-types/workerProfile.type';
import { useWorkerProfileContext } from '@worker/state/workerProfileContext';

import '../styles.css';
import { LoaderSquare } from '@components/elements/ComponentLoader';

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;
const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

function Endorsements() {
  const [showModal, setShowModal] = useState(false);
  const [currentEndorsement, setCurrentEndorsement] = useState<
    Endorsement | PersonalityTrait
  >();
  const { workerEndorsements, workerProfile } = useWorkerProfileContext();

  if (!workerEndorsements || !workerProfile) {
    return <LoaderSquare width="100%" height="100%" />;
  }

  const {
    eventTypes = [],
    personalityTraits = [],
    totalEndorsements = 0,
    totalAsManager = 0,
    totalFromCompany = 0,
    eventsNumbers = [],
  } = workerEndorsements ?? {};
  const workTheMostEvent =
    eventTypes.length > 0
      ? eventTypes.reduce((prev: any, current: any) =>
          prev && prev.endorsers.length > current.endorsers.length
            ? prev
            : current
        )
      : null;
  const traitsTheMost =
    personalityTraits.length > 0
      ? personalityTraits.reduce((prev: any, current: any) =>
          prev && prev.endorsers.length > current.endorsers.length
            ? prev
            : current
        )
      : null;

  const openEndorseeBackModal = (
    endorsement: Endorsement | PersonalityTrait
  ) => {
    setShowModal(true);
    setCurrentEndorsement(endorsement);
  };

  const getEndorseBtnText = (endorser: Endorser) => {
    if (endorser.companyName) {
      return 'Rate this company';
    }
    return endorser.isEndorsedBack ? 'Already Endorsed' : 'Endorse Back!';
  };

  return (
    <Card className="worker-profile-card endorsements">
      <Modal
        show={showModal}
        centered
        backdrop
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            {currentEndorsement?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Would you put your own name behind the people who endorsed you? Give
            them credit!
          </p>
          <Stack
            gap={4}
            className="py-4 overflow-auto"
            style={{ maxHeight: '420px' }}
          >
            {currentEndorsement?.endorsers.map((endorser) => (
              <Stack
                key={endorser.endorserId}
                direction="horizontal"
                gap={3}
                style={{ fontSize: '12px' }}
              >
                <Image
                  src={endorser.icon}
                  width={75}
                  height={75}
                  rounded
                  style={{ boxShadow: '0 3px 6px var(--box-shadow)' }}
                />
                <Stack className="justify-content-between align-items-start">
                  <Stack>
                    <b>{endorser.endorserName}</b>
                    <b>
                      {endorser.companyName
                        ? endorser.companyName
                        : endorser.address}
                    </b>
                  </Stack>
                  <Button
                    style={{ textTransform: 'unset', width: '60%' }}
                    size="sm"
                    target="_blank"
                    href={
                      endorser.companyName
                        ? `${BASE_PATH}reviews?companyName=${endorser.companyName}`
                        : `${SERVER_URL}/dashboard/workerEndorsement?endorseWorkerId=${endorser.endorserId}`
                    }
                    disabled={endorser.isEndorsedBack}
                  >
                    {getEndorseBtnText(endorser)}
                  </Button>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Modal.Body>
      </Modal>
      <Card.Title>Endorsements</Card.Title>
      <Card.Text as="div" className="p-3 pb-0">
        <Row md="auto" className="py-3">
          <Col md={4}>
            <b className="d-flex align-items-center">
              Total Endorsements
              <span className="fs-3 ms-3">{totalEndorsements}</span>
            </b>
          </Col>
          <Col md={4}>
            <b className="d-flex align-items-center">
              Total as Manager
              <span className="fs-3 ms-3">{totalAsManager}</span>
            </b>
          </Col>
          <Col md={4}>
            <b className="d-flex align-items-center">
              Total from Companies
              <span className="fs-3 ms-3">{totalFromCompany}</span>
            </b>
          </Col>
        </Row>
        <Row className="align-items-center border-top py-3">
          <Col md={4}>
            <b>How many events have you worked with them?</b>
          </Col>
          <Col>
            <Stack direction="horizontal">
              {eventsNumbers.map((stat: EventsNumber) => (
                <Stack key={stat.label} gap={1} className="align-items-center">
                  <b>{stat.label}</b>
                  <div style={{ width: 40, height: 40 }}>
                    <CircularProgressbar
                      value={stat.percentage || 0}
                      text={`${
                        stat.percentage ? stat.percentage.toFixed(0) : '0'
                      }%`}
                      styles={buildStyles({
                        textSize: '26px',
                        pathColor: `green`,
                        textColor: '#000',
                      })}
                    />
                  </div>
                </Stack>
              ))}
            </Stack>
          </Col>
        </Row>
        {workTheMostEvent && (
          <Row className="border-top py-3">
            <Stack gap={2}>
              <b>Types of events worked?</b>
              <div>
                <Badge className="text-dark badge" bg="light-success">
                  {workerProfile.firstName} has worked at{' '}
                  <i>{workTheMostEvent.title}</i> events the most.
                </Badge>
              </div>
              <Row>
                {eventTypes.map((eventType: EventType) => (
                  <Col md={4} key={eventType.title}>
                    <b>{eventType.title}</b>
                    <Stack direction="horizontal" gap={1} className="my-2">
                      {eventType.endorsers.map((endorser: Endorser) => (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip>
                              {endorser.companyName
                                ? endorser.companyName
                                : endorser.endorserName}
                            </Tooltip>
                          }
                          key={endorser.endorserId}
                        >
                          <Image
                            src={endorser.icon}
                            width={22}
                            height={22}
                            roundedCircle
                            className="cursor-point"
                            onClick={() => openEndorseeBackModal(eventType)}
                          />
                        </OverlayTrigger>
                      ))}
                    </Stack>
                  </Col>
                ))}
              </Row>
            </Stack>
          </Row>
        )}
        {traitsTheMost && (
          <Row className="border-top pt-3">
            <Stack gap={2}>
              <b>Traits that best describe worker?</b>
              <div>
                <Badge className="text-dark badge" bg="light-success">
                  Most of people that have worked with {workerProfile.firstName}{' '}
                  would describe them as <i>{traitsTheMost.title}</i>.
                </Badge>
              </div>
              <Row>
                {personalityTraits.map((trait: PersonalityTrait) => (
                  <Col md={4} key={trait.title}>
                    <b>{trait.title}</b>
                    <Stack direction="horizontal" gap={1} className="my-2">
                      {trait.endorsers.map((endorser: Endorser) => (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip>
                              {endorser.companyName
                                ? endorser.companyName
                                : endorser.endorserName}
                            </Tooltip>
                          }
                          key={endorser.endorserId}
                        >
                          <Image
                            src={endorser.icon}
                            width={22}
                            height={22}
                            roundedCircle
                            className="cursor-point"
                            onClick={() => openEndorseeBackModal(trait)}
                          />
                        </OverlayTrigger>
                      ))}
                    </Stack>
                  </Col>
                ))}
              </Row>
            </Stack>
          </Row>
        )}
      </Card.Text>
    </Card>
  );
}

export default Endorsements;
