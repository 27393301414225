export interface CompanyCapability {
  id: number;
  companyId: number;
  validUntil: Date;
  capabilityType: CapabilityType;
  capabilityStatus: string;
  capabilityIdentifier: string;
  dateCreated: Date;
}

export enum CapabilityType {
  SPONSORED_BLAST = 'SPONSORED_BLAST',
  REPORTING = 'REPORTING',
  OLD_COMPANY_JOB_PAGE = 'OLD_COMPANY_JOB_PAGE',
  SCHEDULING_V2_ACCESS = 'SCHEDULING_V2_ACCESS',
}
