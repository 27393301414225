import { ActivityDetail } from '@th-types/daily-activity.type';
import IResultResponse from '@th-types/resultResponse.type';
import { AddHours } from '@th-types/time-sheet.type';
import { privateApi } from '@services/api';

export const MAIN_URL = 'jobs';

export const approveTimeSheet = async (jobId: number, timeSheetId: number) => {
  try {
    const result = await privateApi.post<IResultResponse>(
      `${MAIN_URL}/${jobId}/timesheets/${timeSheetId}/approve`
    );
    return result.data;
  } catch (error: any) {
    console.log(
      `Occurred an error when Trusted Herd tried approve a time sheet. jobId: ${jobId} \ntimeSheetId: ${timeSheetId}`
    );
    console.log(error);
    return error.response.data;
  }
};

export const updateSubmittedTimeSheet = async (
  jobId: number,
  timeSheetId: number,
  submitHoursObj: AddHours
) => {
  try {
    const result = await privateApi.post<IResultResponse>(
      `${MAIN_URL}/${jobId}/timesheets/${timeSheetId}/update-submitted-hours`,
      submitHoursObj
    );
    return result.data;
  } catch (error: any) {
    console.log(
      `Occurred an error when Trusted Herd tried approve a time sheet. jobId: ${jobId} \ntimeSheetId: ${timeSheetId}`
    );
    console.log(error);
    return error.response.data;
  }
};

export const submitTimeSheetHours = async (
  jobAssignmentId: number,
  shiftId: number,
  addHoursObj: AddHours
) => {
  try {
    const result = await privateApi.post<IResultResponse>(
      `${MAIN_URL}/${jobAssignmentId}/${shiftId}/add-hours`,
      addHoursObj
    );
    return result.data;
  } catch (error: any) {
    console.log(
      `Occurred an error when Trusted Herd tried to submit hours for a time sheet. jobAssignmentId: ${jobAssignmentId} \nshiftId: ${shiftId}`
    );
    console.log(error);
    return error.response.data;
  }
};

export const markShiftsAsPaid = async (jobId: number, shiftIds: number[]) => {
  const result = await privateApi
    .post<IResultResponse>(`${MAIN_URL}/${jobId}/shifts/mark-paid`, {
      shiftIds,
    })
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried mark shifts as paid. jobId: ${jobId} \nshiftIds: ${shiftIds}`
      );
      console.log(error);
      return error.response;
    });

  return result.data;
};

export const fetchTimeSheetDetails = async (
  jobAssignmentId: number,
  shiftId: number
) => {
  try {
    const url = `assignments/${jobAssignmentId}/shifts/${shiftId}/daily-activity`;
    const result = await privateApi.get<ActivityDetail>(url);
    return result.data;
  } catch (error) {
    console.log(
      `Occurred an error when Trusted Herd tried fetch a time sheet details. jobAssignmentId: ${jobAssignmentId} \n shiftPositionId: ${shiftId}`
    );
    return Promise.reject(error);
  }
};

export const onboardingCompleted = async (
  jobAssignmentIds: number[],
  completed: boolean
) => {
  const url = `assignments/complete-onboarding`;
  const result = await privateApi
    .post<IResultResponse>(url, {
      jobAssignmentIds,
      completed,
    })
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried mark onboarding as completed. jobAssignmentIds: ${jobAssignmentIds}`
      );
      console.log(error);
      return error.response;
    });

  return result.data;
};
