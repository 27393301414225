import { BsChatDots } from '@react-icons/all-files/bs/BsChatDots';
import { Button } from 'react-bootstrap';
import './style.css';

interface DirectMessageButtonProps {
  openDirectMessageChat: () => void;
  disabled: boolean;
  isSearchTalent?: boolean;
}

export default function DirectMessageButton({
  openDirectMessageChat,
  disabled,
  isSearchTalent = false,
}: DirectMessageButtonProps) {
  const renderButton = () => (
    <Button
      disabled={disabled}
      size="sm"
      variant="light"
      className={`fw-bolder ${isSearchTalent ? 'w-100' : ''} dm-button`}
      onClick={openDirectMessageChat}
    >
      <BsChatDots size={isSearchTalent ? 10 : 18} />
      <b style={isSearchTalent ? { fontSize: '9px' } : {}}>&nbsp;MESSAGE</b>
    </Button>
  );

  return renderButton();
}
