import { Button } from 'react-bootstrap';
import './styles.scss';
import { JobAssignment } from '@th-types/job-assignment.type';
import { useActivityContext } from '@company/state/activityContext';

interface ButtonMarkAllOnboardingAsCompletedProps {
  jobAssignments: JobAssignment[];
}

export default function ButtonMarkAllOnboardingAsCompleted({
  jobAssignments,
}: ButtonMarkAllOnboardingAsCompletedProps) {
  const { completeOnboardingAction } = useActivityContext();

  return (
    <Button
      variant="light"
      size="sm"
      disabled={jobAssignments.every((item) => item.onBoardingCompleted)}
      onClick={() => completeOnboardingAction(jobAssignments)}
    >
      MARK ALL AS ONBOARDED
    </Button>
  );
}
