import { privateApi } from '@services/api';
import IApplicationData, {
  IJobApplicationCounters,
} from '@th-types/job-assignment.type';
import ApplicantFilter from '@company/types/applicant-filter.type';
import IResultResponse from '@th-types/resultResponse.type';
import IJobPlacementData from '@company/types/job-placement.type';

export const MAIN_URL = 'jobs';

export function getParams(filters: ApplicantFilter, page = 0, max = 10) {
  const params: any = {
    database: filters.database?.join(',') ?? undefined,
    gender: filters.gender?.join(',') ?? undefined,
    country: filters.country?.join(',') ?? undefined,
    willingToTravel: filters.willingToTravel ?? undefined,
    travelLongerPeriod: filters.travelLongerPeriod ?? undefined,
    nameOrEmail:
      filters.nameOrEmail
        .split(' ')
        .filter((x) => x !== '')
        .join(',') ?? '',
    sortBy: filters.sortBy,
    order: filters.order,
    offset: page,
    max,
  };

  Object.keys(params).forEach(
    (key) =>
      (params[key] === undefined || params[key] === '') && delete params[key]
  );

  return { params };
}

export const fetchApplicantAppliedList = async (
  jobId: number,
  filters: ApplicantFilter,
  page = 0,
  max = 10
) => {
  const url = `${MAIN_URL}/${jobId}/applicants`;
  const config = getParams(filters, page * max, max);
  const result = await privateApi.get<IApplicationData>(url, config);
  return result.data;
};

export const fetchApplicantOfferedList = async (
  jobId: number,
  filters: ApplicantFilter,
  page = 0,
  max = 10
) => {
  const url = `${MAIN_URL}/${jobId}/offers`;
  const config = getParams(filters, page * max, max);
  const result = await privateApi.get<IJobPlacementData>(url, config);
  return result.data;
};

export const fetchAllCandidates = async (
  jobId: number,
  filters: ApplicantFilter,
  page = 0,
  max = 10
) => {
  const url = `${MAIN_URL}/${jobId}/candidates`;
  const config = getParams(filters, page * max, max);
  const result = await privateApi.get<IApplicationData>(url, config);
  return result.data;
};

export const fetchApplicantHiredList = async (
  jobId: number,
  filters: ApplicantFilter,
  page = 0,
  max = 10
) => {
  const url = `${MAIN_URL}/${jobId}/hired`;
  const config = getParams(filters, page * max, max);
  const result = await privateApi.get<IApplicationData>(url, config);
  return result.data;
};

export const fetchApplicantHiddenList = async (
  jobId: number,
  filters: ApplicantFilter,
  page = 0,
  max = 10
) => {
  const url = `${MAIN_URL}/${jobId}/hidden`;
  const config = getParams(filters, page * max, max);
  const result = await privateApi.get<IApplicationData>(url, config);
  return result.data;
};

export const fetchApplicationCounters = async (jobId: number) => {
  const url = `${MAIN_URL}/${jobId}/counters`;
  const result = await privateApi.get<IJobApplicationCounters>(url);
  return result.data;
};

export const hideApplicant = async (jobAssignmentId: number, hide: boolean) => {
  const result = privateApi
    .post<IResultResponse>(`assignments/${jobAssignmentId}/hide`, null, {
      params: { hide },
    })
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried  ${hide ? 'hide' : 'unhide'}
         the applicant. jobAssignmentId: ${jobAssignmentId}`
      );
      console.log(error);
    })
    .then((response) => ({
      data: {
        success: response,
      },
    }));
  return result;
};
