interface BriefcaseProps {
  fillColor: string;
}
function BriefcaseIcon({ fillColor }: BriefcaseProps) {
  return (
    <svg
      width="34"
      height="28"
      viewBox="0 0 34 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="briefcase-icon"
    >
      <g clipPath="url(#clip0_19912_1686)">
        <path
          d="M30.4144 4.48435H24.938V2.87746C24.938 1.29095 23.6471 0 22.0606 0H11.4884C9.90189 0 8.61094 1.29095 8.61094 2.87746V4.48435H3.1346C1.47675 4.48435 0.128052 5.83305 0.128052 7.4909V24.9901C0.128052 26.6479 1.47675 28 3.1346 28H30.4144C32.0722 28 33.4209 26.6479 33.4209 24.9901V7.4909C33.4209 5.83305 32.0722 4.48435 30.4144 4.48435ZM9.96984 2.87746C9.96984 2.04174 10.6493 1.36229 11.4884 1.36229H22.0606C22.8997 1.36229 23.5792 2.04174 23.5792 2.87746V4.48435H9.96984V2.87746ZM32.062 24.9901C32.062 25.9005 31.3215 26.6411 30.4144 26.6411H3.1346C2.22754 26.6411 1.48695 25.9005 1.48695 24.9901V11.2313H5.13557V13.7248C5.13557 14.1019 5.43793 14.4043 5.81502 14.4043H10.2382C10.6119 14.4043 10.9177 14.1019 10.9177 13.7248V11.2313H22.6313V13.7248C22.6313 14.1019 22.9371 14.4043 23.3108 14.4043H27.734C28.1111 14.4043 28.4134 14.1019 28.4134 13.7248V11.2313H32.062V24.9901ZM6.49447 13.0454V11.2313H9.55877V13.0454H6.49447ZM23.9902 13.0454V11.2313H27.0545V13.0454H23.9902ZM32.062 9.87236H1.48695V7.4943C1.48695 6.58384 2.22754 5.84324 3.1346 5.84324H30.4144C31.3215 5.84324 32.062 6.58384 32.062 7.4909V9.87236Z"
          fill="#232223"
        />
        <path
          d="M32.062 11.2312V24.99C32.062 25.9005 31.3214 26.6411 30.4144 26.6411H3.1346C2.22754 26.6411 1.48694 25.9005 1.48694 24.99V11.2312H5.13557V13.7248C5.13557 14.1019 5.43792 14.4042 5.81501 14.4042H10.2382C10.6119 14.4042 10.9177 14.1019 10.9177 13.7248V11.2312H22.6313V13.7248C22.6313 14.1019 22.9371 14.4042 23.3108 14.4042H27.734C28.1111 14.4042 28.4134 14.1019 28.4134 13.7248V11.2312H32.062Z"
          fill={fillColor}
        />
        <path
          d="M27.0545 11.2312H23.9902V13.0453H27.0545V11.2312Z"
          fill="white"
        />
        <path
          d="M9.55881 11.2312H6.49451V13.0453H9.55881V11.2312Z"
          fill="white"
        />
        <path
          d="M23.5792 2.87747V4.48436H9.96985V2.87747C9.96985 2.04175 10.6493 1.3623 11.4884 1.3623H22.0606C22.8997 1.3623 23.5792 2.04175 23.5792 2.87747Z"
          fill="white"
        />
        <path
          d="M32.062 7.49092V9.87238H1.48694V7.49432C1.48694 6.58386 2.22754 5.84326 3.1346 5.84326H30.4144C31.3214 5.84326 32.062 6.58386 32.062 7.49092Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_19912_1686">
          <rect
            width="33.2929"
            height="28"
            fill="white"
            transform="translate(0.128052)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BriefcaseIcon;
