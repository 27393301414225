export interface ITag {
  label: string;
  bg: string;
  bgText: string;
  className?: string;
}

export const IN_NETWORK_TAG = {
  label: 'IN-NETWORK',
  bg: 'primary',
  bgText: 'text-dark',
  className: 'cursor-default',
};
