export const TEN_MINUTES = 10 * 60 * 1000;
export const MINUTE = 60 * 1000;
export const SECOND = 1000;

export const PaymentRating = [
  { index: 6, value: 5, title: '< 1 week' },
  { index: 7, value: 5, title: '1-3 weeks' },
  { index: 8, value: 4.25, title: '3-5 weeks' },
  { index: 9, value: 3, title: '6-8 weeks' },
  { index: 1, value: 1, title: '9+ weeks' },
  { index: null, value: null, title: 'Not Yet Paid' },
];

export type IPaymentRating = (typeof PaymentRating)[0];
