import { useMemo, useRef, useState } from 'react';
import { BsChevronDown } from '@react-icons/all-files/bs/BsChevronDown';
import {
  Accordion,
  Badge,
  Button,
  Col,
  Collapse,
  InputGroup,
  Modal,
  Row,
  Stack,
} from 'react-bootstrap';
import Avatar from 'react-avatar';
import { debounce } from 'lodash';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { BiFilterAlt } from 'react-icons/bi';
import { BsChevronUp } from 'react-icons/bs';
import { GrLocation } from '@react-icons/all-files/gr/GrLocation';
import IApplicationData from '@th-types/job-assignment.type';
import QueryKeys from '@constants/queryKeys';
import DATE_FORMATS from '@constants/dateFormat';
import { formatLocalDate } from '@utils/DateUtils';
import { fetchAllCandidates } from '@company/services/application/api';
import {
  OFFER_SHIFT,
  getFormattedTime,
  offerShifts,
} from '@services/shift/shiftService';
import { useJobContext } from '@company/state/jobContext';
import { useJobShiftContext } from '@company/state/jobShiftContext';
import ApplicantFilters from '@company/components/ApplicantFilters';
import SearchTalentInput from '@company/components/SearchTalentInput/SearchTalentInput';
import './styles.scss';
import ThLoading from '@components/elements/ThLoading';
import { useJobWorkerShiftContext } from '@company/state/jobWorkerShiftContext';
import { IWorkerData } from '@th-types/worker.type';

function OfferShiftMobileModal({
  handleClose,
  shiftOfferedCloseModal,
}: {
  handleClose: () => void;
  shiftOfferedCloseModal: (isSuccess: boolean, message: string) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [showApplicantFilters, setShowApplicantFilters] = useState(false);
  const [showWorkerSelection, setShowWorkerSelection] = useState(false);
  const [talentSearchValue, setTalentSearchValue] = useState('');
  const { job, activeJobId, key } = useJobContext();
  const {
    selectedJobWorkerShifts,
    isJobWorkerSideModalOpen,
    setSelectedJobWorkerShifts,
  } = useJobWorkerShiftContext();
  const {
    selectedShifts,
    selectedWorker,
    applicantFilters,
    setSelectedShifts,
    setApplicantFilters,
  } = useJobShiftContext();
  const [workerToOffer, setWorkerToOffer] = useState<IWorkerData | undefined>(
    selectedWorker
  );
  const shiftList = useMemo(() => {
    return isJobWorkerSideModalOpen ? selectedJobWorkerShifts : selectedShifts;
  }, [isJobWorkerSideModalOpen, selectedJobWorkerShifts, selectedShifts]);

  const { data } = useQuery<IApplicationData, AxiosError>({
    queryKey: [`${QueryKeys.SHIFT_WORKERS_TO_OFFER}_${activeJobId}_${key}`],
    queryFn: () => fetchAllCandidates(activeJobId, applicantFilters, 0, 1000),
    enabled: activeJobId > 0,
  });

  const candidates = useMemo(() => {
    if (data) {
      return selectedWorker
        ? data.results.filter((item) => item.worker.id === selectedWorker.id)
        : data.results;
    }
    return [];
  }, [data, selectedWorker]);

  const toggleWorkerSelection = () => {
    setShowWorkerSelection((prevState) => !prevState);
  };

  const handleTextFilters = useRef(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setApplicantFilters({ ...applicantFilters, [name]: value });
    }, 1000)
  ).current;

  const handleSearchTalentInputOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setTalentSearchValue(value);
    handleTextFilters(event);
  };

  const submitShiftOffers = async () => {
    if (workerToOffer) {
      try {
        setIsLoading(true);
        const shiftIds = shiftList.map((x) => x.id);
        const result = await offerShifts(
          activeJobId,
          workerToOffer.id,
          shiftIds
        );
        shiftOfferedCloseModal(result.success, result.message);
        setSelectedShifts([]);
        setSelectedJobWorkerShifts([]);
      } catch (error) {
        console.error('Error trying to offer shifts to a worker:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal size="lg" show centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Offer a Shift</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <ThLoading />}
        <Row className="m-1 fw-bold">To</Row>
        <Stack className="offer-shift-form-mobile">
          <Stack direction="horizontal" gap={2} className="p-2 bb-1">
            <span className="w-100" onClick={toggleWorkerSelection}>
              {workerToOffer ? workerToOffer.fullName : 'Select Worker'}
            </span>
            {showWorkerSelection ? (
              <BsChevronUp
                color="var(--black)"
                onClick={toggleWorkerSelection}
              />
            ) : (
              <BsChevronDown
                color="var(--black)"
                onClick={toggleWorkerSelection}
              />
            )}
          </Stack>
          <Collapse in={showWorkerSelection}>
            <div>
              <Stack direction="horizontal" className="p-2 bb-1" gap={2}>
                <SearchTalentInput
                  placeholder="Search"
                  name="nameOrEmail"
                  value={talentSearchValue}
                  onChange={handleSearchTalentInputOnChange}
                />
                <Button
                  type="button"
                  variant={showApplicantFilters ? 'primary' : 'light'}
                  onClick={() =>
                    setShowApplicantFilters((prevState) => !prevState)
                  }
                >
                  <BiFilterAlt size={20} />
                </Button>
              </Stack>
            </div>
          </Collapse>
          <Collapse in={showWorkerSelection && showApplicantFilters}>
            <div>
              <div className="bb-1 p-2">
                <ApplicantFilters
                  applicantFilters={applicantFilters}
                  setApplicantFilters={setApplicantFilters}
                />
              </div>
            </div>
          </Collapse>
          <Collapse in={showWorkerSelection}>
            <Stack>
              {candidates.map((jobAssignment) => (
                <Stack
                  key={jobAssignment.id}
                  direction="horizontal"
                  gap={3}
                  className="bb-1 p-2"
                >
                  <Avatar
                    src={jobAssignment.worker.profileImageUrl}
                    name={`${jobAssignment.worker.firstName} ${jobAssignment.worker.lastName}`}
                    size="40"
                    round
                  />
                  <Stack gap={1}>
                    <span className="fs-6 fw-bold">{`${jobAssignment.worker.firstName} ${jobAssignment.worker.lastName}`}</span>
                    <Stack direction="horizontal" gap={2} className="badges">
                      <Badge className="bg-secondary">database</Badge>
                      <Badge className="bg-blue">onboarded</Badge>
                    </Stack>
                    <Stack direction="horizontal">
                      <GrLocation />
                      <span>{jobAssignment.distance.toFixed(1)} miles</span>
                    </Stack>
                  </Stack>
                  <InputGroup.Checkbox
                    value={jobAssignment.id}
                    checked={workerToOffer?.id === jobAssignment.worker.id}
                    onChange={(e) => {
                      setWorkerToOffer(
                        e.target.checked ? jobAssignment.worker : undefined
                      );
                    }}
                  />
                </Stack>
              ))}
            </Stack>
          </Collapse>
        </Stack>
        <Collapse in={!showWorkerSelection}>
          <Stack className="mt-3">
            <span className="fw-bold fs-6 text-uppercase">Position</span>
            <Accordion defaultActiveKey="0" className="shifts-accordion">
              {shiftList.map((shift) => (
                <Accordion.Item key={shift.id} eventKey={shift.id.toString()}>
                  <Accordion.Header className="my-4">
                    <span>{shift.title.name}</span>
                    <span className="mx-1">-</span>
                    <span>
                      {formatLocalDate(
                        new Date(shift.date),
                        DATE_FORMATS.MONTH_AND_DAY
                      )}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body className="py-1">
                    <Row className="my-1">
                      <Col className="shift-data-label">date</Col>
                      <Col className="shift-data-value">{shift.date}</Col>
                    </Row>
                    <Row className="my-1">
                      <Col className="shift-data-label">
                        scheduled shift time
                      </Col>
                      <Col className="shift-data-value">
                        {getFormattedTime(shift, job!.address.timezoneId)}
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col className="shift-data-label">full/back up</Col>
                      <Col className="shift-data-value">{shift.type}</Col>
                    </Row>
                    <Row className="my-1">
                      <Col className="shift-data-label">wage</Col>
                      <Col className="shift-data-value">
                        {shift.wage.payRate}/{shift.wage.payRatePeriod}
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col className="shift-data-label">paid break</Col>
                      <Col className="shift-data-value">
                        {shift.breakPaid ? 'Yes' : 'No'}
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Stack>
        </Collapse>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button variant="light" onClick={handleClose}>
          CANCEL
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={submitShiftOffers}
          disabled={workerToOffer === undefined}
        >
          {OFFER_SHIFT}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OfferShiftMobileModal;
