import { Button, Col, Row } from 'react-bootstrap';
import IActionButton from '@th-types/action-button.type';

interface Params {
  actions: IActionButton[];
  canManageWorker: boolean;
}

export default function ActionButtons({ actions, canManageWorker }: Params) {
  return (
    <Row>
      {actions.map((action: any) => {
        return (
          <Col key={action.label} className="p-1 mt-1">
            <Button
              disabled={action.disabled || !canManageWorker}
              key={action.label}
              variant={action.variant || 'light'}
              size={action.size || 'sm'}
              className="fw-bolder w-100 ps-0 pe-0"
              onClick={action.action}
            >
              <>
                {action.icon}
                <b style={{ fontSize: 9 }}>&nbsp;{action.label}</b>
              </>
            </Button>
          </Col>
        );
      })}
    </Row>
  );
}
