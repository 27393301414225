import { useForm, Controller } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button, CloseButton, Col, Form, Row, Stack } from 'react-bootstrap';
import { ThError, ThLoading } from '@components/elements';
import QueryKeys from '@constants/queryKeys';
import IWorkerFiltersData from '@company/types/workerFilters.type';
import Select from 'react-select';
import * as api from '@company/services/worker/api';
import SelectOption from '@th-types/selectOption.type';
import IFilterData from '@company/types/talent-filterdata.type';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

interface Params {
  handleClose: () => void;
  advancedFilters: IFilterData | undefined;
  setAdvancedFilters: React.Dispatch<React.SetStateAction<IFilterData>>;
  onSearch: () => void;
  filterByStatus?: boolean;
}

function AdvancedFilters({
  handleClose,
  advancedFilters,
  setAdvancedFilters,
  onSearch,
  filterByStatus,
}: Params): JSX.Element {
  const { register, handleSubmit, reset, control } = useForm<IFilterData>({
    defaultValues: advancedFilters,
  });

  useEffect(() => {
    if (isEmpty(advancedFilters)) {
      reset();
    }
  }, [advancedFilters, reset]);

  const filterWorkers = [
    { value: 'ALL', label: 'All' },
    { value: 'FAVORITE', label: 'Favorite' },
    { value: 'BLOCKED', label: 'Blocked' },
  ];

  const workerStatus = [
    { value: 'HIRED', label: 'Hired' },
    { value: 'OFFERED', label: 'Offered' },
    { value: 'APPLIED', label: 'Applied' },
    { value: 'SHARED', label: 'Shared' },
  ];

  const { isLoading, error, data } = useQuery<IWorkerFiltersData, AxiosError>({
    queryKey: [`${QueryKeys.WORKER_FILTERS}`],
    queryFn: () => api.fetchWorkerFilters(),
    staleTime: Infinity, // cache query as long as app is open
  });

  if (isLoading) return <ThLoading />;

  if (error) {
    if (error.response?.status) {
      return <>No data found</>;
    }
    return <ThError message={error.message} />;
  }

  const mountSelect = (
    arr: any,
    field1: any,
    field2: any
  ): { value: number | string; label: string }[] => {
    return arr.map((item: any) => {
      return { value: item[field1], label: item[field2] };
    });
  };

  const mountDefault = (
    arr: any,
    fieldArray: 'skills' | 'eventTypes' | 'traits' | 'badges',
    fieldValue: any,
    fieldLabel: any
  ): { value: number | string; label: string }[] => {
    return arr
      ?.map((item: any) => {
        const arrayToSearch: any[] = data![fieldArray];
        return arrayToSearch.find((s: any) => item === s[fieldValue]);
      })
      ?.map((item: any) => {
        return {
          value: item[fieldValue],
          label: item[fieldLabel],
        } as SelectOption;
      });
  };

  const handleOnChangeValue = (
    value: string | number | (string | number)[] | undefined,
    name: string
  ) => {
    setAdvancedFilters((prevState: IFilterData) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = (dataRequest: IFilterData) => {
    setAdvancedFilters(dataRequest);
  };

  return (
    <div className="p-3">
      <Row>
        <Col md={10}>
          <b>Advanced Filter</b>
        </Col>
        <Col md={2} className="text-end">
          <CloseButton onClick={() => handleClose()} />
        </Col>
        <hr className="mt-3" />
        {/* TODO Add this field when Databased Workers are ready */}
        {/* <Col md={12}>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label={<b>In Database</b>} />
          </Form.Group>
        </Col> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="groupForm">
              <Form.Label>
                <b>Favorite/Blocked Workers</b>
              </Form.Label>
              <Controller
                control={control}
                name="filterWorkers"
                render={({ field: { value, name, ref } }) => (
                  <Select
                    name={name}
                    ref={ref}
                    options={filterWorkers}
                    value={filterWorkers.find((c) => c.value === value)}
                    onChange={(val) => handleOnChangeValue(val?.value, name)}
                  />
                )}
              />
            </Form.Group>
          </Col>

          {filterByStatus && (
            <Col md={12}>
              <Form.Group className="mb-3" controlId="hiredForm">
                <Form.Label>
                  <b>Worker status</b>
                </Form.Label>
                <Controller
                  control={control}
                  name="workerStatus"
                  render={({ field: { value, name, ref } }) => (
                    <Select
                      name={name}
                      ref={ref}
                      options={workerStatus}
                      value={workerStatus.find((c) => c.value === value)}
                      onChange={(val) => handleOnChangeValue(val?.value, name)}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          )}
          <Col md={12}>
            <Form.Group className="mb-3" controlId="skillsForm">
              <Form.Label>
                <b>Skills</b>
              </Form.Label>
              <Controller
                control={control}
                name="skills"
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    ref={ref}
                    name={name}
                    options={mountSelect(data!.skills, 'name', 'name')}
                    defaultValue={mountDefault(
                      advancedFilters?.skills,
                      'skills',
                      'name',
                      'name'
                    )}
                    value={
                      value === undefined
                        ? []
                        : mountSelect(data!.skills, 'name', 'name').find(
                            (c: SelectOption) => value.includes(+c.value)
                          )
                    }
                    onChange={(val) => {
                      onChange(val.map((c) => c.value));
                      handleOnChangeValue(
                        val.map((c) => c.value),
                        name
                      );
                    }}
                    isMulti
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="eventTypeForm">
              <Form.Label>
                <b>Types of Events Worked</b>
              </Form.Label>
              <Controller
                control={control}
                name="eventTypes"
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    ref={ref}
                    name={name}
                    options={mountSelect(data!.eventTypes, 'id', 'name')}
                    defaultValue={mountDefault(
                      advancedFilters?.eventTypes,
                      'eventTypes',
                      'id',
                      'name'
                    )}
                    value={
                      value === undefined
                        ? []
                        : mountSelect(data!.eventTypes, 'id', 'name').find(
                            (c: SelectOption) =>
                              value.includes(c.value.toString())
                          )
                    }
                    onChange={(val) => {
                      onChange(val.map((c) => c.value));
                      handleOnChangeValue(
                        val.map((c) => c.value),
                        name
                      );
                    }}
                    isMulti
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="traitsForm">
              <Form.Label>
                <b>Traits That best describe worker</b>
              </Form.Label>
              <Controller
                control={control}
                name="traits"
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    ref={ref}
                    name={name}
                    options={mountSelect(data!.traits, 'id', 'name')}
                    defaultValue={mountDefault(
                      advancedFilters?.traits,
                      'traits',
                      'id',
                      'name'
                    )}
                    value={
                      value === undefined
                        ? []
                        : mountSelect(data!.traits, 'id', 'name').find(
                            (c: SelectOption) =>
                              value.includes(c.value.toString())
                          )
                    }
                    onChange={(val) => {
                      onChange(val.map((c) => c.value));
                      handleOnChangeValue(
                        val.map((c) => c.value),
                        name
                      );
                    }}
                    isMulti
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="badgesForm">
              <Form.Label>
                <b>Badges</b>
              </Form.Label>
              <Controller
                control={control}
                name="badges"
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    ref={ref}
                    name={name}
                    options={mountSelect(data!.badges, 'id', 'name')}
                    defaultValue={mountDefault(
                      advancedFilters?.badges,
                      'badges',
                      'id',
                      'name'
                    )}
                    value={
                      value === undefined
                        ? []
                        : mountSelect(data!.badges, 'id', 'name').filter(
                            (c: SelectOption) => value.includes(+c.value)
                          )
                    }
                    onChange={(val) => {
                      onChange(val.map((c) => c.value));
                      handleOnChangeValue(
                        val.map((c) => c.value),
                        name
                      );
                    }}
                    isMulti
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="profileStatusForm">
              <Form.Label>
                <b>Profile Status</b>
              </Form.Label>
              <Controller
                control={control}
                name="profileStatus"
                render={({ field: { value, name, ref } }) => (
                  <Select
                    name={name}
                    ref={ref}
                    options={mountSelect(data!.profileStatus, 'name', 'name')}
                    value={mountSelect(
                      data!.profileStatus,
                      'name',
                      'name'
                    ).find((c: SelectOption) => c.value === value)}
                    onChange={(e) => handleOnChangeValue(e?.value, name)}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="countryForm">
              <Form.Label>
                <b>Country</b>
              </Form.Label>
              <Stack direction="horizontal">
                {data!.countries.map((country) => (
                  <Form.Check
                    key={country.type}
                    inline
                    label={country.name}
                    type="checkbox"
                    value={country.type}
                    {...register('countries')}
                    onChange={(e) => {
                      const val = e.target.value;
                      const { countries = [] } = advancedFilters!;
                      const filteredCountries = countries.find((c) => c === val)
                        ? countries.filter((c) => c !== val)
                        : [...countries, val];
                      handleOnChangeValue(filteredCountries, 'countries');
                    }}
                  />
                ))}
              </Stack>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="travelForm">
              <Form.Label>
                <b>Willing to travel nationally?</b>
              </Form.Label>
              <Stack direction="horizontal">
                <Form.Check
                  inline
                  label="Yes"
                  {...register('travelNationally')}
                  type="radio"
                  value="Yes"
                  onChange={(e) =>
                    handleOnChangeValue(e.target.value, 'travelNationally')
                  }
                />
                <Form.Check
                  inline
                  label="No"
                  {...register('travelNationally')}
                  type="radio"
                  value="No"
                  onChange={(e) =>
                    handleOnChangeValue(e.target.value, 'travelNationally')
                  }
                />
              </Stack>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="travelLongerForm">
              <Form.Label>
                <b>Willing to travel for long periods?</b>
              </Form.Label>
              <Stack direction="horizontal">
                <Form.Check
                  inline
                  label="Yes"
                  {...register('travelLonger')}
                  type="radio"
                  value="Yes"
                  onChange={(e) =>
                    handleOnChangeValue(e.target.value, 'travelLonger')
                  }
                />
                <Form.Check
                  inline
                  label="No"
                  {...register('travelLonger')}
                  type="radio"
                  value="No"
                  onChange={(e) =>
                    handleOnChangeValue(e.target.value, 'travelLonger')
                  }
                />
              </Stack>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="genderForm">
              <Form.Label>
                <b>Gender</b>
              </Form.Label>
              <Stack direction="horizontal">
                {data!.genres.map((gender) => (
                  <Form.Check
                    key={gender.name}
                    inline
                    label={gender.name}
                    {...register('gender')}
                    type="radio"
                    value={gender.name}
                    onChange={(e) =>
                      handleOnChangeValue(e.target.value, 'gender')
                    }
                  />
                ))}
              </Stack>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Button
              variant="primary ms-auto"
              className="w-100"
              onClick={onSearch}
            >
              <b>SEARCH</b>
            </Button>
          </Col>
        </form>
      </Row>
    </div>
  );
}

export default AdvancedFilters;
