import { privateApi } from '@services/api';
import { HTTP_UNAUTHORIZED } from '@constants/httpStatus';

export default async function getCompany() {
  try {
    const response = await privateApi.request({
      method: 'GET',
      url: '/company/current',
      withCredentials: true,
    });
    return response.data;
  } catch (error: any) {
    if (error?.status === HTTP_UNAUTHORIZED) {
      return {
        loggedIn: false,
      };
    }
    if (error?.response?.data) {
      return error.response.data;
    }
    // let caller handle
    throw error;
  }
}
