import { Badge, Row, Stack } from 'react-bootstrap';
import { RiArrowUpDownFill } from '@react-icons/all-files/ri/RiArrowUpDownFill';
import { AiOutlineFileAdd } from '@react-icons/all-files/ai/AiOutlineFileAdd';
import './styles.css';
import { HiOutlineShare } from '@react-icons/all-files/hi/HiOutlineShare';
import { FiEye } from '@react-icons/all-files/fi/FiEye';
import { Link } from 'react-router-dom';
import IJobDetailData from '@th-types/job.detail.type';
import { format } from 'date-fns';
import DATE_FORMATS from '@constants/dateFormat';
import { JobCompletedEnum } from '@constants/jobCompletedEnum';
import ModalCompleteJob from '@company/pages/JobOverview/Detail/Modal/ModalCompleteJob';

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

interface Params {
  jobList: IJobDetailData[];
  type: JobCompletedEnum;
  showGrouped: boolean;
}

export default function JobTableWeb({ jobList, type, showGrouped }: Params) {
  return (
    <Row>
      <table className={`col-md-12 ${!showGrouped && 'table-content'}`}>
        <thead>
          <tr>
            {type === JobCompletedEnum.COMPLETED && (
              <th className="t-head-left">
                DATE COMPLETED{' '}
                {!showGrouped && (
                  <span>
                    <RiArrowUpDownFill size={20} color="gray" />
                  </span>
                )}
              </th>
            )}
            <th className="t-head-left">
              Event Name{' '}
              {!showGrouped && (
                <span>
                  <RiArrowUpDownFill size={20} color="gray" />
                </span>
              )}
            </th>
            <th>
              Position{' '}
              {!showGrouped && (
                <span>
                  <RiArrowUpDownFill size={20} color="gray" />
                </span>
              )}
            </th>
            <th>
              Location{' '}
              {!showGrouped && (
                <span>
                  <RiArrowUpDownFill size={20} color="gray" />
                </span>
              )}
            </th>
            <th>
              Dates{' '}
              {!showGrouped && (
                <span>
                  <RiArrowUpDownFill size={20} color="gray" />
                </span>
              )}
            </th>
            <th>
              Internal{' '}
              {!showGrouped && (
                <span>
                  <RiArrowUpDownFill size={20} color="gray" />
                </span>
              )}
            </th>
            <th>
              Filled Positions{' '}
              {!showGrouped && (
                <span>
                  <RiArrowUpDownFill size={20} color="gray" />
                </span>
              )}
            </th>
            <th>
              Pending{' '}
              {!showGrouped && (
                <span>
                  <RiArrowUpDownFill size={20} color="gray" />
                </span>
              )}
            </th>
            <th>
              Hidden{' '}
              {!showGrouped && (
                <span>
                  <RiArrowUpDownFill size={20} color="gray" />
                </span>
              )}
            </th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {jobList.map(
            ({
              id,
              start,
              end,
              eventName,
              nationalJob,
              address,
              position,
              totalPositions,
              jobStatus,
              datePublished,
            }) => (
              <tr className={`pt-5 ${!showGrouped && 't-rows'}`} key={id}>
                {type === JobCompletedEnum.COMPLETED && (
                  // TODO This is another field
                  <td className="t-head-left">
                    {format(new Date(start), DATE_FORMATS.DATE_FORMAT)}
                  </td>
                )}
                <td className="t-head-left">
                  {/* TODO Find the best way to link to Job Details */}
                  <Link
                    style={{ textDecoration: 'none', color: 'unset' }}
                    to={`${BASE_PATH}jobs/${id}`}
                  >
                    {eventName}
                    {!datePublished && (
                      <Badge className="ms-2" bg="secondary" text="dark">
                        Draft
                      </Badge>
                    )}
                  </Link>
                </td>
                <td>{position}</td>
                <td>{nationalJob ? 'National Job' : address?.googleAddress}</td>
                <td className="t-head-left">
                  <p className="mt-3">
                    <strong>Start</strong> {/* TODO: use local job date time */}
                    <span>
                      {format(new Date(start), DATE_FORMATS.DATE_FORMAT_SHORT)}
                    </span>
                    <br />
                    <strong>End</strong>{' '}
                    <span>
                      {format(new Date(end), DATE_FORMATS.DATE_FORMAT_SHORT)}
                    </span>
                  </p>
                </td>
                {/* TODO map this mocked fields */}
                <td>2 (Mocked)</td>
                <td>{totalPositions}</td>
                <td>3 (Mocked)</td>
                <td>5 (Mocked)</td>
                <td>
                  {type === JobCompletedEnum.COMPLETED ? (
                    <AiOutlineFileAdd size={24} className="me-2" />
                  ) : (
                    <Stack direction="horizontal" gap={2}>
                      <>
                        <HiOutlineShare size={20} />
                        <FiEye size={20} />
                        <ModalCompleteJob
                          key={id}
                          jobId={id}
                          jobPosition={position}
                          jobStatus={jobStatus}
                          showIcon
                          iconSize={20}
                        />
                      </>
                    </Stack>
                  )}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </Row>
  );
}
