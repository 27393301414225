import {
  IActiveJob,
  IActiveJobData,
  IActiveJobs,
} from '@th-types/active-jobs.type';
import { fetchActiveJobs } from '@company/services/job/api';
import {
  DropdownListItem,
  DropdownListItemEmpty,
} from '@components/elements/DropdownList';
import { JobAssignment } from '@th-types/job-assignment.type';

export interface FetchActiveJobsListParams {
  shareJobToWorker: (jobId: number, workerId: number) => Promise<void>;
}
export default function useFetchJobList() {
  const getJobLabel = (job: IActiveJob) =>
    `${job.position} - ${job.address?.city}/${
      job.address?.state.ansiAbbreviation ?? ''
    }`;

  const getJobKey = (job: IActiveJob) =>
    `${job.position} ${job.address?.city} ${
      job.address?.state.ansiAbbreviation ?? ''
    }`;

  return (workerId: number) => {
    const fetchActiveJobsList = async ({
      shareJobToWorker,
    }: FetchActiveJobsListParams): Promise<
      DropdownListItem[] | DropdownListItemEmpty
    > => {
      const getTag = (jobAssignment: JobAssignment) => {
        const tag = {
          status: jobAssignment.status,
          className:
            jobAssignment.status === 'SHARED'
              ? 'light bg-primary'
              : 'text-success bg-light-success',
        };
        return tag;
      };
      const { results: activeJobs }: IActiveJobData =
        await fetchActiveJobs(workerId);
      if (!activeJobs || activeJobs.length === 0) {
        return {
          label: 'No published jobs to share',
        } as DropdownListItemEmpty;
      }
      return activeJobs.map(({ job, jobAssignment }: IActiveJobs) => ({
        header: job.eventName,
        label: getJobLabel(job),
        key: getJobKey(job),
        id: job.id,
        tag: jobAssignment ? getTag(jobAssignment) : undefined,
        action: jobAssignment ? null : () => shareJobToWorker(job.id, workerId),
      })) as DropdownListItem[];
    };
    return { fetchActiveJobsList };
  };
}
