export default interface ICompanyPremiumPlans {
  publishableKey: string;
  plans: CompanyPremiumPlan[];
}

export interface CompanyPremiumPlan {
  id: string;
  name: string;
  billingPeriod: BillingPeriod;
  description: string;
  price: number;
}

export enum BillingPeriod {
  MONTHLY = 'monthly',
  HALF_YEARLY = 'halfYearly',
  YEARLY = 'yearly',
}
