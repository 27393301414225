import { Col, Button } from 'react-bootstrap';
import completeIcon from '@assets/survey/complete.svg';
import './styles.scss';
import { useSurveyContext } from '@worker/state/surveyContext';

function SurveyIntro({
  surveyAttemptLimit,
  fetchSurveyLink,
}: {
  surveyAttemptLimit: boolean;
  fetchSurveyLink: () => void;
}) {
  const { profileData, startSurveyDisabled, isLoadingProfile } =
    useSurveyContext();
  const hasProfileData = Object.keys(profileData?.profileData || {}).length > 0;

  return (
    <Col>
      <h1>
        <span className="survey-title">Earn Money</span>{' '}
        <span style={{ fontSize: '1.5rem' }}>Taking Online Surveys</span>
      </h1>
      <p className="body-text">
        In our quest to bring you more opportunities to{' '}
        <b>earn money on Trusted Herd</b>, we’re working with leaders of the
        online survey industry to do just that! While Trusted Herd is not
        responsible for the content of the surveys, nor do we facilitate them,
        each completed survey can be worth <b>$0.50</b> to <b>$5</b> if you
        match the persona they’re seeking.
      </p>
      <ul>
        <li>
          <i>
            To start, please fill out the initial questionnaire to help our
            partners bring you the right survey matches.
          </i>
        </li>
      </ul>
      <span className="initial-questionaire">Initial Questionnaire</span>
      {!hasProfileData ? (
        <p className="body-text">
          Click below to start your baseline questionnaire, this helps present
          the right survey opportunities to you.
        </p>
      ) : (
        <span className="completion-status ms-3">
          <img src={completeIcon} alt="completion" />
          <span className="completion-status button">COMPLETED</span>
        </span>
      )}
      {!hasProfileData && (
        <div className="mt-2">
          <Button
            className="fw-bold"
            onClick={fetchSurveyLink}
            disabled={
              isLoadingProfile || startSurveyDisabled || surveyAttemptLimit
            }
          >
            START NOW
          </Button>
        </div>
      )}
      <p className="text-muted mt-1">
        To improve your survey experience, we store profiling data. If you wish
        to opt-out, please reach out to us at:{' '}
        <strong>support@trustedherd.com</strong>.
      </p>
    </Col>
  );
}

export default SurveyIntro;
