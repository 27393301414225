import { Button, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import {
  IBatchRateWorker,
  IWorkerRatingData,
} from '@company/types/batch-rate-worker.type';

interface Props {
  worker?: IBatchRateWorker;
  workerInfoData: IWorkerRatingData;
}
function WorkerNavigationButtons({
  worker,
  workerInfoData: {
    loading,
    workerOptions,
    activeWorkerIdx,
    allWorkersCompleted,
    onPrevious,
    hasNextWorker,
    canSubmitForm,
    navigateToNextWorker,
  },
}: Props) {
  const continueButtonText = loading ? 'Submitting' : 'Continue';
  const isFirstOrOnlyWorker =
    workerOptions.length === 1 || activeWorkerIdx === 0;
  const previousButtonWidth = isFirstOrOnlyWorker ? 'w-25' : '';

  const hasMoreWorkersAndNotFirst =
    !isMobile && workerOptions.length > 1 && activeWorkerIdx > 0;

  const canMoveToNextWorker = hasNextWorker(0) && canSubmitForm() && worker;

  const renderTooltip = (
    <Tooltip id="button-tooltip">
      All workers need to be rated to continue
    </Tooltip>
  );

  const moveToNextWorker = () => {
    if (!isMobile && canMoveToNextWorker) {
      navigateToNextWorker();
    }
  };

  // TODO: work in a better way to avoid render OverlayTrigger when not needed
  const renderNextWorkerOrContinueButton = () => {
    return (
      <div className={`${isMobile ? 'w-50' : 'w-25'}`}>
        <Button
          type={!isMobile && canMoveToNextWorker ? 'button' : 'submit'}
          form="rateWorkerForm"
          className="fw-bold mt-4 w-100"
          disabled={
            loading ||
            (!isMobile && !hasNextWorker(1) && !allWorkersCompleted())
          }
          onClick={moveToNextWorker}
        >
          {!isMobile && hasNextWorker(1) ? 'Next Worker' : continueButtonText}
        </Button>
      </div>
    );
  };

  return (
    <Stack
      direction="horizontal"
      className="d-flex justify-content-center mb-5 w-100"
      gap={3}
    >
      <Button
        type="button"
        form="rateWorkerForm"
        variant="light"
        className={`fw-bold mt-4 ${isMobile ? 'w-50' : previousButtonWidth}`}
        onClick={onPrevious}
      >
        {hasMoreWorkersAndNotFirst ? 'Previous Worker' : 'Go Back'}
      </Button>
      {!isMobile && !hasNextWorker(1) && !allWorkersCompleted() ? (
        <OverlayTrigger placement="top" overlay={renderTooltip}>
          {renderNextWorkerOrContinueButton()}
        </OverlayTrigger>
      ) : (
        renderNextWorkerOrContinueButton()
      )}
    </Stack>
  );
}

export default WorkerNavigationButtons;
