import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ShiftPosition } from '@th-types/job.detail.type';
import { isDateAfterOrNow } from '@utils/DateUtils';
import { ShiftStatus } from '@th-types/jobs.type';
import { useJobContext } from './jobContext';
import { useJobShiftContext } from './jobShiftContext';

interface IJobWorkerShiftContext {
  showOfferShiftModal: boolean;
  jobWorkerStatusFilter: string;
  isJobWorkerSideModalOpen: boolean;
  jobWorkerShifts: ShiftPosition[];
  selectedJobWorkerShifts: ShiftPosition[];
  setShowOfferShiftModal: (show: boolean) => void;
  setJobWorkerStatusFilter: (status: string) => void;
  setIsJobWorkerSideModalOpen: (isOpen: boolean) => void;
  setJobWorkerShifts: (filteredShifts: ShiftPosition[]) => void;
  setSelectedJobWorkerShifts: (selectedShifts: ShiftPosition[]) => void;
}

interface ProviderProps {
  children: React.ReactNode;
}

const JobWorkerShiftContext: IJobWorkerShiftContext | any = createContext({});

export function JobWorkerShiftProvider({ children }: ProviderProps) {
  const { shifts, workersFilter, selectedWorker } = useJobShiftContext();
  const { jobTimezone } = useJobContext();
  const [isJobWorkerSideModalOpen, setIsJobWorkerSideModalOpen] =
    useState(false);
  const [jobWorkerShifts, setJobWorkerShifts] = useState<ShiftPosition[]>([]);
  const [selectedJobWorkerShifts, setSelectedJobWorkerShifts] = useState<
    ShiftPosition[]
  >([]);
  const [showOfferShiftModal, setShowOfferShiftModal] = useState(false);
  const [jobWorkerStatusFilter, setJobWorkerStatusFilter] = useState<string>(
    ShiftStatus.OPEN.toLowerCase()
  );

  useEffect(() => {
    if (!isJobWorkerSideModalOpen) {
      setSelectedJobWorkerShifts([]);
      setJobWorkerShifts([]);
      setJobWorkerStatusFilter('');
    }
  }, [isJobWorkerSideModalOpen]);

  useEffect(() => {
    const filterAssignedShifts = () => {
      return jobWorkerStatusFilter !== ShiftStatus.OPEN;
    };

    const refreshFilteredShifts = () => {
      if (shifts && selectedWorker) {
        let filteredData: ShiftPosition[] = shifts.filter(
          (item) =>
            item.shiftStatus !== ShiftStatus.OPEN ||
            (item.shiftStatus === ShiftStatus.OPEN &&
              isDateAfterOrNow(item.start, jobTimezone))
        );
        if (jobWorkerStatusFilter) {
          filteredData = filteredData.filter(
            (d) => jobWorkerStatusFilter === d.shiftStatus
          );
        }
        if (filterAssignedShifts()) {
          filteredData = filteredData.filter(
            (item) =>
              item.jobAssignment &&
              item.jobAssignment.worker.id === selectedWorker.id
          );
        }
        setJobWorkerShifts(filteredData);
      }
    };
    refreshFilteredShifts();
  }, [
    shifts,
    jobTimezone,
    workersFilter,
    selectedWorker,
    jobWorkerStatusFilter,
  ]);

  const contextValue: IJobWorkerShiftContext = useMemo(() => {
    return {
      showOfferShiftModal,
      jobWorkerStatusFilter,
      jobWorkerShifts,
      selectedJobWorkerShifts,
      isJobWorkerSideModalOpen,
      setShowOfferShiftModal,
      setJobWorkerStatusFilter,
      setSelectedJobWorkerShifts,
      setJobWorkerShifts,
      setIsJobWorkerSideModalOpen,
    };
  }, [
    showOfferShiftModal,
    jobWorkerStatusFilter,
    jobWorkerShifts,
    selectedJobWorkerShifts,
    isJobWorkerSideModalOpen,
    setShowOfferShiftModal,
    setJobWorkerStatusFilter,
    setSelectedJobWorkerShifts,
    setJobWorkerShifts,
    setIsJobWorkerSideModalOpen,
  ]);

  return (
    <JobWorkerShiftContext.Provider value={contextValue}>
      {children}
    </JobWorkerShiftContext.Provider>
  );
}

export function useJobWorkerShiftContext() {
  const context: IJobWorkerShiftContext = useContext(JobWorkerShiftContext);
  if (context === undefined) {
    throw new Error(
      'useJobContext must be used within a JobProvider. Wrap a parent component in <JobProvider> to fix this error.'
    );
  }
  return context;
}
